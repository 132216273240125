import { computed, defineComponent, PropType } from 'vue';
import { BetTypeEnum, gameTypeDefault } from '@/common'; //RouteEnum
import { StoreChampData, useAuth } from '@/hooks'; //useDetailHook

import { useBet } from '@/hooks/use365Bet.hook';
import ChampOdds from './odds/index';
import { isEmpty } from 'lodash-es';
import './champ-item.less';

// '让球', '大小', '独赢'

export default defineComponent({
  name: 'ChampItem',
  props: {
    data: {
      type: Object as PropType<StoreChampData>,
      default: () => ({})
    },
    betType: {
      type: Number,
      default: BetTypeEnum.proposition
    },
    gameTypeActive: {
      type: Number,
      default: gameTypeDefault
    },
    targetTypeActive: {
      type: Number,
      default: 0
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    isChildShow: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openPop'],
  setup(props) {
    const { addTicket, betTickets } = useBet(true);
    const { isLogin } = useAuth();
    // 显示购物车

    const showCart = computed(() => {
      return !isEmpty(betTickets.value) && isLogin.value;
    });
    return () => (
      <div class={['champ-info', showCart.value ? 'expand' : null]}>
        <div class={'champ-info-header'}>
          <div class={'league-name'}>
            <span>{props.data.LeagueName}</span>
          </div>
        </div>
        <div class={'champ-data'}>
          {props.data?.matches?.map(items => (
            <div class={'leagueBox'}>
              {items.oddset.map(odd => (
                <>
                  <div class={'matcheName'}>{odd.BettypeName}</div>
                  <ChampOdds
                    onAddBetTicket={sel =>
                      addTicket({
                        ...sel,
                        leagueName: props.data.LeagueName,
                        isChamp: true,
                        matchTime: items.ShowTime
                      })
                    }
                    oddset={odd}
                    sportId={props.data.SportType}
                    matchId={items.MatchId}
                    gameTypeActive={props.gameTypeActive}
                  />
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
});
