import { ActionContext } from 'vuex';
import { getSportUnionData } from '@/services';
interface VideoAnimateState {
  videoAndAnimationData: {
    animate: Map<string | number, string>;
    video: Map<string | number, string>;
  };
}

const initState = (): VideoAnimateState => ({
  videoAndAnimationData: {
    animate: new Map(),
    video: new Map()
  }
});

const state: VideoAnimateState = initState();

const getters = {
  getVideoByMid: (state: VideoAnimateState) => (mid: number | string) => {
    return state.videoAndAnimationData.video.get(Number(mid));
  },
  getAnimateyByMid: (state: VideoAnimateState) => (mid: number | string) => state.videoAndAnimationData.animate.get(mid)
};

const actions = {
  /**
   * 获取比赛媒体消息（直播视频、动画）
   */
  async GET_SPORT_MEDIA_DATA({ commit }: ActionContext<VideoAnimateState, any>) {
    const [res, data] = await getSportUnionData();
    const multimedia = {
      animate: new Map(),
      video: new Map()
    };
    if (res && typeof data === 'object') {
      data.video2.forEach((item: any) => {
        // const key = source === 'im' ? item.imId : item.sbId;
        item.imId && multimedia.video.set(item.imId, item.videoListUrl);
        item.sbId && multimedia.video.set(item.sbId, item.videoListUrl);
        item.omId && multimedia.video.set(item.omId, item.videoListUrl);
      });
      data.animate.forEach((item: any) => {
        //const key = source === 'im' ? item.imId : item.sbId;
        item.imId && multimedia.animate.set(item.imId, item.url);
        item.sbId && multimedia.animate.set(item.sbId, item.url);
        item.omId && multimedia.animate.set(item.omId, item.url);
      });
    }
    commit('SET_SPORT_MEDEA_DATA', multimedia);
    return multimedia;
  }
};

// mutations
const mutations = {
  SET_SPORT_MEDEA_DATA(state: VideoAnimateState, payload: any) {
    state.videoAndAnimationData = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
