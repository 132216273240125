/**
 *  比赛与盘口数据
 */
import { reactive } from 'vue';
import {
  DataTypeEnum,
  MarketType,
  _formatMatchInfo,
  _formatHomeMarketOdds,
  _formatHomeMarketOddsChamp
} from '@/common';
import { OddsetChamp, SelChamp } from '@/services/kg-api';
import { ChampionMarket, QueryEventsChampions } from '@/services/kg-api';

import { useFavortMatch } from '@/hooks/use365mobileFavortMatch.hook';
import { useSportOptions, TimerEnum, useTimerHook } from '@/hooks';
import { map, isEmpty } from 'lodash-es';

// import { useStore } from 'vuex';

export interface StoreChamps {
  id: string;
  sportId: number;
  current?: number;
  time: string;
  matchTime: string;
  homeId: number;
  awayId: number;
  date: string;
  leagueName: string;
  sportName: string;
  market: string;
  count: number;
  score: number[];
  sections?: [number, number][];
  video?: boolean;
  animate?: boolean;
  isMark: boolean;
  Number: any;
  tech?: {
    ht: [number, number];
    red: [number, number];
    yellow: [number, number];
    corner?: [number, number];
  };
  moreInfo: any;
  RunTime: number;
  ShowTime?: any;
  animateUrl: string;
  gameStatus: number;
  programmeId: string;
  programmeName: string;
  leagues: EventsAndMarketsChamp[];
  key: string;
  matches: MatchChamp[];
  oddset: OddsetChamp[];
  sels: SelChamp[];
}

export interface StoreChampData {
  programmeId: string;
  programmeName: string;
  leagues: EventsAndMarketsChamp[];
  sportId: number;
  id: number;
  key: string;
  matches: MatchChamp[];
  oddset: OddsetChamp[];
  sels: SelChamp[];
  matchData: string | number;
  info: StoreChamps;
  LeagueId: string;
  LeagueName: string;
  SportType: number;
}

export interface EventsAndMarketsChamp {
  info: StoreChamps;
  LeagueId: string;
  LeagueName: string;
  SportType: number;
  matches: MatchChamp[];
}

export interface MatchChamp {
  sportId: number | undefined;
  GameStatus: string;
  MatchId: string;
  ShowTime: string;
  OutrightEventName: string;
  oddset: OddsetChamp[];
}

export interface mainChamp {
  programmeId: string;
  programmeName: string;
  leagues: EventsAndMarketsChamp[];
}

interface EventChamp {
  /**
   * 请求参数
   */
  form: ChampionMarket;
  /**
   * 下拉加载
   * pull loading
   */
  loading: boolean;
  /**
   * 下拉加载
   * request loading
   */
  requestLoading: boolean;
  /**
   * 转换市场数据
   */
  champList: StoreChampData[];
}

// const store = useStore();

const storeMain = reactive<EventChamp>({
  form: {
    oddsType: MarketType.Europe,
    sportId: 1,
    periodId: DataTypeEnum.championLeague,
    leagueIds: null,
    queryCount: 100
  },
  loading: true,
  requestLoading: false,
  champList: []
});

function getStore() {
  return reactive<EventChamp>({
    form: {
      oddsType: MarketType.Europe,
      sportId: 1,
      periodId: DataTypeEnum.championLeague,
      leagueIds: null,
      queryCount: 100
    },
    requestLoading: false,
    loading: true,
    champList: []
  });
}

export function EventsChampMarket(useOnlyStore = true) {
  let store = storeMain;
  if (useOnlyStore) {
    store = getStore();
  }
  const { optionsStore } = useSportOptions();
  const { addTimer } = useTimerHook();
  const { clearMark } = useFavortMatch();

  /**
   * 获取市场数据
   * {isPull: 是否为下拉}
   */
  const getEventsAndChamp = async (isPull = false) => {
    if (isPull) {
      store.loading = true;
    }
    store.requestLoading = true;
    store.form.oddsType = optionsStore.value.marketType;
    const fn = async () => {
      const [res, data] = await QueryEventsChampions(store.form);
      if (isPull) {
        // 强制给他搞个延迟
        setTimeout(() => {
          store.loading = false;
        }, 1000);
      }
      store.requestLoading = false;
      store.champList = [];
      if (res) {
        clearMark(store.form, data);
        const tempList: any[] = [];
        // 转换数据
        if (!isEmpty(data)) {
          map(data.data, (items: any) => {
            const result = {
              SportType: items.SportType,
              LeagueName: items.LeagueName,
              LeagueId: items.LeagueId,
              matches: items.matches.map((match: MatchChamp) => {
                return {
                  ShowTime: match.ShowTime,
                  OutrightEventName: match.OutrightEventName,
                  GameStatus: match.GameStatus,
                  MatchId: match.MatchId,
                  oddset: match.oddset
                };
              })
            };
            tempList.push(result);
          });
        } else {
          store.champList = [];
        }
        store.champList = tempList;
        // await vueStore.dispatch('GET_SOCCERBALL_TECH_IM', data);
      }
    };
    addTimer(TimerEnum.LIST, fn, Number(store.form.periodId) === DataTypeEnum.championLeague ? 6000 : 60 * 1000);
  };

  return {
    eventChamp: store,
    getEventsAndChamp
  };
}
