import { defineComponent, PropType } from 'vue';
import { SelType } from '@/services/im-api';
import { OddsPrice } from '365-mobile/components';
import { HomeMarketOdds, _keyNameByBettype, BetTypeEnum, gameTypeDefault } from '@/common';
import { hasTicketId } from '@/hooks/use365Bet.hook';
import './odds.less';
import { useDetailHook } from '@/hooks';

export default defineComponent({
  name: 'MatchOdds',
  props: {
    oddset: {
      type: Array as PropType<HomeMarketOdds[]>,
      default: () => ['', '', '', '', '', '']
    },
    matchId: {
      type: String,
      required: true
    },
    sportId: {
      type: Number
    },
    betType: {
      type: Number,
      default: BetTypeEnum.proposition
    },
    gameTypeActive: {
      type: Number,
      default: gameTypeDefault
    }
  },
  emits: ['addBetTicket'],
  setup(props, { emit }) {
    const { store } = useDetailHook();
    const LockOddsRender = () => (
      <div class="lock-odds">
        <svg-icon name="lock" />
      </div>
    );

    const NoneOddsRender = () => <div class="none-odds">-</div>;

    return () => (
      <div class="odds-swiper">
        <div class="odds-swiper-container">
          {props.oddset[props.gameTypeActive - 1].sels.map((sel: SelType, i) => (
            <div
              class="odds-swiper-container-item"
              style={props.gameTypeActive == 3 ? 'width: 100%' : 'width: 50%'}
              key={i}
            >
              {!sel ? (
                <div class="odds-item">
                  <NoneOddsRender />
                </div>
              ) : sel.locked || sel.Price <= 0 ? (
                <div class="odds-item">
                  <LockOddsRender />
                </div>
              ) : (
                <div
                  class={[
                    'odds-item',
                    hasTicketId({
                      matchId: props.matchId,
                      marketId: props.oddset[props.gameTypeActive - 1].OddsId,
                      wagerSelectionId: sel.WagerSelectionId
                    })
                      ? 'selected'
                      : null
                  ]}
                  onClick={() =>
                    emit('addBetTicket', {
                      betType: props.oddset[props.gameTypeActive - 1].Bettype,
                      betTypeName: props.oddset[props.gameTypeActive - 1].BettypeName,
                      matchId: props.matchId,
                      marketId: props.oddset[props.gameTypeActive - 1].OddsId,
                      sportType: props.sportId,
                      oddsType: sel.OddsType,
                      wagerSelectionId: sel.WagerSelectionId,
                      price: sel.Price,
                      periodId: props.oddset[props.gameTypeActive - 1].PeriodId,
                      point: sel.Point,
                      key: sel.Key,
                      keyName: sel.KeyName,
                      combo: props.oddset[props.gameTypeActive - 1].Combo,
                      outrightTeamId: 0,
                      EuropePrice: sel.EuropePrice,
                      matchTime: store.info.matchTime,
                      allowBetBuilder: props.oddset[props.gameTypeActive - 1].allowBetBuilder
                    })
                  }
                >
                  <p>{_keyNameByBettype(props.oddset[props.gameTypeActive - 1].uiType, sel, true)}</p>
                  <OddsPrice type="background" value={sel.Price} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
});
