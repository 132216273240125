import type { App } from 'vue';
import { createStore, Store } from 'vuex';
import { Modules } from './types';

import sportsTech from './modules/sportTech';
import user from './modules/user';
import videoAndAnimation from './modules/videoAndAnimation';

const env = process.env;
const isDev = env.NODE_ENV === 'development';

const store: Store<Modules> = createStore({
  strict: isDev,
  modules: {
    videoAndAnimation,
    sportsTech,
    user
  }
});

export function setupStore(app: App<Element>) {
  app.use(store);
}

export default store;
