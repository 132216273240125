// 语种   标识
// 泰语    th
// 越南    vi
// 印尼    id
// 英语    en
// 马来    ms
// 日语   ja
// 韩语    ko
// 缅甸   my
// 土耳其  tr
// 阿拉伯  ar
// 西班牙  es
// 葡萄牙  pt
// 繁体   zh-TW
export type LocaleType =
  | 'th'
  | 'vi'
  | 'en'
  | 'ja'
  | 'ko'
  | 'my'
  | 'tr'
  | 'ar'
  | 'es'
  | 'pt-PT'
  | 'zh-TW'
  | 'zh-CN'
  | 'id'
  | 'ms'
  | 'pt';
import { Recordable } from '@/types/global';

export interface LocaleSetting {
  showPicker: boolean;
  // Current language
  locale: LocaleType;
  // default language
  fallback: LocaleType;
  // available Locales
  availableLocales: LocaleType[];
}

export const LOCALE: { [key: string]: LocaleType } = {
  ZH_CN: 'zh-CN',
  EN_US: 'en',
  VN: 'vi',
  IN: 'id',
  PT: 'pt'
};

export const localeSetting: LocaleSetting = {
  showPicker: true,
  // Locale
  locale: LOCALE.EN_US,
  // Default locale
  fallback: LOCALE.EN_US,
  // available Locales
  availableLocales: [LOCALE.ZH_CN, LOCALE.EN_US, LOCALE.VN, LOCALE.IN, LOCALE.PT]
};

export interface LangModule {
  message: Recordable;
  momentLocale: Recordable;
  momentLocaleName: string;
}

export const loadLocalePool: LocaleType[] = [];

export const languageList =
  (require('./lang_list.json') as {
    info: string;
    localLanguage: string;
    seriNo: string | any;
  }[]) || [];
