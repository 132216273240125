/**
 * 关注比赛
 */
import { computed, ComputedRef } from 'vue';

import { pull } from 'lodash-es';
import { FAVORT_MATCHS_KEY } from '@/common';
import { RemovableRef, useStorage } from '@vueuse/core';
import { useSportTypes, useI18n } from '@/hooks';
import { Toast } from '@/components';

const FAVORT_KEY = FAVORT_MATCHS_KEY;

interface Match {
  matchId: string;
  sportId: number;
}
export interface useFavortMatchRn {
  mark: (matchInfo: Match) => void;
  isMarkedById: (matchInfo: Match) => boolean;
  clearMark: (form: any, matches?: any) => void;
  markMatchsCount: ComputedRef<number>;
}

export const localMarketedMatchs: RemovableRef<Map<number, string[]>> = useStorage(FAVORT_KEY, new Map());

export function useFavortMatch(): useFavortMatchRn {
  const { updateMatchMarks } = useSportTypes();
  // 关注总数量
  const markMatchsCount = computed(() => {
    let count = 0;
    const deepList = localMarketedMatchs.value;
    deepList.forEach((e, i) => {
      if (i === -1) return;
      count = count + e.length;
    });
    return count;
  });
  /**
   * 关注比赛
   */
  const mark = async ({ matchId, sportId }: Match) => {
    const ids = localMarketedMatchs.value.get(sportId) || [];
    const { t } = useI18n();
    if (!sportId || !matchId || ids.length < 0) return;
    // 是否已关注
    const isExist = ids.includes(matchId);
    if (isExist) {
      pull(ids, matchId);
    } else {
      ids.push(matchId);
    }

    const message = isExist ? t('lang.sport_common_unMark') : t('lang.sport_common_markSuccess');
    Toast(message);
    localMarketedMatchs.value.set(sportId, ids);
    updateMatchMarks();
  };

  const isMarkedById = ({ matchId, sportId }: Match) => {
    const ids = localMarketedMatchs.value.get(sportId) || [];
    if (!sportId || !matchId || ids.length === 0) return false;
    return ids.includes(matchId);
  };

  const clearMark = (form, matches) => {
    if (!form || form.periodId !== 0 || !form.sportId) return;
    const { eventIds } = form;
    if (!eventIds || !Array.isArray(matches) || matches.length === 0) {
      return localMarketedMatchs?.value?.clear();
    }
    const res = [] as any;
    matches.forEach(item => {
      (item.matches || []).forEach(item => {
        res.push(item.MatchId);
      });
    });
    localMarketedMatchs?.value?.set(+form.sportId, res);
  };

  return {
    mark,
    markMatchsCount,
    isMarkedById,
    clearMark
  };
}
