import Cookies from 'js-cookie';
import JSEncrypt from 'jsencrypt';
// const STORE_TOKEN = `ACCESS_TOKEN_${isMobile ? 'H5' : 'WEB'}`;

// 是否为子应用
const isSubApp = !!window.__POWERED_BY_QIANKUN__;

export const CACHE_TOKEN_KEY = isSubApp ? 'h5-token' : 'SP_SAVE_TOKEN';
export const CACHE_ACCOUNT = isSubApp ? 'h5-uid' : 'SP_SAVE_ACCOUNT';
export const CACHE_JUMP_TOKEN = 'SP_SAVE_JUMP_TOKEN';

interface AuthInfo {
  token: string | undefined;
  account: string | undefined;
  jumpToken?: string;
  expireIn?: number;
}

// 获取登录信息
export function getLoginData() {
  // const data = {
  //   token: Cookies.get(CACHE_TOKEN_KEY),
  //   account: Cookies.get(CACHE_ACCOUNT),
  //   jumpToken: Cookies.get(CACHE_JUMP_TOKEN)
  // };
  return {
    token: !!localStorage.getItem('token') ? localStorage.getItem('token') + '' : '',
    jumpToken: null
  };
}

// 设置登录信息
export function setLoginData({ account, token, jumpToken }: AuthInfo) {
  Cookies.set(CACHE_TOKEN_KEY, String(token), {
    expires: 1
  });
  Cookies.set(CACHE_ACCOUNT, account || '', {
    expires: 1
  });
  Cookies.set(CACHE_JUMP_TOKEN, jumpToken || '', {
    expires: 1
  });
}

// 删除登录信息
export function clearLoginData() {
  localStorage.removeItem('token');
  Cookies.remove(CACHE_TOKEN_KEY);
  Cookies.remove(CACHE_ACCOUNT);
  Cookies.remove(CACHE_JUMP_TOKEN);
}

/**
 * rsa加密
 */
//  导入RSA加密
export function getRSAcode(str: string) {
  // 注册方法
  const pubKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDUDogpslAbLYUhBOGc3T928j8KU0BomMEzgKfQGFRUNrSZl83Or+/CqktPNB+QnsZd2+YQ1SATQoS3lIGuOmkFFkJTe/iydARMk7OPzLah3lfPANHflphN5gNUFzv8ydak2Ja9m+ult6s0tLTrBKUBXMmVST2TcBwXHfpuG7Sa3wIDAQAB`; // ES6 模板字符串 引用 rsa 公钥
  const encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubKey); // 设置 加密公钥
  const data = encryptStr.encrypt(str.toString()); // 进行加密
  return data;
}
