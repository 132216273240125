import { defineComponent, computed } from 'vue';
import { useI18n, useSportOptions } from '@/hooks';
import { useBet } from '@/hooks/use365Bet.hook';
import { SportIcon, SvgIcon } from '@/components';

import style from './style/result.module.less';

export default defineComponent({
  setup() {
    const { state, singleData, singleAllBet, singleAllWin, parlayData, handleDoneBet, showHandicapName, prebetInfo } =
      useBet();
    const { t } = useI18n();
    const sportData = computed(() => {
      return singleData.value.filter(item => item.betType);
    });
    const { optionsStore } = useSportOptions();
    const currencySymbol = computed(() => {
      return optionsStore.value.currency;
    });
    const SingleDataRender = () => (
      <section class={style.body}>
        <header class={style.header}>
          <div class={style.item}>
            <span>{t('lang.sport_bet_singBets')}</span>
            <span>
              <SvgIcon name="bet_success" />
              {state.betResultList.length}
              {t('lang.sport_bet_betsNumber')}
            </span>
          </div>
          <div class={style.item}>
            <span>{t('lang.sport_bet_betAmount')}</span>
            <span>
              {singleAllBet.value}({currencySymbol.value})
            </span>
          </div>
          <div class={style.item}>
            <span>{t('lang.sport_bet_winAmount')}</span>
            <span>
              {singleAllWin.value}({currencySymbol.value})
            </span>
          </div>
        </header>
        <div class={style.title}>{t('lang.sport_bet_betSucced')}</div>
        <main class={style.content}>
          {sportData?.value.map(item => {
            return (
              <div>
                <div class={[style.contentTitle]}>
                  <SportIcon sportId={item.sportType} size="18" />
                  {item.keyName} {item.point}
                  <span class={style.odds}>@{item.price}</span>
                  <span class={style.oddType}>
                    <SvgIcon name="bet_success" />
                    {showHandicapName(item.odds_type)}
                  </span>
                </div>
                <p class={style.information}>
                  {item.periodId === 3 ? <span>{t('lang.sport_handicap_live')} </span> : null} {item.betTypeName}
                </p>
                {item.isChamp ? (
                  <p class={style.information} title={item.leagueName}>
                    {item.leagueName}
                  </p>
                ) : (
                  <p
                    class={style.information}
                    title={`${item.home}vs${item.away} ${
                      item.periodId !== null && item.live_home_score != undefined && item.live_away_score != undefined
                        ? `(${item.live_home_score}-${item.live_away_score})`
                        : ''
                    }`}
                  >
                    {item.home} VS {item.away}
                    {item.periodId !== null &&
                    item.live_home_score != undefined &&
                    item.live_away_score != undefined ? (
                      <span>{`(${item.live_home_score}-${item.live_away_score})`}</span>
                    ) : null}
                  </p>
                )}
                <footer class={style.contentFooter}>
                  <div class={style.item}>
                    <span>{t('lang.sport_bet_betAmount')}</span>
                    <span class={style.amount}>
                      {item.stake}
                      <span>{currencySymbol.value}</span>
                    </span>
                  </div>
                  <div class={style.item}>
                    <span>{t('lang.sport_bet_winAmount')}</span>
                    <span class={style.amount}>
                      {item.winAmount}
                      <span>{currencySymbol.value}</span>
                    </span>
                  </div>
                </footer>
              </div>
            );
          })}
        </main>
      </section>
    );
    const BetComoResultRender = () => (
      <div>
        {parlayData.value.map(item => {
          return (
            <section class={style.body} key={item.comboType}>
              <header class={style.header}>
                <div class={style.item}>
                  {t('lang.sport_bet_stringField' /*串场 */)}&nbsp;
                  {item.betCount}
                  {t('lang.sport_bet_betsNumber')}&nbsp;{item.comboType}
                  <span>
                    <SvgIcon name="bet_success" />1{t('lang.sport_bet_betsNum')}
                  </span>
                </div>
                <div class={style.item}>
                  <span>{t('lang.sport_bet_betAmount')}</span>
                  <span>
                    {item.stake || 0}({currencySymbol.value})
                  </span>
                </div>
                <div class={style.item}>
                  <span>{t('lang.sport_bet_winAmount')}</span>
                  <span>
                    {item.winAmount}({currencySymbol.value})
                  </span>
                </div>
              </header>
              <div class={style.title}>{t('lang.sport_bet_betSucced')}</div>
              <div class={style.contentTitle}>
                <span class={style.connect}>{t('lang.sport_bet_connect' /*串 */)}</span>
                {t('lang.sport_bet_stringField' /*串场 */)}&nbsp;&nbsp;
                {item.betCount}
                {t('lang.sport_bet_betsNumber')}&nbsp;{item.comboType}
                <span class={style.oddType}>
                  <SvgIcon name="bet_success" />
                  {showHandicapName(3)}
                </span>
              </div>
              <main class={style.content}>
                {prebetInfo.value
                  .filter(item => item.Combo > 0 && !item.isSameGame && !item.singleClosed)
                  .map(item => {
                    return (
                      <>
                        <p class={style.contentTitle}>
                          <SportIcon sportId={item.sport_type} size="18" />
                          {item.keyName} {item.point}
                          <span class={style.odds}>{item.EuropePrice}</span>
                        </p>
                        <p class={style.information}>
                          {item.periodId === 3 ? <span>{t('lang.sport_handicap_live')} </span> : null}
                          {item.betTypeName}
                        </p>
                        <p class={style.information}>
                          {item.home} VS {item.away}
                          {item.periodId !== null &&
                          item.live_home_score != undefined &&
                          item.live_away_score != undefined ? (
                            <span>{`(${item.live_home_score}-${item.live_away_score})`}</span>
                          ) : null}
                        </p>
                      </>
                    );
                  })}
              </main>
            </section>
          );
        })}
      </div>
    );
    return () => [
      <section class={style.container}>
        {sportData.value.length ? <SingleDataRender /> : null}
        {parlayData.value.length ? <BetComoResultRender /> : null}
        <footer class={style.footer} onClick={handleDoneBet}>
          {t('lang.sport_bet_complete')}
        </footer>
      </section>
    ];
  }
});
