import { defineComponent, reactive, computed } from 'vue';
import { generate7daysCalendar, toTime } from '@/common';
import './week-calendar.style.less';

import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'WeekCalendar',
  emits: ['change'],
  setup(_p, { emit }) {
    const route = useRoute();
    const { t } = useI18n();

    const state = reactive({
      activeDate: 1,
      lastSevenDays: generate7daysCalendar()
    });

    const selectDateComputed = computed(() => Number(route.query?.selectDate) || null);

    const onHandleClickDate = (val: number) => {
      if (state.activeDate === val) return;
      state.activeDate = val;
      emit('change', val);
    };

    return () => (
      <div class="week-calendar">
        <ul>
          {/* <li
            onClick={() => onHandleClickDate(-1)}
            class={{ 'week-calendar-item': true, active: state.activeDate === -1 }}
          >
            <div class="week-calendar-item-inner">
              <span class="date-label">{t('lang.sport_common_quanBu')}</span>
            </div>
          </li> */}
          {state.lastSevenDays.map((item, index) => (
            <li
              onClick={() => onHandleClickDate(item.value)}
              class={{
                'week-calendar-item': true,
                active: (selectDateComputed.value || state.activeDate) === item.value
              }}
              key={index + '_ca_key'}
            >
              <div class="week-calendar-item-inner">
                <span class="date-label">{toTime(item.date, 'MM-DD')}</span>
                <span class="week-label">{t(item.week)}</span>
              </div>
            </li>
          ))}
          <li
            onClick={() => onHandleClickDate(8)}
            class={{ 'week-calendar-item': true, active: state.activeDate === 8 }}
          >
            <div class="week-calendar-item-inner">
              <span class="date-label">其他</span>
            </div>
          </li>
        </ul>
      </div>
    );
  }
});
