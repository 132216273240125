/**
 * 投注
 */

import { computed, reactive, ref, Ref } from 'vue';
import { TimerEnum, useAuth, useBalance, useTimerHook, useI18n, useSportOptions } from '@/hooks';
import { isEmpty, keys, map, values, forEach, min, max, sumBy, every, some, get, intersection } from 'lodash-es';
import { Toast } from '@/components';
import { RemovableRef, useStorage } from '@vueuse/core';
import { getParlayApi, doBetApi } from '@/services/kg-api';
import { MarketType, MarketTypeList, currency, isMobile } from '@/common';
import buttonSfx from '@/assets/voice/betSuccess.mp3';

import { useSound } from '@vueuse/sound';

export const BET_TICKET_KEY = 'SP_BET_TICKETS';

// 投注参数列表
const betTickets: RemovableRef<any> = useStorage(BET_TICKET_KEY, {});

// 投注序号
const sort = ref(Math.round(Math.random() * 10000000));

const betLimit: Ref<any> = ref({});

export interface CombItem {
  bet_count: number;
  comboSelection: number;
  combo_type: string;
  max_bet: string | number;
  min_bet: string | number;
  mulType: string;
  odds: number;
  refId: any;
  stake: string | number;
  betBuilderOdds: betBuilderOdds[];
}
interface betBuilderOdds {
  bettingOfferId: string;
  betBuilderOdds: number;
}
interface BetState {
  isShowPopup: boolean;
  eachAmount: string | number;
  tabActive: number;
  isCheckedAll: {
    0: boolean;
    1: boolean;
    2: boolean;
  };
  checkedAll: {
    0: [number | string][];
    1: [number | string][];
    2: [number | string][];
  };
  showParylayType: boolean;
  parylayTypeResult: string;
  parlayColumns: string[];
  parlayColumnsObject: any[];
  betted: boolean;
  betParlyCombo: Map<string, CombItem>;
  currentCombo: any;
  loading: boolean;
  /**
   * 批量单投投注结果
   */
  betResultList: any[];
  /**
   * 批量串关投注结果
   */
  betComoResult: any[];
  noCombos: boolean;
}

const initState = () => ({
  isShowPopup: false,
  eachAmount: '',
  tabActive: 0,
  isCheckedAll: {
    0: true,
    1: true,
    2: true
  },
  checkedAll: {
    0: [],
    1: [],
    2: []
  },
  showParylayType: false,
  parylayTypeResult: '',
  parlayColumns: [],
  parlayColumnsObject: [],
  betted: false,
  betParlyCombo: new Map(),
  currentCombo: {},
  loading: false,
  betResultList: [],
  betComoResult: [],
  noCombos: false
});

const state: BetState = reactive(initState());

// 检查是否有投注参数id
export const hasTicketId = ({
  matchId,
  marketId,
  wagerSelectionId
}: {
  matchId: string;
  marketId: string;
  wagerSelectionId: string;
}) => {
  const hasMatch = betTickets.value[matchId];
  if (hasMatch) {
    return !!betTickets.value[matchId][`${marketId}_${wagerSelectionId}`];
  }
  return false;
};

export interface BetParam {
  betType: number;
  matchId: string;
  marketId: string;
  sportType: number;
  oddsType: number;
  wagerSelectionId: string;
  key: string;
  price: number;
  periodId: number;
  point: string;
  sort?: string;
  betTeam?: string;
}

export interface BetInfoParam {
  home: string;
  away: string;
  combo: number;
  Combo?: number;
  leagueName: string;
  betTypeName: string;
  market: string;
  EuropePrice: number;
  matchTime: string;
  keyName: string;
  allowBetBuilder: number;
  isChamp?: boolean;
}

export function useBet(bet = false) {
  const { checkIsLogin } = useAuth();
  const { t } = useI18n();
  const { optionsStore } = useSportOptions();
  const { sportBalance, onHandleFresh_365 } = useBalance();

  const { addTimer, clearTimer } = useTimerHook();

  let play;
  if (bet) {
    play = useSound(buttonSfx).play; //多次加载音频文件
  }

  /**
   * 当前选中赛事
   */
  const checdkInfo = computed(
    () => prebetInfo.value.filter(p => state.checkedAll[state.tabActive].includes(p.uniqKey)) || []
  );

  /**
   * 当前选中赛事投注额
   */
  const betAmount = computed(() => {
    let numberVal = 0;
    if (state.tabActive === 0 || state.tabActive === 1) {
      numberVal =
        sumBy(checdkInfo.value, e => {
          if (
            (Number(e.odds_type) === MarketType.Indonesia || Number(e.odds_type) === MarketType.Malay) &&
            e.price < 0
          ) {
            return Math.abs(+e.stake * +e.price);
          }
          return Number(e.stake || 0);
        }) || 0;
      return numberVal;
    } else {
      // 处理单场串关
      if (checdkInfo.value.some(item => item.allowBetBuilder)) {
        numberVal =
          sumBy(checdkInfo.value, e => {
            if (e.allowBetBuilder) {
              return Number(e.stake || 0);
            } else {
              return 0;
            }
          }) || 0;
      }
      //  处理复试串关
      if (isEmpty(state.betParlyCombo)) return numberVal;
      state.betParlyCombo.forEach((v, _k) => {
        const stake = Number(v.stake);
        if (stake > 0 && stake >= +v.min_bet && stake <= +v.max_bet) {
          numberVal = stake * Number(v.bet_count) + numberVal;
        }
      });
      return numberVal;
    }
  });

  /**
   * 当前选中赛事可赢额
   */
  const winAmount = computed(() => {
    // 单投和普通串关
    if (state.tabActive === 0 || state.tabActive === 1) {
      const valWin = sumBy(checdkInfo.value, e => Number(e.stake || 0) * (e.estimatedPayoutAmount || 0)) || 0;
      return valWin;
    } else {
      //  处理复试串关
      let n = 0;
      if (isEmpty(state.betParlyCombo)) return 0;
      state.betParlyCombo.forEach((v, _k) => {
        const stake = Number(v.stake);
        if (stake > 0 && stake >= +v.min_bet && stake <= +v.max_bet) {
          n = stake * Number(v.odds) - stake * v.bet_count + n;
        }
      });
      return n;
    }
  });
  // 返会可赢额
  const getWinAmount = (data, key = 'price') => {
    if (!data || typeof data !== 'object') return 0;
    return Number((getOdds(data[key], data.odds_type) * (data.stake || 0)).toFixed(2) || 0);
  };
  // 转换盘口
  const getOdds = (data: string | number, type?: number | string): any => {
    if (!type || type == 3 || !data) return data; //包含本金
    if (type == 1 || type == 4 || type == 2) {
      if (+data > 0) {
        return 1 + Number(data);
      } else {
        return Math.abs(+data) + 1;
      }
    }
    return data;
  };
  const isSingle = computed(() => state.tabActive === 0); // 选中单注
  const comboResult = computed(() => state.betComoResult || {}); // 串关投注成功结果

  // 投注成功单注
  const singleData = computed(() => {
    if ([state.betResultList, prebetInfo.value].some(item => !Array.isArray(item))) return [];
    // 融合prebetInfo的数据
    return state.betResultList.map(item => {
      return {
        ...(prebetInfo.value.find(subItem => item.sort == subItem.sort) || {}),
        ...item,
        price: item.price || prebetInfo.value.find(subItem => item.sort == subItem.sort).price
      };
    });
  });

  // 投注成功单注下注额
  const singleAllBet = computed(() => {
    return sumBy(singleData.value, item => +Number(item.stake)).toFixed(2) || 0;
  });

  // 投注成功单注可赢额
  const singleAllWin = computed(() => {
    return sumBy(singleData.value, item => +item.winAmount).toFixed(2) || 0;
  });
  // 投注成功串关
  const parlayData = computed(() => {
    return state.betComoResult || [];
  });
  /**
   * 单注投注注单数
   */
  const singleConter = computed(() => {
    let n = 0;
    if (isEmpty(prebetInfo.value)) return 0;
    prebetInfo.value.forEach((v, _k) => {
      const stake = Number(v.stake);
      if (stake > 0 && stake >= v.min_bet && stake <= v.max_bet) {
        n = n + 1;
      }
    });
    return n;
  });
  /**
   * 串关注单数
   */
  const palaryConter = computed(() => {
    let n = 0;
    // 处理单场串关
    if (checdkInfo.value.some(item => item.allowBetBuilder)) {
      checdkInfo.value.forEach((v, _k) => {
        const stake = Number(v.stake);
        if (stake > 0 && stake >= +v.min_bet && stake <= +v.max_bet && v.allowBetBuilder) {
          n = n + 1;
        }
      });
    }
    if (isEmpty(state.betParlyCombo)) return n;
    state.betParlyCombo.forEach((v, _k) => {
      const stake = Number(v.stake);
      if (stake > 0 && stake >= +v.min_bet && stake <= +v.max_bet) {
        n = n + 1;
      }
    });
    return n;
  });

  const currentCheckAll = computed(() => {
    return state.checkedAll[state.tabActive] || [];
  }); // 当前选中赛事集合id
  const openTickets = () => {
    state.isShowPopup = true;
    handleCheckAll();
    getData();
  }; // 初次打开购物撤默认全选，请求预投注

  // 关闭购物车投注完成
  const handleClosePopup = () => {
    state.loading = false;
    if (state.betted) {
      handleDoneBet();
    }
  };

  // 单注，串场，复式串场全选
  const handleCheckAll = () => {
    [0, 1, 2].forEach(item => {
      state.isCheckedAll[item] = true;
      state.checkedAll[item] = prebetInfo.value.map(p => p.uniqKey);
    });
  };

  // 改变全部投注额
  const handleBranchChange = (val: number) => {
    state.eachAmount = val;
    prebetInfo.value.forEach(e => {
      if (state.checkedAll[state.tabActive].includes(e.uniqKey)) {
        betLimit.value[e.uniqKey].stake = Number(val);
      }
    });
  };

  // 显示赔率类型国际化
  const showHandicapName = (oddsType: MarketType) => {
    const ob = MarketTypeList.find(m => Number(oddsType) === m.value);
    return ob ? t(ob.label) : null;
  };

  const needRecharge = computed(() => betAmount.value > sportBalance.value);

  // 判断当前投注额是否大于余额
  const branchLimit = computed(() => {
    const low = max(map(checdkInfo.value, c => c.min_bet)) || 0;
    const up = min(map(checdkInfo.value, c => c.max_bet)) || 0;
    return [low, up];
  });

  /**
   * 添加投注信息
   */
  const addTicket = async (oSel: BetParam & BetInfoParam) => {
    const sel = {
      ...oSel,
      Combo: oSel.combo ? oSel.combo : oSel.Combo,
      sort: `${sort.value++}`,
      uniqKey: `${oSel.marketId}_${oSel.wagerSelectionId}`
    };
    if (sel.betType === 3) {
      Object.assign(sel, { oddsType: 3, price: sel.EuropePrice || sel.price });
    }

    if (!(await checkIsLogin({ loginDialog: isMobile }))) return;

    // 判断当前比赛
    if (betTickets.value[sel.matchId]) {
      const match = betTickets.value[sel.matchId];

      // 相同盘口删除
      if (match[sel.uniqKey]) {
        delete match[sel.uniqKey];
        isEmpty(match) && delete betTickets.value[sel.matchId];
      } else {
        if (prebetInfo.value.length >= 8) {
          Toast(t('lang.sport_common_bet365LimitTips') /*'投注项数量已达上限（8项）'*/);
          return;
        }
        match[sel.uniqKey] = sel;
      }
    } else {
      if (prebetInfo.value.length >= 8) {
        Toast(t('lang.sport_common_bet365LimitTips') /*'投注项数量已达上限（8项）'*/);
        return;
      }

      // 添加投注项目
      betTickets.value[sel.matchId] = {
        [sel.uniqKey]: sel
      };
      if (Object.keys(betTickets.value).length === 1) {
        state.isShowPopup = true;
        handleCheckAll();
      }
    }
    // 请求预投注
    getData();
  };

  /**
   * 请求预投注接口
   */
  const getData = () => {
    clearTimer(TimerEnum.PREBET);
    const fn = () => {
      if (state.tabActive === 0) {
        getSingleData();
      } else {
        getPalaryData();
      }
    };
    addTimer(TimerEnum.PREBET, fn, 10000);
  };

  /**
   * 单投/串关/复式串关 切换
   */
  const onTabChange = (index: number) => {
    if (index === state.tabActive) return;
    state.tabActive = index;
    getData();
  };

  const _formatSelParam = (sel: BetParam & BetInfoParam) => {
    return {
      betTeam: sel.key,
      betType: sel.betType,
      key: sel.key,
      marketId: sel.marketId,
      matchId: sel.matchId,
      oddsType: sel.oddsType || optionsStore.value.marketType,
      marketType: sel.oddsType || optionsStore.value.marketType,
      periodId: sel.periodId,
      point: sel.point,
      price: sel.price,
      sort: sel.sort,
      sportId: sel.sportType,
      wagerSelectionId: sel.wagerSelectionId
    };
  };

  /**
   * 获取单投预投注信息
   */
  const getSingleData = async () => {
    const list: any[] = [];
    forEach(betTickets.value, value => {
      values(value).forEach(v => {
        if (currentCheckAll.value.includes(v.uniqKey)) {
          list.push(v); // 只发送选中接口
        }
        if (!betLimit.value[v.uniqKey]) {
          betLimit.value[v.uniqKey] = {
            max_bet: 0,
            min_bet: 0,
            stake: '',
            closed: false,
            combo: v.combo,
            sort: v.sort
          };
        }
      });
    });
    if (list.length === 0) return;
    const single = list.map(sel => ({
      singleTicketInfo: _formatSelParam(sel),
      sort: sel.sort,
      OpenParlay: false,
      palaryInfoArray: null
    }));
    const [res, data] = await getParlayApi(single);
    // 更新限额以及赔率
    if (res) {
      data.forEach((v: any) => {
        if (v.code === 1) {
          forEach(betLimit.value, (val, key) => {
            if (+v.sort === +val.sort) {
              betLimit.value[key] = {
                ...betLimit.value[key],
                ...v, // 后台更新覆盖， matchId 和 market_id错误传入情况
                max_bet: Number(v.max_bet) || 0,
                min_bet: Number(v.min_bet) || 0,
                closed: v.code === 307 || v.status === 'closed' // 盘口是否关闭
              };
              if (betTickets.value[v.matchId] && betTickets.value[v.matchId][key]) {
                betTickets.value[v.matchId][key]['price'] = v.price; // 更新选中数据赔率
              }
            }
          });
        } else {
          forEach(betLimit.value, (val, key) => {
            if (Number(v.sort) === Number(val.sort)) {
              betLimit.value[key] = {
                ...betLimit.value[key],
                min_bet: 0,
                max_bet: 0,
                matchId: '',
                message: v.message,
                closed: true
              };
              // remove(state.checkedAll[state.tabActive], item => item === key);
            }
          });
        }
      });
    }
  };

  /**
   * 获取串关数据
   */
  const getPalaryData = async () => {
    const list: any[] = [];
    state.noCombos = false;
    forEach(betTickets.value, value => {
      values(value).forEach(v => {
        const item = prebetInfo.value.find(item => item.uniqKey === v.uniqKey);
        if (currentCheckAll.value.includes(v.uniqKey) && v.combo > 0 && item && !item.isSameGame) {
          const item = _formatSelParam(v);
          list.push({ ...item, odds_type: 3, price: v.EuropePrice, sort: v.sort });
        } // 发送选中的
        if (betLimit.value[v.uniqKey]) {
          const { max_bet = 0, min_bet = 0, stake = '' } = betLimit.value[v.uniqKey] || {};
          betLimit.value[v.uniqKey] = {
            ...betLimit.value[v.uniqKey],
            max_bet: max_bet || 0,
            min_bet: min_bet || 0,
            stake: stake || '',
            closed: false,
            sort: v.sort
          };
        } // 重新请求数据前重置closed参数放置上次轮询前数据不对导致未消除的异常
      });
    });
    if (list.length < 2) {
      return (state.betParlyCombo = new Map()); // 清除上次充值的数据
    }
    const palary = [
      {
        singleTicketInfo: null,
        sort: `${get(list, `${list.length - 1}.sort`)}`,
        OpenParlay: true,
        palaryInfoArray: list
      }
    ];
    const [_res, allData] = await getParlayApi(palary);
    if (!_res || !allData) return;
    const [data] = allData;
    if (!data) return;
    const { combos = [], price_info = [] } = data;
    const uniqKeys = Object.keys(betLimit.value);
    price_info.forEach(v => {
      const uniqKey = `${v.market_id}_${v.wagerSelectionId}`;
      betLimit.value[uniqKey] = {
        ...betLimit.value[uniqKey],
        ...v,
        closed: v.code === 307 || v.status === 'closed' // 盘口是否关闭
      };
      // 查找异常未匹配到的key
      if (uniqKeys.includes(uniqKey)) {
        betLimit.value[uniqKey].closed = true;
      }
      if (betTickets.value[v.matchId] && betTickets.value[v.matchId][uniqKey]) {
        betTickets.value[v.matchId][uniqKey].EuropePrice = v.current_price;
      }
    });

    if (data) {
      if (data.code !== 1) {
        // 标识一场不符合串关的
        forEach(betLimit.value, (val, key) => {
          if (data.sort === val.sort) {
            betLimit.value[key] = {
              ...betLimit.value[key],
              message: data.message,
              closed: true
            };
            // remove(state.checkedAll[state.tabActive], item => item === key);
          }
        });
      }
    }

    const temp: any[] = [];
    if (!combos || combos == null) {
      state.noCombos = true;
      return (state.betParlyCombo = new Map());
    }
    combos.forEach(c => {
      const old = state.betParlyCombo.get(mulTypeToName(c.combo_type)) || { stake: '' };
      temp.push({
        ...old,
        ...c,
        mulType: mulTypeToName(c.combo_type)
      });
    });
    state.parlayColumns = temp.filter(bet => bet.bet_count !== 1).map(b => mulTypeToName(b.combo_type));
    state.parlayColumnsObject = temp
      .filter(bet => bet.bet_count !== 1)
      .map(b => ({
        text: `${mulTypeToName(b.combo_type)}  ${b.bet_count}${t('lang.sport_bet_betsNumber')}`,
        values: mulTypeToName(b.combo_type),
        children: null
      }));
    if (state.parylayTypeResult === '' && state.parlayColumns.length) {
      //默认选中复式串关第一个
      state.parylayTypeResult = state.parlayColumns[0];
      state.currentCombo = temp.find(bet => bet.bet_count !== 1) || {};
    }
    state.betParlyCombo = new Map(temp.map(t => [t.mulType, t])); // 复式串关列表数据
  };

  /**
   * 预投注数据
   */
  const prebetInfo = computed(() => {
    const list: any[] = [];
    forEach(betTickets.value, e => {
      // 相同比赛，且选中
      let isSameGame: boolean = keys(e).length > 1 ? intersection(keys(e), currentCheckAll.value).length > 1 : false;
      forEach(e, (val: any) => {
        const betLimitItem = betLimit.value[val.uniqKey];
        if (val.allowBetBuilder) {
          isSameGame = false;
        }
        if (!!betLimitItem) {
          list.push({
            ...val,
            stake: '',
            isSameGame,
            ...betLimitItem,
            keyName: betLimitItem.keyName == null ? val.keyName : betLimitItem.keyName,
            closed: !betLimitItem.matchId && !betLimitItem.min_bet && !betLimitItem.max_bet && betLimitItem.closed
          });
        } else {
          list.push({
            ...val,
            stake: '',
            isSameGame
          });
        }
      });
    });
    return list;
  });
  // 页面数据组装
  const prebetList = computed(() => {
    const list: any[] = [];
    for (const key in betTickets.value) {
      const subList: any[] = [];
      for (let i = 0; i < prebetInfo.value.length; i++) {
        if (prebetInfo.value[i].matchId === key) {
          subList.push(prebetInfo.value[i]);
        }
      }
      list.push(subList);
    }
    return list;
  });
  /**
   * 单投投注动作
   */
  const doSingleBet = async () => {
    const betList = checdkInfo.value.filter(item => item.stake);
    // 选中投注
    const single = betList.map(p => ({
      OpenParlay: false,
      sort: p.sort,
      oddsOption: optionsStore.value.preference,
      betType: p.betType,
      betTypeName: p.betTypeName,
      //customerIP: '127.0.0.1',
      isComboAcceptAnyOdds: true,
      key: p.key,
      live_away_score: p.live_away_score,
      live_home_score: p.live_home_score,
      market: p.market,
      matchId: p.matchId,
      marketId: p.marketId,
      oddsType: p.oddsType || optionsStore.value.marketType,
      outrightTeamId: p.outrightTeamId,
      point: p.point,
      price: p.price,
      sportType: p.sportType,
      stake: p.stake,
      wagerSelectionId: p.wagerSelectionId,
      awayName: p.away,
      homeName: p.home,
      leagueName: p.leagueName,
      matchTime: p.matchTime,
      keyName: p.keyName,
      maxBet: p.maxBet,
      minBet: p.minBet
    }));
    const [res, data] = await doBetApi(single);
    state.loading = false;
    if (res && data) {
      state.betResultList = data
        .map(d => ({
          sort: d.sort,
          isSuccess: d.code === 1,
          betStatus: d.betStatus,
          price: d.betPrice,
          winAmount: d.estimatedPayoutFullAmount,
          ticketStatus: d.ticketStatus
        }))
        .filter(item => item.betStatus); // 过滤失败订单
      if (state.betResultList.length) {
        optionsStore.value.betSuccessSound && play && play();
        state.betted = true;
        onHandleFresh_365();
      } else {
        Toast(get(data, '0.message'));
      }
    } else {
      Toast(t('lang.sport_bet_failRetry'));
      state.betted = false;
      state.loading = false;
    }
  };

  /**
   * @description: 投注动作
   * @param combosList 串关组合
   */
  const doParlayBet = async (combosList: CombItem[]) => {
    const arr: CombItem[] = combosList;
    // 选中投注
    const matches = checdkInfo.value
      .filter(item => item.combo > 0 && !item.isSameGame && !item.closed)
      .map(p => ({
        betType: p.betType,
        betTypeName: p.betTypeName,
        isComboAcceptAnyOdds: true,
        key: p.key,
        live_away_score: p.live_away_score,
        live_home_score: p.live_home_score,
        market: p.market,
        matchId: p.matchId,
        marketId: p.marketId,
        market_id: p.marketId,
        oddsType: 3, // 串关都是欧赔
        outrightTeamId: p.outrightTeamId,
        point: p.point,
        price: p.EuropePrice, // 串关都是欧赔
        sportType: p.sportType,
        sport_type: p.sportType,
        wagerSelectionId: p.wagerSelectionId,
        awayName: p.away,
        homeName: p.home,
        leagueName: p.leagueName,
        matchTime: p.matchTime,
        keyName: p.keyName,
        maxBet: p.maxBet,
        minBet: p.minBet
      }));
    const [res, [data]] = await doBetApi([
      {
        OpenParlay: true,
        parlay_odds_option: true,
        oddsOption: optionsStore.value.preference,
        sort: '0',
        bet_matches: matches,
        customerIP: '127.0.0.1',
        bet_combos: arr.map(a => ({
          combo_type: a.comboSelection,
          stake: Number(a.stake),
          bet_count: Number(a.bet_count),
          betBuilderOdds: a.betBuilderOdds,
          comboPrice: Number(a.odds)
        }))
      }
    ]);
    state.loading = false;
    if (res && data) {
      state.betResultList = [];
      state.betComoResult = (data.current_combos || []).map(
        (d: {
          code: number;
          BetStatus: any;
          estimatedPayoutFullAmount: any;
          TicketStatus: any;
          bet_count: any;
          combo_price: any;
          combo_type: string;
          stake: any;
        }) => ({
          isSuccess: d.code === 1,
          betStatus: d.BetStatus,
          winAmount: Number(d.estimatedPayoutFullAmount),
          ticketStatus: d.TicketStatus,
          betCount: d.bet_count,
          comboPrice: d.combo_price,
          comboType: mulTypeToName(d.combo_type),
          stake: d.stake
        })
      );
      if (state.betComoResult.length) {
        state.betted = true;
        optionsStore.value.betSuccessSound && play && play();
        onHandleFresh_365();
      } else {
        // code返回的对比
        // 100 成功
        // 101 无效时间戳
        // 102 无效令牌
        // 103 无效货币编码
        // 104 会员账户被锁
        // 105 无效转账引用 ID
        // 106 重复转账引用 ID
        // 107 无效开始日期时间
        // 108 无效结束日期时间
        // 109 无效最后更新日期时间
        // 201 货币编码不符
        // 202 无效用户名
        // 205 会员不活跃
        // 301 无效操作类型 ID
        // 302 无效更新值
        // 303 无效金额
        // 304 余额不足
        // 305 无效语言编码
        // 306 无效水位分组 ID
        // 308 更新会员信息失败
        // 310 无效日期筛选类型
        // 311 无效日期范围
        // 312 无记录
        // 313 无效体育 ID
        // 314 转账失败
        // 315 无效投注状态
        // 317 无效最后更新日期的日期
        // 400 系统错误
        Toast(get(data, 'message'));
      }
    } else {
      Toast(t('lang.sport_bet_failRetry'));
      state.betted = false;
      state.loading = false;
    }
  };

  /**
   * 投注动作
   */
  const handleBet = () => {
    clearTimer(TimerEnum.PREBET); // 清除预投注定时器
    if (state.loading) return;
    if (state.tabActive === 0) {
      // 校验单投
      if (every(checdkInfo.value, c => c.stake === '')) {
        // 校验选中数据是否全部为空
        return;
      }
      // 校验投注额输入并且小于最小投注额度
      if (some(checdkInfo.value, c => !c.closed && c.price > 0 && c.stake > 0 && c.stake < c.min_bet)) {
        return;
      }
      state.loading = true;
      if (state.checkedAll[state.tabActive].length > 0) {
        // 校验是否选中
        doSingleBet();
      }
    } else if (state.tabActive === 1) {
      // 是否全串
      let current: any = null;
      state.betParlyCombo.forEach(value => {
        if (value.bet_count === 1) {
          current = value;
          return;
        }
      });
      // 串关
      if (Number(current.stake) >= Number(current.min_bet) && Number(current.stake) <= Number(current.max_bet)) {
        // 有效金额
        state.loading = true;
        doParlayBet(current);
      }
    } else if (state.tabActive === 2) {
      state.loading = true;
      const betList = checdkInfo.value.filter(item => item.stake);
      if (betList.length > 0) {
        // 校验是否选中
        doSingleBet();
      }
      // 复式串关多选
      const comboValidList: CombItem[] = []; // 有效投注串组合列表
      state.betParlyCombo.forEach((v, _k) => {
        if (Number(v.stake) >= Number(v.min_bet) && Number(v.stake) <= Number(v.max_bet)) {
          comboValidList.push(v);
        }
      });
      // 有效金额
      state.loading = true;
      if (comboValidList.length) {
        doParlayBet(comboValidList);
      }
    } else if (state.tabActive === 3) {
      // 复式串关单选
      let current: any = null;
      current = state.betParlyCombo.get(state.parylayTypeResult); // 复式串关
      // 串关
      if (Number(current.stake) >= Number(current.min_bet) && Number(current.stake) <= Number(current.max_bet)) {
        // 有效金额
        state.loading = true;
        doParlayBet([current]);
      }
    }
  };

  /**
   * 投注按钮是否激活状态
   */
  const betBtnActived = computed(() => {
    if (state.tabActive === 0) {
      // 单投
      return betAmount.value > 0;
    } else if (state.tabActive === 1) {
      // 串关
      const allCombo = Array.from(state.betParlyCombo.values()).find(b => b.bet_count === 1);
      return allCombo ? +allCombo.stake > 0 : false;
    } else if (state.tabActive === 2) {
      // 复式串关 多选 仅判断金额
      return betAmount.value > 0;
    } else {
      // 复式串关 单选 仅判断金额
      const current = state.betParlyCombo.get(state.parylayTypeResult);
      const betAmount = current ? Number(currency(Number(current.stake) * current.bet_count)) || 0 : 0;
      return betAmount >= Number(current?.min_bet || 0) && betAmount > 0;
    }
  });

  /**
   * 清除全部选单
   */
  const clearAllTickets = () => {
    clearTimer(TimerEnum.PREBET);
    handleDoneBet();
  };
  // 删除数据
  const deleteTicket = ({
    matchId,
    marketId,
    wagerSelectionId,
    sort
  }: {
    matchId: string;
    marketId: number;
    wagerSelectionId: string;
    sort?: string;
  }) => {
    const match = betTickets.value[matchId];
    let onlyKey = `${marketId}_${wagerSelectionId}`;
    if (sort && !match[onlyKey]) {
      forEach(betTickets.value[matchId], (value, key) => {
        if (value && value.sort === sort) {
          onlyKey = key;
        }
      });
    }
    // 相同盘口
    delete match[`${marketId}_${wagerSelectionId}`];
    isEmpty(match) && delete betTickets.value[matchId];
    setTimeout(() => {
      if (isEmpty(betTickets.value)) {
        clearTimer(TimerEnum.PREBET);
        handleDoneBet(); // 删除全部初始化状态
      }
      state.betParlyCombo = new Map();
      getData();
    }, 100);
  };

  /**
   * 投注选单数量
   */
  const ticketSize = computed(() => {
    let size = 0;
    forEach(betTickets.value, value => {
      size += keys(value).length;
    });
    return size;
  });

  /**
   * 最大串场数
   * @description: 最大串场
   * @returns {number}
   */
  const maxComboCount = computed<number>(() => {
    if (isEmpty(checdkInfo.value)) return 0;
    const n = max(map(checdkInfo.value, info => info.combo));
    return n;
  });

  /**
   * 投注完毕初始状态
   */
  const handleDoneBet = () => {
    Object.keys(betTickets.value).forEach(key => {
      delete betTickets.value[key];
    });
    Object.keys(betLimit.value).forEach(key => {
      delete betLimit.value[key];
    });
    clearBetState();
  };

  /**
   * 清除投注数据
   */
  const clearBetState = () => {
    const initData = initState();
    const keys = Object.keys(initData);
    const temObj: { [name: string]: string } = {};
    keys.map(k => (temObj[k] = initData[k]));
    Object.assign(state, temObj); // 清除数据
  };

  /**
   * 串关类型国际化
   */
  const mulTypeToName = (mulType: string) => {
    switch (mulType) {
      case 'Doubles':
        return `2${t('lang.sport_bet_connect')}1`;
      case 'Trebles':
        return `3${t('lang.sport_bet_connect')}1`;
      case 'Trixie':
        return `3${t('lang.sport_bet_connect')}4`;
      case 'Lucky7':
        return `幸运7`;
      case 'Patent':
        return `幸运7`;
      case 'Fold4':
        return `4${t('lang.sport_bet_connect')}1`;
      case 'Yankee':
        return `洋基`;
      case 'Lucky15':
        return `幸运15`;
      case 'Fold5':
        return `5${t('lang.sport_bet_connect')}1`;
      case 'Canadian':
        return `超级美国佬`;
      case 'Lucky31':
        return `幸运31`;
      case 'Fold6':
        return `6${t('lang.sport_bet_connect')}1`;
      case 'Heinz':
        return `亨氏`;
      case 'Lucky63':
        return `幸运63`;
      case 'Fold7':
        return `7${t('lang.sport_bet_connect')}1`;
      case 'SuperHeinz':
        return `超级亨氏`;
      case 'Lucky127':
        return `幸运127`;
      case 'Fold8':
        return `8${t('lang.sport_bet_connect')}1`;
      case 'Goliath':
        return `大亨`;
      case 'Lucky255':
        return `幸运255`;
      case 'Fold9':
        return `9${t('lang.sport_bet_connect')}1`;
      case 'Fold10':
        return `10${t('lang.sport_bet_connect')}1`;
      case 'SuperYankee':
        return `5串26`;
      case 'Super Yankee':
        return `5串26`;
      case 'Block9':
        return `9${t('lang.sport_bet_connect')}520`;
      case 'Block10':
        return `10${t('lang.sport_bet_connect')}1013`;
      case '3串4':
        return `3${t('lang.sport_bet_connect')}4`;
      case '4串11':
        return `4${t('lang.sport_bet_connect')}11`;
      case '5串26':
        return `5${t('lang.sport_bet_connect')}26`;
      case '6串57':
        return `6${t('lang.sport_bet_connect')}57`;
      case '7串120':
        return `7${t('lang.sport_bet_connect')}120`;
      case '9串502':
        return `9${t('lang.sport_bet_connect')}502`;
      case '10串1013':
        return `10${t('lang.sport_bet_connect')}1013`;
      case '8串247':
        return `8${t('lang.sport_bet_connect')}247`;
      default:
        return mulType;
    }
  };

  return {
    openTickets,
    addTicket,
    clearAllTickets,
    deleteTicket,
    handleBet,
    handleCheckAll,
    handleBranchChange,
    handleClosePopup,
    showHandicapName,
    needRecharge,
    state,
    betBtnActived,
    betAmount,
    winAmount,
    getWinAmount,
    betLimit,
    branchLimit,
    betTickets,
    ticketSize,
    prebetInfo,
    checdkInfo,
    maxComboCount,
    handleDoneBet,
    mulTypeToName,
    onTabChange,
    isSingle,
    comboResult,
    palaryConter,
    currentCheckAll,
    singleData,
    singleAllBet,
    singleAllWin,
    parlayData,
    singleConter,
    prebetList
  };
}
