import { defineComponent } from 'vue';
import { useI18n } from '@/hooks';
import { useBetWeb } from '@/hooks/useBetWeb.hook';
import { SportIcon, SvgIcon } from '@/components';
import style from './style/result.module.less';
import { currency } from '@/common';

export default defineComponent({
  setup() {
    const {
      state,
      singleData,
      singleAllBet,
      singleAllWin,
      parlayData,
      currencySymbol,
      handleDoneBet,
      showHandicapName,
      prebetInfo
    } = useBetWeb();
    const { t } = useI18n();
    return () => (
      <section class={style.container}>
        <div class={style.containerContent}>
          {singleData.value.length ? (
            <section class={style.body}>
              <header class={style.header}>
                <div class={style.item}>
                  <span>{t('lang.sport_bet_singBets')}</span>
                  <span>
                    <SvgIcon name="bet_success" />
                    {state.betResultList.length}
                    {t('lang.sport_bet_betsNum')}
                  </span>
                </div>
                <div class={style.item}>
                  <span>{t('lang.sport_bet_betAmount')}</span>
                  <span>
                    {singleAllBet.value}({currencySymbol.value})
                  </span>
                </div>
                <div class={style.item}>
                  <span>{t('lang.sport_bet_winAmount')}</span>
                  <span>
                    {singleAllWin.value}({currencySymbol.value})
                  </span>
                </div>
              </header>
              <div class={style.title}>{t('lang.sport_bet_betSucced')}</div>
              <main class={style.content}>
                {singleData.value.map(item => {
                  return (
                    <>
                      <div class={[style.contentTitle]}>
                        <SportIcon sportId={item.sportType} size="18" />
                        {item.keyName} {item.point}
                        <span class={style.odds}>@{item.price}</span>
                        <span class={style.oddType}>
                          <SvgIcon name="bet_success" />
                          {showHandicapName(item.oddsType)}
                        </span>
                      </div>
                      <p class={style.information}>
                        {item.periodId === 3 ? <span>{t('lang.sport_handicap_live')} </span> : null} {item.betTypeName}
                      </p>
                      {item.isChamp ? (
                        <p class={style.information} title={item.leagueName}>
                          {item.leagueName}
                        </p>
                      ) : (
                        <p
                          class={style.information}
                          title={`${item.home}vs${item.away} ${
                            item.periodId !== null &&
                            item.live_home_score != undefined &&
                            item.live_away_score != undefined
                              ? `(${item.live_home_score}-${item.live_away_score})`
                              : ''
                          }`}
                        >
                          {item.home} VS {item.away}
                          {item.periodId !== null &&
                          item.live_home_score != undefined &&
                          item.live_away_score != undefined ? (
                            <span>{`(${item.live_home_score}-${item.live_away_score})`}</span>
                          ) : null}
                        </p>
                      )}

                      <footer class={style.contentFooter}>
                        <div class={style.item}>
                          <span>{t('lang.sport_bet_betAmount')}</span>
                          <span class={style.amount}>
                            {currency(item.stake || 0)}
                            <span>{currencySymbol.value}</span>
                          </span>
                        </div>
                        <div class={style.item}>
                          <span>{t('lang.sport_bet_winAmount')}</span>
                          <span class={style.amount}>
                            {item.winAmount}
                            <span>{currencySymbol.value}</span>
                          </span>
                        </div>
                      </footer>
                    </>
                  );
                })}
              </main>
            </section>
          ) : null}
          {parlayData.value.length
            ? parlayData.value.map(item => {
                return (
                  <section class={style.body} key={item.id}>
                    <header class={style.header}>
                      <div class={style.item}>
                        {t('lang.sport_bet_stringField' /*串场 */)}&nbsp;
                        {item.betCount}
                        {t('lang.sport_bet_betsNumber')}&nbsp;{item.comboType}
                        <span>
                          <SvgIcon name="bet_success" />
                          {1}
                          {t('lang.sport_bet_betsNum')}
                        </span>
                      </div>
                      <div class={style.item}>
                        <span>{t('lang.sport_bet_betAmount')}</span>
                        <span>
                          {item.stake || 0}({currencySymbol.value})
                        </span>
                      </div>
                      <div class={style.item}>
                        <span>{t('lang.sport_bet_winAmount')}</span>
                        <span>
                          {item.winAmount}({currencySymbol.value})
                        </span>
                      </div>
                    </header>
                    <div class={style.title}>{t('lang.sport_bet_betSucced')}</div>
                    <div class={style.contentTitle}>
                      <span class={style.connect}>{t('lang.sport_bet_connect' /*串 */)}</span>
                      {t('lang.sport_bet_stringField' /*串场 */)}&nbsp;&nbsp;
                      {item.comboType}
                      &nbsp;&nbsp;
                      {item.betCount} {t('lang.sport_bet_betsNumber')}
                      <span class={style.oddType}>
                        <SvgIcon name="bet_success" />
                        {showHandicapName(3)}
                      </span>
                    </div>
                    <main class={style.content}>
                      {prebetInfo.value
                        .filter(item => item.Combo > 0 && !item.isSameGame && !item.singleClosed)
                        .map(item => {
                          return (
                            <>
                              <p class={style.contentTitle}>
                                <SportIcon sportId={item.sport_type} size="18" />
                                {item.keyName} {item.point}
                                <span class={style.odds}>{item.EuropePrice}</span>
                              </p>
                              <p class={style.information}>
                                {item.periodId === 3 ? <span>{t('lang.sport_handicap_live')} </span> : null}
                                {item.betTypeName}
                              </p>
                              <p class={style.information}>
                                {item.home} VS {item.away}
                                {item.periodId !== null &&
                                item.live_home_score != undefined &&
                                item.live_away_score != undefined ? (
                                  <span>{`(${item.live_home_score}-${item.live_away_score})`}</span>
                                ) : null}
                              </p>
                            </>
                          );
                        })}
                    </main>
                  </section>
                );
              })
            : null}
        </div>
        <footer class={style.footer} onClick={handleDoneBet}>
          {t('lang.sport_bet_complete')}
        </footer>
      </section>
    );
  }
});
