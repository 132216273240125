import { ref, watch, defineComponent } from 'vue';
import styleClass from './odds-price.module.less';

export default defineComponent({
  name: 'OddsPrice',
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    type: {
      type: String,
      default: ''
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const floatClass = ref('');

    // 监听值
    watch(
      () => Number(props.value),
      (newVal, oldVal) => {
        if (newVal > oldVal) {
          floatClass.value = styleClass.up;
        } else if (newVal < oldVal) {
          floatClass.value = styleClass.down;
        }
        emit('change');
        setTimeout(() => {
          floatClass.value = '';
        }, 3 * 1000);
      }
    );

    return () => (
      <span class={[floatClass.value, styleClass.oddsValue, props.type === 'background' ? styleClass.background : '']}>
        {props.value}
      </span>
    );
  }
});
