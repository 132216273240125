import { createVNode, render } from 'vue';
import ToastComp from './Loading';

interface ToastOptions {
  text?: string;
  loadingIcon?: string;
}

let _vm: any = null;
const microAppRoot = document.getElementById('microApp');

function Loading(options?: ToastOptions) {
  const app = createVNode(ToastComp, { ...options, visible: true });
  const root = document.createElement('div');
  root.classList.add('--sp-laoding-container--');
  _vm = microAppRoot?.appendChild(root);

  render(app, root);
}

// 显示
Loading.show = (options?: ToastOptions) => Loading(options);

// 隐藏
Loading.hide = () => {
  if (_vm) {
    microAppRoot?.removeChild(_vm);
    _vm = null;
  }
};

export { Loading };
