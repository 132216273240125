import { defineComponent, reactive } from 'vue';
import { generate7daysCalendar, toTime } from '@/common';
import style from './week-calendar.module.less';

import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'WeekCalendar',
  emits: ['change'],
  setup(_p, { emit }) {
    const route = useRoute();
    const { t } = useI18n();

    const state = reactive({
      activeDate: Number(route.query.selectDate) || 1,
      lastSevenDays: generate7daysCalendar()
    });

    const onHandleClickDate = (val: number) => {
      if (state.activeDate === val) return;
      state.activeDate = val;
      emit('change', val);
    };

    return () => (
      <div class={style.weekCalendar}>
        <ul>
          {state.lastSevenDays.map((item, index) => (
            <li
              onClick={() => onHandleClickDate(item.value)}
              class={[style.weekCalendarItem, state.activeDate === item.value ? style.active : null]}
              key={index + '_ca_key'}
            >
              <div class={style.weekCalendarItemInner}>
                <span class={style.dateLabel}>{toTime(item.date, 'MM-DD')}</span>
                <span class={style.weekLabel}>{t(item.week)}</span>
              </div>
            </li>
          ))}
          <li
            onClick={() => onHandleClickDate(8)}
            class={[style.weekCalendarItem, state.activeDate === 8 ? style.active : null]}
          >
            <div class={style.weekCalendarItemInner}>
              <span class={style.dateLabel}>
                {/* 其他 */}
                {t('lang.sport_common_other')}
              </span>
            </div>
          </li>
        </ul>
      </div>
    );
  }
});
