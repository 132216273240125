import { defineComponent } from 'vue';

export default defineComponent({
  name: '404notFound',
  setup() {
    return () => (
      <div class="404-page" style="font-size: 100px">
        404 Not Found!
      </div>
    );
  }
});
