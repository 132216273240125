import { sumBy } from 'lodash-es';

// 比分处理
export function scoreFormat(MoreInfo: any, key: 'home' | 'away') {
  if (!MoreInfo) return 0;
  const llp = Number(MoreInfo.LLP);
  let score = 0;
  if ([6, 9].includes(MoreInfo.SportType)) {
    // 羽毛球 排球
    const numbeArr: number[] = [];
    for (let index = 1; index <= llp; index++) {
      numbeArr.push(Number(MoreInfo[key === 'home' ? `H${index}S` : `A${index}S`]));
    }
    score = sumBy(numbeArr) || 0;
  } else if ([18].includes(MoreInfo.SportType)) {
    // 乒乓球
    score = key === 'home' ? MoreInfo.GetGamesH : MoreInfo.GetGamesA || 0;
  } else if ([5].includes(MoreInfo.SportType)) {
    // 网球
    score = key === 'home' ? MoreInfo.TennisHomePointScore : MoreInfo.TennisAwayPointScore || '0';
  } else if ([4].includes(MoreInfo.SportType)) {
    // 冰球
    const numbeArr: number[] = [];
    for (let index = 1; index <= llp; index++) {
      numbeArr.push(Number(MoreInfo[key === 'home' ? `H${index}P` : `A${index}P`]));
    }
    score = sumBy(numbeArr) || 0;
  } else {
    // 其他
    score = key === 'home' ? MoreInfo.scoreH : MoreInfo.scoreA || 0;
  }
  return score;
}
