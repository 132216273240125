/**
 * 公告
 */

import { ref, Ref } from 'vue';
import { getPublicMessage } from '@/services';
import dayjs from 'dayjs';
import { Fn } from '@vueuse/core';

interface PublicityRn {
  publicityData: Ref<string>;
  getPublicityData: Fn;
}

export function usePublicNotice(): PublicityRn {
  const publicityData = ref<string>('');

  // 获取公告列表
  const getPublicityData = async () => {
    const startDate = dayjs().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
    const endDate = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [res, data] = await getPublicMessage({ startDate, endDate, source: 'im' });
    const arr: any = [];
    if (res) {
      for (let i = 0; i < 5 && i < data.length; i++) {
        arr.push(data[i].content);
      }
      publicityData.value = arr.join('  ');
    }
  };

  return {
    getPublicityData,
    publicityData
  };
}
