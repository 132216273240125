import { computed, reactive, ref, Ref } from 'vue';
import { Toast } from '@/components';

import { useStore } from 'vuex';
import { useSound } from '@vueuse/sound';
import { get, isEmpty, keys, sumBy, values, forEach as forEach_, sum } from 'lodash-es';
import { RemovableRef, useStorage } from '@vueuse/core';

/**
 * 投注
 */
import { TimerEnum, useAuth, useTimerHook, useI18n, useSportOptions, useBalance } from '@/hooks';
// 预投注，投注api
import { getParlayApi, doBetApi } from '@/services/kg-api';

import { isMobile, MarketType, MarketTypeList } from '@/common';
import buttonSfx from '@/assets/voice/betSuccess.mp3';

export const BET_TICKET_KEY = 'SP_BET_TICKETS';

// 投注参数列表
const betTickets: RemovableRef<any> = useStorage(BET_TICKET_KEY, {});

// 投注序号
const sort = ref(Math.round(Math.random() * 10000000));

// 投注信息保存金额
const betLimit: Ref<any> = ref({});
interface BetState {
  eachAmount: string | number; // 批量单注
  showParylayType: boolean; //投注类型
  betted: boolean;
  betParlyCombo: Map<string, any>; // 串关集合数据
  loading: boolean;
  betResultList: any[]; // 单注投注成功数据
  betComoResult: any[]; // 串关投注成功数据
}
const initState = () => ({
  //初始状态
  eachAmount: '',
  showParylayType: false,
  betted: false,
  betParlyCombo: new Map(),
  loading: false,
  betResultList: [],
  betComoResult: []
});

const state: BetState = reactive(initState());

// 检查是否有投注参数id
export const hasTicketId = ({
  matchId,
  marketId,
  wagerSelectionId
}: {
  matchId: string;
  marketId: string;
  wagerSelectionId: string;
}) => {
  const hasMatch = betTickets.value[matchId];
  if (hasMatch) {
    return !!betTickets.value[matchId][`${marketId}_${wagerSelectionId}`];
  }
  return false;
};

// 投注提交参数
export interface BetParam {
  betType: number;
  matchId: string;
  marketId: string;
  sportType: number;
  oddsType: number;
  wagerSelectionId: string;
  key: string;
  price: number;
  periodId: number;
  point: string;
  sort?: string;
  betTeam?: string;
}

export interface BetInfoParam {
  home: string;
  away: string;
  combo: number;
  Combo?: number;
  leagueName: string;
  betTypeName: string;
  market: string;
  EuropePrice: number;
  matchTime: string;
  keyName: string;
  allowBetBuilder: number;
  programmeName?: string;
  isChamp?: boolean;
}

export function useBetWeb(isWeb = true, bet = false) {
  const { sportBalance, onHandleFresh } = useBalance({
    // 余额
    platformCode: 'IM',
    gameType: 'SPORT'
  });

  const { checkIsLogin } = useAuth(); //登录才能投注

  const { t } = useI18n();

  const { optionsStore } = useSportOptions(); // 设置的参数项目

  const { addTimer, clearTimer } = useTimerHook(); // 全局定时器

  const store = useStore();

  let play;

  if (bet) {
    play = useSound(buttonSfx).play;
  }
  // 货币符号
  const currencySymbol = computed(() => store.state.user.currency);

  // 复式串关投注项数组
  const allCombo = computed(() => {
    const res = Array.from(state.betParlyCombo.values());
    return res;
  });

  // 转换盘口
  const getOdds = (data: string | number, type?: number | string): any => {
    if (!type || type == 3 || !data) return data; //包含本金
    if (type == 1 || type == 4 || type == 2) {
      if (+data > 0) {
        return 1 + Number(data);
      } else {
        return Math.abs(+data) + 1;
      }
    }
    return data;
  };

  // 投注成功单注
  const singleData = computed(() => {
    if ([state.betResultList, prebetInfo.value].some(item => !Array.isArray(item))) return [];
    // 融合prebetInfo的数据
    return state.betResultList.map(item => {
      return {
        ...(prebetInfo.value.find(subItem => item.sort == subItem.sort) || {}),
        ...item
      };
    });
  });

  // 投注成功单注下注额
  const singleAllBet = computed(() => {
    return sumBy(singleData.value, item => +Number(item.stake)).toFixed(2) || 0;
  });

  // 投注成功单注可赢额
  const singleAllWin = computed(() => {
    return sumBy(singleData.value, item => +item.winAmount).toFixed(2) || 0;
  });

  // 投注成功串关
  const parlayData = computed(() => {
    return state.betComoResult || [];
  });

  // 总类型投注金额
  const betAmount = computed(() => {
    const parlay = allCombo.value
      ? sum(allCombo.value.map(item => Number(((item.stake || 0) * (item.bet_count || 0)).toFixed(2)) || 0))
      : 0;
    const single = betPrebetInfo.value
      ? sum(betPrebetInfo.value.map(item => Number((+(item.stake || 0)).toFixed(2)) || 0))
      : 0;
    return sum([parlay, single]).toFixed(2);
  });

  // 返会可赢额
  const getWinAmount = (data, key = 'price') => {
    if (!data || typeof data !== 'object') return 0;
    return Number((getOdds(data[key], data.odds_type) * (data.stake || 0)).toFixed(2) || 0);
  };

  // 欧洲赔率下可赢额度
  const winAmount = computed(() => {
    const parlay = allCombo.value
      ? sum(allCombo.value.map(item => Number((item.odds * (item.stake || 0)).toFixed(2)) || 0))
      : 0;
    const single = betPrebetInfo.value
      ? +sum(
          betPrebetInfo.value.map(
            item => Number((getOdds(item.price, item.odds_type) * (item.stake || 0)).toFixed(2)) || 0
          )
        ).toFixed(2)
      : 0;
    return (parlay + single).toFixed(2) || 0;
  });

  // 盘口类型国际化
  const showHandicapName = (oddsType: MarketType) => {
    const ob = MarketTypeList.find(m => Number(oddsType) === m.value);
    return ob ? t(ob.label) : null;
  };
  const needRecharge = computed(() => {
    return Number(betAmount.value) == 0 ? sportBalance.value == 0 : Number(betAmount.value) > sportBalance.value;
  }); // 判断当前投注额是否大于余额
  /**
   * 添加投注信息
   */
  const addTicket = async (oSel: BetParam & BetInfoParam, isBet365 = false) => {
    if (state.betted) {
      await handleDoneBet();
      state.betted = false;
    }
    const sel = {
      ...oSel,
      Combo: oSel.combo ? oSel.combo : oSel.Combo,
      sort: `${sort.value++}`,
      uniqKey: `${oSel.marketId}_${oSel.wagerSelectionId}`
    };
    if (sel.betType === 3) {
      Object.assign(sel, { oddsType: 3, price: sel.EuropePrice || sel.price });
    }

    if (!(await checkIsLogin({ loginDialog: isMobile, isToast: isBet365 }))) return;

    // 判断当前比赛
    if (betTickets.value[sel.matchId]) {
      const match = betTickets.value[sel.matchId];

      // 相同盘口删除
      if (match[sel.uniqKey]) {
        delete match[sel.uniqKey];
        isEmpty(match) && delete betTickets.value[sel.matchId];
      } else {
        if (prebetInfo.value.length >= 8) {
          Toast(t('lang.sport_common_bet365LimitTips') /*'投注项数量已达上限（8项）'*/);
          return;
        }
        match[sel.uniqKey] = sel;
      }
    } else {
      if (prebetInfo.value.length >= 8) {
        Toast(t('lang.sport_common_bet365LimitTips') /*'投注项数量已达上限（8项）'*/);
        return;
      }

      // 添加投注项目
      betTickets.value[sel.matchId] = {
        [sel.uniqKey]: sel
      };
    }
    // 请求预投注
    getData();
  };

  const getData = () => {
    const fn = () => {
      if (isWeb) {
      }
      getSingleData();
      getPalaryData();
    };
    addTimer(TimerEnum.PREBET, fn, 10000);
  };

  const _formatSelParam = (sel: BetParam & BetInfoParam) => {
    return {
      betTeam: sel.key,
      betType: sel.betType,
      key: sel.key,
      marketId: sel.marketId,
      matchId: sel.matchId,
      oddsType: sel.oddsType || optionsStore.value.marketType,
      marketType: sel.oddsType || optionsStore.value.marketType,
      periodId: sel.periodId,
      point: sel.point,
      price: sel.price,
      sort: sel.sort,
      sportId: sel.sportType,
      wagerSelectionId: sel.wagerSelectionId
    };
  };

  const getSingleData = async () => {
    const list: any[] = [];
    forEach_(betTickets.value, value => {
      values(value).forEach(v => {
        list.push(v);
        if (!betLimit.value[v.uniqKey]) {
          betLimit.value[v.uniqKey] = {
            max_bet: 0,
            min_bet: 0,
            stake: '',
            singleClosed: false,
            sort: v.sort,
            Combo: v.Combo,
            closed: false
          };
        }
      });
    });
    if (list.length === 0) return;
    const single = list.map(sel => ({
      singleTicketInfo: _formatSelParam(sel),
      sort: sel.sort,
      OpenParlay: false,
      palaryInfoArray: null
    }));
    const [res, data] = await getParlayApi(single);
    // 更新限额以及赔率
    if (res) {
      data.forEach((v: any) => {
        if (v.code === 1) {
          forEach_(betLimit.value, (val, key) => {
            if (v.sort == val.sort) {
              betLimit.value[key] = {
                ...betLimit.value[key],
                //...v, // 后台更新覆盖， matchId 和 market_id错误传入情况
                max_bet: Number(v.max_bet) || 0,
                min_bet: Number(v.min_bet) || 0,
                betBuilderOdds: v.betBuilderOdds,
                singleClosed: v.code === 307 || v.status === 'closed' // 盘口是否关闭
              };
              if (betTickets.value[v.matchId] && betTickets.value[v.matchId][key]) {
                betTickets.value[v.matchId][key]['price'] = v.price; // 更新选中数据赔率
              }
            }
          });
        } else {
          forEach_(betLimit.value, (val, key) => {
            // 投注异常
            if (v.sort == val.sort) {
              betLimit.value[key] = {
                ...betLimit.value[key],
                min_bet: 0,
                max_bet: 0,
                singleMessage: v.message,
                singleClosed: true // 单注盘口关闭
              };
            }
          });
        }
      });
    }
  };

  const getPalaryData = async () => {
    const list: any[] = [];
    forEach_(betTickets.value, value => {
      values(value).forEach(v => {
        const item = prebetInfo.value.find(item => item.uniqKey === v.uniqKey);
        if (v.Combo > 0 && item && !item.isSameGame && !item.singleClosed) {
          // 过滤串关 相同比赛和玩法不支持和单注盘口异常关闭 订单需保持一致
          list.push(v);
        }
        if (betLimit.value[v.uniqKey]) {
          betLimit.value[v.uniqKey] = {
            ...betLimit.value[v.uniqKey],
            closed: false
          };
        } // 重新请求数据前重置closed参数放置上次轮询前数据不对导致未消除的异常
      });
    });

    if (list.length < 2) {
      return (state.betParlyCombo = new Map()); // 清除上次充值的数据
    }

    const palary = [
      {
        singleTicketInfo: null,
        sort: `${get(list, `${list.length - 1}.sort`)}`,
        OpenParlay: true,
        palaryInfoArray: list.map(item => {
          return { ..._formatSelParam(item), oddsType: 3, price: item.EuropePrice }; // 串关使用欧赔
        })
      }
    ];

    const [_res, allData] = await getParlayApi(palary);
    if (!_res || !allData) return;
    const [data] = allData;
    if (!data) return;
    const { combos = [], price_info = [] } = data;

    price_info.forEach(v => {
      const uniqKey = `${v.market_id}_${v.wagerSelectionId}`;
      if (betLimit.value[uniqKey]) {
        betLimit.value[uniqKey] = {
          ...betLimit.value[uniqKey],
          betBuilderOdds: v.betBuilderOdds,
          closed: v.code === 307 || v.status === 'closed' // 盘口是否关闭
        };
      }
      if (betTickets.value[v.matchId] && betTickets.value[v.matchId][uniqKey]) {
        betTickets.value[v.matchId][uniqKey].EuropePrice = v.current_price;
      }
    });

    if (data) {
      if (data.code !== 1) {
        // 标识一场不符合串关的
        forEach_(betLimit.value, (val, key) => {
          if (data.sort == val.sort) {
            betLimit.value[key] = {
              ...betLimit.value[key],
              message: data.message,
              closed: true
            };
          }
        });
      }
    }

    const temp: any[] = [];
    combos &&
      combos.forEach(c => {
        const old = state.betParlyCombo.get(mulTypeToName(c.combo_type)) || { parlayStake: '' };
        temp.push({
          ...old,
          ...c,
          mulType: mulTypeToName(c.combo_type)
        });
      });

    // 串关投注数据
    state.betParlyCombo = new Map(temp.map(t => [t.mulType, t]));
  };

  // 混合betLimit
  const prebetInfo = computed(() => {
    const list: any[] = [];
    forEach_(betTickets.value, e => {
      let isSameGame: boolean = keys(e).length > 1;
      forEach_(e, (val: any) => {
        if (val.allowBetBuilder) {
          isSameGame = false;
        }
        list.push({
          ...val,
          stake: '',
          isSameGame,
          ...(betLimit.value[val.uniqKey] ? betLimit.value[val.uniqKey] : {}),
          Combo: val.combo ? val.combo : val.Combo
        });
      });
    });
    return list;
  });
  // 页面数据组装
  const prebetList = computed(() => {
    const list: any[] = [];
    for (const key in betTickets.value) {
      const subList: any[] = [];
      for (let i = 0; i < prebetInfo.value.length; i++) {
        if (prebetInfo.value[i].matchId === key) {
          subList.push(prebetInfo.value[i]);
        }
      }
      list.push(subList);
    }
    return list;
  });

  // 过滤盘口关闭单注
  const betPrebetInfo = computed(() => {
    const list: any[] = [];
    forEach_(betTickets.value, e => {
      const isSameGame: boolean = keys(e).length > 1;
      forEach_(e, (val: any) => {
        list.push({
          ...val,
          stake: '',
          isSameGame,
          ...(betLimit.value[val.uniqKey] ? betLimit.value[val.uniqKey] : {})
        });
      });
    });
    return list.filter(item => !item.singleClosed);
  });

  const doSingleBet = async array => {
    // 批量单投
    state.loading = true;
    const single = array.map(p => ({
      OpenParlay: false,
      sort: p.sort,
      oddsOption: optionsStore.value.preference,
      betType: p.betType,
      betTypeName: p.betTypeName,
      //customerIP: '127.0.0.1',
      isComboAcceptAnyOdds: true,
      key: p.key,
      live_away_score: p.live_away_score,
      live_home_score: p.live_home_score,
      market: p.market,
      matchId: p.matchId,
      marketId: p.marketId,
      oddsType: p.oddsType || optionsStore.value.marketType,
      outrightTeamId: p.outrightTeamId,
      point: p.point,
      price: p.price,
      sportType: p.sportType,
      stake: p.stake,
      wagerSelectionId: p.wagerSelectionId,
      awayName: p.away,
      homeName: p.home,
      leagueName: p.leagueName,
      matchTime: p.matchTime,
      keyName: p.keyName,
      maxBet: p.maxBet,
      minBet: p.minBet
    }));
    const [res, data] = await doBetApi(single);
    state.loading = false;
    if (res && data) {
      state.betComoResult = [];
      state.betResultList = data
        .map(d => ({
          sort: d.sort,
          isSuccess: d.code === 1,
          betStatus: d.betStatus,
          price: d.betPrice,
          winAmount: d.estimatedPayoutFullAmount,
          ticketStatus: d.ticketStatus
        }))
        .filter(item => item.betStatus); // 过滤失败订单
      if (state.betResultList.length) {
        optionsStore.value.betSuccessSound && play && play();
        state.betted = true;
        onHandleFresh();
      } else {
        Toast(get(data, '0.message'));
      }
    } else {
      Toast(t('lang.sport_bet_failRetry'));
      state.betted = false;
      state.loading = false;
    }
  };

  const doParlayBet = async (current: any) => {
    // 串关投注
    state.loading = true;
    const array: any[] = Array.isArray(current) ? current : [current];
    const matches = prebetInfo.value
      .filter(item => item.Combo > 0 && !item.isSameGame && !item.singleClosed)
      .map(p => ({
        betType: p.betType,
        betTypeName: p.betTypeName,
        isComboAcceptAnyOdds: true,
        key: p.key,
        live_away_score: p.live_away_score,
        live_home_score: p.live_home_score,
        market: p.market,
        matchId: p.matchId,
        marketId: p.marketId,
        market_id: p.marketId,
        oddsType: 3, // 串关都是欧赔
        outrightTeamId: p.outrightTeamId,
        point: p.point,
        price: p.EuropePrice, // 串关都是欧赔
        sportType: p.sportType,
        sport_type: p.sportType,
        wagerSelectionId: p.wagerSelectionId,
        awayName: p.away,
        homeName: p.home,
        leagueName: p.leagueName,
        matchTime: p.matchTime,
        keyName: p.keyName,
        maxBet: p.maxBet,
        minBet: p.minBet
      }));

    const [res, [data]] = await doBetApi([
      {
        OpenParlay: true,
        parlay_odds_option: true,
        sort: '0',
        oddsOption: optionsStore.value.preference,
        bet_matches: matches,
        //customerIP: '127.0.0.1',
        bet_combos: array.map(a => ({
          combo_type: a.comboSelection,
          stake: a.stake,
          bet_count: a.bet_count,
          betBuilderOdds: a.betBuilderOdds,
          comboPrice: Number(a.odds)
        }))
      }
    ]);
    state.loading = false;
    if (res && data) {
      if (!data.current_combos) return Toast(data.message);
      state.betResultList = [];
      state.betComoResult = (data.current_combos || []).map(d => ({
        isSuccess: d.code === 1,
        betStatus: d.betStatus,
        winAmount: d.estimatedPayoutFullAmount,
        ticketStatus: d.ticketStatus,
        betCount: d.bet_count,
        comboPrice: d.combo_price,
        comboType: mulTypeToName(d.combo_type),
        stake: d.stake
      }));
      if (state.betComoResult.length) {
        state.betted = true;
        optionsStore.value.betSuccessSound && play && play();
        onHandleFresh();
      } else {
        //         100 成功
        // 101 无效时间戳
        // 102 无效令牌
        // 103 无效货币编码
        // 104 会员账户被锁
        // 105 无效转账引用 ID
        // 106 重复转账引用 ID
        // 107 无效开始日期时间
        // 108 无效结束日期时间
        // 109 无效最后更新日期时间
        // 201 货币编码不符
        // 202 无效用户名
        // 205 会员不活跃
        // 301 无效操作类型 ID
        // 302 无效更新值
        // 303 无效金额
        // 304 余额不足
        // 305 无效语言编码
        // 306 无效水位分组 ID
        // 308 更新会员信息失败
        // 310 无效日期筛选类型
        // 311 无效日期范围
        // 312 无记录
        // 313 无效体育 ID
        // 314 转账失败
        // 315 无效投注状态
        // 317 无效最后更新日期的日期
        // 范围
        // 400 系统错误
        Toast(get(data, 'message'));
      }
      state.betted = true;
    } else {
      Toast(t('lang.sport_bet_failRetry'));
      state.betted = false;
      state.loading = false;
    }
  };

  const handleBet = () => {
    // 投注
    clearTimer(TimerEnum.PREBET);
    if (state.loading) return;
    if (betAmount.value && betAmount.value > sportBalance.value) {
      return Toast(t('lang.sport_bet_noMoreMoney'));
    }
    const arraySingle = prebetInfo.value.filter(item => {
      return Number(item.stake) && !item.singleClosed && item.price && Number(item.stake) >= Number(item.min_bet);
    }); // 过滤单注可投

    // 单注
    if (arraySingle.length) {
      doSingleBet(arraySingle);
    }

    const array = Array.from(state.betParlyCombo.values()).filter(
      current =>
        current.stake &&
        Number(current.stake) >= Number(current.min_bet) &&
        Number(current.stake) <= Number(current.max_bet)
    );

    // 串关
    if (array.length) {
      doParlayBet(array);
    }
  };

  // 清除
  const clearAllTickets = () => {
    if (!isEmpty(betTickets.value) && !!TimerEnum.PREBET) {
      !!TimerEnum.PREBET && clearTimer(TimerEnum.PREBET);
      handleDoneBet();
    }
  };

  const deleteTicket = ({
    matchId,
    marketId,
    wagerSelectionId,
    sort
  }: {
    matchId: string;
    marketId: string;
    wagerSelectionId: string;
    sort?: string;
  }) => {
    const match = betTickets.value[matchId];
    let onlyKey = `${marketId}_${wagerSelectionId}`;
    if (sort && !match[onlyKey]) {
      forEach_(betTickets.value[matchId], (value, key) => {
        if (value && value.sort == sort) {
          onlyKey = key;
        }
      });
    }
    // 相同盘
    delete match[onlyKey];
    isEmpty(match) && delete betTickets.value[matchId];
    if (isEmpty(betTickets.value)) {
      clearTimer(TimerEnum.PREBET);
      handleDoneBet();
    }
    state.betParlyCombo = new Map();
    getData();
  };

  // 清除 betTickets等投注相关数据
  const handleDoneBet = () => {
    Object.keys(betTickets.value).forEach(key => {
      delete betTickets.value[key];
    });
    Object.keys(betLimit.value).forEach(key => {
      delete betLimit.value[key];
    });
    const newState = initState();
    Object.keys(state).forEach(key => {
      state[key] = newState[key];
    });
    document.querySelector('body')?.setAttribute('style', 'overflow: auto;');
  };

  // 串关类型国际化
  const mulTypeToName = (mulType: string) => {
    switch (mulType) {
      case 'Doubles':
        return `2${t('lang.sport_bet_connect')}1`;
      case 'Trebles':
        return `3${t('lang.sport_bet_connect')}1`;
      case 'Trixie':
        return `3${t('lang.sport_bet_connect')}4`;
      case 'Lucky7':
        return `幸运7`;
      case 'Fold4':
        return `4${t('lang.sport_bet_connect')}1`;
      case 'Yankee':
        return `洋基`;
      case 'Lucky15':
        return `幸运15`;
      case 'Fold5':
        return `5${t('lang.sport_bet_connect')}1`;
      case 'Canadian':
        return `超级美国佬`;
      case 'Lucky31':
        return `幸运31`;
      case 'Patent':
        return `幸运7`;
      case 'Fold6':
        return `6${t('lang.sport_bet_connect')}1`;
      case 'Heinz':
        return `亨氏`;
      case 'Lucky63':
        return `幸运63`;
      case 'Fold7':
        return `7${t('lang.sport_bet_connect')}1`;
      case 'SuperHeinz' || 'Super Yankee':
        return `超级亨氏`;
      case 'Lucky127':
        return `幸运127`;
      case 'Fold8':
        return `8${t('lang.sport_bet_connect')}1`;
      case 'Goliath':
        return `大亨`;
      case 'Lucky255':
        return `幸运255`;
      case 'Fold9':
        return `9${t('lang.sport_bet_connect')}1`;
      case 'Fold10':
        return `10${t('lang.sport_bet_connect')}1`;
      case 'SuperYankee':
        return `5串26`;
      case 'Super Yankee':
        return `5串26`;
      case 'Block9':
        return `9${t('lang.sport_bet_connect')}520`;
      case 'Block10':
        return `10${t('lang.sport_bet_connect')}1013`;
      case '3串4':
        return `3${t('lang.sport_bet_connect')}4`;
      case '4串11':
        return `4${t('lang.sport_bet_connect')}11`;
      case '5串26':
        return `5${t('lang.sport_bet_connect')}26`;
      case '6串57':
        return `6${t('lang.sport_bet_connect')}57`;
      case '7串120':
        return `7${t('lang.sport_bet_connect')}120`;
      case '9串502':
        return `9${t('lang.sport_bet_connect')}502`;
      case '10串1013':
        return `10${t('lang.sport_bet_connect')}1013`;
      case '8串247':
        return `8${t('lang.sport_bet_connect')}247`;
      default:
        return mulType;
    }
  };

  return {
    addTicket,
    clearAllTickets,
    deleteTicket,
    handleBet,
    showHandicapName,
    needRecharge,
    state,
    currencySymbol,
    betAmount,
    winAmount,
    betLimit,
    betTickets,
    prebetInfo,
    betPrebetInfo,
    handleDoneBet,
    mulTypeToName,
    getData,
    getOdds,
    allCombo,
    getWinAmount,
    singleData,
    singleAllBet,
    singleAllWin,
    parlayData,
    prebetList
  };
}
