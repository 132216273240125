// 深度拾取值
import { pick, isObject } from 'lodash-es';

export function pickValue(source: any, key: string): any {
  return pick(source, [key])[key];
}
export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
  let key: string;
  for (key in target) {
    src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
  }
  return src;
}
