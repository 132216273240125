import { computed, defineComponent, PropType, reactive, ref, withModifiers } from 'vue';
import {
  BetTypeEnum,
  RouteEnum,
  gameTypeDefault,
  gameType1,
  gameType2,
  targetType,
  DataTypeEnum,
  toTime,
  queryToStr
} from '@/common';
import { StoreMarketData, StoreMatchInfo, useDetailHook, useI18n, useAuth } from '@/hooks';
import { useFavortMatch } from '@/hooks/use365mobileFavortMatch.hook';
import { FavoritStar, TeamLogo } from '365-mobile/components';
import { useBet } from '@/hooks/use365Bet.hook';
import { useRoute, useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import MatchOdds from './odds/index';
import { isEmpty } from 'lodash-es';
import './market-item.less';

// '让球', '大小', '独赢'

const RightInfoRender = defineComponent({
  props: {
    matchData: {
      type: Object as PropType<any>,
      default: () => ({})
    },
    targetTypeActive: {
      type: Number,
      default: 0
    },
    isChildShow: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const outTarget = ref(null);
    const notOutTarget = ref(null);
    const showEventsPop = ref(false);
    const route = useRoute();

    onClickOutside(outTarget, () => (showEventsPop.value = false), { ignore: [notOutTarget] });
    const periodId = computed(() => Number(route.query.periodId || 3));

    return () => (
      <div class="right-info">
        {showEventsPop.value && !isEmpty(props.matchData.moreInfo) ? (
          <div class={'favo-team-pop'} ref={notOutTarget} onClick={withModifiers(() => {}, ['stop'])}>
            <div class={'favo-team-pop-item'}>
              {/*左右结构*/}
              <div class={'favo-team-pop-logo'}>
                <TeamLogo
                  class={'favo-team-pop-logo-img'}
                  sportId={props.matchData.sportId}
                  teamId={props.matchData.homeId}
                  teamName={props.matchData.home}
                  logoUrl={props.matchData.homeLogo}
                />
                <TeamLogo
                  class={'favo-team-pop-logo-img'}
                  sportId={props.matchData.awayId}
                  teamName={props.matchData.away}
                  logoUrl={props.matchData.awayLogo}
                />
              </div>
              <div class={'favo-team-pop-right'}>
                {/*右边每一列*/}
                {props.matchData.moreInfo.length &&
                  props.matchData.moreInfo.map((item, index) => (
                    <div class={'favo-team-pop-flex'}>
                      <p>
                        <svg-icon name={targetType[index].icon} />
                      </p>
                      <p>{item[0]}</p>
                      <p>{item[1]}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : null}
        <div class="team-num">
          {props.isChildShow &&
          !isEmpty(props.matchData.moreInfo) &&
          props.matchData.moreInfo[props.targetTypeActive].length ? (
            <span class="green-card">
              {props.matchData.moreInfo[props.targetTypeActive][0]}
              {targetType[props.targetTypeActive].isPercent ? '%' : null}
            </span>
          ) : null}
          {props.matchData.score ? <span class={'team-score hight'}>{props.matchData.score[0]}</span> : null}
        </div>
        <div class="team-num">
          {props.isChildShow &&
          !isEmpty(props.matchData.moreInfo) &&
          props.matchData.moreInfo[props.targetTypeActive].length ? (
            <span class="green-card">
              {props.matchData.moreInfo[props.targetTypeActive][1]}
              {targetType[props.targetTypeActive].isPercent ? '%' : null}
            </span>
          ) : null}
          {props.matchData.score ? <span class="team-score hight">{props.matchData.score[1]}</span> : null}
        </div>
        <div
          v-show={
            [DataTypeEnum.Live].includes(periodId.value) &&
            props.matchData.sportId == 1 &&
            !isEmpty(props.matchData.moreInfo)
          }
          class="favo-team-right"
          onClick={withModifiers(() => {
            showEventsPop.value = !showEventsPop.value;
          }, ['self', 'stop'])}
          ref={outTarget}
        ></div>
      </div>
    );
  }
});

export default defineComponent({
  name: 'MarketItem',
  props: {
    data: {
      type: Object as PropType<StoreMarketData>,
      default: () => ({})
    },
    betType: {
      type: Number,
      default: BetTypeEnum.proposition
    },
    gameTypeActive: {
      type: Number,
      default: gameTypeDefault
    },
    targetTypeActive: {
      type: Number,
      default: 0
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    isChildShow: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openPop'],
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { addTicket, betTickets } = useBet(true);
    const { mark, isMarkedById } = useFavortMatch();
    const { store } = useDetailHook();
    const { t } = useI18n();
    const { isLogin } = useAuth();
    // const { checkIsLogin } = useAuth();
    // 显示购物车
    const showCart = computed(() => {
      return !isEmpty(betTickets.value) && isLogin.value;
    });
    const pathToDetail = (matchId: string, sportId: number) => {
      store.showMatchs = false;
      const queryObj = {
        ...route.query,
        matchId: matchId,
        sportId: sportId
      };
      const queryStr = queryToStr(queryObj);
      router.replace(RouteEnum.SPORT_DETAIL + queryStr);
    };
    const sportId = computed(() => Number(props.data.matches[0]?.info.sportId || route.query.sportId || 1));

    const stores = reactive({
      favoritShow: false,
      longTouch: false
    });

    let loop: any = {};
    const onTouchStart = (matchData: any) => {
      clearTimeout(loop);
      stores.longTouch = false;
      loop = setTimeout(() => {
        stores.longTouch = true;
        matchData.isMark = !matchData.isMark;
        mark({ matchId: matchData.id, sportId: matchData.sportId });
      }, 3000);
    };

    const onTouchEnd = () => {
      clearTimeout(loop);
    };

    const MatchInfo = ({ matchData }: { matchData: StoreMatchInfo }) => (
      <div
        class="team-info"
        onTouchstart={async () => {
          // if (!(await checkIsLogin())) return;
          onTouchStart(matchData);
        }}
        onTouchend={() => onTouchEnd()}
        onClick={() => pathToDetail(matchData.id, matchData.sportId)}
      >
        <div class="left-info">
          <div class="home-team">
            <TeamLogo class={'left-info-img'} logoUrl={matchData.homeLogo} />
            <span class="team-name">{matchData.home}</span>
          </div>
          <div class="away-team">
            <TeamLogo class={'left-info-img'} logoUrl={matchData.awayLogo} />
            <span class="team-name">{matchData.away}</span>
          </div>
          <div class="favo-team">
            <div class="favo-team-left">
              <FavoritStar
                v-show={isMarkedById({ matchId: matchData.id, sportId: matchData.sportId })}
                class="favo-team-left-icon"
                // isMarked={matchData.isMark}
                onMark={() => mark({ matchId: matchData.id, sportId: matchData.sportId })}
              />
              <div
                class="favo-team-left-icon"
                style="color: red; --sp-primary-color: #05e79d !important;"
                v-show={matchData.animate}
              >
                <svg-icon name="event_animation" />
              </div>
              <div class="favo-team-left-time">
                {matchData.isLive || Number(route.query.periodId) === 3
                  ? `${t(matchData.period)} ${matchData.time ? matchData.time : ''}`
                  : ''}
                <span>
                  {Number(route.query.periodId) === 1 ? `${toTime(matchData.date, 'MM-DD')} ${matchData.time}` : ''}
                  {Number(route.query.periodId) === 2 ? `${toTime(matchData.date, 'MM-DD')} ${matchData.time}` : ''}
                  {/* {t(matchData.period)} {t(matchData.time)} */}
                </span>
              </div>
              <div class={['icon', !matchData.isLive ? 'is-early' : null]} v-show={matchData.video}>
                <svg-icon name="event_video" />
              </div>
              <div class="more-matches" onClick={() => pathToDetail(matchData.id, matchData.sportId)}>
                <span>{matchData.count || 0}</span>
                <svg-icon name="arrow_right" />
              </div>
            </div>
          </div>
        </div>
        <RightInfoRender
          matchData={matchData}
          isChildShow={props.isChildShow}
          targetTypeActive={props.targetTypeActive}
        />
        {/* <div class="right-info">

          <div class="team-num">
            {props.isChildShow && !isEmpty(matchData.moreInfo) && matchData.moreInfo[props.targetTypeActive].length ? (
              <span class="green-card">
                {matchData.moreInfo[props.targetTypeActive][0]}
                {targetType[props.targetTypeActive].isPercent ? '%' : null}
              </span>
            ) : null}
            {matchData.score ? <span class={'team-score hight'}>{matchData.score[0]}</span> : null}
          </div>
          <div class="team-num">
            {props.isChildShow && !isEmpty(matchData.moreInfo) && matchData.moreInfo[props.targetTypeActive].length ? (
              <span class="green-card">
                {matchData.moreInfo[props.targetTypeActive][1]}
                {targetType[props.targetTypeActive].isPercent ? '%' : null}
              </span>
            ) : null}
            {matchData.score ? <span class="team-score hight">{matchData.score[1]}</span> : null}
          </div>
          <div
            v-show={[DataTypeEnum.Live].includes(periodId.value) && matchData.sportId == 1}
            class="favo-team-right"
            onClick={withModifiers(() => {
              // stores.showEventsPop = !stores.showEventsPop;
            }, ['self', 'stop'])}
            // ref={outTarget}
          ></div>
        </div> */}
      </div>
    );

    return () => (
      <div class={['match-info', showCart.value ? 'expand' : null]}>
        <div class={'match-info-header'}>
          <div class={'league-name'}>
            <TeamLogo class={'league-name-img'} logoUrl={props.data.leagueLogo} />
            <span>{props.data.leagueName}</span>
          </div>
          <div class={'league-scores'}>
            {props.gameTypeActive === 3 && sportId.value === 1
              ? gameType1[props.gameTypeActive]?.bettypeRuleFooterball.map((item, index) => (
                  <i key={index + 'g'}>{t(item)}</i>
                ))
              : sportId.value === 1 || sportId.value === 2 || sportId.value === 16
              ? gameType1[props.gameTypeActive].bettypeRule.map((item, index) => <i key={index + 'g'}>{t(item)}</i>)
              : gameType2[props.gameTypeActive].bettypeRule.map((item, index) => <i key={index + 'g'}>{t(item)}</i>)}
          </div>
        </div>
        <div class={'match-data'}>
          {props.data.matches.map(matchItem => (
            <>
              <div
                class={['match-markets', route.query.matchId == matchItem.info.id ? 'selected' : null]}
                key={matchItem.info.id}
              >
                <MatchInfo matchData={matchItem.info} />
                <MatchOdds
                  onAddBetTicket={sel =>
                    addTicket({
                      ...sel,
                      home: matchItem.info.home,
                      away: matchItem.info.away,
                      leagueName: props.data.leagueName,
                      market: matchItem.info.market,
                      matchTime: matchItem.info.matchTime
                    })
                  }
                  betType={props.betType}
                  oddset={matchItem.oddset}
                  sportId={matchItem.info.sportId}
                  matchId={matchItem.info.id}
                  gameTypeActive={props.gameTypeActive}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    );
  }
});
