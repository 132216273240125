/**
 * 设置盘口
 */

import { useStorage, RemovableRef } from '@vueuse/core';
import { MarketType, OPTIONS_SETTING_KEY, PreferenceEnum, ThemeType } from '@/common';
import { languageList, LocaleType } from '@/i18n/localeTypes';

export type Currency = 'CNY' | 'USD' | 'JPY' | 'KRW' | 'VND' | 'IDR' | 'MYR' | 'INR' | 'MMK' | 'THB';

interface OptiosStore {
  /**
   * 盘口
   */
  marketType: MarketType;
  /**
   * 投注偏好设置
   */
  preference: PreferenceEnum;
  /**
   * 音效设置
   */
  switchSound: boolean;
  /**
   * 投注成功音效
   */
  betSuccessSound: boolean;
  /**
   * 投注确认中音效
   */
  confirmationSound: boolean;
  /**
   * currency symboy
   * 货币符号
   */
  currency: Currency;
  /**
   * 语言
   */
  lang: LocaleType;
  /**
   * 主题
   */
  theme: ThemeType;
}

const optionsStore: RemovableRef<OptiosStore> = useStorage(OPTIONS_SETTING_KEY, {
  marketType: MarketType.Europe,
  preference: PreferenceEnum.AutoAcceptedAny,
  lang: localStorage.getItem('lang') == null ? 'zh-CN' : localStorage.getItem('lang') || languageList[0].seriNo,
  switchSound: true,
  currency: 'CNY',
  betSuccessSound: true,
  confirmationSound: false,
  theme: 'default'
});

export function useSportOptions() {
  // 更改设置
  const changeSetiing = (optionKey: string, value: any) => {
    if (optionsStore.value[optionKey] === value) return;
    optionsStore.value[optionKey] = value;
  };

  return {
    changeSetiing,
    optionsStore
  };
}
