
import { computed, onMounted, watch } from 'vue';
import { Header as SportHeader, DevTools, Container } from 'kg-web/components';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { RouteEnum } from '@/common';

export default {
  components: { SportHeader, Container, DevTools },
  setup() {
    const store = useStore();
    const route = useRoute();

    const showHeader = computed(() => {
      return !!route.meta.showHeader;
    });

    const whiteRoutes = [RouteEnum.ROOT, RouteEnum.HOME, RouteEnum.SPORT_DETAIL] as string[];
    const showRoute = whiteRoutes.includes(route.path);
    watch(
      () => route.path,
      val => {
        if (val === RouteEnum.ROOT || val === RouteEnum.HOME) {
          const body = document.querySelector('body');
          body?.classList.add('scroll');
        }
      }
    );
    // 订阅赛事数据ws
    onMounted(async () => {
      document.title = 'Bet365';
      Promise.all([
        store.dispatch('CHECK_IS_LOGIN'),
        store.dispatch('SUBSCRIBE_SPORTS_TECH'),
        store.dispatch('GET_SPORT_MEDIA_DATA', { source: 'im' }),
        store.dispatch('UPDATE_BLANCE')
      ]);
      if (route.path === RouteEnum.ROOT || route.path === RouteEnum.HOME) {
        const body = document.querySelector('body');
        body?.classList.add('body-scroll');
      }
    });

    // 非测试环境不显示
    const sitDevTools = window.DEBUG;

    return { showHeader, sitDevTools, showRoute };
  }
};
