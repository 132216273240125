import { virtualClearInterval, virtualInterval } from '@/common';
import { reactive } from 'vue';
export enum TimerEnum {
  // 菜单列表
  MENU = 'menuTimer',
  //  赛事列表
  LIST = 'listTimer',
  //   赛事详情
  DETAIL = 'detailTimer',
  //   预投注
  PREBET = 'preBetTimer',
  // web赛事列表
  SPORT_LIST = 'webSportList',
  // 注单列表
  ORDER_LIST = 'orderList'
}
const store: { [key in TimerEnum]: any } = reactive({
  [TimerEnum.MENU]: null,
  [TimerEnum.LIST]: null,
  [TimerEnum.DETAIL]: null,
  [TimerEnum.PREBET]: null,
  [TimerEnum.SPORT_LIST]: null,
  [TimerEnum.ORDER_LIST]: null
});
export function useTimerHook() {
  const addTimer = (key: TimerEnum, fn: Function, time = 5000) => {
    virtualClearInterval(store[key]);
    fn();
    store[key] = virtualInterval(fn, time);
  };
  const clearTimer = (key: TimerEnum) => {
    virtualClearInterval(store[key]);
    store[key] = null;
  };
  return { addTimer, clearTimer, store };
}
