import { AxiosRequestConfig, AxiosError } from 'axios';
import qs from 'qs';
import md5 from 'blueimp-md5';
import { createHttp } from './src';
import { HttpCode } from '@/common/enums';
import { getLoginData, clearLoginData, getTenant, getSportApi, langFormat } from '@/common/utils';
import { isEmpty, isUndefined, isNull } from 'lodash-es';
import { getOsType } from '.';
import { useSportOptions } from '@/hooks';
import store from '@/store';
import { useBetWeb } from '@/hooks/useBetWeb.hook';

const APP_VERSION = process.env.VUE_APP_VERSION;

export const httpSB = createHttp({
  baseURL: getSportApi(),
  timeout: 20000
});

function urlEncode(param: any, key?: string, encode?: string) {
  if (param == null) return '';
  let paramStr = '';
  const t = typeof param;
  if (t == 'string' || t == 'number' || t == 'boolean') {
    paramStr += `&${key}=${encode == null || encode ? encodeURIComponent(param) : param}`;
  } else {
    for (const i in param) {
      const k = key == null ? i : key + (param instanceof Array ? `[${i}]` : `.${i}`);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
}

// H5头参数
function getH5Header() {
  const { optionsStore } = useSportOptions();
  const timestamp = Date.now();
  const signParams: any = {};
  const { token } = getLoginData();
  signParams['device-id'] = 'no-uuid';
  signParams['os-type'] = getOsType();
  signParams.timestamp = timestamp;
  signParams.version = APP_VERSION || '1.0.0';
  const urlStr = urlEncode(signParams).substr(1);
  signParams.sign = md5(`${urlStr}global`);
  signParams['app-type'] = 1;
  // TODO
  if (!!token) {
    signParams.token = token;
    signParams.uid = '';
  }
  signParams.country = langFormat(optionsStore.value.lang);
  signParams.tenant = getTenant();
  return signParams;
}

httpSB.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    if (config.method === 'post') {
      const params = config.params;
      if (typeof params === 'object') {
        config.data = qs.stringify(params, { allowDots: true });
      }
    }
    config.headers = Object.assign(
      {
        'Content-Type': 'application/json',
        ...(await getH5Header())
      },
      config.headers
    );
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

httpSB.interceptors.response.use(
  function (response: any) {
    if (response.code === HttpCode.FAIL) {
      return [false, response.message || response, response.code];
    }
    if (response.code === HttpCode.SUCCESS || response.code === 100) {
      return [true, response.data, response.code];
    }
    if (response.code == 112) {
      clearLoginData();
      // 清除购物车信息
      const { clearAllTickets } = useBetWeb(true);
      clearAllTickets();
      store.dispatch('SIGN_OUT');
      return [false, { msg: response.message, code: response.code }, response.code];
    }
    /**
     * 特殊101错误 system error
     */
    if (response.code === 101) {
      return [true, [], response.code];
    }
    /**
     * 特殊处理部分接口数据结构混乱
     */
    if (response.code === undefined) {
      return response;
    }
    if (response.code === HttpCode.AUTH_FAIL) {
      return [false, response.message || 'Login Fail!', response.code];
    }
    if (response && isEmpty(response)) {
      return [false, undefined, response?.code];
    }
    if (response && (isUndefined(response.code) || isNull(response.data))) {
      return [false, response ? response.data : undefined, response.code];
    }
    if (response.code === HttpCode.EXPIRE || response.code === HttpCode.KICK) {
      // 退出登录到login
      // 如果是APP登录失效 退会app 登录
      clearLoginData();

      return [false, response, response.code];
    }
    // captureError(data, signParams);
    return [false, response, response.code];
  },
  function (error: AxiosError<any>) {
    const response = { code: (error.response && error.response.status) || -999, message: error.message };
    if (error.response && error.response.status) {
      if (error.response.status === 404) {
        response.message = 'ERROR 网络请求丢失';
      } else if ([500, 502, 503, 504].includes(error.response.status)) {
        response.message = 'ERROR 服务器维护中';
      } else if ([501].includes(error.response.status)) {
        response.message = 'ERROR 网络请求错误';
      } else if (error.response.statusText.includes('timeout')) {
        response.message = 'ERROR 请求超时';
      } else {
        response.message = 'ERROR 请求失败!';
      }
    }
    return Promise.resolve([false, response, response.code]);
  }
);

window.http = httpSB;
