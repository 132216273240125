import { defineComponent, PropType } from 'vue';
import emptyStyle from './empty.module.less';
import NO_OTHER_IMG from './assets/no_other.png';
import NO_MSG_IMG from './assets/no_mesaage.png';
import NO_RECORD_IMG from './assets/no_record.png';
import NO_ODDS_IMG from './assets/no_odds.png';
import NO_NOTICE_IMG from './assets/no_notice.png';
import NO_RESULT_IMG from './assets/no_result.png';
import NO_ORDERS_IMG from './assets/no_orders.png';

const IMGS = {
  other: NO_OTHER_IMG,
  message: NO_MSG_IMG,
  record: NO_RECORD_IMG,
  odds: NO_ODDS_IMG,
  notice: NO_NOTICE_IMG,
  result: NO_RESULT_IMG,
  orders: NO_ORDERS_IMG
};

export default defineComponent({
  name: 'DataEmpty',
  props: {
    desc: {
      default: '',
      type: String
    },
    title: {
      default: '',
      type: String
    },
    type: {
      default: 'other',
      type: String as PropType<'other' | 'message' | 'record' | 'odds' | 'notice' | 'result' | 'orders'>
    },
    textColor: {
      default: '',
      type: String
    }
  },
  setup(props) {
    return () => (
      <div class={emptyStyle.empty}>
        <img src={IMGS[props.type]} />
        <div class={emptyStyle.dsc}>
          <span style={{ color: props.textColor }}>{props.title}</span>
          {/*<span>{props.desc}</span>*/}
        </div>
      </div>
    );
  }
});
