import { defineComponent, Transition, computed } from 'vue';
import { SvgIcon } from '@/components';
import loading from './loading.module.less';

export default defineComponent({
  name: 'Loading',
  inheritAttrs: false,
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loadingIcon: {
      type: String,
      default: 'loading_v2'
    },
    text: {
      type: String,
      default: 'Loading...'
    }
  },
  emits: ['update:visible'],
  setup(props) {
    const loadingText = computed<string[]>(() => {
      return props.text.split('');
    });

    const css_var: any = [];

    loadingText.value.forEach((_item: string, index: number) => {
      css_var.push({ '--i': index });
    });

    return () => (
      <Transition name="fade" appear>
        <div class={loading.loading} v-show={props.visible}>
          <div class={loading.loadingModel}>
            <div class={loading.loadingModelImg}>
              <SvgIcon name={props.loadingIcon} />
            </div>
            <div class={loading.loadingModelText}>
              {loadingText.value.map((text: string, i: number) => (
                <i style={css_var[i]}>{text}</i>
              ))}
            </div>
          </div>
        </div>
      </Transition>
    );
  }
});
