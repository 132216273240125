// 菜单
export enum Tag {
  HOST = 'host',
  SOCCERBALL = 'soccerBall',
  BASKETBALL = 'basketball',
  E_SPORT = 'e-sport'
}

// 直播视频类型
export enum LiveType {
  VIDEO = 'video',
  ANIME = 'anime'
}

// 直播状态
export enum LiveStatus {
  // 直播中
  LIVE = 'live',
  // 未开始
  WAIT = 'wait'
}

// 折叠菜单
export enum Collapse {
  // 聊天室
  CHAT = 'chat',
  // 数据分析
  ANALYZE = 'analyze'
}
