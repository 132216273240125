import { ThemeEnum, cssRootVar, ThemeType } from '@/common';

const themes = {
  default: {
    '--van-primary-color': ThemeEnum.green,
    '--sp-primary-color': ThemeEnum.green,
    '--sp-bg-color': '#f2f2f2',
    '--sp-white-color': '#fff',
    '--sp-border-color': '#f2f2f2',
    '--sp-tabbar-height': '50px'
  },
  golden: {
    '--van-primary-color': ThemeEnum.golden,
    '--sp-primary-color': ThemeEnum.golden
  },
  green: {
    '--van-primary-color': ThemeEnum.green,
    '--sp-primary-color': ThemeEnum.green,
    '--sp-bg-color': '#f2f2f2',
    '--sp-white-color': '#fff',
    '--sp-border-color': '#f2f2f2'
  },
  darkGreen: {
    '--van-primary-color': ThemeEnum.darkGreen,
    '--sp-primary-color': ThemeEnum.darkGreen,
    '--sp-bg-color': '#f2f2f2',
    '--sp-white-color': '#fff',
    '--sp-border-color': '#f2f2f2'
  },
  dark: {
    '--van-primary-color': ThemeEnum.darkGold,
    '--sp-primary-color': ThemeEnum.darkGold,
    '--sp-bg-color': '#f2f2f2',
    '--sp-white-color': '#333',
    '--sp-border-color': ThemeEnum.darkGold
  }
};

// 设置主题
export async function setTheme(theme: ThemeType = 'default') {
  cssRootVar(themes[theme]);
}
