import { createVNode, render } from 'vue';
import ToastComp from './toast';

interface ToastOptions {
  text?: string;
  loadingIcon?: string;
}

let _vm: any = null;
const microAppRoot = document.getElementById('microApp');

function Laoding(options?: ToastOptions) {
  const app = createVNode(ToastComp, { ...options, visible: true });
  const root = document.createElement('div');
  root.classList.add('--sp-laoding-container--');
  const currentLoading = (_vm = microAppRoot?.appendChild(root));
  render(app, root);
  return currentLoading;
}

// 显示
Laoding.show = (options?: ToastOptions) => Laoding(options);

// 隐藏
Laoding.hide = (currentLoading?) => {
  if (currentLoading) {
    microAppRoot?.removeChild(currentLoading);
  } else {
    if (_vm) {
      microAppRoot?.removeChild(_vm);
      _vm = null;
    }
  }
};

export { Laoding };
