import { computed, defineComponent, onMounted } from 'vue';
import style from './result.module.less';
import { Skeleton, Select, SelectOption } from 'ant-design-vue';

import { LeagueFilter, DataEmpty, TeamLogo, Scrollbar, Collapse, CollapsePanel, SelectView } from 'kg-web/components';
import { useEventResult, LAST_7_DAYS, useI18n } from '@/hooks';
import { isEmpty } from 'lodash-es';

import ResultItem from './item';

export default defineComponent({
  name: 'JBBsportPersonal',
  setup() {
    const { getData, state, openLeagues, onHandleDate, onHandleSport, form } = useEventResult();
    const { t } = useI18n();

    const sourceList = computed(() => {
      return Object.keys(state.matchMapByLeagueId);
    });

    onMounted(getData);

    const HeaderRender = () => {
      return (
        <div class={style.resultHeader}>
          <div class={style.labelTitle}>
            {/* 赛事 */}
            {t('lang.sport_common_match')}
          </div>
          <div class={style.sortSwitch}>
            <SelectView>
              <Select
                v-model:value={state.currentTimeIndex}
                onChange={(v: any) => onHandleDate(v, true)}
                placeholder={t(LAST_7_DAYS[0].week)}
              >
                {LAST_7_DAYS.map((item, index) => (
                  <SelectOption key={index} value={index}>
                    {`${t(item.week)} (${item.date})`}
                  </SelectOption>
                ))}
              </Select>
            </SelectView>
          </div>
          <div class={style.sortSwitch}>
            <SelectView>
              <Select
                v-model={form.sportId}
                onChange={(v: any) => onHandleSport(v, true)}
                placeholder={state.sportTypes[0]?.sportTypeName}
              >
                {state.sportTypes.map((item, index) => (
                  <SelectOption key={index} value={item.sportType}>
                    {item.sportTypeName}
                  </SelectOption>
                ))}
              </Select>
            </SelectView>
          </div>
          <p class={style.labelTitle} onClick={() => openLeagues()}>
            {t('lang.sport_filterLeague_allLeagues')}
          </p>
        </div>
      );
    };

    return () => (
      <div class={style.tabContainer}>
        <HeaderRender />
        <Skeleton loading={state.loading} paragraph={{ rows: 8 }}>
          <Scrollbar class={style.result}>
            {!isEmpty(sourceList.value) ? (
              <div>
                {sourceList.value.map((key, index) => (
                  <Collapse key={index} class="result-l-name" v-model={state.expandKeys}>
                    <CollapsePanel
                      class="Collapse"
                      v-slots={{
                        header: () => (
                          <div class={style.leagueName}>
                            <TeamLogo
                              size={'xs'}
                              sportId={state.matchMapByLeagueId[key][0]?.gameId}
                              teamId={state.matchMapByLeagueId[key][0]?.leagueName}
                            />
                            {state.matchMapByLeagueId[key][0]?.leagueName}
                          </div>
                        )
                      }}
                    >
                      {!isEmpty(state.matchMapByLeagueId[key]) &&
                        state.matchMapByLeagueId[key].map((info: any) => <ResultItem info={info} key={info.matchId} />)}
                    </CollapsePanel>
                  </Collapse>
                ))}
              </div>
            ) : (
              <DataEmpty type={'result'} title={t('lang.sport_common_noAnyData')} />
            )}
          </Scrollbar>
        </Skeleton>
        {state.showLeagus ? (
          <div class={style.leaguePop}>
            <LeagueFilter
              isResult
              v-model={form.leagueId}
              sportId={form.sportId}
              onConfirm={getData}
              time={{
                startTime: LAST_7_DAYS[state.currentTimeIndex].startTime,
                endTime: LAST_7_DAYS[state.currentTimeIndex].endTime
              }}
              onClose={() => openLeagues()}
            />
          </div>
        ) : null}
      </div>
    );
  }
});
