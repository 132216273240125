import { HttpResponse } from '@/types/global';
import { http, toTime, MarketType, httpSB } from '@/common';
import qs from 'qs';
import { useSportOptions, localMarketedMatchs } from '@/hooks';
import {
  EventSummaryApi,
  // ClassifyItem,
  PlaceBetPrams,
  LeagueListPrams,
  SingleTicketData,
  EventsAndMarketsPrams,
  PlaceParlayBetData,
  ChampionMarket,
  mainChamp,
  // OddTypsList,
  MatchItem,
  EventsAndMarkets
} from './types';
import { isEmpty, concat } from 'lodash-es';
import { ParlayTicketPrams, SingleTicketPrams, PlacePreBetData, LeagueItem } from '@/services';
import { GetMessageForm } from '../types/global.interface';

/**
 * @description: 可以获取到滚球、早盘、今日等类目的体育项目类型数量
 * @param language 语种
 * @return {Promise<HttpResponse>}
 */
const getEventSummaryApi = (): Promise<[boolean, EventSummaryApi[]]> => {
  return http.post('/agent/getEventSummary');
};

/**
 * 03:获取赛事和市场数据
 * @description: 传体育运动项目id、赛事区间、赛事id取得当下的赛事盘口信息
 * @param {number} oddsType 盘口类型 1、马来盘 2、 香港盘 3、欧洲盘 4、印度尼西亚盘 5、美国盘
 * @param {string} sportId 体育项目ID
 * @param {number} periodId 赛事区间 1: Today 今日(预设) 、2: Early 早盘 、3: Live 滚球
 * @param {string} leagueIds 联赛ids,以,隔开
 * @param {number} selectDate 查询向后指定天数偏移的matches(赛事)数据，传值范围：1-7，当periodId传值为2时，此值才会生效。
 * @param {string} eventIds 指定赛事 ID 来获取赛事状态(包括半场比分数据)
 * @return {Promise<HttpResponse>}
 */

const queryEventsAndMarkets = ({
  oddsType,
  sportId,
  periodId,
  leagueIds,
  queryCount,
  pageNo,
  selectDate,
  eventIds,
  isParlay
}: EventsAndMarketsPrams): Promise<HttpResponse> => {
  return http.post<HttpResponse<EventsAndMarkets[]>>('/agent/queryEventsAndMarkets', {
    oddsType,
    sportId,
    periodId,
    leagueIds: !!leagueIds ? leagueIds.join(',') : '',
    queryCount,
    pageNo,
    selectDate,
    eventIds,
    isParlay
  });
};

const getMarketData = ({
  sportId,
  periodId,
  leagueIds,
  selectDate,
  pageNo,
  isMatchTimeSort,
  pageSize,
  isParlay
}: EventsAndMarketsPrams): Promise<HttpResponse> => {
  const pageSizeNumber = () => {
    // 滚球和关注不使用分页功能
    if (periodId == 3 || periodId == 0) {
      pageNo = 0;
      return 999;
    }
    return pageSize;
  };
  const { optionsStore } = useSportOptions();
  let ids: string[] = [];
  localMarketedMatchs.value.forEach((item, i) => {
    if (i === -1) return;
    ids = concat(ids, item);
  });
  const getEventIds = () => {
    return periodId === -1 ? ids : [];
  };

  if (periodId === -1 && isEmpty(ids)) {
    return [true, []] as any;
  }

  const pramsObj = {
    oddsType: optionsStore.value.marketType,
    sportId,
    periodId: periodId == -1 ? -1 : periodId,
    leagueIds: !!leagueIds ? leagueIds.join(',') : '',
    selectDate,
    eventIds: getEventIds().join(','),
    pageNo,
    pageSize: pageSizeNumber(),
    isParlay,
    isMatchTimeSort
  };

  Object.keys(pramsObj).forEach(
    key => (pramsObj[key] == null || pramsObj[key] == undefined || String(pramsObj[key]) == '') && delete pramsObj[key]
  );
  return http.post<HttpResponse<any[]>>('/agent/queryEventsAndMarketsPage', pramsObj);
};

const QueryEventsChampions = ({ oddsType, sportId, periodId }: ChampionMarket): Promise<HttpResponse> => {
  return http.post<HttpResponse<mainChamp[]>>('/agent/queryChampionEventAndMarket', {
    oddsType,
    sportId,
    periodId
  });
};

/**
 * 05:联赛列表数据
 * @description: 通过体育运动项目id、赛事区间获取联赛列表数据
 * @param {number} periodId 赛事区间 1: Today 今日(预设) 、2: Early 早盘 、3: Live 滚球
 * @param {number} sportId 体育项目ID
 * @return {Promise<HttpResponse>}
 */
const queryLeagueList = (prams: LeagueListPrams): Promise<[boolean, LeagueItem[]]> => {
  return http.post('/agent/queryLeagueList', prams);
};

// const getBetTypes = (): any => {
//   return httpSB.post('/agent/getBetypes');
// };

const getBetTypes = ({ oddsType, commonly }: { oddsType: number; commonly: boolean }): any => {
  return http.post<HttpResponse<any>>('/agent/getBetypes?' + qs.stringify({ oddsType: oddsType, commonly: commonly }));
};

const getOddTypes = (): any => {
  return http.get<HttpResponse<any>>('/agent/getOddTypes');
};

/**
 * 06:单条预投注
 * @description: 用于更新单注的盘口数据
 * @param {number} sportType 体育项目ID
 * @param {string} marketId 赔率ID
 * @param {string} key 从queryEventsAndMarkets接口取得,并可参考沙巴附件-投注类型与选项
 * @param {number} oddsType 盘口类型 1、马来盘 2、 香港盘 3、欧洲盘 4、印度尼西亚盘 5、美国盘
 * @return {Promise<HttpResponse>}
 */
const getSingleTicket = (params: any): Promise<HttpResponse> => {
  return http.post<HttpResponse<SingleTicketData>>('/agent/getSingleTicket', params);
};

/**
 * 07:单条投注
 * @description: 用户单条投注
 * @param {number} sportType 体育项目ID
 * @param {string} marketId 赔率ID
 * @param {string} price 赔率
 * @param {string} stake 投注金额
 * @param {string} point 点数
 * @param {number} oddsOption 赔率选项 0:不接受盘口变更 (预设)、 1:只接受更好赔率、 2:接受任何赔率
 * @param {number} oddsType 盘口类型 0、默认赔率|1、马来赔率|2、香港赔率|3、Decimal赔率|4、印地赔率|5、美国赔率
 * @param {string} key 从queryEventsAndMarkets接口取得,并可参考沙巴附件-投注类型与选项
 * @return {Promise<HttpResponse>}
 */
const doPlaceBet = ({
  sportType,
  marketId,
  price,
  stake,
  point,
  key,
  oddsType,
  oddsOption
}: PlaceBetPrams): Promise<HttpResponse> => {
  return http.post<HttpResponse<any>>('/agent/doPlaceBet', {
    sportType,
    marketId,
    price,
    stake,
    point,
    oddsType,
    oddsOption,
    key
  });
};

/**
 * 08:串关预投注
 * @description: 用户单条投注
 * @param {number} sportType 体育项目ID
 * @param {string} marketId 赔率ID
 * @param {string} price 赔率
 * @param {string} stake 投注金额
 * @param {string} point 点数
 * @param {number} oddsOption 赔率选项 0:不接受盘口变更 (预设)、 1:只接受更好赔率、 2:接受任何赔率
 * @param {number} oddsType 盘口类型 0、默认赔率|1、马来赔率|2、香港赔率|3、Decimal赔率|4、印地赔率|5、美国赔率
 * @param {string} key 从queryEventsAndMarkets接口取得,并可参考沙巴附件-投注类型与选项
 * @return {Promise<HttpResponse>}
 */
const getParlayTickets = (prams: SingleTicketPrams[]): Promise<HttpResponse> => {
  return http.post<HttpResponse<PlacePreBetData>>('/agent/getParlayTickets', prams);
};

/**
 * 08-a:串关投注
 * @description: 用户串关投注
 * @param {array} matches 串关投注信息，需要组装成jsonArray即数组对象传入，具体值烦请直接对照沙巴文档placeParlayBet接口
 * @param {array} combos 串关投注组合，需要组装成jsonArray即数组对象传入，具体值烦请直接对照沙巴文档placeParlayBet接口
 * @param {string} oddsOption 下注选项 0:不接受盘口变更 (预设)、 1:接受任何赔率
 * @return {Promise<HttpResponse>}
 */
const doPlaceParlayBet = (prams: ParlayTicketPrams): Promise<HttpResponse> => {
  return http.post<HttpResponse<PlaceParlayBetData>>('/agent/placeParlayBet', prams);
};

/**
 * @description: 查获玩法分类
 * @return {Promise<HttpResponse>}
 */
const getClassifyApi = ({
  oddsType
}: {
  oddsType: MarketType;
}): Promise<
  [
    boolean,
    {
      classifyId: number;
      classifyName: string;
    }[]
  ]
> => {
  return http.post('/agent/getClassify', { oddsType });
};

/**
 * @description: 获取投注分类信息
 * @return {*}
 */

// const getPlayClassify = ({ oddsType }: { oddsType: MarketType }): Promise<HttpResponse<ClassifyItem[]>> => {
//   return http.post<HttpResponse<ClassifyItem[]>>('/agent/getClassify', { oddsType });
// };

/**
 * @description: 赛事盘口推荐
 * @param {string} sportId 体育id
 * @param {string} periodId 时间类型
 * @param {string} oddsType 盘口类型
 * @param {string} source
 * @return {*}
 */
// const getRecommendedMarketList = (): Promise<HttpResponse> => {
//   return http.post<HttpResponse<MatchItem[]>>('/agent/recommendedMatchList');
// };

const getRecommendedMarketList = (): Promise<HttpResponse> => {
  const { optionsStore } = useSportOptions();
  return http.post<HttpResponse<MatchItem[]>>('/agent/recommendedMatchList', {
    oddsType: optionsStore.value.marketType
  });
};

/**
 * 批量预投注
 * @param params``
 * @returns
 */
const getParlayApi = (
  params: {
    singleTicketInfo: any;
    OpenParlay: boolean;
    palaryInfoArray: any[] | null;
    sort: string;
  }[]
): Promise<HttpResponse> => {
  return httpSB.post<HttpResponse<MatchItem[]>>('/agent/getBatchTickets1', params);
};

/**
 * 获取赛事列表补充信息
 *
 * @param {string} eventIds 赛事ID  ，隔开
 * @return {*}
 */
const getMatchInfoByIds = (eventIds: string): any => {
  return http.post<HttpResponse<any>>('/sports/im/player/getGameDetail', {
    eventIds
  });
};

/**
 * 批量预投注
 * @param params
 * @returns
 */
const doBetApi = (params: Array<SingleBetParam | ParlyBetParam>): Promise<HttpResponse> => {
  return httpSB.post<HttpResponse<MatchItem[]>>('/agent/doPlaceMultiBet1', params);
};

interface DoBetMustParam {
  OpenParlay: boolean;
  sort: string;
  oddsOption: number;
}
export type SingleBetParam = DoBetMustParam & DoBetParamItem;
export type ParlyBetParam = DoBetMustParam & ParlySelfParam;
interface ParlySelfParam {
  parlay_odds_option: boolean;
  bet_matches: DoBetParamItem[];
  bet_combos: {
    combo_type: number;
    stake: number;
    bet_count: number;
  }[];
}
interface DoBetParamItem {
  betType: string;
  customerIP?: string;
  isComboAcceptAnyOdds: boolean;
  key: string;
  live_away_score: string;
  live_home_score: string;
  market: string;
  marketId: number;
  matchId: string;
  oddsType: number;
  outrightTeamId: number;
  point: number;
  price: string;
  sportType: number;
  stake?: number;
  wagerSelectionId: string;
}

/**
 * @description:赛果接口
 * @param {string} sportId 体育id
 * @param {string} startTime 开始时间
 * @param {string} endTime 结束时间
 * @param {string} leagueId 联赛id列表
 * @return {*}
 */
const getResultsApi = ({
  startTime,
  endTime,
  sportId,
  leagueId
}: {
  sportId: number;
  startTime: number;
  endTime: number;
  leagueId?: string | null;
}): Promise<[boolean, { resultsInfo: any[] }]> => {
  //getMarchResults
  return http.post<HttpResponse<any>>(
    '/agent/getMatchResults?' +
      qs.stringify({
        startTime: toTime(startTime, 'YYYY-MM-DD HH:mm:ss'),
        endTime: toTime(endTime, 'YYYY-MM-DD HH:mm:ss'),
        oddsType: 2,
        sportId: sportId,
        leagueId: leagueId
      })
  );
};

/**
 * @description: 可以获取到滚球、早盘、今日等类目的体育项目类型数量
 * @param language 语种
 * @return {Promise<HttpResponse>}
 */
const getSportLangAPI = ({ language }: { language: string }): any => {
  return http.get<HttpResponse<any>>(
    '/agent/getSportLang?' +
      qs.stringify({
        language: language
      })
  );
};

/**
 * @description: 查获取视频链接
 * @return {Promise<HttpResponse>}
 */
const getSportVideoByMatchID = (apiUrl: string): Promise<HttpResponse<any>> => {
  return http.get<HttpResponse<any>>(apiUrl);
};

const getPublicMessage = (prams: GetMessageForm): any => {
  return http.post<HttpResponse<any>>('/agent/getPublicMessage', prams);
};

/**
 * 获取用户余额
 * @description:
 * @return {Promise<HttpResponse>}
 */
const getBalance = (): Promise<
  [
    boolean,
    (
      | {
          balance: number;
          currency: string;
          userName: string;
        }
      | string
    )
  ]
> => {
  return http.get('/agent/balance');
};

export {
  getPublicMessage,
  doPlaceBet,
  getBalance,
  getSportVideoByMatchID,
  queryLeagueList,
  getBetTypes,
  getSingleTicket,
  getParlayTickets,
  doPlaceParlayBet,
  getMatchInfoByIds,
  getRecommendedMarketList,
  getSportLangAPI,
  queryEventsAndMarkets,
  getMarketData,
  getEventSummaryApi,
  getParlayApi,
  doBetApi,
  getResultsApi,
  getClassifyApi,
  getOddTypes,
  QueryEventsChampions
};
