import { defineComponent } from 'vue';
import { currency, CurrencyIcons } from '@/common';
import { SvgIcon } from '@/components';
import { useBalance, useSportOptions } from '@/hooks';
import style from './balance.module.less';

const url = window.location.href;
const searchParams = new URLSearchParams(url).get('currency')?.split('#')[0];
const curr = searchParams;

export default defineComponent({
  name: 'HeaderBalance',
  setup() {
    const { optionsStore } = useSportOptions();
    const { sportBalance, isFreshing, onHandleFresh_365 } = useBalance();

    return () => (
      <div class={style.balanceInfo} onClick={() => onHandleFresh_365()}>
        <div
          class={style.iconMoney}
          style={{ backgroundImage: `url(${CurrencyIcons[optionsStore.value.lang] || CurrencyIcons.none})` }}
        />
        <span class={style.textMoney}>
          {currency(sportBalance.value)} {curr}
          {/* {optionsStore.value.currency} */}
        </span>
        <div class={style.iconRecharge}>
          <div class={style.loadingIcon}>
            <SvgIcon name="refresh" class={[isFreshing.value ? style.isLoad : null]} />
          </div>
        </div>
      </div>
    );
  }
});
