import { isEmpty } from 'lodash-es';
import { getLiveList, getAnchorList } from '@/services';
import { reactive } from 'vue';
import { LiveMatchItem } from '@/services/types/global.interface';
import { toTime } from '@/common';

export interface LiveData {
  // 直播中
  liveGroup: MatchInfoCard[];
  // 未开始
  noLiveGroup: MatchInfoCard[];
}

export interface MatchInfoCard {
  homeId: number | string;
  awayId: number | string;
  home: string;
  away: string;
  homeLogo: string;
  awayLogo: string;
  score: [number, number];
  status: number;
  videoApi: string;
  time: any;
  date?: any;
  id: any;
  league: string;
  startTime?: any;
  anchorNick?: string;
  cover?: string;
}

const initData: LiveData = {
  liveGroup: [],
  noLiveGroup: []
};

// 数据格式化
function _formatLiveListData(data: LiveMatchItem[]): LiveData {
  if (isEmpty(data)) return initData;
  const tempList: MatchInfoCard[] = [];
  data.map(e => {
    tempList.push({
      home: e.home,
      away: e.away,
      homeId: e.home,
      awayId: e.away,
      score: [Number(e.homeScore) || 0, Number(e.awayScore) || 0],
      status: e.status,
      videoApi: e.vvideoListUrl,
      time: e.matchTime,
      id: e.matchId,
      homeLogo: e.hmicon,
      awayLogo: e.awicon,
      league: e.league
    });
  });
  initData.liveGroup = tempList.filter(e => e.status === 1);
  initData.noLiveGroup = tempList.filter(e => e.status === 0);
  return initData;
}

// 数据格式化
function _formatAnchorListData(data: any[]): any[] {
  if (isEmpty(data)) return [];
  const tempList: MatchInfoCard[] = [];
  data.map(e => {
    tempList.push({
      home: e.homeName,
      away: e.awayName,
      homeId: e.homeName,
      awayId: e.awayName,
      score: [Number(e.homeScore) || 0, Number(e.awayScore) || 0],
      status: e.liveStatus === 1 ? 1 : 0,
      videoApi: e.m3u8 || e.videoSource.m3u8,
      time: e.startTime,
      id: e.liveRoomId,
      homeLogo: e.hmicon,
      awayLogo: e.awicon,
      league: e.league,
      anchorNick: e.hostName,
      cover: e.hostPhotoId,
      date: e.startDate,
      startTime: toTime(`${e.startDate} ${e.startTime}`, 'MM-DD HH:mm')
    });
  });
  return tempList;
}

const _tempData = [
  {
    half: null,
    matchType: '足球',
    liveRoomId: 1161,
    homeName: '贝塔纳哈里亚',
    matchSource: null,
    source: null,
    gamePart: 2,
    matchStatus: 0,
    videoSource: {
      rtmp: 'rtmp://anchorplay.scgpbl.cn/live/intsitzhubo0543812905?auth_key=1664700089-0-0-cc72f6d8f4865f5911108f3eda24c852',
      flv: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0543812905.flv?auth_key=1664700089-0-0-56cbe1eddd68421f86fa8679bb4a0e9b',
      m3u8: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0543812905.m3u8?auth_key=1664700089-0-0-c1e50e7fbd392283d5b85fc791165a51'
    },
    isAsc: null,
    id: 1161,
    liveStatus: 1,
    tenant: null,
    league: '以色列杯',
    hostId: 518,
    totalRows: 0,
    anchorId: null,
    awayName: '马卡比凯尔耶特',
    gamePartStatus: 1,
    startDate: '2022-09-02',
    liveStatusList: null,
    voidSourceUrl: null,
    status: null,
    anchorType: null,
    hostName: '香香',
    gid: 1,
    matchLive: null,
    pageN: -1,
    orderBy: '',
    orderByColumn: null,
    chId: null,
    upLiveTime: null,
    rtmp: 'rtmp://anchorplay.scgpbl.cn/live/intsitzhubo0543812905?auth_key=1664700089-0-0-cc72f6d8f4865f5911108f3eda24c852',
    nick: null,
    uid: null,
    awayScore: 0,
    hmicon: 'https://cdn.sportnanoapi.com/football/team/3efb6150b58ccf06c238b175d3a5de63.png',
    startTime: '16:00',
    anchorMatchType: null,
    lang: null,
    matchId: 3812905,
    gameParts: null,
    homeScore: 0,
    hostPhotoId: 'https://kgsports-apk.galalive.vip/api/gl/file/files/20220713/80e1aaf1ee974490a68fff8d447b6e09.jpg',
    downLiveTime: null,
    awicon: '',
    anchorLive: null,
    flv: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0543812905.flv?auth_key=1664700089-0-0-56cbe1eddd68421f86fa8679bb4a0e9b',
    sportId: 1,
    pageS: -1,
    totalPages: 0,
    m3u8: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0543812905.m3u8?auth_key=1664700089-0-0-c1e50e7fbd392283d5b85fc791165a51'
  },
  {
    half: null,
    matchType: '足球',
    liveRoomId: 1163,
    homeName: '皇家游骑兵FC',
    matchSource: null,
    source: null,
    gamePart: 2,
    matchStatus: 0,
    videoSource: {
      rtmp: 'rtmp://anchorplay.scgpbl.cn/live/intsitzhubo0243812482?auth_key=1664700104-0-0-11d3a230770c8d68c4f778188a198728',
      flv: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0243812482.flv?auth_key=1664700104-0-0-dab88f8722d2a712db9f80318692f792',
      m3u8: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0243812482.m3u8?auth_key=1664700104-0-0-4bb7d1076ebe661aaa570af53d6db268'
    },
    isAsc: null,
    id: 1163,
    liveStatus: 0,
    tenant: null,
    league: '印德联',
    hostId: 515,
    totalRows: 0,
    anchorId: null,
    awayName: '德里流浪者',
    gamePartStatus: 1,
    startDate: '2022-09-02',
    liveStatusList: null,
    voidSourceUrl: null,
    status: null,
    anchorType: null,
    hostName: '小汐',
    gid: 1,
    matchLive: null,
    pageN: -1,
    orderBy: '',
    orderByColumn: null,
    chId: null,
    upLiveTime: null,
    rtmp: 'rtmp://anchorplay.scgpbl.cn/live/intsitzhubo0243812482?auth_key=1664700104-0-0-11d3a230770c8d68c4f778188a198728',
    nick: null,
    uid: null,
    awayScore: 2,
    hmicon: 'https://cdn.sportnanoapi.com/football/team/7eaa1463656475e425dd40f1de2f9412.png',
    startTime: '16:00',
    anchorMatchType: null,
    lang: null,
    matchId: 3812482,
    gameParts: null,
    homeScore: 1,
    hostPhotoId: 'https://kgsports-apk.galalive.vip/api/gl/file/files/20220713/541fef7d09bf4528bdafced318003648.jpg',
    downLiveTime: null,
    awicon: 'https://cdn.sportnanoapi.com/football/team/f043f24b1a4aa697a706c9fb9d581cbe.png',
    anchorLive: null,
    flv: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0243812482.flv?auth_key=1664700104-0-0-dab88f8722d2a712db9f80318692f792',
    sportId: 1,
    pageS: -1,
    totalPages: 0,
    m3u8: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0243812482.m3u8?auth_key=1664700104-0-0-4bb7d1076ebe661aaa570af53d6db268'
  },
  {
    half: null,
    matchType: '足球',
    liveRoomId: 1164,
    homeName: '摩顿湾联U23',
    matchSource: null,
    source: null,
    gamePart: 1,
    matchStatus: 0,
    videoSource: {
      rtmp: 'rtmp://anchorplay.scgpbl.cn/live/intsitzhubo0343812944?auth_key=1664700112-0-0-12d75c76f69860987e0bbe4f3151a080',
      flv: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0343812944.flv?auth_key=1664700112-0-0-578aa1cae066331b1f0e133ad09a922c',
      m3u8: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0343812944.m3u8?auth_key=1664700112-0-0-782d1442689b46f6e27d7fff446554ea'
    },
    isAsc: null,
    id: 1164,
    liveStatus: 0,
    tenant: null,
    league: '澳昆U23',
    hostId: 516,
    totalRows: 0,
    anchorId: null,
    awayName: '布里斯班狮吼U23',
    gamePartStatus: 0,
    startDate: '2022-09-02',
    liveStatusList: null,
    voidSourceUrl: null,
    status: null,
    anchorType: null,
    hostName: '糖糖',
    gid: 1,
    matchLive: null,
    pageN: -1,
    orderBy: '',
    orderByColumn: null,
    chId: null,
    upLiveTime: null,
    rtmp: 'rtmp://anchorplay.scgpbl.cn/live/intsitzhubo0343812944?auth_key=1664700112-0-0-12d75c76f69860987e0bbe4f3151a080',
    nick: null,
    uid: null,
    awayScore: 0,
    hmicon: 'https://cdn.sportnanoapi.com/football/team/43ca0efe954027759f6af03fc4def8aa.png',
    startTime: '17:00',
    anchorMatchType: null,
    lang: null,
    matchId: 3812944,
    gameParts: null,
    homeScore: 0,
    hostPhotoId: 'https://kgsports-apk.galalive.vip/api/gl/file/files/20220713/63d7a616e44944a48b4b055d53a0dd47.jpg',
    downLiveTime: null,
    awicon: '',
    anchorLive: null,
    flv: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0343812944.flv?auth_key=1664700112-0-0-578aa1cae066331b1f0e133ad09a922c',
    sportId: 1,
    pageS: -1,
    totalPages: 0,
    m3u8: 'https://anchorplay.scgpbl.cn/live/intsitzhubo0343812944.m3u8?auth_key=1664700112-0-0-782d1442689b46f6e27d7fff446554ea'
  }
];

export const useLiveList = function () {
  const param = reactive({
    sportId: 1
  });

  const store = reactive({
    data: {
      liveGroup: [],
      noLiveGroup: []
    } as LiveData,
    currentMatch: null as any,
    loading: false
  });

  const getData = async () => {
    resetData();
    store.loading = true;
    try {
      const { result, data } = await getLiveList(param);
      if (result && !isEmpty(data)) {
        store.data = _formatLiveListData(data);
      }
    } catch (error) {
      throw error;
    } finally {
      store.loading = false;
    }
  };

  const resetData = () => {
    store.loading = false;
    store.data = {
      liveGroup: [],
      noLiveGroup: []
    };
  };

  return {
    param,
    store,
    getData,
    resetData
  };
};

/**
 * 主播相关
 */
export const useAnchor = () => {
  const store = reactive({
    data: [] as MatchInfoCard[],
    loading: false
  });

  const resetData = () => {
    store.loading = false;
    store.data = [];
  };

  const getData = async () => {
    resetData();
    try {
      store.loading = true;
      const { result, data } = await getAnchorList();
      if (result) {
        store.data = _formatAnchorListData(data);
      }
    } catch (error) {
      error;
    } finally {
      store.loading = false;
    }
  };

  return {
    store,
    getData,
    resetData
  };
};
