/**
 * 点击dom之外区域执行
 * @param [fn] :传入执行函数
 */
const nodeList = new Map();

// dom添加点击监听
document.addEventListener('click', el => {
  nodeList.forEach(fn => fn(el.target));
});

// 每个dom创建处理函数
function createDocumentHandler(el, binding) {
  let fnList = [] as any;
  if (Array.isArray(binding.value)) {
    fnList = binding.value;
  } else {
    fnList.push(binding.value);
  }
  return function (clickEl) {
    if (!el.contains(clickEl)) {
      fnList.forEach(fn => {
        fn(clickEl, el);
      });
    }
  };
}

const clickOutsideDirective = {
  beforeMount(el, binding) {
    nodeList.set(el, createDocumentHandler(el, binding));
  },
  unmounted(el) {
    nodeList.delete(el);
  }
};

export default clickOutsideDirective;
