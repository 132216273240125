import { defineComponent, inject, PropType } from 'vue';
import { isNumber } from 'lodash-es';
import { useI18n } from 'vue-i18n';
import { DataEmpty } from 'kg-web/components';

import total from './data-total.module.less';

export default defineComponent({
  name: 'JBBsportDataTotal',
  props: {
    realTimelist: {
      type: Array as PropType<any[]>,
      default: () => []
    }
  },

  setup(props) {
    const getSubstitution = (array: any): any[] => {
      if (!Array.isArray(array) && array.length) return [];
      return [];
      // return array
      //   .filter(item => item.type === 9)
      //   .map(item => {
      //     const inName = get(/(?<= 换 )([\u4e00-\u9fa5]*)/.exec(item.data), 0);
      //     const offName = get(/([\u4e00-\u9fa5]*)(?= 换 )/.exec(item.data), 0);
      //     return {
      //       time: item.time,
      //       offName,
      //       inName,
      //       isHome: item.position === 1
      //     };
      //   });
    };
    const { t } = useI18n();
    const { moreMatchInfo } = inject('matchInfo') as any;
    const formatScore = (score: string | null | undefined | number): string | number => {
      if (isNumber(score)) return score;
      return '-';
    };
    const getHeight = (height, total) => {
      if (total === 0 || height === 0) return 0;
      return (height / total) * 100;
    };
    const rate = (array: number[], radius: number, position?: string) => {
      const circumference = Math.PI * radius * 2; // 计算周长
      const res = {
        strokeDasharray: [0.5, 0.5].map(item => circumference * item).join(' '),
        strokeDashoffset: position === 'away' ? (Math.PI * radius) / 2 : -(0.5 * circumference - (Math.PI * radius) / 2)
      };
      if (!Array.isArray(array) || array.length !== 2)
        // 返回1：1模型
        return res;
      const total = array.reduce((prev: number, next: number) => prev + next, 0);
      if (!total) return res;
      const rate = array.map(item => item / total).map(item => circumference * item); // 按比例分割周长
      return {
        strokeDasharray: (position === 'away' ? rate.slice(0).reverse() : rate).join(' '), // 按位置和比例分割周长[1, 2] [2, 1]
        strokeDashoffset: position === 'away' ? (Math.PI * radius) / 2 : -(rate[1] - (Math.PI * radius) / 2) // 按比例偏移从90度开始画偏移Math.PI * radius) / 2， -号代表往逆时针
      };
    };
    return () => (
      <div class={total.page}>
        <div class={total.header}>{t('lang.sport_details_statistics')}</div>
        <div class={total.cardBox}>
          <div class={total.cardItem}>
            {formatScore(moreMatchInfo.value.YellowCardH)}
            <div class={total.cardIcon}></div>
            {formatScore(moreMatchInfo.value.RedCardH)}
          </div>
          <span class={total.cardText}>{t('lang.sport_details_card')}</span>
          <div class={total.cardItem}>
            {formatScore(moreMatchInfo.value.YellowCardA)}
            <div class={total.cardIcon}></div>
            {formatScore(moreMatchInfo.value.RedCardA)}
          </div>
        </div>
        <div class={total.rangePrecent}>
          <span class={total.rangeTitle}>{t('lang.sport_details_conversionRate')}</span>
          <div class={total.rangeTextBox}>
            <span class={total.rangeText}>-</span>
            <span class={total.rangeText}>-</span>
          </div>
          <div class={total.rangeSplit}>
            <div class={total.rangeSplitItem}></div>
            <div class={total.rangeSplitItem}></div>
          </div>
        </div>
        <div class={total.dataBox}>
          <div class={total.dataBg}>
            {[
              {
                name: t('lang.sport_details_cornerBall'),
                home: moreMatchInfo.value.homeCorners,
                away: moreMatchInfo.value.awayCorners,
                type: 'rangeCornors'
              },
              {
                name: t('lang.sport_common_shotOnTarget'),
                home: moreMatchInfo.value.homeShootRight,
                away: moreMatchInfo.value.awayShootRight,
                type: 'rangeShootRight'
              },
              {
                name: t('lang.sport_common_Deviated'),
                home: moreMatchInfo.value.homeShotOff,
                away: moreMatchInfo.value.awayShotOff,
                type: 'rangeShotOff'
              },
              {
                name: t('lang.sport_common_attack'),
                home: moreMatchInfo.value.homeAttack,
                away: moreMatchInfo.value.awayAttack,
                type: 'rangeAttack'
              }
            ].map(item => (
              <div class={`${total.techItem} ${total[item.type]}`}>
                <div class={total.techName}>{item.name}</div>
                <div class={total.techBox}>
                  <div class={total.techTeamItem}>
                    <div
                      class={total.techHomeItem}
                      style={{ height: `${getHeight(item.home || 0, Math.max(item.home || 0, item.away || 0))}%` }}
                    ></div>
                  </div>
                  <div class={total.techTeamItem}>
                    <div
                      class={total.techAwayItem}
                      style={{ height: `${getHeight(item.away || 0, Math.max(item.home || 0, item.away || 0))}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class={total.otherData}>
            {[
              {
                name: t('lang.sport_common_assist'),
                icon: 'ruleIcon',
                value: [moreMatchInfo.value.homeAssist || 0, moreMatchInfo.value.awayAssist || 0]
              },
              {
                name: t('lang.sport_details_freeKick'),
                icon: 'anyIcon',
                value: [moreMatchInfo.value.homeFree || 0, moreMatchInfo.value.awayFree || 0]
              },
              {
                name: t('lang.sport_details_offside'),
                icon: 'upToIcon',
                value: [moreMatchInfo.value.homeFoul || 0, moreMatchInfo.value.awayFoul || 0]
              }
            ].map(item => (
              <div class={total.otherItem}>
                <svg width="60" height="60" class={total.otherItemSvg}>
                  <circle cx="30" cy="30" r="25" stroke-width="5" stroke="#fff" fill="none" />
                  <circle
                    cx="30"
                    cy="30"
                    r="25"
                    stroke-width="5"
                    stroke="#B52520"
                    fill="none"
                    stroke-dasharray={rate(item.value, 25).strokeDasharray}
                    stroke-dashoffset={rate(item.value, 25).strokeDashoffset}
                  />
                  <circle
                    cx="30"
                    cy="30"
                    r="25"
                    stroke-width="5"
                    stroke="#5FBD96"
                    fill="none"
                    stroke-dasharray={rate(item.value, 25, 'away').strokeDasharray}
                    stroke-dashoffset={rate(item.value, 25, 'away').strokeDashoffset}
                  />
                </svg>
                <div class={`${total.otherIcon} ${total[item.icon]}`}></div>
                <span class={total.otherText}>{item.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div class={total.header}>{t('lang.sport_details_substitube')}</div>
        <div class={total.changeBox}>
          {getSubstitution(props.realTimelist).length ? (
            getSubstitution(props.realTimelist).map(item => (
              <div class={`${total.changeItem} ${item.isHome ? total.changeTurn : ''}`}>
                <div class={total.changeContent}></div>
                <div class={`${total.changeTime} ${item.isHome ? total.homeTime : total.awayTime}`}>49`</div>
                <div class={`${total.changeContent} ${item.isHome ? total.flexRight : ''}`}>
                  <div class={total.changeTop}>
                    <span class={total.changeName}>{item.offName}</span>
                    <div class={`${total.changeOff} ${total.changeIcon}`}></div>
                  </div>
                  <div class={total.changeBottom}>
                    <div class={`${total.changeIn} ${total.changeIcon}`}></div>
                    <span class={total.changeName}>{item.inName}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <DataEmpty title={t('lang.sport_common_noMoreData')} />
          )}
        </div>
      </div>
    );
  }
});
