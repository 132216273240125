(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vant"));
	else if(typeof define === 'function' && define.amd)
		define(["vant"], factory);
	else if(typeof exports === 'object')
		exports["sports_bet-kg-web"] = factory(require("vant"));
	else
		root["sports_bet-kg-web"] = factory(root["vant"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__3089__) {
return 