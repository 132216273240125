import { defineComponent, computed } from 'vue';
import { currency, CurrencyIcons } from 'common';
import { SvgIcon } from '@/components';
import { useBalance, useSportOptions } from '@/hooks';
import './balance.style.less';

export default defineComponent({
  name: 'BetBalance',
  emits: ['to-recharge'],
  setup() {
    const { sportBalance, isFreshing, onHandleFresh_365 } = useBalance();
    const { optionsStore } = useSportOptions();
    const currencySymbol = computed(() => {
      return optionsStore.value.currency;
    });
    return () => (
      <div class="bet-balance-info" onClick={() => onHandleFresh_365()}>
        <div class="user-details">
          <div class="icon-money" style={{ backgroundImage: `url(${CurrencyIcons[currencySymbol.value] || null})` }} />
          {isFreshing.value ? <SvgIcon name="loading" /> : <div class="text-money">{currency(sportBalance.value)}</div>}
        </div>
        <div class="loading-icon">
          <SvgIcon class={{ 'is-load': isFreshing.value }} name="365refresh" />
        </div>
      </div>
    );
  }
});
