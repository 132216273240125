import axios, { type AxiosInstance } from 'axios';
import { getUUID } from '@/common/utils';
import { getLoginData, clearLoginData, getTenant, getCMSapi } from '@/common/utils';
import { isH5 } from '@/common';
import { useBet } from '@/hooks';
import { useBetWeb } from '@/hooks/useBetWeb.hook';
import store from '@/store';
import qs from 'qs';

import { useSportOptions } from '@/hooks';
import { makeRandomId } from '@/common/utils/createId.util';

const PROJECT_VERSION = process.env.VUE_APP_VERSION;

export interface Sign {
  [key: string]: string | number;
}

const requestMap: string[] = [];

interface HttpAxiosInstance extends AxiosInstance {
  get: (url: string, params?: any) => any;
}

export const v2_http: HttpAxiosInstance = axios.create({
  baseURL: getCMSapi(),
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  },
  timeout: 10 * 1000,
  withCredentials: true
});

v2_http.interceptors.request.use(
  async (config: any) => {
    const requestParams = `${config.url}${JSON.stringify(config.params)}`;
    if (requestMap.includes(requestParams)) return;
    requestMap.push(requestParams);
    if (config?.method && ['post', 'put'].includes(config?.method)) {
      config.data = qs.stringify(config.data, {
        arrayFormat: 'indices',
        allowDots: true
      });
    }
    config.headers = Object.assign(config.headers, await creatHeaders());
    return config;
  },
  () => {
    return Promise.reject({ code: 1, status: 200, data: {}, result: false, message: '请求异常' });
  }
);

// 覆盖get
v2_http.get = (url: string, params: any): any =>
  v2_http({
    method: 'get',
    url,
    params,
    headers: creatHeaders()
  });

v2_http.interceptors.response.use(
  response => {
    const requestParams = `${response.config.url}${JSON.stringify(response.config.params)}`;
    requestMap.splice(requestMap.indexOf(requestParams), 1);
    const { data, status } = response;
    const { code } = data || {};

    if ([401, 403, 405].includes(code)) {
      if (!isH5) {
        const { clearAllTickets } = useBetWeb();
        clearAllTickets();
      } else {
        const { clearAllTickets } = useBet();
        clearAllTickets();
      }
      clearLoginData();
      store.dispatch('SIGN_OUT');
      return Promise.resolve(Object.assign(data, { result: false }));
    }
    if (typeof data === 'string' && data.length > 10 && status === 200) {
      return Promise.resolve(Object.assign({ data }, { result: true, status }));
    } else if (code !== 0) {
      console.error('[N00001]', data.message);
      Promise.resolve({ ...response, result: false });
    }

    return Promise.resolve(Object.assign(data, { result: code === 0, status }));
  },
  error => {
    const { response, message } = error;
    const requestParams = response && `${response?.config.url}${JSON.stringify(response?.config?.params)}`;
    requestMap.splice(requestMap.indexOf(requestParams), 1);
    const { status, data = {} } = response || {};
    if (message.includes('timeout')) {
      return Promise.resolve(Object.assign(data ? data : {}, { result: false, message: '请求超时，请稍后再试!' }));
    }
    if ([401, 403, 405].includes(status)) {
      if (!isH5) {
        const { clearAllTickets } = useBetWeb();
        clearAllTickets();
      } else {
        const { clearAllTickets } = useBet();
        clearAllTickets();
      }
      clearLoginData();
      store.dispatch('SIGN_OUT');
      return Promise.resolve(Object.assign(data, { result: false }));
    }
    if (status >= 500 || status === 400) {
      console.error('[N00002]', data.message);
      return Promise.resolve({ ...data, result: false });
    }
    console.error('[N00004]', error);
    return Promise.resolve({ data: undefined, result: false });
  }
);

// 创建header
export async function creatHeaders() {
  const { optionsStore } = useSportOptions();

  const timestamp = Date.now();
  const sign = makeRandomId(-16);
  const langue = optionsStore.value.lang;
  const signParams: Sign = {
    timestamp,
    sign,
    tenant: getTenant(),
    version: PROJECT_VERSION || '',
    client_type: 'h5',
    device_id: getUUID() || '',
    langue
  };
  const { token } = getLoginData();
  if (token) {
    signParams.Authorization = `${token}`;
  }
  return signParams;
}
