import { computed, ComputedRef, onMounted, onUnmounted, reactive, inject, toRefs, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useIntervalFn } from '@vueuse/core';
import { isEmpty, sumBy, take, result, forEach, split } from 'lodash-es';
import { queryEventsAndMarkets } from '@/services/kg-api';
import { lineupApi, liveTextApi, analysisApi, informationApi } from '@/services';
import { pickValue, scoreFormat, TechId } from '@/common';
import { useI18n } from '@/hooks';
// callback 定时请求的api
export function useTextLive(callback: any, running = false): any {
  const route = useRoute();
  const periodId: ComputedRef<number> = computed(() => Number(route?.query?.periodId));
  const stop: any = ref(null);

  !running &&
    onMounted(() => {
      callback && callback();
      if (periodId.value === 3) {
        // periodId = 3,滚球状态下开启定时器
        const { pause } = useIntervalFn(() => callback && callback(), 6000);
        stop.value = pause;
      }
    });

  if (running) {
    const { pause } = useIntervalFn(() => callback && callback(), 6000);
    stop.value = pause;
  }

  !running && onUnmounted(() => stop.value && stop.value());

  return {
    stop
  };
}

export function useRangeData(props: any) {
  const { t } = useI18n();
  const date = reactive({
    homeScheduleInfo: [0, 0, 0], //index~ 0赢  1输 2平
    HomeText: [],
    guestScheduleInfo: [0, 0, 0], //index~ 0赢  1输 2平
    guestText: [],
    scheduleInfo: [0, 0, 0],
    homeTeamName: null,
    guestTeamName: null
  }) as any;
  const methods: any = {
    sumArr: function (arr) {
      return sumBy(arr, o => Number(o));
    },
    mapArr: function (arr, str, id = 0, teamate) {
      if (arr.length === 0) return;
      if (!teamate) {
        const [{ home_team, guest_team }, { home_team: home_team_2, guest_team: guest_team_2 }] = arr;
        // 某只队伍 主或客
        teamate = home_team === home_team_2 || home_team === guest_team_2 ? home_team : guest_team;
        id === 1 ? (date.homeTeamName = teamate) : id === 2 ? (date.guestTeamName = teamate) : void 0;
      }
      arr.map(item => {
        if (id == 1) {
          if (item.home_team === teamate) {
            if (item.home_score > item.guest_score) {
              date[str][0] += 1;
              date.HomeText.push(t('lang.sport_details_win'));
            } else if (item.home_score < item.guest_score) {
              date[str][1] += 1;
              date.HomeText.push();
            } else if (item.home_score == item.guest_score) {
              date[str][2] += 1;
              date.HomeText.push(t('lang.sport_details_draw'));
            }
          } else {
            if (item.guest_score > item.home_score) {
              date[str][0] += 1;
              date.HomeText.push(t('lang.sport_details_win'));
            } else if (item.guest_score < item.home_score) {
              date[str][1] += 1;
              date.HomeText.push(t('lang.sport_details_lost'));
            } else if (item.home_score == item.guest_score) {
              date[str][2] += 1;
              date.HomeText.push(t('lang.sport_details_draw'));
            }
          }
        } else if (id == 2) {
          if (item.guest_team === teamate) {
            if (item.guest_score > item.home_score) {
              date[str][0] += 1;
              date.guestText.push(t('lang.sport_details_win'));
            } else if (item.guest_score < item.home_score) {
              date[str][1] += 1;
              date.guestText.push(t('lang.sport_details_lost'));
            } else if (item.guest_score == item.home_score) {
              date[str][2] += 1;
              date.guestText.push(t('lang.sport_details_draw'));
            }
          } else {
            if (item.home_score > item.guest_score) {
              date[str][0] += 1;
              date.guestText.push(t('lang.sport_details_win'));
            } else if (item.home_score < item.guest_score) {
              date[str][1] += 1;
              date.guestText.push(t('lang.sport_details_lost'));
            } else if (item.home_score == item.guest_score) {
              date[str][2] += 1;
              date.guestText.push(t('lang.sport_details_draw'));
            }
          }
        } else {
          if (teamate === item.home_team) {
            if (item.home_score > item.guest_score) {
              date[str][0] += 1;
            } else if (item.home_score < item.guest_score) {
              date[str][1] += 1;
            } else if (item.home_score == item.guest_score) {
              date[str][2] += 1;
            }
          } else {
            if (item.guest_score > item.home_score) {
              date[str][0] += 1;
            } else if (item.guest_score < item.home_score) {
              date[str][1] += 1;
            } else if (item.guest_score == item.home_score) {
              date[str][2] += 1;
            }
          }
        }
      });
    },
    filterscore: function (canzhao, homeScore, guseScore, item) {
      const obj = {
        Str: '',
        color: ''
      }; //home_score guest_score
      if (canzhao === homeScore) {
        obj.Str =
          item.home_score > item.guest_score
            ? t('lang.sport_details_win')
            : item.home_score == item.guest_score
            ? t('lang.sport_details_draw')
            : t('lang.sport_details_lost');
        obj.color =
          item.home_score > item.guest_score
            ? 'redgrund'
            : item.guest_score == item.home_score
            ? 'huigrund'
            : 'greengrund';
      } else if (canzhao === guseScore) {
        obj.Str =
          item.guest_score > item.home_score
            ? t('lang.sport_details_win')
            : item.guest_score == item.home_score
            ? t('lang.sport_details_draw')
            : t('lang.sport_details_lost');
        obj.color =
          item.guest_score > item.home_score
            ? 'redgrund'
            : item.guest_score == item.home_score
            ? 'huigrund'
            : 'greengrund';
      }
      return obj;
    }
  };
  const analylist = props.analylist;
  const homeLeagueJF = computed(() => {
    return result(analylist, 'leagueJF.home.total');
  });
  const guestLeagueJF = computed(() => {
    return result(analylist, 'leagueJF.guest.total');
  });
  const IsAnalylist = Object.keys(analylist).length >= 1;
  const IsleagueJF = computed(() => JSON.stringify(analylist.leagueJF?.home) != '{}');
  const { matchInfo, sportType, moreMatchInfo } = inject('matchInfo') as any;
  // following lines upto if (IsAnalylist) are to transform underscore seperated labels like his_match to the structure of old api
  if (!analylist.hisMatch.scheduleInfo) {
    const scheduleInfo = analylist.hisMatch;
    analylist.hisMatch = { scheduleInfo: scheduleInfo };
  }
  if (IsAnalylist) {
    methods.mapArr(take(analylist.lastMatch.homeScheduleInfo, 5), 'homeScheduleInfo', 1);
    methods.mapArr(take(analylist.lastMatch.guestScheduleInfo, 5), 'guestScheduleInfo', 2);
    methods.mapArr(take(analylist.hisMatch.scheduleInfo, 5), 'scheduleInfo', 0, date.homeTeamName);
  }
  return {
    matchInfo,
    ...toRefs(date),
    IsAnalylist,
    IsleagueJF,
    isEmpty,
    ...methods,
    sportType,
    moreMatchInfo,
    homeLeagueJF,
    guestLeagueJF
  };
}

export function useSheetIndex() {
  const state = reactive({
    sheetIndex: 0
  });
  const changeSheetIndex = (index: number) => {
    if (state.sheetIndex === index) return;
    state.sheetIndex = index;
  };
  return {
    state,
    changeSheetIndex
  };
}

export function useGameInfo(useMonted = true) {
  const route = useRoute();
  const store = useStore();

  const sportType = computed(
    () =>
      Number(Array.isArray(route.query.sportId) ? route.query.sportId[0] : route.query.sportId) ||
      gameInfo.value.SportType
  );
  const matchId = route.query.matchId;
  const gameInfo: Ref<any> = ref({});
  const getGameInfo = async () => {
    const params = {
      sportId: Number(route.query.sportId),
      periodId: Number(route.query.periodId),
      eventIds: route.query.matchId,
      isParlay: route.query.isParlay === 'true'
    };

    const [res, data] = await queryEventsAndMarkets(params as any);
    if (!isEmpty(data) && res) {
      gameInfo.value = data[0];
    }
  };
  const matchInfo: ComputedRef<any> = computed(
    () => (!isEmpty(gameInfo.value) && { ...gameInfo.value.matches[0], ...gameInfo.value }) || {}
  );
  // 其他赛事数据
  const moreMatchInfo = computed(() => {
    let source = matchInfo.value.MoreInfo;
    let aHTScore = '';
    let hHTScore = '';
    let RedCardH = '';
    let RedCardA = '';
    let YellowCardA = '';
    let YellowCardH = '';
    let object = {};
    const { MatchId } = matchInfo.value;
    if (MatchId) {
      // 取socekt的比赛信息
      const tech = store.getters.getTechById(MatchId);
      if (tech) {
        const [homeHalfScore, awayHalfScore] = tech.get(TechId.HALF_SCORE) || [undefined, undefined];
        const [homeCorners, awayCorners] = tech.get(TechId.CORNER) || [0, 0];
        const [homeShootRight, awayShootRight] = tech.get(TechId.SHOOT_RIGHT) || [0, 0];
        const [homeShotOff, awayShotOff] = tech.get(TechId.SHOT_OFF) || [0, 0];
        const [homeFree, awayFree] = tech.get(TechId.FREE) || [0, 0];
        const [homeFoul, awayFoul] = tech.get(TechId.FOUL) || [0, 0];
        const [homeAttack, awayAttack] = tech.get(TechId.ATTACK) || [0, 0];
        const [homeAssist, awayAssist] = tech.get(TechId.ASSIST) || [0, 0];
        object = {
          homeCorners,
          awayCorners,
          homeShootRight,
          awayShootRight,
          homeShotOff,
          awayShotOff,
          homeFree,
          awayFree,
          homeFoul,
          awayFoul,
          homeAttack,
          awayAttack,
          homeAssist,
          awayAssist
        };
        aHTScore = awayHalfScore;
        hHTScore = homeHalfScore;
        const RedCard = tech.get(TechId.RED) || [];
        RedCardH = RedCard[0];
        RedCardA = RedCard[1];
        const YellowCard = tech.get(TechId.YELLOW) || [];
        YellowCardH = YellowCard[0];
        YellowCardA = YellowCard[1];
      } else if (!!source) {
        const HalfScoreH = source.halfScoreH;
        const HalfScoreA = source.halfScoreA;
        RedCardH = source.redCardH;
        RedCardA = source.redCardA;
        YellowCardH = source.yellowCardH;
        YellowCardA = source.yellowCardA;
        object = {
          homeCorners: source.cornersH,
          awayCornerssource: source.cornersA,
          homeShootRight: source.shotsOnTargetH,
          awayShootRight: source.shotsOnTargetA,
          homeShotOff: source.shotsOffTargetH,
          awayShotOff: source.shotsOffTargetA,
          homeFree: 0,
          awayFree: 0,
          homeFoul: 0,
          awayFoul: 0,
          homeAttack: source.dangerousAttacksH,
          awayAttack: source.dangerousAttacksA,
          homeAssist: source.assistsH,
          awayAssist: source.assistsA
        };
        aHTScore = HalfScoreA;
        hHTScore = HalfScoreH;
        const HalfTimeH = source.halfScoreH;
        const HalfTimeA = source.halfScoreA;
        const CornerH = source.cornersH;
        const CornerA = source.cornersA;
        source = Object.assign(source || {}, {
          CornerH,
          CornerA,
          HalfTimeH,
          HalfTimeA,
          RedCardH,
          RedCardA,
          YellowCardH,
          YellowCardA
        });
      }
    }
    return {
      homeScore: scoreFormat({ ...source, SportType: Number(sportType.value) }, 'home'),
      guestScore: scoreFormat({ ...source, SportType: Number(sportType.value) }, 'away'),
      // 足球赛事数据
      ...object,
      RedCardA: RedCardA || pickValue(source, 'RedCardA'),
      RedCardH: RedCardH || pickValue(source, 'RedCardH'),
      YellowCardA: YellowCardA || pickValue(source, 'YellowCardA'),
      YellowCardH: YellowCardH || pickValue(source, 'YellowCardH'),
      htScoreA: aHTScore || pickValue(source, 'ht_away_score') || 0,
      htScoreH: hHTScore || pickValue(source, 'ht_home_score') || 0,
      ...source
    };
  });

  // 请求数据
  useMonted && onMounted(getGameInfo);
  return {
    getGameInfo,
    matchId,
    moreMatchInfo,
    matchInfo,
    sportType
  };
}

export interface FootballEvents {
  real: any;
  lineUp: any;
  analysis: any;
  intelligence: any;
}

interface useEventsDataFnReturn {
  soccerBallEventsData: ComputedRef<FootballEvents>;
  isEmptyFootballEventData: ComputedRef<boolean>;
  GetRealTimelist: () => void;
  getEventsData: () => void;
}

export function useEventsData({ matchId }: { matchId: any }, useMonted = true): useEventsDataFnReturn {
  const soccerBallEventsData: ComputedRef<FootballEvents> = computed(() => {
    return {
      real: realTimeList.value,
      lineUp: lineUpData.value,
      analysis: analysisList.value,
      intelligence: intelligenceList.value
    };
  });

  // 足球实时
  const realTimeList = ref([]);
  // 足球阵容
  const lineUpData = ref({});
  // 足球分析
  const analysisList = ref({});
  // 足球情报
  const intelligenceList = ref({});
  // 足球赛事数据是否为空
  const isEmptyFootballEventData = computed(() => {
    return (
      isEmpty(realTimeList.value) &&
      isEmpty(lineUpData.value) &&
      isEmpty(analysisList.value) &&
      isEmpty(intelligenceList.value)
    );
  });
  /**
   *格式化阵容
   *
   * @param {{
   *     awayArray: string;
   *     awayBackup: string;
   *     awayLineup: string;
   *     homeArray: string;
   *     homeBackup: string;
   *     homeLineup: string;
   *     logos: string;
   *   }} {
   *     awayArray,
   *     awayBackup,
   *     awayLineup,
   *     homeArray,
   *     homeBackup,
   *     homeLineup,
   *     logos
   *   }
   * @return {*}
   */
  const _formatLineup = ({
    awayArray,
    awayBackup,
    awayLineup,
    homeArray,
    homeBackup,
    homeLineup,
    logos
  }: {
    awayArray: string;
    awayBackup: string;
    awayLineup: string;
    homeArray: string;
    homeBackup: string;
    homeLineup: string;
    logos: string;
  }) => {
    const logoStrArr = split(logos, ';');
    logoStrArr.pop();
    const logoMap: { [key: string]: string } = {};
    forEach(logoStrArr, l => {
      const [id, logo] = split(l, ',');
      logoMap[id] = `http://cdn.sportnanoapi.com/soccerBall/player/${logo}`;
    });
    const _formatBackup = (backup: string) => {
      const temp = split(backup, ';');
      temp.pop();
      return temp.map(t => {
        const [id, name, , no] = split(t, ',');
        return { id, name, no, logo: logoMap[id] };
      });
    };
    const homeBackupData = _formatBackup(homeBackup);
    const awayBackupData = _formatBackup(awayBackup);
    const homeLineupData = _formatBackup(homeLineup);
    const awayLineupData = _formatBackup(awayLineup);
    const backup = {
      home: homeBackupData,
      away: awayBackupData
    };
    const lineup = {
      home: homeLineupData,
      away: awayLineupData
    };
    return {
      awayArray,
      homeArray,
      backup,
      lineup
    };
  };

  const _formatAnalysis = (data: { injury: { home: { logo: string }[]; away: { logo: string }[] } }) => {
    const injury = data.injury;
    const home = injury?.home;
    const away = injury?.away;
    home &&
      home.forEach((h: { logo: string }) => {
        h.logo = `http://cdn.sportnanoapi.com/soccerBall/player/${h.logo}`;
      });
    away &&
      away.forEach((a: { logo: string }) => {
        a.logo = `http://cdn.sportnanoapi.com/soccerBall/player/${a.logo}`;
      });
    return data;
  };

  const methods = {
    //实时数据
    GetRealTimelist: async () => {
      const api = liveTextApi;
      if (!matchId) return;
      const [isSuccess, data] = await api({ source: 'im', matchId });
      if (isSuccess) {
        realTimeList.value = data;
      }
    },
    //阵容数据接口  阵容暂未找到 可用数据
    GetIntellGence: async () => {
      if (!matchId) return;
      const api = lineupApi;
      const [isSuccess, data] = await api({ source: 'im', matchId });
      if (isSuccess && data) lineUpData.value = _formatLineup(data);
    },
    //分析数据接口
    GetAnalylist: async () => {
      if (!matchId) return;
      const api = analysisApi;
      const [isSuccess, data] = await api({ source: 'im', matchId });
      if (isSuccess) analysisList.value = _formatAnalysis(data);
    },
    //情报数据接口
    GetInforlist: async () => {
      if (!matchId) return;
      const api = informationApi;
      const [isSuccess, data] = await api({ source: 'im', matchId });
      if (isSuccess) intelligenceList.value = data;
    }
  };

  /**
   * 获取足球时间数据
   */
  const getEventsData = () => {
    methods.GetRealTimelist();
    methods.GetIntellGence();
    methods.GetAnalylist();
    methods.GetInforlist();
  };

  useMonted &&
    onMounted(async () => {
      getEventsData();
    });

  return {
    getEventsData,
    soccerBallEventsData,
    isEmptyFootballEventData,
    GetRealTimelist: methods.GetRealTimelist
  };
}
