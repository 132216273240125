<template>
  <div class="Score_box" v-for="(item, index) in goalDist.home.all.scored" :key="index">
    <span :class="['left', item[0] !== 0 ? 'red' : '']">{{ item[0] }}</span>
    <div style="flex: 8" class="line_box">
      <span>{{ TextLeagueJF[index] }}</span>
      <Progress
        v-if="!(item[0] == 0 && goalDist.away.all.scored[index][0] == 0)"
        :percent="(item[1] / (item[1] + goalDist.away.all.scored[index][1])) * 100"
        :strokeWidth="4"
        :showInfo="false"
        strokeColor="#A63838"
      />
      <Progress v-else :strokeWidth="4" />
    </div>
    <span :class="['right', item[0] !== 0 ? 'green' : '']">{{ goalDist.away.all.scored[index][0] }}</span>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Progress } from 'ant-design-vue';

export default {
  components: {
    Progress
  },
  props: {
    goalDist: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { t } = useI18n();
    const TextLeagueJF = ref([
      `1-15${t('lang.sport_common_minute')}`,
      `16-30${t('lang.sport_common_minute')}`,
      `31-45${t('lang.sport_common_minute')}`,
      `46-60${t('lang.sport_common_minute')}`,
      `61-75${t('lang.sport_common_minute')}`,
      `75-90${t('lang.sport_common_minute')}`
    ]);
    return { TextLeagueJF };
  }
};
</script>

<style lang="less" scoped>
.Score_box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 30px;

  .left {
    font-size: @font-size-xs;
  }

  .red {
    color: #ff0000;
  }

  .green {
    color: #009944;
  }

  .right {
    font-size: @font-size-xs;
  }

  span {
    flex: 1;
    display: flex;
    justify-content: center;
    color: #7b7b7b;
    font-weight: 550;
  }

  .line_box {
    padding-bottom: 5px;
  }
  :deep(.el-progress-bar__outer) {
    background-color: #7bd3b1;
  }
}
</style>
