import { defineComponent, PropType, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { StoreChampData, useSportTypes, useI18n } from '@/hooks';
import { getSportNameById } from '@/common';

import style from './champ-list.module.less';
import { ChampSummary } from '..';
// import { BettingOrders } from '../../../../../kg-web/components/shop-cart/index';
import { SvgIcon } from '@/components';
import { Collapse, DataEmpty } from 'kg-web/components';
import { Skeleton } from 'ant-design-vue';

export default defineComponent({
  name: 'ChampList',
  props: {
    ChampData: {
      type: Array as PropType<StoreChampData[]>,
      default: () => []
    },
    leagueCounter: {
      type: Number
    },
    loading: Boolean
  },
  emits: ['leagueChange', 'openLeague'],
  setup(props, { emit }) {
    const { activeSportType, getSportName } = useSportTypes();
    const { t } = useI18n();
    const route = useRoute();

    const state = reactive({
      showLeagueFilter: false,
      isExpandAll: true
    }) as any;

    watch(
      () => route.query.periodId,
      () => {
        state.showLeagueFilter = false;
      }
    );

    const onClick = () => {
      state.isExpandAll = !state.isExpandAll;
    };

    const filterToggle = () => emit('openLeague');

    const ChampListHeader = () => {
      return (
        <div class={style.mainHeader}>
          <span class={style.sportLabel}>{getSportName() || getSportNameById(activeSportType.value)}</span>
          <div class={style.headerTools}>
            <div class={style.leagueFilterBtn} onClick={() => filterToggle()}>
              {/* 联赛筛选 */}
              {t('lang.sport_common_leagueFilter')} [
              <span>
                {props.leagueCounter && props.leagueCounter > 0
                  ? props.leagueCounter
                  : `${t('lang.sport_common_quanBu')}`}
              </span>
              ]{/* : '全部' */}
            </div>
            <div class={[style.toggleIcon, state.isExpandAll ? null : style.rotate]} onClick={onClick}>
              <SvgIcon name="arrow_top_v2" />
            </div>
          </div>
        </div>
      );
    };

    return () => (
      <>
        <div class={style.champList}>
          <ChampListHeader />
          <Skeleton loading={props.loading} paragraph={{ rows: 8 }}>
            {props.ChampData && props.ChampData.length ? (
              <div class={style.champListScroll}>
                <Collapse expandAll={state.isExpandAll}>
                  {props.ChampData.map(item => (
                    <ChampSummary isExpandAll={state.isExpandAll} data={item} key={item.key} />
                  ))}
                </Collapse>
              </div>
            ) : (
              <DataEmpty title={t('lang.sport_common_noAnyData')} type="odds" />
            )}
          </Skeleton>
        </div>
      </>
    );
  }
});
