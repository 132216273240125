/**
 * 数字键盘输入
 */
import { defineComponent, ref, reactive, computed, watch } from 'vue';
import { keyboardExtras, keyboardNumbers, Extras } from './config';
// import { useBet } from 'common/useBet';
import './keyboard.style.less';
import KeyboardKey from './keyboard-key-new';
import { useI18n } from '@/hooks';
import { currency } from '@/common';
import { SvgIcon } from '@/components';
import { debounce } from 'lodash-es';
import { useBet } from '@/hooks/use365Bet.hook';

export default defineComponent({
  name: 'KeyboradInput',
  props: {
    balance: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: ''
    },
    showCurrency: {
      type: Boolean,
      default: true
    },
    // 是否激活显示键盘
    activeKeyboard: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: ''
    },
    /**
     * 显示小数
     */
    decimal: {
      type: Boolean,
      default: true
    },
    showInformation: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String,
      default: ''
    },
    /**
     * 鍵盤是否禁用
     */
    disabled: {
      type: Boolean,
      default: false
    },
    comboLabel: {
      type: String,
      default: ''
    },
    marketId: {
      type: String,
      default: '1'
    },
    keyboardId: {
      type: String,
      default: '0'
    }
  },
  slots: {
    parlayName: null
  },
  emits: ['update:amount', 'open-keyboard', 'change'],
  setup(props, { emit, slots }) {
    const { prebetInfo, state } = useBet();
    const activeKeyboard = computed(() => prebetInfo.value.length === 1);
    const states = reactive({
      defaultValue: '',
      showKeyboard: props.activeKeyboard && !props.disabled
    });
    const keyboardRoot = ref<any>(null);
    const { t } = useI18n();
    const onKeyboardSwitch = debounce(
      (isShow: boolean) => {
        emit('change', props.marketId);
        if (isShow && props.min > 0 && props.max >= props.min) {
          states.showKeyboard = true;
          return;
        }
        states.showKeyboard = isShow;
      },
      500,
      { leading: true }
    );
    watch(
      () => state.tabActive,
      () => {
        clearInput();
      }
    );
    // onClickOutside(keyboardRoot, () => onKeyboardSwitch(false));
    const getValue = (value: any) => {
      const reg = /^((\d+)|(\d+((\.{1,1})|(\.{1,1}\d{0,2}))))$/; // 数字正则输入
      if (reg.test(value)) {
        return value;
      } else {
        let tureValue = value;
        for (let i = value.length - 1; i >= 0; i--) {
          tureValue = tureValue.slice(0, i);
          if (reg.test(tureValue) || tureValue === '') {
            value = tureValue;
            break;
          }
        }
        return value;
      }
    };

    // 清除输入
    const clearInput = () => {
      states.defaultValue = '';
      emit('update:amount', '');
    };

    // 扩展预设数字
    const handleClickExpend = (inputValue: number | string) => {
      let newValue: any = '';
      const walletBlance = Number(props.balance);
      // 处理数字
      if (typeof inputValue === 'number') {
        newValue = inputValue + '';
      } else if (inputValue === 'MAX') {
        // 处理最大Max
        newValue = Math.min(props.max, props.balance);
      }
      // 检查值是否大于最大 或 者大于钱包
      const flag = newValue > props.max || newValue > walletBlance;
      if (flag) {
        newValue = props.max - walletBlance < 0 ? props.max : Math.floor(walletBlance);
      }
      newValue = newValue === '' ? 0 : Number(newValue);
      newValue = getValue(newValue);
      states.defaultValue = newValue;
      emit('update:amount', newValue || '');
    };

    // 处理普通数字
    const _handleClickNumber = (value: '.' | 'del' | number) => {
      let newValue: any = '';
      const oldValue = Number(states.defaultValue);
      const walletBlance = Number(props.balance);
      // 删除一位
      if (value === 'del') {
        const oldStrValue = String(oldValue);
        if (!oldStrValue.length) return;
        newValue = oldStrValue.substring(0, oldStrValue.length - 1);
      }
      // 数字
      if (typeof value === 'number') {
        newValue =
          oldValue === 0 && value === 0
            ? '0'
            : `${Number(states.defaultValue) === 0 ? '' : states.defaultValue}${value}`;
      }
      // 处理 .
      if (value === '.') {
        newValue = `${states.defaultValue === '0' || states.defaultValue === '' ? '' : states.defaultValue}${value}`;
        // ....
      }
      // 检查值是否大于最大 或 者大于钱包
      const flag = +newValue > props.max || +newValue > walletBlance;
      if (flag) {
        newValue = Math.floor(props.max - walletBlance < 0 ? props.max : walletBlance);
      }
      // newValue = newValue === '' ? 0 : Number(newValue);
      newValue = getValue(newValue);
      states.defaultValue = newValue;
      emit('update:amount', newValue || '');
    };

    const KeyboardInput = () => (
      <div class="keyboard-input" onClick={() => onKeyboardSwitch(true)}>
        {slots.default?.()}
        <div
          class={{
            'keyboard-input-container': true,
            'is-active': activeKeyboard.value || props.keyboardId === props.marketId,
            'is-disabled': true
          }}
        >
          {props.showCurrency ? <div class="currency-code">{props.currency}</div> : null}
          <div class="input-amount">{props.amount || t('lang.sport_bet_settingStake')}</div>
          <div class="clear-amount" v-show={props.amount} onClick={() => clearInput()}></div>
        </div>
      </div>
    );

    return () => (
      <div class="keyboard" ref={keyboardRoot}>
        {props.showInformation ? (
          Number(props.amount) > 0 && +props.min > Number(props.amount) ? (
            <div class="input-tips-label warning">{t('lang.sport_bet_belowTheMinimumStake') + ':' + props.min}</div>
          ) : (
            <div class="input-tips-label">
              <div class="combo-label">{props.comboLabel}</div>
              <div>
                {props.placeholder ||
                  `${t('lang.sport_bet_limit')}: ${currency(props.min) || 0}～${currency(props.max) || 0}`}{' '}
              </div>
            </div>
          )
        ) : null}
        <KeyboardInput />
        <div class="keyboard-container" v-show={activeKeyboard.value || props.keyboardId === props.marketId}>
          <div class="keyboard-numbers">
            {keyboardNumbers.map((num, index: number) => (
              <div class="btn-item-warp" key={index + '_BTN_KEY'}>
                <KeyboardKey class={{ 'btn-item': true, del: num === 'del' }} onPress={() => _handleClickNumber(num)}>
                  {num === 'del' ? <SvgIcon name="del" /> : num}
                </KeyboardKey>
              </div>
            ))}
          </div>
          <div class="keyboard-expand">
            {keyboardExtras.map((num: Extras, index: number) => (
              <div class="btn-item-warp" key={index + '_BTN_KEY'}>
                <KeyboardKey
                  class={{ 'btn-item': true, MAX: num.value === 'MAX' }}
                  onPress={() => handleClickExpend(num.value)}
                >
                  {num.value === 'MAX' ? 'MAX' : num.label}
                </KeyboardKey>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
});
