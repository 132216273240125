import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video-player" }
const _hoisted_2 = { class: "video-player-lines-switch" }
const _hoisted_3 = {
  key: 0,
  class: "video-player-candidate-line"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  id: "dplayer",
  ref: "player",
  class: "live-video-view"
}
const _hoisted_6 = {
  id: "live-video-test",
  ref: "video",
  style: {"display":"none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "video-player-current-line",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.showLines = !_ctx.state.showLines))
      }, " 线路" + _toDisplayString(_ctx.state.currentLine + 1), 1),
      (_ctx.state.showLines)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.urls, (line, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "video-player-line-item",
                key: index,
                onClick: ($event: any) => (_ctx.switchLine(line, index))
              }, [
                _createTextVNode(" 线路" + _toDisplayString(index + 1) + " ", 1),
                _withDirectives(_createVNode(_component_svg_icon, { name: "checked" }, null, 512), [
                  [_vShow, _ctx.state.currentLine === index]
                ])
              ], 8, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.urls.length > 1]
    ]),
    _createElementVNode("div", _hoisted_5, null, 512),
    _createElementVNode("video", _hoisted_6, null, 512)
  ]))
}