/**
 * 数字键盘输入
 */
import { defineComponent, ref, reactive } from 'vue';
import { keyboardExtras } from './config';
// import { useBet } from 'common/useBet';
import './keyboard.style.less';
import { useI18n } from '@/hooks';
import { currency } from '@/common';
// import { SvgIcon } from '@/components';
import { debounce } from 'lodash-es';

export default defineComponent({
  name: 'KeyboradInput',
  props: {
    balance: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: ''
    },
    showCurrency: {
      type: Boolean,
      default: true
    },
    // 是否激活显示键盘
    activeKeyboard: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: ''
    },
    /**
     * 显示小数
     */
    decimal: {
      type: Boolean,
      default: true
    },
    showInformation: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String || Number,
      default: '0' || 0
    },
    /**
     * 鍵盤是否禁用
     */
    disabled: {
      type: Boolean,
      default: false
    },
    comboLabel: {
      type: String,
      default: ''
    },
    marketId: {
      type: String,
      default: '1'
    },
    keyboardId: {
      type: String,
      default: '0'
    }
  },
  slots: {
    parlayName: null
  },
  emits: ['update:amount', 'open-keyboard', 'change'],
  setup(props, { emit, slots }) {
    const state = reactive({
      defaultValue: '',
      showKeyboard: props.activeKeyboard && !props.disabled
    });
    const keyboardRoot = ref<any>(null);
    const { t } = useI18n();
    const onKeyboardSwitch = debounce(
      (isShow: boolean) => {
        emit('change', props.marketId);
        if (isShow && props.min > 0 && props.max >= props.min) {
          state.showKeyboard = true;
          return;
        }
        state.showKeyboard = isShow;
      },
      500,
      { leading: true }
    );

    // onClickOutside(keyboardRoot, () => onKeyboardSwitch(false));
    const getValue = (value: any) => {
      const reg = /^((\d+)|(\d+((\.{1,1})|(\.{1,1}\d{0,2}))))$/; // 数字正则输入
      if (reg.test(value)) {
        return value;
      } else {
        let tureValue = value;
        for (let i = value.length - 1; i >= 0; i--) {
          tureValue = tureValue.slice(0, i);
          if (reg.test(tureValue) || tureValue === '') {
            value = tureValue;
            break;
          }
        }
        return value;
      }
    };

    // 清除输入
    const clearInput = () => {
      state.defaultValue = '';
      emit('update:amount', '');
    };

    // 扩展预设数字
    const handleClickExpend = (inputValue: number | string) => {
      let newValue: any = '';
      const walletBlance = Number(props.balance);
      // 处理数字
      newValue = inputValue + '';
      // 检查值是否大于最大 或 者大于钱包
      const flag = newValue > props.max || newValue > walletBlance;
      if (flag) {
        newValue = props.max - walletBlance < 0 ? props.max : Math.floor(walletBlance);
      }
      newValue = newValue === '' ? 0 : Number(newValue);
      newValue = getValue(newValue);
      state.defaultValue = newValue;
      emit('update:amount', newValue || '');
    };
    const onKeydown = element => {
      if (element.code === 'KeyE') {
        element.preventDefault();
      }
    };

    const onInput = element => {
      const value = element.target.value;
      const reg = /^((\d+)|(\d+((\.{1,1})|(\.{1,1}\d{0,2}))))$/; // 数字正则输入
      if ((reg.test(value) || value === '') && value.length < 10) {
        emit('update:amount', value);
      } else {
        let tureValue = value;
        for (let i = value.length - 1; i >= 0; i--) {
          tureValue = tureValue.slice(0, i);
          if (reg.test(tureValue) || tureValue === '') {
            emit('update:amount', tureValue);
            break;
          }
        }
      }
    };

    const onChange = element => {
      // 有效值输入
      let value = element.target.value;
      const reg = /(^[1-9]([0-9]){0,}((\.)|(\.[0-9]{1,5}){0,1})$)|(^0$)/; // 数字正则
      // 检查值是否大于最大 或 者大于钱包
      const flag = Number(value) > Number(props.max) || Number(value) > Number(props.balance);
      if (flag) {
        value = Math.floor(+props.max - +props.balance < 0 ? +props.max : Number(props.balance));
      }

      if ((Number(value) > 0 && Number(value) < Number(props.min)) || Number(value) < 0) {
        value = Number(props.min);
      }
      if (reg.test(Number(value) + '') || value === '') {
        if (typeof value === 'string' && value.includes('.')) {
          emit('update:amount', value);
        } else {
          emit('update:amount', Number(value));
        }
      } else {
        emit('update:amount', 0);
      }
    };

    const KeyboardInput = () => (
      <div class="keyboard-web-input" onClick={() => onKeyboardSwitch(true)}>
        {slots.default?.()}
        <div
          class={{
            'keyboard-web-input-container': true,
            'is-active': state.showKeyboard && props.keyboardId === props.marketId,
            'is-disabled': true
          }}
        >
          {props.showCurrency ? <div class="currency-code">{props.currency}</div> : null}
          <input
            type="number"
            class="input"
            v-model={props.amount}
            onChange={onChange}
            onInput={onInput}
            onKeydown={onKeydown}
            placeholder={t('lang.sport_bet_settingStake')}
            disabled={props.disabled}
          />
          <div class="clear-amount" v-show={props.amount} onClick={() => clearInput()}></div>
        </div>
      </div>
    );

    return () => (
      <div class="keyboard-web" ref={keyboardRoot}>
        {props.showInformation ? (
          Number(props.amount) > 0 && +props.min > Number(props.amount) ? (
            <div class="input-tips-label warning">{t('lang.sport_bet_belowTheMinimumStake') + ':' + props.min}</div>
          ) : (
            <div class="input-tips-label">
              <div class="combo-label">{props.comboLabel}</div>
              <div>
                {props.placeholder ||
                  `${t('lang.sport_bet_limit')}: ${currency(props.min) || 0}～${currency(props.max) || 0}`}{' '}
              </div>
            </div>
          )
        ) : null}
        <KeyboardInput />
        <div class="keyboard-web-container" v-show={state.showKeyboard && props.keyboardId === props.marketId}>
          <div class="keyboard-expand">
            {keyboardExtras.map((num: number, index: number) => (
              <div
                class={['btn-item-warp', +num === +props.amount && 'act']}
                onClick={() => handleClickExpend(num)}
                key={index + '_BTN_KEY'}
              >
                <div class="btn-item">{num}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
});
