/**
 *  比赛与盘口数据
 */
import { reactive, nextTick } from 'vue';
import {
  DataTypeEnum,
  MarketType,
  _formatMatchInfo,
  _formatHomeMarketOdds,
  HomeMarketOdds,
  TechId,
  formatMoreInfo
} from '@/common';
import {
  MatchItem,
  EventsAndMarkets,
  EventsAndMarketsPrams,
  queryEventsAndMarkets,
  getMarketData
} from '@/services/kg-api';
import { useSportOptions, TimerEnum, useTimerHook } from '@/hooks';
import { useFavortMatch } from '@/hooks/use365mobileFavortMatch.hook';
import { map, isEmpty } from 'lodash-es';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export interface StoreMatchInfo {
  id: string;
  home: string;
  homeLogo: string;
  league: string;
  away: string;
  awayLogo: string;
  sportId: number;
  current?: number;
  time: string;
  matchTime: string;
  homeId: number;
  awayId: number;
  date: string;
  leagueName: string;
  sportName: string;
  isNeutral: boolean;
  period: string;
  isLive: boolean;
  market: string;
  count: number;
  score: number[];
  sections?: [number, number][];
  video?: boolean;
  animate?: boolean;
  isMark: boolean;
  Number: any;
  tech?: {
    ht: [number, number];
    red: [number, number];
    yellow: [number, number];
    corner?: [number, number];
  };
  moreInfo: any;
  RunTime: number;
  ShowTime?: any;
  animateUrl: string;
  gameStatus: number;
}

export interface StoreMarketData {
  leagueId: string;
  leagueName: string;
  leagueLogo: string;
  sportId: number;
  key: string;
  matches: {
    sportId: number;
    matchId: string;
    info: StoreMatchInfo;
    oddset: HomeMarketOdds[];
  }[];
}

interface EventStore {
  /**
   * 请求参数
   */
  form: EventsAndMarketsPrams;
  /**
   * 下拉加载
   * pull loading
   */
  loading: boolean;
  /**
   * 请求中加载,用于骨架图
   * request loading
   */
  requestLoading: boolean;
  /**
   * 请求中停止 用于分页
   * request loading
   */
  requestFinash: boolean;
  /**
   * 请求中加载
   * request loading
   */
  pageLoading?: boolean;
  /**
   * 转换市场数据
   */
  marketList: StoreMarketData[];
}

const storeMain = reactive<EventStore>({
  form: {
    oddsType: MarketType.Europe,
    sportId: 1,
    periodId: DataTypeEnum.Live,
    leagueIds: null,
    selectDate: null,
    eventIds: [],
    isMatchTimeSort: false,
    pageNo: 0,
    pageSize: 10
  },
  loading: true,
  requestLoading: false,
  pageLoading: false,
  marketList: [],
  requestFinash: false
});

function getStore() {
  return reactive<EventStore>({
    form: {
      oddsType: MarketType.Europe,
      sportId: 1,
      periodId: DataTypeEnum.Live,
      leagueIds: null,
      selectDate: null,
      eventIds: [],
      pageNo: 0,
      pageSize: 10,
      isMatchTimeSort: false
    },
    requestLoading: false,
    pageLoading: false,
    loading: true,
    marketList: [],
    requestFinash: false
  });
}

export function useEventsAndMarkets(useOnlyStore = true) {
  let store = storeMain;
  if (useOnlyStore) {
    store = getStore();
  }
  const vuexStore = useStore();
  const route = useRoute();
  const { optionsStore } = useSportOptions();
  const { isMarkedById, clearMark } = useFavortMatch();
  const { addTimer, clearTimer } = useTimerHook();

  /**
   * 异步获取事件与市场数据
   * @param isPull      是否需要下拉刷新
   * @param pageLoad    是否加载更多数据
   */
  const getEventsAndMarkets = async (isPull = false, pageLoad = false, periodId?: number) => {
    // 检查是否是当前选项的请求
    const isCurrentRequest = (periodId?: number) => {
      if (!periodId) return true;
      return periodId === Number(route.query.periodId);
    };
    // 清除定时器
    clearTimer(TimerEnum.LIST);
    // 临时储存页面
    const pageN = Number(store.form.pageNo);
    // 判断是否为刷新页面或下拉刷新
    const isPageLoad = () => pageLoad || isPull;

    // 下拉刷新
    if (isPull) {
      store.loading = true;
      store.pageLoading = false;
      store.form.pageNo = 0;
      store.marketList = [];
      store.requestFinash = false;
    }

    // 加载更多数据
    if (pageLoad) {
      store.loading = false;
      store.pageLoading = true;
      store.requestFinash = false;
      store.form.pageNo = pageN + 1; // 增加页数
    }

    // 骨架图加载(刷新页面和下拉)
    !pageLoad && (store.requestLoading = true);

    const fn = async () => {
      // 储存市场数据
      let tempMarketList: any[] = [];
      // 请求结果是否成功
      let requestResult = true;

      if (Number(store.form.pageNo) > 0 && !isPageLoad()) {
        // 有分页 自动刷新的情况
        for (let index = 0; index <= Number(store.form.pageNo); index++) {
          const [res, data] = (await getMarketData({ ...store.form, pageNo: index })) as [boolean, EventsAndMarkets[]];
          // 请求成功就把数据合并起来
          if (res && !isEmpty(data)) {
            tempMarketList = tempMarketList.concat(data);
          } else {
            store.marketList = [];
            tempMarketList = [];
          }
          requestResult = res;
        }
      } else {
        const [res, data] = (await getMarketData(store.form)) as [boolean, EventsAndMarkets[]];
        // 如果请求成功就替换当前列表数据
        requestResult = res && !isEmpty(data);
        if (requestResult && isCurrentRequest(periodId)) {
          tempMarketList = data;
        } else {
          tempMarketList = [];
          store.marketList = [];
        }
      }
      if (isPull || isPageLoad()) {
        // 强制给他搞个延迟
        nextTick(() => {
          store.loading = false;
          store.pageLoading = false;
        });
      }

      // 清除标记并转换数据
      if (periodId == -1) {
        clearMark(store.form, tempMarketList);
      }
      // 关闭请求状态
      store.requestLoading = false;
      if (requestResult) {
        // 清除标记并转换数据
        if (!isCurrentRequest(periodId)) {
          // 请求参数与当前页面不符，重新请求
          getEventsAndMarkets(false, false, Number(route.query.periodId));
        }
        const list = formatMarketData(tempMarketList, vuexStore, isMarkedById);
        store.marketList = isPageLoad() ? store.marketList.concat(list) : list;
      } else {
        store.requestFinash = true;
        // 还原页码
        store.form.pageNo = pageN;
      }

      // 还原加载参数
      pageLoad = false;
      isPull = false;
    };

    // 添加定时器
    addTimer(
      TimerEnum.LIST,
      fn,
      Number(store.form.periodId) === DataTypeEnum.Live ? 15 * 1000 : 60 * 1000 // 定时器时间
    );
  };
  // 格式化市场数据
  function formatMarketData(source: any[], vuexStore: any, isMarkedById: any) {
    const tempList: any[] = [];
    map(source, (marketItem: any) => {
      const result = {
        leagueId: marketItem.leagueId ? marketItem.leagueId : marketItem.LeagueId,
        leagueName: marketItem.LeagueName,
        leagueLogo: marketItem.LeagueLogo,
        sportId: marketItem.SportType,
        matches: marketItem.matches.map((match: MatchItem) => {
          const animate = match.animate;
          if (match?.MatchId) {
            const tech = vuexStore?.getters?.getTechById(match?.MatchId);
            if (tech) {
              const RedCard = tech.get(TechId.RED) || [];
              const RedCardH = RedCard[0];
              const RedCardA = RedCard[1];
              const [CornerH, CornerA] = tech.get(TechId.CORNER) || [0, 0];
              const YellowCard = tech.get(TechId.YELLOW) || [];
              const YellowCardH = YellowCard[0];
              const YellowCardA = YellowCard[1];
              const [HalfTimeH, HalfTimeA] = tech.get(TechId.HALF_SCORE) || [0, 0];

              match.MoreInfo = Object.assign(match.MoreInfo || {}, {
                CornerH,
                CornerA,
                HalfTimeH,
                HalfTimeA,
                RedCardH,
                RedCardA,
                YellowCardH,
                YellowCardA
              });
            } else if (!!match.MoreInfo) {
              const RedCardH = match.MoreInfo.redCardH;
              const RedCardA = match.MoreInfo.redCardA;
              const CornerH = match.MoreInfo.cornersH;
              const CornerA = match.MoreInfo.cornersA;
              const YellowCardH = match.MoreInfo.yellowCardH;
              const YellowCardA = match.MoreInfo.yellowCardA;
              const HalfTimeH = match.MoreInfo.halfScoreH;
              const HalfTimeA = match.MoreInfo.halfScoreA;
              match.MoreInfo = Object.assign(match.MoreInfo || {}, {
                CornerH,
                CornerA,
                HalfTimeH,
                HalfTimeA,
                RedCardH,
                RedCardA,
                YellowCardH,
                YellowCardA
              });
            }
          }
          return {
            info: {
              ..._formatMatchInfo({
                match,
                league: marketItem,
                isInProgress: match.IsLive === 1,
                sportId: Number(marketItem.SportType)
              }),
              video: !!vuexStore.getters?.getVideoByMid(match.MatchId),
              animate: animate ? animate : !!vuexStore.getters?.getAnimateyByMid(match.MatchId),
              isMark: isMarkedById({ matchId: match.MatchId, sportId: marketItem.SportType }),
              moreInfo: !!match.MoreInfo ? formatMoreInfo(match.MoreInfo) : {},
              RunTime: match.RunTime,
              ShowTime: match.ShowTime,
              awayLogo: match.AwayLogo,
              homeLogo: match.HomeLogo
            },
            oddset: _formatHomeMarketOdds(marketItem.SportType, match.oddset)
          };
        })
      };
      tempList.push(result);
    });
    return tempList;
  }
  /**
   * 获取市场数据
   * {isPull: 是否为下拉}
   */
  const getEventsAndMarkets2 = async (isPull = false) => {
    if (isPull) {
      store.loading = true;
    }
    store.requestLoading = true;
    store.form.oddsType = optionsStore.value.marketType;
    const fn = async () => {
      const [res, data] = (await queryEventsAndMarkets(store.form)) as [boolean, EventsAndMarkets[]];
      if (isPull) {
        // 强制给他搞个延迟
        setTimeout(() => {
          store.loading = false;
        }, 1000);
      }
      store.requestLoading = false;
      store.marketList = [];
      if (res) {
        clearMark(store.form, data);
        const tempList: any[] = [];
        // 转换数据
        if (!isEmpty(data)) {
          console.log(data, 'data');
          map(data, (marketItem: any) => {
            const result = {
              leagueId: marketItem.leagueId ? marketItem.leagueId : marketItem.LeagueId,
              leagueName: marketItem.LeagueName,
              leagueLogo: marketItem.LeagueLogo,
              sportId: marketItem.SportType,
              matches: marketItem.matches.map((match: MatchItem) => {
                const animate = match.animate;
                if (match?.MatchId) {
                  const tech = vuexStore?.getters?.getTechById(match?.MatchId);
                  if (tech) {
                    const RedCard = tech.get(TechId.RED) || [];
                    const RedCardH = RedCard[0];
                    const RedCardA = RedCard[1];
                    const [CornerH, CornerA] = tech.get(TechId.CORNER) || [0, 0];
                    const YellowCard = tech.get(TechId.YELLOW) || [];
                    const YellowCardH = YellowCard[0];
                    const YellowCardA = YellowCard[1];
                    const [HalfTimeH, HalfTimeA] = tech.get(TechId.HALF_SCORE) || [0, 0];

                    match.MoreInfo = Object.assign(match.MoreInfo || {}, {
                      CornerH,
                      CornerA,
                      HalfTimeH,
                      HalfTimeA,
                      RedCardH,
                      RedCardA,
                      YellowCardH,
                      YellowCardA
                    });
                  } else if (!!match.MoreInfo) {
                    const RedCardH = match.MoreInfo.redCardH;
                    const RedCardA = match.MoreInfo.redCardA;
                    const CornerH = match.MoreInfo.cornersH;
                    const CornerA = match.MoreInfo.cornersA;
                    const YellowCardH = match.MoreInfo.yellowCardH;
                    const YellowCardA = match.MoreInfo.yellowCardA;
                    const HalfTimeH = match.MoreInfo.halfScoreH;
                    const HalfTimeA = match.MoreInfo.halfScoreA;
                    match.MoreInfo = Object.assign(match.MoreInfo || {}, {
                      CornerH,
                      CornerA,
                      HalfTimeH,
                      HalfTimeA,
                      RedCardH,
                      RedCardA,
                      YellowCardH,
                      YellowCardA
                    });
                  }
                }
                return {
                  info: {
                    ..._formatMatchInfo({
                      match,
                      league: marketItem,
                      isInProgress: match.IsLive === 1,
                      sportId: Number(marketItem.SportType)
                    }),
                    video: !!vuexStore.getters?.getVideoByMid(match.MatchId),
                    animate: animate ? animate : !!vuexStore.getters?.getAnimateyByMid(match.MatchId),
                    isMark: isMarkedById({ matchId: match.MatchId, sportId: marketItem.SportType }),
                    moreInfo: !!match.MoreInfo ? formatMoreInfo(match.MoreInfo) : {},
                    RunTime: match.RunTime,
                    ShowTime: match.ShowTime,
                    awayLogo: match.AwayLogo,
                    homeLogo: match.HomeLogo
                  },
                  oddset: _formatHomeMarketOdds(marketItem.SportType, match.oddset)
                };
              })
            };
            tempList.push(result);
          });
        } else {
          store.marketList = [];
        }
        store.marketList = tempList;
        // await vueStore.dispatch('GET_SOCCERBALL_TECH_IM', data);
      }
    };
    addTimer(TimerEnum.LIST, fn, Number(store.form.periodId) === DataTypeEnum.Live ? 6000 : 60 * 1000);
  };

  return {
    eventStore: store,
    getEventsAndMarkets,
    getEventsAndMarkets2
  };
}
