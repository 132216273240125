import { isEmpty, sortBy, groupBy } from 'lodash-es';
import { LeagueItem } from '@/services';

interface Letters {
  [name: string]: LeagueItem[];
}

// 格式化联赛列表
export async function formatLeagueList(data: LeagueItem[]): Promise<Letters | []> {
  if (isEmpty(data)) return [];
  const letterArr: any[] = [];
  const pinyin = (await import('pinyin')).default;
  data.forEach((e: any) => {
    const firstChar = e.leagueName[0];
    letterArr.push({
      leagueName: e.leagueName,
      leagueLogo: e.leagueLogo,
      leagueId: e.leagueId,
      letter: firstChar === '阿' ? 'A' : pinyin(firstChar, { style: pinyin.STYLE_FIRST_LETTER })[0][0][0].toUpperCase()
    });
  });
  // 根据首字母排序
  const sortData = sortBy(letterArr, el => el.letter);
  return groupBy(sortData, 'letter');
}
