import { defineComponent } from 'vue';
import { TeamLogo } from 'kg-web/components';
import { toTime } from '@/common';
import { useI18n } from '@/hooks';
import { get } from 'lodash-es';
import './match-item.less';

export default defineComponent({
  name: 'ResultItem',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { t } = useI18n();

    // const _isHight = (a: number, b: number) => {
    //   if (a === 0 && b === 0) return false;
    //   return a >= b;
    // };

    return () => (
      <div class="sports-match-item">
        <div class="left-info">
          <div class="time-header">
            <span class="match-time"> {toTime(Number(props.info.startTime), 'MM-DD HH:mm') || '--'} </span>
          </div>
          <div class="team-name">
            <span class="item">
              <div class="itemH">
                <TeamLogo class="t-logo" sportId={props.info.gameId} teamId={props.info.home} />
                <span>{props.info.home || '--'}</span>
              </div>
            </span>
            <span class="item">
              <div class="itemA">
                <TeamLogo class="t-logo" sportId={props.info.gameId} teamId={props.info.away} />
                <span>{props.info.away || '--'}</span>
              </div>
            </span>
          </div>
        </div>
        <div class="right-info">
          <div class="time-header">
            <div class="match-info-title">
              {get(props.info, 'sportId') == '1' ? (
                <>
                  <span class="match-time">{t('lang.sport_details_cornerBall')}</span>
                  <span class="match-time">{t('lang.sport_earlyHandicap_halfTime')}</span>
                </>
              ) : null}
              <span class="match-time">
                {/* 完场 */}
                {t('lang.sport_common_finally')}
              </span>
            </div>
          </div>
          <div class="match-item-content">
            <div class="score-right">
              {get(props.info, 'sportId') == '1' ? (
                <>
                  <div class="score">
                    <span>{props.info.cornerH || '0'}</span>
                    <span>{props.info.cornerA || '0'}</span>
                  </div>
                  <div class="score">
                    <span>{props.info.halfHomeScore || '0'}</span>
                    <span>{props.info.halfAwayScore || '0'}</span>
                  </div>
                </>
              ) : null}
              <div class="score">
                <span>{props.info.homeScore || '0'}</span>
                <span>{props.info.awayScore || '0'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});
