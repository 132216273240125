import { defineComponent, ref } from 'vue';
import { SvgIcon } from '@/components';
import style from './favorit-star.module.less';
import { useAuth } from '@/hooks';

export default defineComponent({
  name: 'FavoritStar',
  props: {
    isMarked: {
      type: Boolean,
      require: true
    }
  },
  emits: ['mark'],
  setup(props, { emit }) {
    const mark = ref(props.isMarked);
    const { checkIsLogin } = useAuth();

    const onMark = async (e: Event) => {
      if (!(await checkIsLogin())) return;
      mark.value = !mark.value;
      emit('mark');
      e.stopPropagation();
      e.preventDefault();
    };

    return () => (
      // <div class={[style.favoritStar, mark.value ? style.isMark : null]} onClick={() => onMark()}>
      <div class={[style.favoritStar, style.isMark]} onClick={async event => await onMark(event)}>
        <SvgIcon name="star" />
      </div>
    );
  }
});
