import { defineComponent, onMounted, computed } from 'vue';
import { useBetRecord, TAB_LIST, getDateList, useI18n } from '@/hooks';
import { Select, SelectOption, Pagination, Skeleton } from 'ant-design-vue';
import { DataEmpty, SelectView, PaginationView } from 'kg-web/components';
import BettingResultItem from './compoents/item';
import { isEmpty, get } from 'lodash-es';
import './style/index.less';

export default defineComponent({
  name: 'Orders',
  setup() {
    const { betStore, getData, form, state, onTabChange } = useBetRecord();
    const { t } = useI18n();

    const isShow = computed(() => Number(betStore.count.betCount) > 0 && state.activeTab !== 1);

    const currency = computed(() => {
      const res = get(betStore, 'count.currency');
      return res ? `(${res})` : '';
    });

    const backTop = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };

    const isHidePage = computed(() => betStore.total < form.size);

    onMounted(async () => {
      await getData();
    });

    const TabsRender = () => (
      <div class="bet-history__top">
        <div class="tab-container">
          <div class="filter-tabs">
            {TAB_LIST.map((item, i) => (
              <div
                key={i + '_T_KEY'}
                onClick={() => {
                  onTabChange({ key: 'activeTab', index: i });
                  backTop();
                }}
                class={['filter-tab', i === state.activeTab ? 'tab--active ' : null]}
              >
                <a> {t(item.label)}</a>
              </div>
            ))}
          </div>
          <div class="time-filter">
            {state.activeTab !== 1 ? (
              <SelectView>
                <Select
                  v-model:value={state.avtiveFilterDate}
                  onChange={v => onTabChange({ key: 'avtiveFilterDate', index: v } as any, () => null, true)}
                >
                  {getDateList().map((item, index) => (
                    <SelectOption key={item.i18n} value={index}>
                      {t(item.i18n)}
                    </SelectOption>
                  ))}
                </Select>
              </SelectView>
            ) : null}
          </div>
        </div>
      </div>
    );

    return () => (
      <div class="bet-history">
        <div>
          {/* <div class="bet-history-title">
            <p>{t('lang.sport_common_bettingRecords')}</p>
          </div> */}
          <TabsRender />
          {!isEmpty(betStore.betRecord) ? (
            <>
              {isShow.value ? (
                <div class="orders-total">
                  {t('lang.sport_common_recordTotal')}:
                  <span class="orders-total-number">{betStore.count.betCount}</span>
                </div>
              ) : null}
              {state.activeTab !== 1 && !isEmpty(betStore.betRecord) ? (
                <div class="statice-data">
                  <div class="s-d-item">
                    {t('lang.sport_bet_betAmount')}:{' '}
                    <span>
                      {betStore.count.betAmount}
                      {currency.value}
                    </span>
                  </div>
                  <div class="s-d-item">
                    {t('lang.sport_orders_winLost')}:{' '}
                    <span>
                      {betStore.count.winAmount}
                      {currency.value}
                    </span>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <Skeleton loading={state.loading} paragraph={{ rows: 8 }}>
          {!isEmpty(betStore.betRecord) ? (
            <div style={{ overflow: 'auto' }}>
              {betStore.betRecord.map(item => (
                <BettingResultItem status={form.status} key={item.orderId} data={item} />
              ))}
            </div>
          ) : (
            <DataEmpty type="orders" title={t('lang.sport_common_noBetData')} class="empty" />
          )}
        </Skeleton>
        <PaginationView>
          <Pagination
            class="orders-pagination"
            v-model:current={form.current}
            onChange={async () => await getData()}
            hide-on-single-page={isHidePage.value}
            page-size={form.size}
            pager-count={5}
            total={betStore.total}
          />
        </PaginationView>
      </div>
    );
  }
});
