import { defineComponent, watch, ref, provide, computed } from 'vue';
import './collapse.style.less';
import { useBetWeb } from '@/hooks/useBetWeb.hook';
import { useAuth } from '@/hooks';
import { isEmpty } from 'lodash-es';

export default defineComponent({
  name: 'Collapse',
  props: {
    expandAll: {
      type: Boolean,
      default: true
    },
    arrow: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { slots }) {
    const { betTickets } = useBetWeb(true);
    const { isLogin } = useAuth();
    // 显示购物车
    const showCart = computed(() => {
      return !isEmpty(betTickets.value) && isLogin.value;
    });
    const expand = ref(true);

    watch(
      () => props.expandAll,
      () => {
        expand.value = props.expandAll;
      },
      {
        immediate: true
      }
    );

    provide('expandAll', expand);
    provide('arrow', props.arrow);

    return () => (
      <div class={['mobile-365-collapse', props.expandAll ? 'expand-all' : null, showCart.value ? null : null]}>
        {slots.default?.()}
      </div>
    );
  }
});
