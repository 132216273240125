/**
 * Deprecated!
 * 设置盘口
 */

import { computed } from 'vue';
import store from '@/store';
import { SET_ODD_TYPE_KEY, SET_OPTION_TYPE_KEY } from '@/common/enums/cacheEnum';

export function useGameSet() {
  /**
   * 当前盘口类型
   */
  // const currentOddsType = computed(() => {
  //   return store.state.sports.oddsType;
  // });

  /**
   * 设置类型
   */
  // const setOddsType = (value: number) => {
  //   if (currentOddsType.value === value) return;
  //   store.dispatch('SET_oddsType', value);
  // };

  // 当前投注设置
  const currentBetOption = computed(() => {
    return store.state.sports.betSetType;
  });

  /**
   * 设置类型
   */
  const setBetOption = (value: number) => {
    if (currentBetOption.value === value) return;
    store.dispatch('SET_BET_TYPE', value);
  };

  // 初始化配置
  const initGameSet = () => {
    const localOddsType = localStorage.getItem(SET_ODD_TYPE_KEY);
    const localOptionType = localStorage.getItem(SET_OPTION_TYPE_KEY);
    !!localOddsType && store.dispatch('SET_oddsType', JSON.parse(localOddsType));
    !!localOptionType && store.dispatch('SET_BET_TYPE', JSON.parse(localOptionType));
  };

  return {
    currentBetOption,
    // currentOddsType,
    setBetOption,
    // setOddsType,
    initGameSet
  };
}
