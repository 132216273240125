import { ActionContext } from 'vuex';
import { useSportsTech } from '@/hooks/useSportsWs';
import { unzip } from '@/common/utils/gzip.util';
import { formatSportsTech } from '@/common';
import { SportsTechState } from '@/store/types';

const ws = useSportsTech();

const initState = (): SportsTechState => ({
  isConnected: false,
  dataMap: new Map(),
  halfScoreMap: new Map(),
  wsClient: null
});

const state: SportsTechState = initState();

const getters = {
  getTechById: (state: SportsTechState) => (matchId: String) => {
    return state.dataMap.get(matchId);
  },
  getHalfById: (state: SportsTechState) => (matchId: number) => state.halfScoreMap.get(matchId)
};

const actions = {
  // 订阅统计数据
  async SUBSCRIBE_SPORTS_TECH({ dispatch, commit }: ActionContext<any, any>) {
    ws.initWs({
      onConnect: () => {
        ws.isConnected.value = true;
        commit('SUBSCRIBE_SPORTS_TECH_SUCCESS', true);
        // 订阅技术统计链接
        ws.subscribe('/topic/global.message', ({ body }) => {
          dispatch('FORMAT_TECH_DATA', JSON.parse(body));
        });
        // 取消订阅
        // ws.unSubscribe('/topic/global.message', () => {
        //   commit('SUBSCRIBE_SPORTS_TECH_SUCCESS', false);
        // });
      }
    });
  },
  //   处理数据
  FORMAT_TECH_DATA({ commit }: ActionContext<any, any>, { data }) {
    const formatData = formatSportsTech(unzip(data));
    // 格式化赛事数据
    commit('FORMAT_TECH_DATA_SUCCESS', formatData);
  }
};

// mutations
const mutations = {
  SUBSCRIBE_SPORTS_TECH_SUCCESS(state: SportsTechState, paylod: boolean) {
    state.isConnected = paylod;
  },
  FORMAT_TECH_DATA_SUCCESS(state: SportsTechState, payload: Map<String, any>) {
    state.dataMap = payload;
  },
  GET_HALF_DATA_SUCCESS(state: SportsTechState, payload: Map<number, number[]>) {
    state.halfScoreMap = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
