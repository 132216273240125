import { CSSProperties } from 'vue';
export const inBrowser = typeof window !== 'undefined';

export function getZIndexStyle(zIndex?: string | number) {
  const style: CSSProperties = {};
  if (zIndex !== undefined) {
    style.zIndex = +zIndex;
  }
  return style;
}

// cache
let rootFontSize: number;

function getRootFontSize() {
  if (!rootFontSize) {
    const doc = document.documentElement;
    const fontSize = doc.style.fontSize || window.getComputedStyle(doc).fontSize;

    rootFontSize = parseFloat(fontSize);
  }

  return rootFontSize;
}

function convertRem(value: string) {
  value = value.replace(/rem/g, '');
  return +value * getRootFontSize();
}

function convertVw(value: string) {
  value = value.replace(/vw/g, '');
  return (+value * window.innerWidth) / 100;
}

function convertVh(value: string) {
  value = value.replace(/vh/g, '');
  return (+value * window.innerHeight) / 100;
}

// 转换像素
export function unitToPx(value: string | number): number {
  if (typeof value === 'number') {
    return value;
  }

  if (inBrowser) {
    if (value.includes('rem')) {
      return convertRem(value);
    }
    if (value.includes('vw')) {
      return convertVw(value);
    }
    if (value.includes('vh')) {
      return convertVh(value);
    }
  }

  return parseFloat(value);
}
// 修改URL参数
export function replaceParamVal(oldName: string, newWith: string) {
  const oUrl = window.location.href.toString();
  const re = eval('/(' + oldName + '=)([^&]*)/gi');
  const nUrl = oUrl.replace(re, oldName + '=' + newWith);
  window.location.href = nUrl;
}
// URL参数转换字符串
export function queryToStr(query: any) {
  console.log(query);
  let str = '';
  let index = 0;
  for (const key in query) {
    index = index + 1;
    if (index === 1) {
      str = str + `?${key}=${query[key]}`;
    } else {
      str = str + `&${key}=${query[key]}`;
    }
  }
  return str;
}
