
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup(props) {
    return { iconName: `#icon-${props.name}` };
  }
});
