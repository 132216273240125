import { defineComponent, onMounted, PropType } from 'vue';
import styles from './recommended-match.module.less';

import { StoreMatchInfo, useI18n } from '@/hooks';
import { useRecommendedMarket } from '@/hooks/use365RecommendedMarket.hook';
import { useBetWeb, hasTicketId } from '@/hooks/useBetWeb.hook';
import { OddsPrice } from 'kg-web/components';
import { SelType } from '@/services/kg-api';
import { isEmpty } from 'lodash-es';
import { promiseTimeout } from '@vueuse/shared';
import { useTimeoutPoll } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { DataTypeEnum, SportIdEnum, _keyNameByBettype, RouteEnum } from '@/common';
import { SvgIcon } from '@/components';

// 默认刷新时间
const DETAULT_INTERVAL = 5 * 1000;

export interface TeamInfoTypes {
  headerLable: string;
  home: any;
  away: any;
}

export default defineComponent({
  name: 'RecommendedMatch',
  props: {
    periodId: {
      type: Number as PropType<DataTypeEnum.Live | DataTypeEnum.Today | DataTypeEnum.championLeague>,
      default: DataTypeEnum.Live
    }
  },
  setup(props) {
    const { getRecommendedMarketData, recStore } = useRecommendedMarket();
    const { addTicket } = useBetWeb();
    const router = useRouter();
    const { t } = useI18n();

    const fetchData = async () => {
      await promiseTimeout(DETAULT_INTERVAL);
      await getRecommendedMarketData();
    };

    // sportId=1&periodId=3&matchId=52800361
    const pathToDetail = ({ sportId, matchId }: any) => {
      router.replace(RouteEnum.SPORT_DETAIL + `?sportId=${sportId}&periodId=${props.periodId}&matchId=${matchId}`);
    };
    const { resume } = useTimeoutPoll(fetchData, DETAULT_INTERVAL);

    onMounted(async () => {
      await getRecommendedMarketData();
      resume();
    });

    const formatScoreInfo = (teamInfo: StoreMatchInfo): TeamInfoTypes[] => {
      const { tech, score, sportId, sections, isLive } = teamInfo;
      if (sportId === SportIdEnum.Soccerball) {
        if (!score || !isLive)
          return [
            { headerLable: `${t('lang.sport_common_red')}`, home: '-', away: '-' }, // 红牌
            { headerLable: `${t('lang.sport_common_yellow')}`, home: '-', away: '-' }, // 黄牌
            { headerLable: `${t('lang.sport_common_corn')}`, home: '-', away: '-' }, // 角球
            { headerLable: `${t('lang.sport_common_haftTime')}`, home: '-', away: '-' }, // 半场
            { headerLable: `${t('lang.sport_common_totalScore')}`, home: '-', away: '-' } // 总分
          ];
        return [
          { headerLable: `${t('lang.sport_common_red')}`, home: tech?.red[0] || 0, away: tech?.red[1] || 0 }, // 红牌
          { headerLable: `${t('lang.sport_common_yellow')}`, home: tech?.yellow[0] || 0, away: tech?.yellow[1] || 0 }, // 黄牌
          { headerLable: `${t('lang.sport_common_corn')}`, home: tech?.corner![0] || 0, away: tech?.corner![1] }, // 角球
          { headerLable: `${t('lang.sport_common_haftTime')}`, home: tech?.ht[0] || 0, away: tech?.ht[1] || 0 }, // 半场
          {
            headerLable: `${t('lang.sport_common_totalScore')}`,
            home: score && Number(score[0]),
            away: score && Number(score[1])
          } // 总分
        ];
      } else if (sportId === SportIdEnum.Basketball) {
        if (!sections)
          return [
            { headerLable: `${t('lang.sport_common_1st')}`, home: '-', away: '-' }, // 第一节
            { headerLable: `${t('lang.sport_common_2nd')}`, home: '-', away: '-' }, // 第二节
            { headerLable: `${t('lang.sport_common_half')}`, home: '-', away: '-' }, // 半
            { headerLable: `${t('lang.sport_common_3rd')}`, home: '-', away: '-' }, // 第三节
            { headerLable: `${t('lang.sport_common_4th')}`, home: '-', away: '-' }, // 第四节
            { headerLable: `${t('lang.sport_common_totalScore')}`, home: '-', away: '-' } // 总分
          ];
        return [
          { headerLable: `${t('lang.sport_common_1st')}`, home: sections[0][0] || '-', away: sections[0][1] || '-' }, // 第一节
          { headerLable: `${t('lang.sport_common_2nd')}`, home: sections[1][0] || '-', away: sections[1][1] || '-' }, // 第二节
          {
            headerLable: `${t('lang.sport_common_half')}`,
            home: sections[0][0] + sections[1][0] || '-',
            away: sections[0][1] + sections[1][1] || '-'
          }, // 半
          { headerLable: `${t('lang.sport_common_3rd')}`, home: sections[2][1] || '-', away: sections[2][1] || '-' }, // 第三节
          { headerLable: `${t('lang.sport_common_4th')}`, home: sections[3][1] || '-', away: sections[3][1] || '-' }, // 第四节
          {
            headerLable: `${t('lang.sport_common_totalScore')}`,
            home: score && Number(score[0]),
            away: score && Number(score[1])
          } // 总分
        ];
      } else {
        return [
          {
            headerLable: `${t('lang.sport_common_totalScore')}`,
            home: score && Number(score[0]),
            away: score && Number(score[1])
          } // 总分
        ];
      }
    };

    const LockOddsRender = () => (
      <div class={styles.LockOdds}>
        <svg-icon name="lock" />
      </div>
    );

    const RecHeacer = ({ teamInfo }: { teamInfo: StoreMatchInfo }) => {
      return (
        <div class={styles.RecHeader}>
          <div class={styles.LeagueInfoWrapper}>
            <span class={styles.LeagueName}>{teamInfo.leagueName}</span>
            <span class={[styles.LeagueStatus, teamInfo.isLive ? styles.IsLive : null]}>
              {teamInfo.isLive
                ? teamInfo.time || `${t('lang.sport_handicap_live')}`
                : `${t('lang.sport_common_comingSoon')}`}
            </span>
          </div>
          <div class={styles.TeamNames}>
            <span>{teamInfo.home}</span>
            <span>{teamInfo.away}</span>
          </div>
        </div>
      );
    };

    const Teams = ({ teamInfo }: { teamInfo: StoreMatchInfo; BettypeName: string }) => (
      <div class={styles.Teams}>
        <div class={styles.Headers}>
          {formatScoreInfo(teamInfo).map((header, index) => (
            <span key={index} class={styles.InfoLabel}>
              {header.headerLable}
            </span>
          ))}
        </div>
        <div class={styles.TeamsInfo}>
          <div class={styles.HomeTeam}>
            {formatScoreInfo(teamInfo).map((val, indx) => (
              <span key={indx} class={styles.InfoLabel}>
                {val.home}
              </span>
            ))}
          </div>
          <div class={styles.AwayTeam}>
            {formatScoreInfo(teamInfo).map((val, indx) => (
              <span key={indx} class={styles.InfoLabel}>
                {val.away}
              </span>
            ))}
          </div>
        </div>
      </div>
    );

    const getBgClass = sportId => {
      switch (sportId) {
        case SportIdEnum.Soccerball:
          return styles.SoccerBg;

        case SportIdEnum.Basketball:
          return styles.BasketballBg;

        default:
          return styles.OtherSportsBg;
      }
    };

    const MainRender = () => (
      <div class={styles.RecommendedMatch}>
        <div class={styles.ScrollContainer}>
          {recStore.recMatchList.map(item => (
            <div key={item.key} class={styles.RecommendedMatchItem}>
              <div class={[styles.Container, getBgClass(item.info.sportId)]}>
                <div class={styles.LeagueInfo}>
                  <RecHeacer teamInfo={item.info} />
                  <Teams teamInfo={item.info} BettypeName={item.oddset[0].BettypeName} />
                </div>
                <div class={styles.Odds}>
                  <div class={styles.OddsContainer}>
                    {item.oddset[0].sels.map((sel: SelType) =>
                      !sel ? (
                        <div class={[styles.OddsItem]}></div>
                      ) : (
                        <div
                          onClick={() =>
                            addTicket(
                              {
                                betType: item.oddset[0].Bettype,
                                betTypeName: item.oddset[0].BettypeName,
                                matchId: item.info.id,
                                marketId: item.oddset[0].OddsId as string,
                                sportType: item.info.sportId,
                                oddsType: sel.OddsType,
                                wagerSelectionId: sel.WagerSelectionId,
                                price: sel.Price,
                                periodId: item.oddset[0].PeriodId,
                                point: sel.Point,
                                key: sel.Key,
                                keyName: sel.KeyName,
                                home: item.info.home,
                                away: item.info.away,
                                leagueName: item.info.leagueName,
                                market: item.info.market,
                                combo: item.oddset[0].Combo,
                                Combo: item.oddset[0].Combo,
                                EuropePrice: sel.EuropePrice,
                                matchTime: item.info.matchTime,
                                allowBetBuilder: item.oddset[0].allowBetBuilder
                              },
                              true
                            )
                          }
                          class={[
                            styles.OddsItem,
                            hasTicketId({
                              matchId: item.info.id,
                              marketId: item.oddset[0].OddsId as string,
                              wagerSelectionId: sel.WagerSelectionId
                            })
                              ? styles.Active
                              : null
                          ]}
                        >
                          {item.oddset[0]?.lock ? (
                            <LockOddsRender />
                          ) : (
                            <>
                              <span>{_keyNameByBettype(item.oddset[0].uiType, sel, false, true)}</span>
                              <OddsPrice class={styles.OddsValue} value={sel.Price} />
                            </>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div
                    class={styles.ArrowBtn}
                    onClick={() => pathToDetail({ sportId: item.info.sportId, matchId: item.info.id })}
                  >
                    <span>{item.info.count}</span>
                    <SvgIcon class={styles.arrIcon} name="arrow_right" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

    return () => (!isEmpty(recStore.recMatchList) ? <MainRender /> : null);
  }
});
