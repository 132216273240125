import { defineComponent, PropType, inject } from 'vue';
import { isEmpty, reverse } from 'lodash-es';
import { TeamLogo, DataEmpty } from 'kg-web/components';
import { soccerBallStatus } from './live-events-status';
import text from './text-live.module.less';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'JBBsportTextLive',
  props: {
    realTimelist: {
      type: Array as PropType<any[]>,
      default: () => []
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { matchInfo } = inject('matchInfo') as any;
    return () =>
      isEmpty(props.realTimelist) ? (
        <DataEmpty title={t('lang.sport_details_noTextLive')} />
      ) : (
        <div class={text.page}>
          <div class={text.header}>{t('lang.sport_details_textLive')}</div>
          {reverse(props.realTimelist.slice(0))?.map((item, index) => (
            <div class={text.content} key={index + '_R_KEY'}>
              <div class={text.contentItem}>
                <div>
                  <img class={text.liveIcon} src={soccerBallStatus[item.type].value} />
                </div>
                <div class={text.liveTextBox}>
                  <div class={text.liveHeader}>
                    <TeamLogo
                      class={text.logo}
                      teamId={matchInfo.value[item.position === 1 || item.position === 0 ? 'HomeID' : 'AwayID']}
                      size={'xs'}
                    />
                    {item.position === 1 || item.position === 0 ? (
                      <span class={text.liveTeamName}>{matchInfo.value.HomeName}</span>
                    ) : (
                      <span class={text.liveTeamName}>{matchInfo.value.AwayName}</span>
                    )}
                  </div>
                  <div class={text.liveText}>{item.data}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
  }
});
