/**
 * 数据格式化
 */

import { isEmpty, isArray, map, filter, sortBy, fill, uniq, split, isNumber, max, concat } from 'lodash-es';
import { EventSummary } from '@/services';
import { DataTypeEnum, SportIdEnum, MarketStatusEnum, SPORT_MAP_KEY, UiType } from '@/common/enums';
import { SportTypeItem } from '@/store/types';
import { OddsetChamp, OddsetData, SelType, SelChamp } from '@/services/kg-api';
import { eventTimeFormatForIM } from '@/common/utils';
import dayjs from 'dayjs';
import { moreInfoItem } from '@/services/365-api';

const oddTemp: any = {};

const convertSportsSort = (list: EventSummary[]) => {
  const temp: {
    live: SportTypeItem[];
    today: SportTypeItem[];
    early: SportTypeItem[];
    championLeague: SportTypeItem[];
  } = {
    live: [],
    today: [],
    early: [],
    championLeague: []
  };
  const keys = Object.keys(temp);
  list.forEach(item => {
    keys.forEach(k => {
      item[k] > 0 &&
        temp[k].push({
          sportId: item.sportType,
          sportName: item.sportTypeName,
          count: item[k],
          live: item.live
        });
    });
  });
  return new Map([
    [DataTypeEnum.Live, temp.live],
    [DataTypeEnum.Today, temp.today],
    [DataTypeEnum.Early, temp.early],
    [DataTypeEnum.championLeague, temp.championLeague]
  ]);
};

/**
 * 格式化体育盘口列表数据
 */
/*const convertSportsHandicap = (
  list: any[],
  {
    periodId,
    sportId,
    showOdds,
    oldOdds
  }: { periodId: DataTypeEnum; sportId: SportIdEnum; showOdds: number[]; oldOdds: any }
) => {
  // 是否滚球
  const isInProgress = periodId === DataTypeEnum.Live;
  // 暂时先注解掉
  // const filterList = filter(list, l => !isEmpty(filter(l.matches, m => m.ParentID === 0)));
  const formatedList = map(list, league => {
    return {
      leagueId: league.LeagueId,
      key: `${league.LeagueId}_${league.matches[0].MatchId}`,
      time: dayjs(league.matches[0].ShowTime).valueOf(),
      leagueName: league.LeagueName,
      matches: map(league.matches, match => ({
        info: _formatMatchInfo({ match, league, isInProgress, sportId }),
        odds: _formatMatchOdds({ odds: match.oddset, showOdds, oldOdds })
      }))
    };
  });
  setTimeout(() => {
    oddTemp = {};
  });
  return { list: formatedList, odd: oddTemp };
};*/

/**
 *格式化赛事基本信息
 *
 * @param {*} { match, isInProgress, sportId }
 * @return {*}
 */
function _formatMatchInfo({ match, league, isInProgress, sportId }: any): any {
  const { score = {}, period } = isInProgress ? _formatScoreAndTimeText({ match, sportId }) : { period: '' };
  const filterTime = (time: string): string => {
    if (time && !time.includes(':')) return '';
    return time;
  };
  const isNeutral = (sportId: number): boolean => {
    return sportId === 1;
  };
  return {
    matchTime: match.ShowTime,
    sportName: league.SportName,
    time: isInProgress ? filterTime(match.RunTime) : dayjs(match.ShowTime).format('HH:mm'),
    home: match.HomeName,
    homeLogo: match.HomeLogo,
    homeId: match.HomeID,
    away: match.AwayName,
    awayLogo: match.AwayLogo,
    awayId: match.AwayID,
    leagueName: league.LeagueName,
    market: match.Market,
    id: match.MatchId,
    isLive: isInProgress,
    count: match.MarketCount,
    isNeutral: match.IsNeutral && isNeutral(sportId),
    gameStatus: match.GameStatus,
    date: dayjs(match.ShowTime).format('MM/DD'),
    period,
    sportId,
    ...score
  };
}
/**
 *格式化赛事基本信息
 *
 * @param {*} { match, isInProgress, sportId }
 * @return {*}
 */
function _formatMatchInfo2({ match, league, isInProgress, sportId }: any): any {
  const { score = {} } = isInProgress ? _formatScoreAndTimeText({ match, sportId }) : {};
  const filterTime = (time: string): string => {
    if (time && !time.includes(':')) {
      function formatMins(time) {
        if (time < 10) {
          const newTime = '0' + time;
          return newTime;
        } else {
          const newTime = time.toString();
          return newTime;
        }
      }
      const hour = formatMins(Math.floor(parseInt(time) / 60));
      const minutes = formatMins(parseInt(time) % 60);
      const newTime = hour + ':' + minutes;
      return newTime;
    }
    return time;
  };
  const isNeutral = (sportId: number): boolean => {
    return sportId === 1;
  };
  return {
    sportName: league.sportName,
    matchTime: match.ShowTime,
    // time: match.gameTime ? filterTime(match.gameTime) : dayjs(match.startTime).format('HH:mm'),
    time: match.gameTime ? filterTime(match.gameTime) : match.startTime,
    home: match.home,
    homeScore: match.homeScore,
    homeYellow: match.homeYellowCards,
    homeRed: match.homeRedCards,
    // homeId: match.HomeID,
    away: match.away,
    awayScore: match.awayScore,
    awayYellow: match.awayYellowCards,
    awayRed: match.awayRedCards,
    // awayId: match.AwayID,
    leagueName: league.leagueName,
    // market: match.Market,
    id: match.matchId,
    // isLive: isInProgress,
    isLive: match.isInProgress == 1 ? true : false,
    count: match.MarketCount,
    isNeutral: match.IsNeutral && isNeutral(sportId),
    gameStatus: match.GameStatus,
    date: dayjs(match.startDate).format('MM/DD'),
    period: match.status,
    sportId,
    ...score
  };
}

/**
 *格式化比分信息和时间文本
 *
 * @param {*} { match, isInProgress, sportId }
 */
function _formatScoreAndTimeText({ match, sportId }: any) {
  return {
    score: _formatScore({ match, sportId }),
    period: eventTimeFormatForIM({ ...match, ...match.MoreInfo, SportType: sportId })
  };
}
/**
 *格式化比分
 *
 * @param {*} {match, sportId}
 */
function _formatScore({ match, sportId }) {
  // 主队分数 客队分数 目前进行到第几节 赛事总节数 目前赛事时间
  const { scoreH, GetGamesA, scoreA, GetGamesH } = match.MoreInfo;
  // 足球
  if (sportId === SportIdEnum.Soccerball) {
    const { RedCardH, RedCardA, YellowCardH, YellowCardA, CornerH, CornerA, HalfTimeH, HalfTimeA } = match.MoreInfo;
    return {
      sportId: sportId,
      score: [scoreH || 0, scoreA || 0],
      tech: {
        ht: [HalfTimeH || 0, HalfTimeA || 0], // TODO 半场比分
        red: [RedCardH || 0, RedCardA || 0],
        yellow: [YellowCardH || 0, YellowCardA || 0],
        corner: [CornerH || 0, CornerA || 0]
      }
    };
  }
  // 篮球
  if (sportId === SportIdEnum.Basketball) {
    const { a1Q, a2Q, a3Q, a4Q, h1Q, h2Q, h3Q, h4Q, LLP } = match.MoreInfo;
    return {
      sportId: sportId,
      score: [scoreH || 0, scoreA || 0],
      current: Number(LLP) - 1,
      sections:
        h1Q !== undefined
          ? [
              [h1Q || 0, a1Q || 0],
              [h2Q || 0, a2Q || 0],
              [h3Q || 0, a3Q || 0],
              [h4Q || 0, a4Q || 0]
            ]
          : null
    };
  }
  //网球
  if (sportId === SportIdEnum.Tennis) {
    const { h1Set, h2Set, h3Set, h4Set, h5Set, a1Set, a2Set, a3Set, a4Set, a5Set, LLP } = match.MoreInfo;
    return {
      sportId: sportId,
      score: [scoreH || 0, scoreA || 0],
      current: Number(LLP) - 1,
      sections: [
        [h1Set, a1Set],
        [h2Set, a2Set],
        [h3Set, a3Set],
        [h4Set, a4Set],
        [h5Set, a5Set]
      ]
    };
    // const { TennisAwayGameScore, TennisHomeGameScore, TennisHomePointScore, TennisAwayPointScore, TennisCurrentSet } =
    // match.MoreInfo;
    // return {
    //   sportId: sportId,
    //   score: [scoreH || 0, scoreA || 0],
    //   current: TennisCurrentSet - 1,
    //   pointScore: [TennisHomePointScore, TennisAwayPointScore],
    //   sections: map(TennisHomeGameScore, (v, k) => {
    //     return [v, TennisAwayGameScore[k]];
    //   })
    // };
  }
  //排球、 乒乓球
  if (sportId === SportIdEnum.Volleyball || sportId === SportIdEnum.TableTennis) {
    const { a1Set, a2Set, a3Set, a4Set, h1Set, h2Set, h3Set, h4Set, LLP } = match.MoreInfo;
    return {
      sportId: sportId,
      score: [scoreH || 0, scoreA || 0],
      current: Number(LLP) - 1,
      sections: [
        [h1Set, a1Set],
        [h2Set, a2Set],
        [h3Set, a3Set],
        [h4Set, a4Set]
      ]
    };
  }
  // 羽毛球
  if (sportId === SportIdEnum.Badminton) {
    const { a1Set, a2Set, a3Set, a4Set, a5Set, a6Set, a7Set, h1Set, h2Set, h3Set, h4Set, h5Set, h6Set, h7Set, LLP } =
      match.MoreInfo;
    return {
      sportId: sportId,
      score: [scoreH || 0, scoreA || 0],
      current: Number(LLP) - 1,
      sections: [
        [h1Set, a1Set],
        [h2Set, a2Set],
        [h3Set, a3Set],
        [h4Set, a4Set],
        [h5Set, a5Set],
        [h6Set, a6Set],
        [h7Set, a7Set]
      ]
    };
  }

  // 其他
  return {
    sportId: sportId,
    score: [typeof scoreH === 'number' ? scoreH : GetGamesH, typeof scoreA === 'number' ? scoreA : GetGamesA]
  };
}
/**
 *格式化赛事盘口信息
 *
 * @param {{ odds: any[]; showOdds: number[] }} { odds, sportId }
 * @return {*}
 */
function _formatMatchOdds({
  odds,
  uiType,
  showOdds,
  oldOdds
}: {
  odds: any[];
  uiType: string;
  showOdds: number[];
  oldOdds: any;
}) {
  // const showOdd = FAST_BET_TYPES.get(sportId) || DEFAULT_BET_TYPES;
  return map(showOdds, id => {
    const oldOdd = sortBy(
      filter(odds, o => id === o.Bettype),
      s => -s.Sort
    )[0] || {
      Bettype: id
    };
    const odd = { ...oldOdd, sels: _fixListOddSel(oldOdd.sels, uiType) };
    return {
      betType: odd.Bettype,
      sels: map(odd.sels, sel => {
        const change =
          sel.Price > oldOdds[`${odd.OddsId}_${sel.Key}`]
            ? 'rise'
            : sel.Price < oldOdds[`${odd.OddsId}_${sel.Key}`]
            ? 'reduce'
            : 'smooth';
        sel.Price && (oddTemp[`${odd.OddsId}_${sel.Key}`] = sel.Price);
        return sel.filled
          ? sel
          : {
              betType: odd.Bettype,
              betTypeName: odd.BettypeName,
              category: odd.Category,
              combo: odd.Combo,
              marketStatus: odd.MarketStatus,
              maxBet: odd.MaxBet,
              oddsId: odd.OddsId,
              oddsType: odd.oddsType,
              key: sel.Key,
              selKey: `${odd.OddsId}_${sel.Key}`,
              keyName: sel.KeyName,
              name: _formatSelName(odd.uiType, sel),
              price: sel.Price,
              point: sel.Point,
              locked: odd.MarketStatus !== MarketStatusEnum['running'] || sel.Price <= 0,
              change
            };
      })
    };
  });
}

/**
 *补充列表盘口
 *
 * @param {any[]} sels
 * @param {number} betType
 */
function _fixListOddSel(sels: any[] | undefined, uiType: string) {
  // 独赢处理
  if (uiType === UiType.UiType3) {
    return sels
      ? sels.length === 3
        ? [sels[0], sels[2], sels[1]]
        : fill(Array(3), { locked: true, filled: true })
      : fill(Array(3), { filled: true });
  }
  return sels
    ? sels.length > 0
      ? sels
      : fill(Array(2), { locked: true, filled: true })
    : fill(Array(2), { filled: true });
}

function _formatSelName(uiType: string, { KeyName, Point }: { Key: string; KeyName: string; Point: number }) {
  if (_isHandicap(uiType)) {
    return !!Point ? Point : KeyName;
  } else if (
    /*[
      // 单双类型
      5,
      // 普通显示3列类型
      22, 707, 713,
      // 波胆类型
      6,
      // 反波胆类型
      158
    ].includes(betType)*/
    _isNeedThreeCol(uiType)
  ) {
    return KeyName;
    // } else if (
    //   [
    //     // 显示主客类型
    //     1
    //   ].includes(betType)
    // ) {
    //   return {
    //     h: '主',
    //     a: '客'
    //   }[Key];
  } else if (_isBigBall(uiType)) {
    return KeyName || (Point ? Point : '');
  } else if (_isCapot(uiType)) {
    // return {
    //   '1': '主',
    //   x: '和',
    //   '2': '客'
    // }[Key];
    return KeyName;
    // } else if (
    //   [
    //     // 双重机会类型
    //     24
    //   ].includes(betType)
    // ) {
    //   return {
    //     '1x': '主 或 和',
    //     '12': '主 或 客',
    //     '2x': '客 或 和'
    //   }[Key];
    // } else if (
    //   [
    //     // 显示大小
    //     702, 705, 709, 9003, 9009, 9013, 9019, 9025, 9029, 9035, 9047, 9053, 9058, 9060, 9070
    //   ].includes(betType)
    // ) {
    //   return {
    //     o: '大',
    //     u: '小'
    //   }[Key];
  } else {
    return KeyName || Point;
  }
}

// @ts-ignore
/**
 * 赛事详情-列表数据
 */
const convertSportsDetailList = (
  list: any[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  { sportId }: { sportId: SportIdEnum },
  //{ periodId }: { periodId: DataTypeEnum },
  isFill = false
): any => {
  if (isEmpty(list) || !isArray(list) || isEmpty(list[0].matches) || !isArray(list[0].matches)) return {};
  const newList = _mergeSameData(list[0].matches[0].oddset);
  let groups: any[] = [];
  let formatedList = map(newList, item => {
    groups = [...groups, ...(item[0].group || [])];
    return {
      betType: item[0].Bettype,
      betTypeName: item[0].BettypeName,
      category: item[0].Category,
      group: item[0].group,
      showTeamName: _isShowTeamName(item[0].uiType),
      uiType: item[0].uiType,
      allowBetBuilder: item[0].allowBetBuilder,
      list: sortMarket(
        sportId,
        map(item, item2 => {
          return {
            oddsId: item2.OddsId,
            periodId: item2.PeriodId,
            combo: item2.Combo,
            maxBet: item2.MaxBet,
            MarketStatus: item2.MarketStatus,
            BettypeName: item2.BettypeName,
            sels: _setSels(item2.sels, item2.uiType, item2.MarketStatus, isFill, item[0].allowBetBuilder),
            isOneLine: _showOneName(item2.uiType),
            uiType: item2.uiType,
            allowBetBuilder: item[0].allowBetBuilder
          };
        }),
        false
      )
    };
  });
  //过滤掉空数据sels
  formatedList = formatedList.filter(x1 => !isEmpty(x1.list.filter(x2 => !isEmpty(x2.sels))));
  const match = list[0].matches[0];
  // 是否滚球
  const isInProgress = match.IsLive === 0 ? false : true;
  const result = {
    leagueId: list[0].LeagueId,
    leagueName: list[0].LeagueName,
    info: _formatMatchInfo({ match, league: list[0], isInProgress, sportId }),
    groups: uniq(groups),
    odds: formatedList
  };
  return result;
};
function _showOneName(uiType: string) {
  // 让球、大小球、独赢、胜负、单双5、波胆、反波胆
  if (!_isHandicap(uiType) && !_isCapot(uiType) && !_isBigBall(uiType) && !_isNeedThreeCol(uiType)) {
    return false;
  }
  return true;
  //return !['让球', '大/小', '独赢', '胜负', '单双', '波胆'].includes(Bettype);
}
function _setSels(
  sels: any[],
  uiType: string,
  MarketStatus: MarketStatusEnum,
  isFill = false,
  allowBetBuilder: number
) {
  // 波胆
  if (uiType === UiType.UiType4) {
    return _formatScoreBet(sels, MarketStatus, isFill);
  }
  const arr = map(sels, item => {
    return {
      name: _formatSelName(uiType, item),
      price: item.Price,
      allowBetBuilder: allowBetBuilder,
      locked: MarketStatus !== MarketStatusEnum['running'] || item.Price <= 0,
      ...item
    };
  });
  return arr;
}

// 处理波胆投注显示
function _formatScoreBet(sels: any[], MarketStatus: MarketStatusEnum, isFill = false) {
  const other = sels[sels.length - 1].KeyName.indexOf('-') === -1 ? sels.pop() : {};
  let [home, tie, away]: [any[], any[], any[]] = [[], [], []];
  sels.forEach(sel => {
    const keyNameSplit = sel.KeyName.split(' ');
    const [homeScore, awayScore] = keyNameSplit[keyNameSplit.length - 1].split('-');
    if (Number(homeScore) > Number(awayScore)) {
      home.push({
        name: sel.KeyName,
        price: sel.Price,
        locked: MarketStatus !== MarketStatusEnum['running'] || sel.Price <= 0,
        ...sel
      });
    } else if (Number(homeScore) < Number(awayScore)) {
      away.push({
        name: sel.KeyName,
        price: sel.Price,
        locked: MarketStatus !== MarketStatusEnum['running'] || sel.Price <= 0,
        ...sel
      });
    } else {
      tie.push({
        name: sel.KeyName,
        price: sel.Price,
        locked: MarketStatus !== MarketStatusEnum['running'] || sel.Price <= 0,
        ...sel
      });
    }
  });
  if (isFill) {
    const maxNum = max([home.length, away.length, tie.length]);
    if (maxNum) {
      const fillObj = {};
      const homeArray = fill(Array(maxNum - home.length), fillObj);
      home = concat(home, homeArray);

      const awayArray = fill(Array(maxNum - away.length), fillObj);
      away = concat(away, awayArray);

      const tieArray = fill(Array(maxNum - tie.length), fillObj);
      tie = concat(tie, tieArray);
    }
  }
  home.sort((m1, m2) => {
    const s1 = m1.KeyName;
    const s2 = m2.KeyName;
    return s1 ? s1.localeCompare(s2) : 1;
  });
  tie.sort((m1, m2) => {
    const s1 = m1.KeyName;
    const s2 = m2.KeyName;
    return s1 ? s1.localeCompare(s2) : 1;
  });
  away.sort((m1, m2) => {
    const s1 = m1.KeyName;
    const s2 = m2.KeyName;
    return s1 ? s1.localeCompare(s2) : 1;
  });
  return [
    home,
    tie,
    away,
    [
      {
        name: other.KeyName,
        price: other.Price,
        locked: MarketStatus !== MarketStatusEnum['running'] || other.Price <= 0,
        ...other
      }
    ]
  ];
}
function _isShowTeamName(uiType: string) {
  if (uiType === UiType.UiType1) return true;
  return false;
}
/**
 * 将相同的Bettype放一个数组里面,并排序
 */
function _mergeSameData(list: any[]) {
  const keyArr: Array<any> = [];
  const newArr: Array<any> = [];
  map(list, item => {
    const key = `${item.BettypeName}_${item.PeriodId}`;
    if (!keyArr.includes(key)) {
      keyArr.push(key);
      newArr.push(
        list
          .filter(x => `${x.BettypeName}_${x.PeriodId}` === key && x.BettypeName === item.BettypeName)
          .sort((a, b) => {
            return a.Sort - b.Sort;
          })
      );
    }
  });
  return newArr;
}
const LOCAL_SP_MAPS = localStorage.getItem(SPORT_MAP_KEY);

/**
 * 获取运动名字
 * @param {id: number}
 */
function getSportNameById(id: number): string {
  if (LOCAL_SP_MAPS) {
    return JSON.parse(LOCAL_SP_MAPS)[id];
  }
  return '';
}

// 首页扩展接口
export interface HomeMarketOdds extends OddsetData {
  /**
   * 锁盘
   */
  locked: boolean;
}

function _isHandicap(uiType: string): boolean {
  if (uiType === UiType.UiType1) {
    return true;
  }
  return false;
}

function _isBigBall(uiType: string): boolean {
  if (uiType === UiType.UiType2) {
    return true;
  }
  return false;
}

function _isCapot(uiType: string): boolean {
  if (uiType === UiType.UiType3 || uiType === UiType.UiType6) {
    return true;
  }
  return false;
}

function _isOddEven(uiType: string): boolean {
  if (uiType === UiType.UiType5) {
    return true;
  }
  return false;
}

function _isNeedThreeCol(uiType: string): boolean {
  if (uiType === UiType.UiType4) {
    return true;
  }
  return false;
}

// function _isHomeKey(keyName: string): boolean {
//   if (keyName.startsWith('主')) {
//     return true;
//   }
//   return false;
// }

function _keyNameByBettype(uiType: string, { KeyName, Point }: SelType, isBet = false, isWeb = false): string {
  const text = KeyName;
  // web显示 - 而不是什么都不显示
  const t = isWeb ? '-' : '';
  if (_isHandicap(uiType)) {
    return !!Point ? Point : text;
  } else if (_isBigBall(uiType)) {
    return text;
  } else if (_isCapot(uiType)) {
    if (isBet) return t;
    return text;
  }
  return text;
}

function _keyNameByBettype2(uiType: string, { KeyName, Point }: SelChamp, isBet = false, isWeb = false): string {
  const text = KeyName;
  // web显示 - 而不是什么都不显示
  const t = isWeb ? '-' : '';
  if (_isHandicap(uiType)) {
    return !!Point ? Point : text;
  } else if (_isBigBall(uiType)) {
    return text + (!!Point ? Point : '');
  } else if (_isCapot(uiType)) {
    if (isBet) return t;
    return text;
  }
  return text;
}

function keyNameByBettype(uiType: string, { Key, Point }: SelType): string {
  const text = Key;
  if (_isHandicap(uiType)) {
    return !!Point ? Point : text;
  } else if (_isBigBall(uiType)) {
    return text + (!!Point ? Point : '');
  } else if (_isCapot(uiType)) {
    return text;
  }
  return text;
}

/**
 * 转换首页盘口
 */
function _formatHomeMarketOdds(sportId: number, oddset: OddsetData[]): any[] {
  const sportList = [1];
  const isThree = sportList.some(item => item === sportId);
  if (isEmpty(oddset))
    return [
      { sels: ['', ''] },
      { sels: ['', ''] },
      { sels: isThree ? ['', '', ''] : ['', ''] },
      { sels: ['', ''] },
      { sels: ['', ''] },
      { sels: isThree ? ['', '', ''] : ['', ''] }
    ];
  const SORT_KEYS =
    sportId === 1 ? [UiType.UiType1, UiType.UiType2, UiType.UiType3] : [UiType.UiType1, UiType.UiType5, UiType.UiType6];
  const tempList: any[] = [];
  SORT_KEYS.map(key => {
    const obj = oddset.find(e => {
      return e.uiType == key;
    });
    const emptyData = key === UiType.UiType3 ? { sels: isThree ? ['', '', ''] : ['', ''] } : { sels: ['', ''] };
    tempList.push(!!obj && obj.sels.length > 0 ? obj : { ...emptyData, ...obj });
  });
  SORT_KEYS.map(key => {
    const obj = oddset.find(e => {
      return e.uiType === key && e.PeriodId === 2;
    });
    const emptyData = key === UiType.UiType3 ? { sels: isThree ? ['', '', ''] : ['', ''] } : { sels: ['', ''] };
    tempList.push(!!obj && obj.sels.length > 0 ? obj : { ...emptyData, ...obj });
  });
  sortMarket(sportId, tempList, true);
  return tempList;
}

function _formatHomeMarketOddsChamp(sportId: number, oddset: OddsetChamp[]): any[] {
  const sportList = [1];
  const isThree = sportList.some(item => item === sportId);
  if (isEmpty(oddset))
    return [
      { sels: ['', ''] },
      { sels: ['', ''] },
      { sels: isThree ? ['', '', ''] : ['', ''] },
      { sels: ['', ''] },
      { sels: ['', ''] },
      { sels: isThree ? ['', '', ''] : ['', ''] }
    ];
  const SORT_KEYS =
    sportId === 1 ? [UiType.UiType1, UiType.UiType2, UiType.UiType3] : [UiType.UiType1, UiType.UiType5, UiType.UiType6];
  const tempList: any[] = [];
  SORT_KEYS.map(key => {
    const obj = oddset.find(e => {
      return e;
    });
    const emptyData = key === UiType.UiType3 ? { sels: isThree ? ['', '', ''] : ['', ''] } : { sels: ['', ''] };
    tempList.push(!!obj && obj.sels.length > 0 ? obj : { ...emptyData, ...obj });
  });
  SORT_KEYS.map(key => {
    const obj = oddset.find(e => {
      return e && e.PeriodId === 5;
    });
    const emptyData = key === UiType.UiType3 ? { sels: isThree ? ['', '', ''] : ['', ''] } : { sels: ['', ''] };
    tempList.push(!!obj && obj.sels.length > 0 ? obj : { ...emptyData, ...obj });
  });
  sortMarket(sportId, tempList, true);
  return tempList;
}

function sortMarket(sportId: number, marketList: any[], isHome: boolean): any[] {
  const sportList = [1];
  const isThree = sportList.some(item => item === sportId);
  marketList.map(item => {
    item.locked = item.MarketStatus !== MarketStatusEnum['running'] || item.Price <= 0;
    if (!item.locked) {
      item.sels.sort((s1, s2) => {
        if (_isCapot(item.uiType)) {
          if (!s1.KeyName || !s2.KeyName) return;
          return s1.KeyName.startsWith('主')
            ? -1
            : s2.KeyName.startsWith('主')
            ? 1
            : s1.KeyName.startsWith('客')
            ? 1
            : s2.KeyName.startsWith('客')
            ? -1
            : s1.KeyName.startsWith('和')
            ? 1
            : s2.KeyName.startsWith('和')
            ? -1
            : 1;
        }
        if (_isBigBall(item.uiType)) {
          return s1.KeyName && (s1.KeyName.startsWith('大') ? -1 : 1);
        }
        if (_isOddEven(item.uiType)) {
          return s1.KeyName && (s1.KeyName.startsWith('单') ? -1 : 1);
        }
        if (_isHandicap(item.uiType)) {
          return s1.KeyName && (s1.KeyName.startsWith('客') ? 1 : -1);
        }
        return s1.KeyName && (s1.KeyName.startsWith('主') ? -1 : 1);
      });
      if ((_isBigBall(item.uiType) || item.BettypeName.indexOf('大') > -1) && item.sels.length < 2) {
        if (item.sels.length == 0) {
          item.sels.push('');
          item.sels.push('');
        } else {
          const k = item.sels[0];
          if (k.KeyName.startsWith('大')) {
            item.sels.push('');
          } else {
            item.sels.splice(0, 0, '');
          }
        }
      } else if (_isHandicap(item.uiType) && item.sels.length < 2) {
        if (item.sels.length == 0) {
          item.sels.push('');
          item.sels.push('');
        } else {
          const k = item.sels[0];
          if (k.KeyName.indexOf('主') > -1) {
            item.sels.push('');
          } else {
            item.sels.splice(0, 0, '');
          }
        }
      } else if (_isOddEven(item.uiType) && item.sels.length < 2) {
        if (item.sels.length == 0) {
          item.sels.push('');
          item.sels.push('');
        } else {
          const k = item.sels[0];
          if (k.KeyName.indexOf('单') > -1) {
            item.sels.push('');
          } else {
            item.sels.splice(0, 0, '');
          }
        }
      } else if (_isCapot(item.uiType) || item.BettypeName.indexOf('独赢') > -1) {
        if (isThree && item.sels.length < 3) {
          if (item.sels.length == 0) {
            item.sels.push('');
            item.sels.push('');
            item.sels.push('');
          } else {
            const k = item.sels[0];
            if (k.KeyName.startsWith('和')) {
              if (item.sels.length == 1) {
                item.sels.splice(0, 0, '');
                item.sels.push('');
              } else {
                item.sels.splice(0, 0, '');
              }
            }
            if (k.KeyName.startsWith('客')) {
              item.sels.splice(0, 0, '');
              item.sels.splice(0, 0, '');
            }
            if (k.KeyName.startsWith('主')) {
              if (item.sels.length == 1) {
                item.sels.push('');
                item.sels.push('');
              } else {
                const ii = item.sels[1];
                if (ii.KeyName.startsWith('和')) {
                  item.sels.push('');
                }
                if (ii.KeyName.startsWith('客') && sportId !== 2 && sportId !== 4) {
                  item.sels.splice(1, 0, '');
                }
              }
            }
          }
        } else if (item.sels.length < 2) {
          if (item.sels.length == 0) {
            item.sels.push('');
            item.sels.push('');
          } else {
            const k = item.sels[0];
            if (k.KeyName.startsWith('客')) {
              item.sels.splice(0, 0, '');
            }
            if (k.KeyName.startsWith('主')) {
              if (item.sels.length == 1) {
                item.sels.push('');
              }
            }
          }
        }
      } else if (isThree && _isCapot(item.uiType) && item.sels.length === 5) {
        item.sels.push('');
      } else if (!isThree && _isCapot(item.uiType) && item.sels.length < 2) {
        if (item.sels.length == 0) {
          item.sels.push('');
          item.sels.push('');
        } else {
          const k = item.sels[0];
          if (k.KeyName.startsWith('和')) {
            item.sels.splice(0, 0, '');
          }
          if (k.KeyName.startsWith('客')) {
            item.sels.splice(0, 0, '');
            item.sels.splice(0, 0, '');
          }
          if (k.KeyName.startsWith('主')) {
            if (item.sels.length == 1) {
              item.sels.push('');
              item.sels.push('');
            } else {
              const ii = item.sels[1];
              if (ii.KeyName.startsWith('和')) {
                item.sels.push('');
              }
              if (ii.KeyName.startsWith('客')) {
                item.sels.splice(1, 0, '');
              }
            }
          }
        }
      } else if (item.sels.length % 2 != 0) {
        if (item.sels.length == 0) {
          item.sels.push('');
          item.sels.push('');
        } else {
          item.sels.push('');
        }
      }
    }
  });
  if (!isHome) {
    marketList.sort((m1, m2) => {
      const s1 = m1.sels[0].KeyName;
      const s2 = m2.sels[0].KeyName;
      return s1 ? s1.localeCompare(s2) : 1;
    });
  }
  return marketList;
}

/**
 * 转换首页盘口
 */
function _formatRecMarketOdds(oddset: OddsetData[]): any[] {
  if (isEmpty(oddset)) return [{ sels: ['', ''] }];
  const oddslist = oddset;
  oddslist.map((item: any) => {
    item.locked = item.MarketStatus !== MarketStatusEnum['running'] || item.Price <= 0;
    if (!item.locked) {
      item.sels.sort((s1, s2) => {
        if (_isCapot(item.uiType)) {
          return s1.keyName == '主'
            ? -1
            : s2.KeyName == '主'
            ? 1
            : s1.KeyName == '客'
            ? 1
            : s2.KeyName == '客'
            ? -1
            : s1.KeyName == '和'
            ? 1
            : s2.KeyName == '和'
            ? -1
            : 1;
        }
        if (_isBigBall(item.uiType)) {
          return s1.KeyName && (s1.KeyName.indexOf('大') > -1 ? -1 : 1);
        }
        if (_isHandicap(item.uiType)) {
          return s1.KeyName && (s1.KeyName.indexOf('客') > -1 ? 1 : -1);
        }
      });
      if (_isBigBall(item.uiType) && item.sels.length < 2) {
        if (item.sels.length == 0) {
          item.sels.push('');
          item.sels.push('');
        } else {
          const k = item.sels[0];
          if (k.KeyName.indexOf('大') > -1) {
            item.sels.push('');
          } else {
            item.sels.splice(0, 0, '');
          }
        }
      }
      if (_isHandicap(item.uiType) && item.sels.length < 2) {
        if (item.sels.length == 0) {
          item.sels.push('');
          item.sels.push('');
        } else {
          const k = item.sels[0];
          if (k.KeyName.indexOf('主') > -1) {
            item.sels.splice(0, 0, '');
          } else {
            item.sels.push('');
          }
        }
      }
      if (_isCapot(item.uiType) && item.sels.length < 3) {
        if (item.sels.length == 0) {
          item.sels.push('');
          item.sels.push('');
          item.sels.push('');
        } else {
          const k = item.sels[0];
          if (k.KeyName == '和') {
            item.sels.splice(0, 0, '');
          }
          if (k.KeyName == '客') {
            item.sels.splice(0, 0, '');
            item.sels.splice(0, 0, '');
          }
          if (k.KeyName == '主') {
            if (item.sels.length == 1) {
              item.sels.push('');
              item.sels.push('');
            } else {
              const ii = item.sels[1];
              if (ii.KeyName == '和') {
                item.sels.push('');
              }
              if (ii.KeyName == '客') {
                item.sels.splice(1, 0, '');
              }
            }
          }
        }
      }
    }
  });
  return oddslist;
}

export function formatSportsTech(str = '') {
  const matchTempStrs = split(str, '&');
  return new Map(
    matchTempStrs.map(mStr => {
      const [matchId, otherStr] = split(mStr, '^');
      const [, , ...techStrs] = split(otherStr, ';');
      techStrs.pop();
      const techMap = new Map(
        techStrs.map(tStr => {
          const [techType, ...techValue] = split(tStr, ',').map(s => (s === 'null' ? null : Number(s)));
          return [techType, techValue];
        })
      );
      return [matchId, techMap];
    })
  );
}

/**
 * 首页 目标 展开数据处理
 */
function formatMoreInfo(moreInfo: moreInfoItem) {
  const moreData = [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0]
  ];
  moreData[0] = [
    isNumber(moreInfo.goalKicksH) ? moreInfo.goalKicksH : 0,
    isNumber(moreInfo.goalKicksA) ? moreInfo.goalKicksA : 0
  ];
  moreData[1] = [
    isNumber(moreInfo.dangerousAttacksH) ? moreInfo.dangerousAttacksH : 0,
    isNumber(moreInfo.dangerousAttacksA) ? moreInfo.dangerousAttacksA : 0
  ];
  moreData[2] = [
    isNumber(moreInfo.assistsH) ? moreInfo.assistsH : 0,
    isNumber(moreInfo.assistsA) ? moreInfo.assistsA : 0
  ];
  moreData[3] = [
    isNumber(moreInfo.possessionPercentageH) ? moreInfo.possessionPercentageH : 0,
    isNumber(moreInfo.possessionPercentageA) ? moreInfo.possessionPercentageA : 0
  ];
  moreData[4] = [
    isNumber(moreInfo.shotsOnTargetH) ? moreInfo.shotsOnTargetH : 0,
    isNumber(moreInfo.shotsOnTargetA) ? moreInfo.shotsOnTargetA : 0
  ];
  moreData[5] = [
    isNumber(moreInfo.shotsOffTargetH) ? moreInfo.shotsOffTargetH : 0,
    isNumber(moreInfo.shotsOffTargetA) ? moreInfo.shotsOffTargetA : 0
  ];
  moreData[6] = [
    isNumber(moreInfo.cornersH) ? moreInfo.cornersH : 0,
    isNumber(moreInfo.cornersA) ? moreInfo.cornersA : 0
  ];
  moreData[7] = [
    isNumber(moreInfo.yellowCardH) ? moreInfo.yellowCardH : 0,
    isNumber(moreInfo.yellowCardA) ? moreInfo.yellowCardA : 0
  ];
  moreData[8] = [
    isNumber(moreInfo.redCardH) ? moreInfo.redCardH : 0,
    isNumber(moreInfo.redCardA) ? moreInfo.redCardA : 0
  ];
  return moreData;
}

export {
  formatMoreInfo,
  _formatRecMarketOdds,
  convertSportsSort,
  _formatHomeMarketOddsChamp,
  //convertSportsHandicap,
  convertSportsDetailList,
  _formatMatchInfo,
  _formatMatchInfo2,
  getSportNameById,
  _keyNameByBettype,
  _keyNameByBettype2,
  _formatHomeMarketOdds,
  keyNameByBettype,
  sortMarket
};
