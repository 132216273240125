import qs from 'qs';
import { HttpResponse } from '#/global';
import { http } from '@/common';
import { SourceType } from '@/types/config';

const TypesMap = {
  im: 4,
  sb: 30
};

/**
 * @description: 足球文字直播
 * @param {object} param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const liveTextApi = ({ source, matchId }: { source: SourceType; matchId: string | number }): Promise<HttpResponse> => {
  return http.get('/match/liveText?' + qs.stringify({ source: TypesMap[source], matchId }));
};
// const liveTextApi = (): Promise<HttpResponse> => {
//   return http.get('match/liveText?matchId=3588667');
// };

/**
 * @description: 足球比赛阵容
 * @param {object} param1 param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const lineupApi = ({ source, matchId }: { source: SourceType; matchId: string | number }): Promise<HttpResponse> => {
  return http.get('/match/player/lineup?' + qs.stringify({ source: TypesMap[source], matchId }));
};

/**
 * @description:  足球赛事分析
 * @param {object} param1 param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const analysisApi = ({ source, matchId }: { source: SourceType; matchId: string | number }): Promise<HttpResponse> => {
  return http.get('/match/analysis?' + qs.stringify({ source: TypesMap[source], matchId }));
};

/**
 * @description:  足球比赛情报
 * @param {object} param1 param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const informationApi = ({
  source,
  matchId
}: {
  source: SourceType;
  matchId: string | number;
}): Promise<HttpResponse> => {
  return http.get('/match/information?' + qs.stringify({ source: TypesMap[source], matchId }));
};

/**
 * @description: 篮球文字直播
 * @param {object} param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const bLiveTextApi = ({ source, matchId }: { source: SourceType; matchId: string | number }): Promise<HttpResponse> => {
  return http.get('/sports/lq/player/TextLive?' + qs.stringify({ source: TypesMap[source], matchId }));
};

/**
 * @description: 篮球比赛阵容
 * @param {object} param1 param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const bLineupApi = ({ source, matchId }: { source: SourceType; matchId: string | number }): Promise<HttpResponse> => {
  return http.get('/sports/lq/player/lineup?' + qs.stringify({ source: TypesMap[source], matchId }));
};

/**
 * @description:  篮球赛事分析
 * @param {object} param1 param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const bAnalysisApi = ({ source, matchId }: { source: SourceType; matchId: string | number }): Promise<HttpResponse> => {
  return http.get('/sports/lq/player/analysis?' + qs.stringify({ source: TypesMap[source], matchId }));
};

/**
 * @description:  篮球比赛情报
 * @param {object} param1 param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const bInformationApi = ({
  source,
  matchId
}: {
  source: SourceType;
  matchId: string | number;
}): Promise<HttpResponse> => {
  return http.get('/sports/lq/player/information?' + qs.stringify({ source: TypesMap[source], matchId }));
};

/**
 * @description:  篮球历史分析
 * @param {object} param1 param matchId: 赛事ID  source:3:id188 29:bti 30:shaba
 * @return {Promise<HttpResponse>}
 */
const bIntelligenceApi = ({
  source,
  matchId
}: {
  source: SourceType;
  matchId: string | number;
}): Promise<HttpResponse> => {
  return http.get('/sports/lq/player/intelligence?' + qs.stringify({ source: TypesMap[source], matchId }));
};

export {
  liveTextApi,
  lineupApi,
  analysisApi,
  informationApi,
  bLiveTextApi,
  bLineupApi,
  bAnalysisApi,
  bInformationApi,
  bIntelligenceApi
};
