/**
 * common bootstrap
 */

import { createApp, Component } from 'vue';
import 'ant-design-vue/dist/antd.css';
import { initPlugin } from './plugins';
import { initRouter } from './router';
import { setupStore } from '@/store';
import { setLoginData, SPORT_ACCESS_UUID_KEY, SPORT_WEB_URL_KEY, SPORT_TENANT_KEY } from '@/common';
import { setTheme } from '@/themes';
import { useSportOptions } from '@/hooks';
import { useUrlSearchParams } from '@vueuse/core';
import { QueryLoginInfo } from '@/types/global';
import { languageList, setupI18n } from '@/i18n';

import { useBetWeb } from '@/hooks/useBetWeb.hook';

let instance: any;

export async function render(app: Component) {
  instance = createApp(app);
  // 取消inject使用ref提示
  instance.config.unwrapInjectedRef = true;
  const { optionsStore, changeSetiing } = useSportOptions();

  // 路由url参数
  const { token, lang, currencySymbol, account, jumpToken, device_id, tenant, backUrl } = useUrlSearchParams(
    'history'
  ) as QueryLoginInfo;

  console.log('[LOG]LOGIN INFO:', useUrlSearchParams('history'));

  // 判断是否为跳转 必须参数
  if (token && lang && tenant) {
    localStorage.setItem(SPORT_WEB_URL_KEY, backUrl || '');
    localStorage.setItem(SPORT_ACCESS_UUID_KEY, device_id || 'no-uuid');
    localStorage.setItem(SPORT_TENANT_KEY, tenant || 'intsit');
    // 存登录信息
    setLoginData({ token, account, jumpToken });
    const oldToken = localStorage.getItem('oldToken');
    if (oldToken !== token) {
      // 清除投注信息
      const { clearAllTickets } = useBetWeb();
      clearAllTickets();
    }
  }

  // 设置主题
  setTheme('darkGreen');
  changeSetiing('theme', 'darkGreen' || optionsStore.value.theme);
  changeSetiing('currency', currencySymbol || optionsStore.value.currency);
  const isExist = !!lang && languageList.find(e => e.seriNo === lang);
  if (isExist) {
    changeSetiing('lang', lang);
  }
  // 设置国际化
  await setupI18n(instance, lang || optionsStore.value.lang);
  const router = initRouter();
  await instance.use(router);

  // 3初始化vuex
  setupStore(instance);

  // 1. 注册全局组件
  initPlugin(instance);

  await router.isReady();
  await instance.mount('#microApp');
}
