import { defineComponent, PropType } from 'vue';
// import { useRoute, useRouter } from 'vue-router';
import ChampSummaryOdds from './odds/index';
// import { SvgIcon } from '@/components';
import { StoreChampData } from '@/hooks';
import { useBetWeb } from '@/hooks/useBetWeb.hook';

import style from './champ-summary.module.less';

// import { RouteEnum } from '@/common';
import { CollapsePanel } from 'kg-web/components';

// const { t } = useI18n();
// // ['让球', '大小', '独赢']
// const types_1 = ['lang.sport_common_handicap', 'lang.sport_common_size', 'lang.sport_common_winAlone'];
// const types_2 = ['lang.sport_common_handicap', 'lang.sport_odd_even', 'lang.sport_common_winAlone'];

export default defineComponent({
  name: 'ChampSummary',
  props: {
    isExpandAll: {
      type: Boolean
    },
    data: {
      type: Object as PropType<StoreChampData>,
      default: () => ({})
    }
  },
  setup(props) {
    const { addTicket } = useBetWeb();

    return () => (
      <CollapsePanel
        v-slots={{
          header: () => {
            return (
              <div class={style.header}>
                <div class={style.titlemain}>
                  {props.data.LeagueName}
                  <span class={style.volor}> </span>
                </div>

                <div class={style.matchCount}>{/* if needed <span> {items.matches.length} </span> */}</div>
              </div>
            );
          }
        }}
      >
        {props.data.matches.map(items => (
          <div class={style.leagueBox}>
            {items.oddset.map(odd => (
              <>
                <div class={style.matcheName}>{odd.BettypeName}</div>
                <ChampSummaryOdds
                  onAddBetTicket={sel =>
                    addTicket(
                      {
                        ...sel,
                        leagueName: props.data.LeagueName,
                        isChamp: true,
                        matchTime: items.ShowTime
                      },
                      true
                    )
                  }
                  matchId={items.MatchId}
                  oddset={odd}
                  gameStatus={items.GameStatus}
                  sportId={props.data.SportType}
                />
              </>
            ))}
          </div>
        ))}
      </CollapsePanel>
    );
  }
});
