import { defineComponent, reactive, onMounted } from 'vue';

import less from './ring-pie-chart.module.less';
export default defineComponent({
  name: 'RingPieChart',
  props: {
    win: {
      default: 0,
      type: Number
    },
    lost: {
      default: 0,
      type: Number
    }
  },
  setup(props) {
    const data = reactive({
      list: [
        // 占比列表
        String(100 - (props.win + props.lost)),
        String(props.lost),
        String(props.win)
      ],
      renderList: [], // 处理后用于渲染环形图的数据
      svgData: {
        // svg 数据 即画布参数
        width: 200,
        height: 200
      },
      arcData: {
        // 环形图参数
        r: 80, // 环形图的半径
        x0: 100, // 圆心x，一般把环形图放在画布中心位置就好
        y0: 100, // 同上
        stockWidth: 30 // 环形图的粗度...
      },
      colorMap: [
        // 环形图颜色映射表
        '#636363',
        '#7BD3B1',
        '#B52520'
      ]
    }) as any;

    const getPath = (
      cur: { relayPer: number; start: { x: number; y: number }; end: { x: number; y: number } },
      arcData: { x0: any; y0: any; r: any }
    ) => {
      // 这里在通过 圆心(x0, y0) r ,拼接好路径数据
      const { x0, y0, r } = arcData;
      let str = 'M';
      const isLargeArc = cur.relayPer > 50 ? 1 : 0;
      const startX = cur.start.x * r + x0;
      const startY = cur.start.y * r + y0;
      const endX = cur.end.x * r + x0;
      const endY = cur.end.y * r + y0;

      str +=
        ' ' +
        startX +
        ' ' +
        startY +
        ' ' +
        'A' +
        ' ' +
        r +
        ' ' +
        r +
        ' ' +
        '0' +
        ' ' +
        isLargeArc +
        ' ' +
        '1' +
        ' ' +
        endX +
        ' ' +
        endY;
      return str;
    };

    const handleChartData = (list: any[]): any => {
      // 这里按照 圆心点为(0,0), r 为 1 来处理
      const newList: any[] = [];
      list.forEach((item: string, index: number) => {
        const obj: any = {};
        let per = +item;
        // 保留真实占比,后面需要判断是否是大小弧
        obj.relayPer = per;
        const PI = Math.PI;
        if (index !== 0) {
          per += newList[index - 1].per;
        }
        // 因为是拼接，所以本次的终点要在之前的基础上，所要要累加占比
        obj.per = per;
        const deg = (per / 100) * PI * 2;
        obj.start = {};
        obj.end = {};
        if (index === 0) {
          obj.start.x = Math.cos(0);
          obj.start.y = Math.sin(0);
        } else {
          obj.start = newList[index - 1].end;
        }
        obj.end.x = Math.cos(deg);
        obj.end.y = Math.sin(deg);
        newList.push(obj);
      });
      return newList;
    };

    onMounted(() => {
      data.renderList = handleChartData(data.list);
    });
    return () => (
      <div>
        <svg
          width={data.svgData.width}
          height={data.svgData.height}
          viewBox={`0 0 ${data.svgData.width} ${data.svgData.height}`}
          class={less.ringPieChartSvg}
        >
          {...data.renderList.map((item, index) =>
            item.relayPer < 100 ? (
              <path
                key={index}
                d={getPath(item, data.arcData)}
                stroke={data.colorMap[index]}
                stroke-width={data.arcData.stockWidth}
                fill="none"
              ></path>
            ) : (
              <circle
                key={index}
                stroke={data.colorMap[index]}
                fill="transparent"
                class={less.ringPieChartCircle}
                stroke-width={data.arcData.stockWidth}
                r={100 - data.arcData.stockWidth / 2}
                cx={0}
                cy={0}
              />
            )
          )}
        </svg>
      </div>
    );
  }
});
