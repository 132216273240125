import { defineComponent, provide, watch, ref, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { SvgIcon } from '@/components';
import RangeData from '../range-data/index.vue';
import DataTotal from '../data-total/index';
import TextLive from '../text-live/index';
import LineUp from '../live-up/index';
import Intelligence from '../intelligence/index.vue';
import dataStyle from './data-view.module.less';
import { useSheetIndex, useGameInfo, useEventsData, useTextLive } from '@/hooks/useDetailData';

const TAB_ICONS = [
  'detail_tab_ranking',
  'detail_tab_live',
  'detail_tab_lineup',
  'event_animation',
  'detail_tab_intelligence'
];

export default defineComponent({
  name: 'DetailData',
  setup() {
    const route = useRoute();
    const { state, changeSheetIndex } = useSheetIndex();
    const { moreMatchInfo, matchInfo, matchId, sportType, getGameInfo } = useGameInfo();
    // 获取足球事件
    const soccerBallEventsData = ref(null) as any;
    const GetRealTimelist = ref(null) as any;
    const eventsData = useEventsData({
      matchId: matchId
    });
    soccerBallEventsData.value = eventsData.soccerBallEventsData;
    GetRealTimelist.value = eventsData.GetRealTimelist;
    const stop = ref(null) as any;
    const textLive = useTextLive(eventsData.GetRealTimelist);
    stop.value = textLive.stop;
    provide('matchInfo', { ...moreMatchInfo.value, matchInfo, sportType, moreMatchInfo });
    provide('soccerBallEventsData', soccerBallEventsData);

    watch(
      // 切换赛事刷新数据
      () => route.query,
      async () => {
        const eventsData = useEventsData(
          {
            matchId: route.query.matchId
          },
          false
        );
        soccerBallEventsData.value = eventsData.soccerBallEventsData;
        GetRealTimelist.value = eventsData.GetRealTimelist;
        stop?.value?.value && stop?.value?.value(); //暂停上次定时器
        if (Number(route?.query?.periodId) === 3) {
          stop.value = useTextLive(eventsData.GetRealTimelist, true).stop;
        }
        Promise.all([getGameInfo(), eventsData.getEventsData()]);
      }
    );
    onUnmounted(() => stop?.value?.value && stop?.value?.value());
    return () => (
      <div class={dataStyle.dataSheet}>
        <div class={dataStyle.sheetTabs}>
          {TAB_ICONS.map((icon, index) => (
            <div
              key={icon}
              onClick={() => changeSheetIndex(index)}
              class={[dataStyle.sheetTabItem, state.sheetIndex === index ? dataStyle.active : null]}
            >
              <SvgIcon name={icon} />
            </div>
          ))}
        </div>
        <div class={dataStyle.tabContainer}>
          {state.sheetIndex === 0 ? (
            <DataTotal realTimelist={soccerBallEventsData.value.value.real} />
          ) : state.sheetIndex === 1 ? (
            <TextLive realTimelist={soccerBallEventsData.value.value.real} />
          ) : state.sheetIndex === 3 ? (
            <RangeData analylist={soccerBallEventsData.value.value.analysis} />
          ) : state.sheetIndex === 2 ? (
            <LineUp lineUpData={soccerBallEventsData.value.value.lineUp} />
          ) : (
            <Intelligence intelllist={soccerBallEventsData.value.value.intelligence} />
          )}
        </div>
      </div>
    );
  }
});
