/**
 * 滚球事件显示
 */
import { defineComponent, PropType } from 'vue';
import style from './live-statistics.module.less';
import { SvgIcon } from '@/components';

import { useSoccerScore } from './live-statistics';
import { StoreMatchInfo, useI18n } from '@/hooks';

import { SportIdEnum } from '@/common';
import { get } from 'lodash-es';

export default defineComponent({
  name: 'LiveEvetsStatistics',
  props: {
    info: {
      type: Object as PropType<StoreMatchInfo>,
      default: () => ({})
    }
  },
  setup(props) {
    const { t } = useI18n();
    // 转换足球
    const { soccerTech } = useSoccerScore(props.info.id);

    const FootballRender = () => (
      <>
        {soccerTech.value.homeCorners && soccerTech.value.awayCorners ? (
          <>
            <SvgIcon name="corner" />
            <span class={style.EventVal}>
              {soccerTech.value.homeCorners}-{soccerTech.value.awayCorners}
            </span>
          </>
        ) : null}
        {soccerTech.value.homeHalfScore && soccerTech.value.awayHalfScore ? (
          <>
            <SvgIcon name="ht" />
            <span class={style.EventVal}>
              {soccerTech.value.homeHalfScore || props.info.tech?.ht[0]}-
              {soccerTech.value.awayHalfScore || props.info.tech?.ht[1]}
            </span>
          </>
        ) : null}
      </>
    );

    const OtherSocerRender = () => {
      return [18, 5].includes(props.info.sportId) ? (
        <div>
          <span>{t('lang.sport_detail_matchItem')}</span>
          {get(props.info.sections, Number(props.info.current), {})[0] || 0}-
          {get(props.info.sections, Number(props.info.current), {})[1] || 0}
        </div>
      ) : null;
    };

    return () => (
      <div class={style.EvetsStatistics}>
        {props.info.sportId === SportIdEnum.Soccerball && props.info.isLive ? <FootballRender /> : <OtherSocerRender />}
      </div>
    );
  }
});
