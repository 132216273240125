/**
 * 加载骨架
 */
import { defineComponent } from 'vue';
import './index.less';

export default defineComponent({
  name: 'LoadSkeleton',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, { slots }) {
    return () =>
      _props.loading ? (
        <div class="bl-Preloader_SpinnerContainer">
          <div class="bl-Preloader_Spinner "></div>
        </div>
      ) : (
        slots.default?.()
      );
  }
});
