import { defineComponent, ref, watch } from 'vue';
import style from './input-number.module.less';
import { debounce } from 'lodash-es';
import { SvgIcon } from '@/components';

export default defineComponent({
  props: {
    amount: {
      type: [String, Number],
      default: ''
    },
    max: {
      type: [String, Number],
      default: 0
    },
    min: {
      type: [String, Number],
      default: 0
    },
    balance: {
      type: [String, Number],
      default: 0
    },
    count: {
      type: [String, Number],
      default: 0
    },
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * 显示小数
     */
    decimal: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: 'CNY'
    },
    marketId: {
      type: String,
      default: '1'
    },
    keyboardId: {
      type: String,
      default: '0'
    }
  },
  emits: ['update:amount', 'change'],
  setup(props, { emit }) {
    const localValue = ref('') as any;
    const keyboardExtras: number[] = [100, 500, 1000, 5000, 10000, 50000];
    const onKeydown = element => {
      if (element.code === 'KeyE') {
        element.preventDefault();
      }
    };
    const showKeyboard = ref(false);
    const onInput = element => {
      const value = element.target.value;
      const reg = /^((\d+)|(\d+((\.{1,1})|(\.{1,1}\d{0,2}))))$/; // 数字正则输入
      if ((reg.test(value) || value === '') && value.length < 10) {
        localValue.value = value;
        emit('update:amount', value);
      } else {
        let tureValue = value;
        for (let i = value.length - 1; i >= 0; i--) {
          tureValue = tureValue.slice(0, i);
          if (reg.test(tureValue) || tureValue === '') {
            localValue.value = tureValue;
            emit('update:amount', localValue.value);
            break;
          }
        }
      }
    };
    // 扩展预设数字
    const handleClickExpend = (inputValue: number | string) => {
      let newValue: any = '';
      const walletBlance = Number(props.balance);
      // 处理数字
      newValue = inputValue + '';
      // 检查值是否大于最大 或 者大于钱包
      const flag = newValue > props.max || newValue > walletBlance;
      if (flag) {
        newValue = +props.max - walletBlance < 0 ? props.max : Math.floor(walletBlance);
      }
      newValue = newValue === '' ? 0 : Number(newValue);
      localValue.value = newValue;
      emit('update:amount', newValue || 0);
    };
    const onChange = element => {
      // 有效值输入
      let value = element.target.value;
      const reg = /(^[1-9]([0-9]){0,}((\.)|(\.[0-9]{1,5}){0,1})$)|(^0$)/; // 数字正则
      // 检查值是否大于最大 或 者大于钱包
      const flag = Number(value) > Number(props.max) || Number(value) > Number(props.balance);
      if (flag) {
        value = Math.floor(+props.max - +props.balance < 0 ? +props.max : Number(props.balance));
      }
      if ((Number(value) > 0 && Number(value) < Number(props.min)) || Number(value) < 0) {
        value = Number(props.min);
      }
      if (reg.test(Number(value) + '') || value === '') {
        if (typeof value === 'string' && value.includes('.')) {
          localValue.value = value;
          emit('update:amount', value);
        } else {
          localValue.value = Number(value);
          emit('update:amount', Number(value));
        }
      } else {
        localValue.value = 0;
        emit('update:amount', 0);
      }
    };
    const clearAmount = () => {
      localValue.value = '';
      emit('update:amount', '');
    };
    const onKeyboardSwitch = debounce(
      (isShow: boolean) => {
        if (props.disabled) return;
        emit('change', props.marketId);
        if (isShow && +props.min > 0 && props.max >= props.min) {
          showKeyboard.value = true;
          return;
        }
        showKeyboard.value = isShow;
      },
      500,
      { leading: true }
    );

    watch(
      () => props.amount,
      () => {
        localValue.value = props.amount;
      },
      {
        immediate: true
      }
    );
    return () => (
      <div class={style.inputContainer}>
        <div class={style.inputBox} onClick={() => onKeyboardSwitch(true)}>
          <div class={style.currency}>{props.currency}</div>
          <input
            type="number"
            style={{ color: props.color ? props.color : 'inherit' }}
            class={[style.input, props.disabled ? style['is-disabled'] : '']}
            v-model={localValue.value}
            onChange={onChange}
            onInput={onInput}
            onKeydown={onKeydown}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
          <div class={style.inputDel} v-show={localValue.value} onClick={() => clearAmount()}>
            <SvgIcon name="365del" />
          </div>
        </div>
        <div class={style.quickAmount} v-show={showKeyboard.value && props.keyboardId === props.marketId}>
          {keyboardExtras.map((num: number, index: number) => (
            <div
              class={[style.btnItemWarp, +num === +props.amount && style.act]}
              onClick={() => handleClickExpend(num)}
              key={index + '_BTN_KEY'}
            >
              <div class={style.btnItem}>{num}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
});
