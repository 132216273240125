const timeMap: any = {};
let id = 0;
const virtualInterval = (cb: Function, time: number) => {
  const timeId = id;
  id++;
  const fn = () => {
    cb();
    timeMap[timeId] = setTimeout(() => {
      fn();
    }, time);
  };
  timeMap[timeId] = setTimeout(fn, time);
  return timeId;
};
const virtualClearInterval = (id: number) => {
  clearTimeout(timeMap[id]);
  delete timeMap[id];
};

export { virtualInterval, virtualClearInterval };
