import { defineComponent, computed, PropType, onMounted } from 'vue';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { message } from 'ant-design-vue';
import { FormatOrderItem } from '@/common';
import { toTime } from '@/common/utils';
import { useI18n } from 'vue-i18n';
import '../style/order-item.less';
import { useBetTypes } from '@/hooks';

export default defineComponent({
  name: 'OrdersItem',
  props: {
    data: {
      type: Object as PropType<FormatOrderItem>,
      default: () => {}
    },
    status: {
      type: Number as PropType<any>
    }
  },
  setup(props) {
    const { betStore, getData } = useBetTypes();
    const parlayData = computed(() => props.data.parlayList || []);
    const { t } = useI18n();

    onMounted(async () => {
      await getData();
    });

    // 复制
    const handleClickCopy = (txt: string) => {
      const { isSuccessRef } = useCopyToClipboard(txt);
      if (isSuccessRef.value) {
        message.success({
          content: t('lang.sport_common_copySuccessfully')
        });
      }
    };

    // 处理国际化
    const mulTypeToName = (mulType: string) => {
      switch (mulType) {
        case 'Doubles':
          return `2${t('lang.sport_bet_connect')}1`;
        case 'Trebles':
          return `3${t('lang.sport_bet_connect')}1`;
        case 'Trixie':
          return `3${t('lang.sport_bet_connect')}4`;
        case 'Lucky7':
          return `幸运7`;
        case 'Patent':
          return `幸运7`;
        case 'Fold4':
          return `4${t('lang.sport_bet_connect')}1`;
        case 'Yankee':
          return `洋基`;
        case 'Lucky15':
          return `幸运15`;
        case 'Fold5':
          return `5${t('lang.sport_bet_connect')}1`;
        case 'Canadian':
          return `超级美国佬`;
        case 'Lucky31':
          return `幸运31`;
        case 'Fold6':
          return `6${t('lang.sport_bet_connect')}1`;
        case 'Heinz':
          return `亨氏`;
        case 'Lucky63':
          return `幸运63`;
        case 'Fold7':
          return `7${t('lang.sport_bet_connect')}1`;
        case 'SuperHeinz':
          return `超级亨氏`;
        case 'Lucky127':
          return `幸运127`;
        case 'Fold8':
          return `8${t('lang.sport_bet_connect')}1`;
        case 'Goliath':
          return `大亨`;
        case 'Lucky255':
          return `幸运255`;
        case 'Fold9':
          return `9${t('lang.sport_bet_connect')}1`;
        case 'Fold10':
          return `10${t('lang.sport_bet_connect')}1`;
        case 'SuperYankee':
        case 'Super Yankee':
          return `5串26`;
        case 'Block9':
          return `9${t('lang.sport_bet_connect')}520`;
        case 'Block10':
          return `10${t('lang.sport_bet_connect')}1013`;
        default:
          return mulType;
      }
    };

    const findOddsName = (oddsType: string) => {
      const match = betStore.betTypes.filter(betItem => betItem.bettype == oddsType);
      const matchedBettypeName = match[0]?.bettypeName;
      return matchedBettypeName ? matchedBettypeName : oddsType;
    };

    const SingleRender = () => (
      <div class={['betting-result-item', props.data.ticketTag]}>
        <div class="tag"></div>
        <div class="bet-title">
          <div class="title-text">
            <span class="font-text">
              {props.data.homeName} vs {props.data.awayName}
            </span>
            <span class="font-text">{t(props.data.ticketTag_i18n)}</span>
          </div>
          <div class="match-info">
            <span>{props.data.leagueName || '--'}</span>
            <span>{props.data.matchTime || '--'}</span>
          </div>
        </div>
        <div class="hr"></div>
        <div class="p-w16 bet-match-info">
          <div class="black-text flex-center f-s-p">
            <div class="information">
              <span>
                <span>
                  {props.data.betSelection} {props.data.handicap}
                </span>
              </span>
              <span class="betting-result-oddprice">@{props.data.odds || '--'}</span>
              {props.data.ticketStatus > 2 &&
              typeof props.data.homeScore === 'number' &&
              typeof props.data.awayScore === 'number' ? (
                <span>
                  {t('lang.sport_tabbar_result')}
                  {`${props.data.homeScore}-${props.data.awayScore}`}
                </span>
              ) : null}
            </div>
          </div>
          <div class="gray-text bet-information">
            {findOddsName(props.data.betType)}
            {props.data.ticketStatus > 2 && (props.data.homeScore || props.data.awayScore) ? (
              <span>({`${props.data.homeScore || '--'}:${props.data.awayScore || '--'}`})</span>
            ) : null}
          </div>
          <div class="black-text bet-match-info-footer">
            <div class="amount">
              {t('lang.sport_bet_betAmount')}
              <span>
                {props.data.stake || '--'}
                <span class="amount-kind">({props.data.currency})</span>
              </span>
            </div>
            <div class="amount">
              {props.data.ticketStatus <= 2 ? t('lang.sport_bet_winAmount') : t('lang.sport_orders_winLost')}
              <span class={props.status === 1 ? props.data.winLostAmountState : null}>
                {props.data.ticketStatus > 2 ? props.data?.winLostAmount : props.data.winLostAmount || '--'}
                <span class="amount-kind">({props.data.currency})</span>
              </span>
            </div>
          </div>
        </div>
        <div class="p-w16">
          <div class="gray-text">
            {toTime(props.data.transactionTime, 'YYYY-MM-DD HH:mm:ss') || '--:--'}({t(props.data.oddsTypeName_i18n)})
          </div>
          <div class="gray-text">
            <span>
              {t('lang.sport_orders_orderNumber')}: {props.data.id || '--'}{' '}
            </span>
            {props.data.id ? (
              <span class="copy-text" onClick={() => handleClickCopy(props.data.id)}>
                {t('lang.sport_orders_copy')}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );

    const ParlayRender = () => (
      <div>
        <div class={['betting-result-item', props.data.ticketTag]}>
          <div class="tag"></div>
          <div class="betting-result-parlayData">
            <div class="bet-title">
              <div class="title-text">
                <span class="font-text">{t('lang.sport_orders_parlayBet')}</span>
                <span class="font-text">{t(props.data.ticketTag_i18n)}</span>
              </div>
              <div class="match-info">
                <span class="match">
                  {props.data.parlayCout || 0}
                  {t('lang.sport_bet_gamsCouter')}:{mulTypeToName(props.data.comboType)}
                </span>
                <span></span>
              </div>
            </div>
          </div>
          <div class="hr"></div>
          <div class="parlay">
            {parlayData.value.map(item => (
              <div class="parlay-item background" key={item.parlayId}>
                <div class="black-text flex-center">
                  <div class="title-parlay-data-text">
                    {item.homeName && item.awayName ? (
                      <span class="font-text parlay-data-text">
                        {item.homeName} vs {item.awayName}
                      </span>
                    ) : null}
                    {props.data.ticketStatus > 2 &&
                    typeof item.homeScore === 'number' &&
                    typeof item.awayScore === 'number' ? (
                      <span>
                        {t('lang.sport_tabbar_result')}
                        {`${item.homeScore}-${item.awayScore}`}
                      </span>
                    ) : null}
                  </div>
                </div>
                <span class="gray-text">
                  {item.betSelection} {item.handicap}&nbsp;@{item.odds || '--'}
                </span>
                <div class="gray-text">
                  <span>{findOddsName(item.betType)}</span>
                </div>
                <div class="gray-text">{item.leagueName}</div>
                <div class="gray-text">{item.matchTime}</div>
              </div>
            ))}
          </div>
          <div class="p-w16">
            <div class="parlay-item-footer background">
              <div class="amount">
                {t('lang.sport_bet_betAmount')}
                <span>
                  {props.data.stake || '--'}
                  <span class="amount-kind">({props.data.currency})</span>
                </span>
              </div>
              <div class="amount">
                {props.data.ticketStatus <= 2 ? t('lang.sport_bet_winAmount') : t('lang.sport_orders_winLost')}
                <span class={props.status === 1 ? props.data.winLostAmountState : null}>
                  {props.data.ticketStatus > 2 ? props.data?.winLostAmount : props.data.winLostAmount || '--'}
                  <span class="amount-kind">({props.data.currency})</span>
                </span>
              </div>
            </div>
          </div>
          <div class="p-w16">
            <div class="gray-text">
              {toTime(props.data.transactionTime, 'YYYY-MM-DD HH:mm:ss') || '--:--'}({t(props.data.oddsTypeName_i18n)})
            </div>
            <div class="gray-text">
              <span>
                {t('lang.sport_orders_orderNumber')}: {props.data.orderId || '--'}{' '}
              </span>
              {!!props.data.orderId ? (
                <span class="copy-text" onClick={() => handleClickCopy(props.data.orderId)}>
                  {t('lang.sport_orders_copy')}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );

    return () => <div>{parlayData.value.length > 0 && parlayData.value ? <ParlayRender /> : <SingleRender />}</div>;
  }
});
