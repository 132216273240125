import { defineComponent, ref } from 'vue';
import { Settings, MatchResult } from './components';
import { Tabs, TabPane } from 'ant-design-vue';
import { RouteEnum, queryToStr } from '@/common';
import style from './options.module.less';
import { useI18n } from '@/hooks';
import { useRoute, useRouter } from 'vue-router';

type TabName = 'setting' | 'notice' | 'result';

export default defineComponent({
  name: 'JBBmatchInfoPop',
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const activeName = ref<TabName>((route.query.tab as any) || 'setting');
    const pathTo = (activeKey: string) => {
      const queryObj = {
        ...route.query,
        tab: activeKey
      };
      const queryStr = queryToStr(queryObj);
      router.replace(RouteEnum.OPTIONS + queryStr);
    };

    return () => (
      <div class={style.optionsPage}>
        <Tabs v-model:activeKey={activeName.value} onChange={(activeKey: string) => pathTo(activeKey)}>
          <TabPane tab={t('lang.sport_common_setting')} key="setting">
            <Settings />
          </TabPane>
          {/* <TabPane tab={t('lang.sport_common_matchNotice')} key="notice">
            {activeName.value === 'notice' ? <PublicNotce></PublicNotce> : null}
          </TabPane> */}
          <TabPane tab={t('lang.sport_common_result')} key="result">
            {activeName.value === 'result' ? <MatchResult></MatchResult> : null}
          </TabPane>
        </Tabs>
      </div>
    );
  }
});
