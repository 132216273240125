
import { reactive, ref } from 'vue';
import { captchaCode, jumpGameApi, signInApi, UserLoginForm } from '@/services';
import { Captcha, CaptchaType } from '@/components';
import { jsencrypt, setLoginData, isH5 } from '@/common';
import { useBet } from '@/hooks';
import { useBetWeb } from '@/hooks/useBetWeb.hook';
import { useStore } from 'vuex';

export default {
  setup() {
    const isLogining = ref(false);
    const store = useStore();

    const form = reactive<UserLoginForm>({
      username: '',
      password: '',
      geetest_seccode: '',
      geetest_validate: '',
      geetest_challenge: ''
    });

    // 打开验证器
    const openCaptcha = async () => {
      if (isLogining.value) return;
      isLogining.value = true;

      const prams = { name: CaptchaType.GEETEST, clientType: 'web' };
      const { data, result } = await captchaCode(prams);
      if (result) {
        const captcha = new Captcha({
          type: CaptchaType.GEETEST,
          config: {
            gt: data.gt,
            challenge: data.challenge
          },
          onSuccess: async (prams: any) => {
            form.geetest_challenge = prams.geetest_challenge;
            form.geetest_seccode = prams.geetest_seccode;
            form.geetest_validate = prams.geetest_validate;
            await onSubmit();
          },
          onClose: () => (isLogining.value = false),
          onFail: () => (isLogining.value = false)
        });
        captcha.open();
      }
    };

    const onSubmit = async () => {
      if (isLogining.value) return;
      isLogining.value = true;
      const { data, result, code, message } = await signInApi({
        ...form,
        password: await jsencrypt(form.password)
      });
      isLogining.value = false;
      if (code === 20003) {
        openCaptcha();
        return;
      }
      if (result) {
        const { accessToken, useInfo } = data;
        if (!isH5) {
          const { clearAllTickets } = useBetWeb();
          clearAllTickets();
        } else {
          const { clearAllTickets } = useBet();
          clearAllTickets();
        }
        setLoginData({ account: useInfo.account, token: accessToken });
        await store.dispatch('OAUTH_SUCCESS');
        const {
          data: jumpData,
          result: res,
          message
        } = await jumpGameApi({
          gameType: 'SPORT',
          platformCode: 'KGTY',
          isMobile: false,
          baseUrl: ' https://kg266.com'
        });
        if (res) {
          const jumpToken = jumpData.split('jumpToken=')[1].split('&')[0];
          setLoginData({ account: useInfo.account, token: accessToken, jumpToken });
        } else {
          alert(message);
          return;
        }
        await (window.location.href = '/');
      } else {
        alert(message);
      }
    };

    return {
      form,
      isLogining,
      onSubmit,
      openCaptcha
    };
  }
};
