import { createVNode, render } from 'vue';
import LoginDialogComp from './LoginDialog';

let _vm: any = null;
const idStr = '__SP_LOGIN_DIALOG__';
const microAppRoot = document.body;
const app = createVNode(LoginDialogComp);

function openLoginDialog() {
  // const isExisted = !!document.getElementById(idStr);
  // if (isExisted && !!_vm && !!app) {
  //   const comp = app.component?.proxy as ComponentPublicInstance<{
  //     show: boolean;
  //     hideAnimate: boolean;
  //     _onOpen: () => void;
  //   }>;
  //   comp.show = true;
  //   return;
  // }
  if (_vm) {
    microAppRoot?.removeChild(_vm);
    _vm = null;
  }
  const root = document.createElement('div');
  root.id = idStr;
  _vm = microAppRoot?.appendChild(root);
  render(app, root);
}
export { openLoginDialog };
