import { defineComponent } from 'vue';

import style from './index.module.less';

export default defineComponent({
  props: {
    width: {
      type: String,
      default: '300px'
    }
  },
  setup(props, { slots }) {
    return () => (
      <aside class={[style.aside]} style={{ width: props.width }}>
        {slots.default?.()}
      </aside>
    );
  }
});
