/**
 * 获取全局配置
 */

import { decrypt, SPORT_API_KEY, SPORT_H5_URL_KEY, SPORT_WEB_URL_KEY, WS_API_KEY } from '@/common';
import { getDomainListApi } from '@/services';
import { reactive } from 'vue';

export interface ConfigStore {
  domainList: DomaiType | null;
}

interface DomaiSubType {
  cdn: string[];
  normal: string[];
}

export interface DomaiType {
  api_domain?: DomaiSubType;
  jump_h5_domain?: DomaiSubType;
  oss_img_domain?: DomaiSubType;
  pt_websocket?: DomaiSubType;
  socket_domain?: DomaiSubType;
  sport_api_domain?: DomaiSubType;
  oss_domain?: DomaiSubType;
  sport_domain?: DomaiSubType;
  web_domain: DomaiSubType;
}

const store = reactive<ConfigStore>({
  domainList: null
});

export function useConfig() {
  // 获取域名
  const setDomain = setDomainList();

  return {
    setDomain,
    store
  };
}

export async function setDomainList() {
  if (store.domainList) return;
  const { result, data } = await getDomainListApi();
  if (result) {
    const domain = JSON.parse(await decrypt(data)) as DomaiType;
    localStorage.setItem(SPORT_API_KEY, domain.sport_api_domain?.normal[0] || '');
    // localStorage.setItem(SPORT_LOGO_URL_KEY, domain.oss_img_domain?.cdn[0] || 'https://kgsports-apk.galalive.vip');
    // localStorage.setItem(CMS_API_KEY, domain.api_domain?.cdn[0] || '');
    localStorage.setItem(WS_API_KEY, domain.socket_domain?.normal[0] || '');
    localStorage.setItem(SPORT_H5_URL_KEY, domain.jump_h5_domain?.normal[0] || '');
    localStorage.setItem(SPORT_WEB_URL_KEY, domain.web_domain?.normal[0] || '');
    store.domainList = domain || {};
    console.log('配置的域名: domain:', domain);
  }
  return result;
}
