/**
 *沙巴体育运动ID
 *
 * @export
 * @enum {number}
 */
export enum SportIdEnum {
  /** 初始值-不存在 */
  Init = -1,
  /** 足球 */
  Soccerball = 1,
  /** 篮球 */
  Basketball = 2,
  /** 美式足球 */
  AmericanFootball = 3,
  /** 冰上曲棍球 */
  IceHockey = 4,
  /** 网球 */
  Tennis = 5,
  /** 排球 */
  Volleyball = 6,
  /** 斯诺克/台球 */
  Billiards = 7,
  /** 棒球 */
  Baseball = 8,
  /** 羽毛球 */
  Badminton = 9,
  /** 高尔夫球 */
  Golf = 10,
  /** 赛车 */
  RacingCar = 11,
  /** 游泳 */
  Swimming = 12,
  /** 政治 */
  Politics = 13,
  /** 水球 */
  WaterPolo = 14,
  /** 跳水 */
  Diving = 15,
  /** 拳击 */
  Boxing = 16,
  /** 射箭 */
  Archery = 17,
  /** 乒乓球 */
  TableTennis = 18,
  /** 举重 */
  Weightlifting = 19,
  /** 皮划艇 */
  Canoeing = 20,
  /** 体操 */
  Gymnastics = 21,
  /** 田径 */
  Athletics = 22,
  /** 马术 */
  Equestrian = 23,
  /** 手球 */
  Handball = 24,
  /** 飞镖 */
  Darts = 25,
  /** 橄榄球 */
  Rugby = 26,
  /** 曲棍球 */
  Hockey = 28,
  /** 橄榄球 */
  WinterSports = 29,
  /** 壁球 */
  Squash = 30,
  /** 娱乐 */
  Entertainment = 31,
  /** 篮网球 */
  Netball = 32,
  /** 自行车 */
  Bicycle = 33,
  /** 击剑 */
  Fencing = 34,
  /** 柔道 */
  Judo = 35,
  /** 现代五项 */
  ModernPentathlon = 36,
  /** 划船 */
  Rowing = 37,
  /** 帆船 */
  Sailboat = 38,
  /** 射击 */
  Shooting = 39,
  /** 跆拳道 */
  Taekwondo = 40,
  /** 铁人三项 */
  Triathlon = 41,
  /** 角力 */
  Wrestling = 42,
  /** 电子竞技 */
  ElectronicSports = 43,
  /** 泰拳 */
  ThaiBoxing = 44,
  /** 沙滩排球 */
  BeachVolleyball = 45,
  /** 板球 */
  Cricket = 50,
  /** 金融 */
  Finance = 55,
  /** 乐透 */
  Lottery = 56,
  /** 其他 */
  Other = 99
}
