import { useI18n, useSportOptions } from '@/hooks';
import { defineComponent, ref } from 'vue';
// import sport from '@/pages/jbb-web/views/rules/i18n-json/cn.json';
import style from './rules.module.less';

import i18nRules from './i18n-json';

export default defineComponent({
  name: 'Rules',

  setup() {
    const { t } = useI18n();
    const { optionsStore } = useSportOptions();
    // 如果不是中文就使用英文
    const lang = optionsStore.value.lang === 'zh-CN' ? 'zh-CN' : 'en';

    const focusTab = ref(0);

    const changePath = (i: number) => {
      focusTab.value = i;
    };

    return () => (
      <>
        <div class={style.helpPage}>
          <div class={style.helpPageBox}>
            <div class={style.helpPageTitle}>{t('lang.sport_common_betRules')} </div>
            <div class={style.helpWrap}>
              <div class={style.helpPageNav}>
                <div class={style.helpNavItem}>
                  {i18nRules[lang].tabs.map((tbas, i) => (
                    <div class={[style.childrenItem, focusTab.value === i ? style.active : null]}>
                      <div class={style.routeTitle} onClick={() => changePath(i)}>
                        {tbas}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class={style.helpPageContent}>
                <div class={style.helpDetailPage}>
                  <div class={style.detailContent}>
                    <div class={style.sbkRulesTitle}>{i18nRules[lang].tabs[focusTab.value] || '--'} </div>
                    <div>
                      {i18nRules[lang].data.map((item, index) => (
                        <div key={index}>
                          {index == focusTab.value && (
                            <div>
                              <p class={style.stitle}>{i18nRules[lang].title || '--'}</p>
                              <ul style="list-style: decimal;">
                                {item.Data.map((text, i) => (
                                  <li key={i}>{text}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
