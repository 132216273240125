import { defineComponent, reactive, ref, computed, PropType } from 'vue';
import { Spin } from 'ant-design-vue';
import { SvgIcon } from '@/components';
import { OddsPrice } from 'kg-web/components';
import { currency } from 'common';
import { useI18n, useBalance, StoreChampData } from '@/hooks';
import { useBetWeb } from '@/hooks/useBetWeb.hook';

import InputNumber from './components/InputNumber/index';
import { subtract } from 'lodash-es';

import './style/betting-orders.style.less';

export default defineComponent({
  name: 'BettingOrders',
  components: { SvgIcon },
  props: {
    ChampData: {
      type: Array as PropType<StoreChampData[]>,
      default: () => []
    }
  },

  emits: ['clearAllTickets'],
  setup(props) {
    const { t } = useI18n();
    const { sportBalance } = useBalance({
      platformCode: 'IM',
      gameType: 'SPORT'
    });

    const {
      state,
      allCombo,
      prebetInfo,
      betAmount,
      betLimit,
      winAmount,
      currencySymbol,
      handleBet,
      deleteTicket,
      getWinAmount,
      getOdds,
      clearAllTickets,
      getData,
      prebetList
    } = useBetWeb(true, true);
    getData();
    const states = reactive({
      hide: true, // 订单展开标志位
      onClickHide() {
        states.hide = true;
      },
      expand: true, // 串关展开位置
      clickExpand(value) {
        states.expand = value;
      }
    });
    const keyboardId = ref('0');
    const changeKeyBoardId = (id: string) => {
      keyboardId.value = id;
    };
    const contentHeight = computed(() => {
      const height = 90;
      return `calc(100% - ${height}px)`;
    });
    return () => (
      <section class="web-bet">
        <>
          {/*批量投注数据 */}
          <div class="web-bet-content" style={{ maxHeight: contentHeight.value }}>
            <>
              {prebetList.value.map(item => (
                <div class="league-match">
                  <div class="league-match-name">{item[0].leagueName}</div>
                  {!item[0].isChamp ? (
                    <div class="league-match-sub-name">
                      {item[0].home} VS {item[0].away}
                      {item[0].periodId !== null &&
                      item[0].live_home_score != undefined &&
                      item[0].live_away_score != undefined ? (
                        <span class="t-teams-score">{`(${item[0].live_home_score}-${item[0].live_away_score})`}</span>
                      ) : null}
                    </div>
                  ) : null}

                  {item.map(e => (
                    <div class={['web-betting-mutiple-info']}>
                      <div class="web-betting-mutiple-infoBox">
                        <span class="web-betting-mutiple-normalText line-height24">
                          <span class="web-betting-mutiple-normalText-left">
                            <span class="round"></span>
                            <span>
                              {e.periodId === 3 ? <span>{t('lang.sport_handicap_live')} </span> : null}
                              {e.betTypeName}
                            </span>
                            <span
                              class={`${e.oddsUp ? 'web-betting-mutiple-oddsUp' : ''} web-betting-mutiple-oddsNormal`}
                            >
                              {e.singleClosed || !e.price ? (
                                <span class={'web-betting-mutiple-oddsErrors'}>
                                  {t('lang.sport_common_oddsOff' /* 盘口关闭 */)}
                                </span>
                              ) : (
                                <>
                                  <OddsPrice
                                    symbol="@"
                                    value={e.price} //串关显示欧赔
                                    rangeIcon
                                  />
                                </>
                              )}
                            </span>
                          </span>
                          <span class="del-box" onClick={() => deleteTicket(e)}>
                            <SvgIcon name="365del" />
                          </span>
                        </span>
                        {props.ChampData?.map(items =>
                          items.leagues?.map(match => {
                            return <span class="web-betting-mutiple-normalText">{match.LeagueName}</span>;
                          })
                        )}

                        <div class="web-betting-mutiple-betName">
                          <span class="web-betting-mutiple-betName-text">
                            {e.periodId !== null && e.live_home_score != undefined && e.live_away_score != undefined
                              ? `(${e.live_home_score}-${e.live_away_score})`
                              : ''}
                            {e.keyName ? e.keyName : e.point}
                          </span>
                          {e.isSameGame ? ( // 不符合串场规则 相同比赛
                            <div class={['web-betting-mutiple-oddsErrors']} style={{ textAlign: 'center' }}>
                              {t('lang.sport_bet_betNotAllow')}
                            </div>
                          ) : !(e.Combo > 0) ? (
                            <div class={['web-betting-mutiple-oddsErrors']} style={{ textAlign: 'center' }}>
                              {t('lang.sport_common_fieldNotAllow' /* 不支持串场 */)}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* 可以单注 */}
                      <div class="web-betting-mutiple-input">
                        <InputNumber
                          placeholder={t('lang.sport_bet_stake' /* 本金 */)}
                          max={betLimit.value[e.uniqKey].max_bet}
                          min={betLimit.value[e.uniqKey].min_bet}
                          balance={sportBalance.value}
                          currency={currencySymbol.value}
                          amount={betLimit.value[e.uniqKey].stake}
                          disabled={
                            e.singleClosed ||
                            !e.price ||
                            !betLimit.value[e.uniqKey].max_bet ||
                            betLimit.value[e.uniqKey].max_bet == 0
                          }
                          marketId={e.key}
                          keyboardId={keyboardId.value}
                          onChange={changeKeyBoardId}
                          v-model:amount={betLimit.value[e.uniqKey].stake}
                        ></InputNumber>
                        <div class="win-amount-information">
                          <span class={['exclamatory', e.isSameGame ? 'red' : '']}>
                            <SvgIcon name="exclamatory" />
                            {betLimit.value[e.uniqKey].stake ? (
                              <div class="box">
                                <div>
                                  <p>{t('lang.sport_bet_stakeWinAmount')}</p>
                                  <p>
                                    {betLimit.value[e.uniqKey].stake} x {getOdds(e.price, e.odds_type)} ={' '}
                                    {getWinAmount(e)}
                                  </p>
                                </div>
                                <div>
                                  <p>{t('lang.sport_bet_stake' /* 本金 */)}</p>
                                  <p>{betLimit.value[e.uniqKey].stake}</p>
                                </div>
                                <div>
                                  <p>{t('lang.sport_bet_winAmount' /* 可赢额 */)}</p>
                                  <p>
                                    {getWinAmount(e)} - {betLimit.value[e.uniqKey].stake} ={' '}
                                    {currency(subtract(getWinAmount(e), betLimit.value[e.uniqKey].stake))}
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </span>
                          <>
                            {t('lang.sport_bet_limit')}: {currency(e.min_bet) || 0}～{currency(e.max_bet) || 0}
                          </>
                        </div>
                        <div class="win-amount-information">
                          {getWinAmount(e) > 0 ? (
                            <>
                              {t('lang.sport_bet_winAmount' /* 可赢额 */)}:{' '}
                              {currency(subtract(getWinAmount(e), betLimit.value[e.uniqKey].stake))}{' '}
                              {currencySymbol.value}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </>
            {allCombo.value.length ? (
              states.expand ? (
                <div class="web-betting-mutiple-parlay-button" onClick={() => states.clickExpand(false)}>
                  <span>{t('lang.sport_bet_hideDuxpleItem') /*收起复式投注选项*/}</span>
                  <SvgIcon name="arrow_bottom" />
                </div>
              ) : (
                <div
                  class={[
                    'web-betting-mutiple-parlay-button',
                    !states.expand ? 'web-betting-mutiple-parlay-button-close' : ''
                  ]}
                  onClick={() => states.clickExpand(true)}
                >
                  <span>{t('lang.sport_bet_showDuxpleItem') /*展开过关投注选项*/}</span>
                  <SvgIcon name="arrow_bottom" />
                </div>
              )
            ) : null}
            {/* 复式串关投注 */}
            {allCombo.value && allCombo.value.length > 0 && prebetInfo.value.length >= 2 ? (
              <>
                {allCombo.value.map((item: any) => {
                  if (states.expand === false) return null;
                  return (
                    <>
                      <section class="web-betting-mutiple-parlay" key={item.id}>
                        <div class="web-betting-mutiple-parlay-infoBoxWrap">
                          <div class="web-betting-mutiple-parlay-infoBox">
                            <span class={`web-betting-mutiple-oddsTpye`}>
                              {item.mulType} {item.bet_count}
                              {t('lang.sport_bet_betsNumber')}
                            </span>
                            <span class={`web-betting-mutiple-oddsNormal`}>
                              {item.odds ? <OddsPrice symbol="@" rangeIcon value={item.odds} /> : null}
                            </span>
                          </div>
                          <div class="web-betting-mutiple-parlay-winAmount">
                            {getWinAmount(item, 'odds') > 0 ? (
                              <>
                                {t('lang.sport_bet_winAmount' /* 可赢额 */)}:{' '}
                                {currency(subtract(getWinAmount(item, 'odds'), item.stake * item.bet_count))}{' '}
                                {currencySymbol.value}
                              </>
                            ) : (
                              <>
                                {t('lang.sport_bet_limit')}: {currency(item.min_bet) || 0}～
                                {currency(item.max_bet) || 0}
                              </>
                            )}
                          </div>
                        </div>
                        <div class="web-betting-mutiple-input">
                          <InputNumber
                            placeholder={t('lang.sport_bet_stake' /* 本金 */)}
                            v-model:amount={item.stake}
                            max={item.max_bet}
                            min={item.min_bet}
                            disabled={!item.max_bet || item.max_bet == 0}
                            count={item.bet_count}
                            balance={sportBalance.value}
                            currency={currencySymbol.value}
                            marketId={item.combo_type}
                            keyboardId={keyboardId.value}
                            onChange={changeKeyBoardId}
                          ></InputNumber>
                        </div>
                      </section>
                    </>
                  );
                })}
              </>
            ) : null}
          </div>
          {
            <footer
              class={['web-betting-mutiple-footer', betAmount.value ? 'web-betting-mutiple-footer-action' : '']}
              onClick={handleBet}
            >
              <div class="win-amount">
                <div>
                  {t('lang.sport_bet_betAmount')}:{' '}
                  <i>
                    {currency(betAmount.value)} {currencySymbol.value}
                  </i>
                </div>
                <div>
                  {t('lang.sport_bet_winAmount')}:{' '}
                  <i class="gren">
                    {currency(+winAmount.value - +betAmount.value)} {currencySymbol.value}
                  </i>
                </div>
              </div>
              <div class={'web-betting-mutiple-footer-btn'}>
                <Spin spinning={state.loading}></Spin>
                <div>{t('lang.sport_bet_bet') /* 投注 */}</div>
              </div>
              <div class={'web-betting-mutiple-footer-del'} onClick={() => clearAllTickets()}>
                <SvgIcon name="clear" />
              </div>
            </footer>
          }
        </>
      </section>
    );
  }
});
