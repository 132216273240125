import device from 'current-device';
const project = '' || (process.env.VUE_APP_PAGE as any);

// 手机
export const isMobile = device.mobile();
// 苹果手机
export const isIOS = device.iphone() && isMobile;
// 安卓手机
export const isAndriod = device.android() && isMobile;

export const isH5 = project.includes('mobile');
