import { defineComponent, PropType, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { get } from 'lodash-es';
import { RouteEnum, queryToStr } from '@/common';
import { TeamLogo } from 'kg-web/components';
import { PeriodI18n, useI18n } from '@/hooks';
import LiveStatistics from './live-statistics/index';

import style from './scorecard-minimal.module.less';

export default defineComponent({
  props: {
    matchInfo: {
      type: Object as PropType<any>,
      default: () => ({
        score: [0, 0]
      })
    }
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const showMatchInfoTime = computed(() => {
      return [1, 2].includes(props.matchInfo.sportId);
    });

    const odds = computed(() => {
      const name = PeriodI18n[Number(route.query.periodId)] || '';
      return t(name);
    });

    const goToHome = () => {
      const queryObj = {
        ...route.query
      };
      const queryStr = queryToStr(queryObj);
      router.replace(RouteEnum.ROOT + queryStr);
    };
    return () => (
      <div class={style.matchInfoHeader}>
        <div class={style.scorecard__backlink}>
          <div onClick={goToHome}>
            <svg-icon name={'arrow_bottom'} />
            {odds.value} {props?.matchInfo.sportName} {props?.matchInfo.leagueName}
          </div>
        </div>
        <section class={style.scorecard}>
          <div class={style.scorecard__main}>
            <div class={style.scorecard__team + ' ' + style.scorecard__team_home}>
              {props.matchInfo.tech?.red[0] > 0 ? (
                <span class={[style.penalityCard, style.penalityCard_red]}>{get(props.matchInfo, 'tech.red[0]')}</span>
              ) : null}
              {props.matchInfo.tech?.yellow[0] ? (
                <span class={style.penalityCard}>{get(props.matchInfo, 'tech.yellow[0]')}</span>
              ) : null}

              <span title={props.matchInfo.home || '--'} class={style.scorecard__teamNameWrap}>
                <h2 class={style.scorecard__teamTitle}>{props.matchInfo.home || '--'}</h2>
                <TeamLogo
                  class={style.teamLogo}
                  sportId={props.matchInfo.sportId}
                  teamId={props.matchInfo.homeId}
                  size={'xxxl'}
                  teamName={props.matchInfo.home}
                  logoUrl={props.matchInfo.homeLogo}
                />
              </span>
            </div>
            <div class={style.scorecard__info}>
              {!props.matchInfo.isLive && props.matchInfo.gameStatus !== 3 ? (
                <div class={style.scorecard__infoHeader}>VS</div>
              ) : (
                <div class={[style.scorecard__infoHeader, style.scorecard__infoHeader_started]}>
                  {props.matchInfo.score && ![18, 5].includes(props.matchInfo.sportId) ? (
                    <span>
                      {props.matchInfo.score ? `${props.matchInfo.score[0]} : ${props.matchInfo.score[1]}` : ''}
                    </span>
                  ) : [18, 5].includes(props.matchInfo.sportId) ? (
                    <span>
                      {props.matchInfo.score ? `${props.matchInfo.score[0]} : ${props.matchInfo.score[1]}` : ''}
                    </span>
                  ) : null}
                </div>
              )}
              <div class={style.scorecard__infoBody_wrapper}>
                {props.matchInfo.gameStatus === 3 ? (
                  <div class={[style.scorecard__infoBody, style.scorecard__infoBody_started]}>
                    {t('lang.sport_common_end')}
                    {showMatchInfoTime.value && props.matchInfo.time ? (
                      <span class={style.scorecard__infoBody_time}>{props.matchInfo.time || '--'}</span>
                    ) : null}
                  </div>
                ) : !props.matchInfo.isLive && props.matchInfo.gameStatus !== 3 ? (
                  <div class={style.scorecard__infoBody}>
                    {/* 未开始 */}
                    {t('lang.sport_common_noStart')}
                    {props.matchInfo.time || '--'}
                  </div>
                ) : (
                  <div class={[style.scorecard__infoBody, style.scorecard__infoBody_started]}>
                    {props.matchInfo.period ? <span>{t(props.matchInfo.period)}</span> : null}
                    {showMatchInfoTime.value && props.matchInfo.time ? (
                      <span class={style.scorecard__infoBody_time}>{props.matchInfo.time || '--'}</span>
                    ) : null}
                  </div>
                )}
              </div>
              <div class={style.scorecard__infoFooter_wrapper}>
                {!props.matchInfo.isLive && props.matchInfo.gameStatus !== 3 ? (
                  <div class={style.scorecard__infoFooter}>{props.matchInfo.date || '--'}</div>
                ) : null}
                <LiveStatistics info={props.matchInfo} />
              </div>
            </div>
            <div class={style.scorecard__team}>
              <span title={props.matchInfo.away || '--'} class={style.scorecard__teamNameWrap}>
                <TeamLogo
                  class={style.teamLogo}
                  sportId={props.matchInfo.sportId}
                  teamId={props.matchInfo.awayId}
                  size={'xxxl'}
                  teamName={props.matchInfo.away}
                  logoUrl={props.matchInfo.awayLogo}
                />
                <h2 class={style.scorecard__teamTitle}>{props.matchInfo.away || '--'}</h2>
              </span>
              {props.matchInfo.tech?.red[1] ? (
                <span class={[style.penalityCard, style.penalityCard_red]}>{get(props.matchInfo, 'tech.red[1]')}</span>
              ) : null}
              {props.matchInfo.tech?.yellow[1] ? (
                <span class={style.penalityCard}>{get(props.matchInfo, 'tech.yellow[1]')}</span>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    );
  }
});
