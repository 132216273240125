export enum HttpCode {
  /**
   * 失败
   */
  FAIL = 0,
  /**
   * 成功
   */
  SUCCESS = 1,
  /**
   * SB无用户
   */
  NO_CUSTOMER = 3,
  /**
   * 过期
   */
  EXPIRE = 600,
  /**
   * 找不到用户，大部分都是这种错误
   */
  NULL_USER = 400,
  /**
   * 被踢出
   */
  KICK = 603,
  /**
   * 被踢出
   */
  AUTH_FAIL = 401,
  /**
   * 禁止
   */
  FORBIDDEN = 403,
  /**
   * 需要滑动验证
   */
  VALIDATE_CODE = 20003
}
