import { defineComponent, onMounted, computed, onUnmounted } from 'vue';
import { useBettingRecord, FILTER_TABS, EARLY_TABS, getDateList1, useI18n, useTimerHook, TimerEnum } from '@/hooks';

import { Pagination } from 'ant-design-vue'; //spin
import { DataEmpty, PaginationView } from 'kg-web/components';
import BettingResultItem from './compoents/item';
import { isEmpty } from 'lodash-es';
import './style/index-new.less';
import Laoding from '@/components/loading-toast';

export default defineComponent({
  name: 'OrdersNew',
  setup() {
    const {
      betStore,
      earlyStore,
      getData,
      form,
      form1,
      state,
      onTabChange,
      onTabChange1,
      toggleDate,
      filterArrayWeb,
      earlyData,
      getCashOutValues,
      combineLoadData
    } = useBettingRecord();
    const { t } = useI18n();
    const { clearTimer } = useTimerHook();
    const isShow = computed(() => Number(betStore.count.betCount) > 0);

    const backTop = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };

    const isHidePage = computed(() => !betStore.total);
    const getCashOutValueData = async () => {
      const l = Laoding.show({
        text: t('lang.sport_order_earlySettlementLoading' /**提前结算更新中 */)
      });
      getCashOutValues().finally(async () => {
        await earlyData({ isPull: false, isLaoding: false });
        Laoding.hide(l);
      });
    };
    onMounted(() => {
      Promise.all([getCashOutValueData(), onTabChange1({ key: 'activeTab', index: 0 }, backTop, t)]);
      setTimeout(() => {
        combineLoadData();
      }, 5000);
    });
    onUnmounted(() => {
      clearTimer(TimerEnum.ORDER_LIST);
    });
    const TabsRender = () => (
      <div class="bet-history__top">
        <div class="tab-container">
          <div class="filter-tabss">
            {EARLY_TABS.map((item, i) => (
              <div
                key={i + '_T_KEY'}
                onClick={() => {
                  combineLoadData();
                  onTabChange1({ key: 'activeTab', index: i }, undefined, t);
                  backTop();
                }}
                class={['filter-tabs', !state.activeTab ? 'tab--actives ' : null]}
              >
                <a> {t(item.label)}</a>
              </div>
            ))}
            {FILTER_TABS.map((item, i) => (
              <div
                key={i + '_T_KEY'}
                onClick={() => {
                  clearTimer(TimerEnum.ORDER_LIST);
                  onTabChange({ key: 'activeTab', index: i + 1 });
                  backTop();
                }}
                class={['filter-tabs', i + 1 === state.activeTab ? 'tab--actives ' : null]}
              >
                <a> {t(item.label)}</a>
              </div>
            ))}
          </div>
          {state.activeTab !== 0 ? (
            <div class={'select'} onClick={toggleDate}>
              <span class={'date-filter'}>{t(getDateList1()[state.avtiveFilterDate].i18n)}</span>
              <svg-icon class={'icon'} name="pull_down" />
            </div>
          ) : null}
        </div>
      </div>
    );

    return () => (
      <div class="bet-history">
        <TabsRender />
        {console.log(betStore.betRecord, 'betStore.betRecord')}
        {!isEmpty(betStore.betRecord) ? (
          <>
            {state.activeTab == 1 || state.activeTab == 2 || state.activeTab == 3 ? (
              <>
                <div class="statice-datas">
                  <div class="s-d-item">
                    {t('lang.sport_bet_betAmount')}:{' '}
                    <span class="s-d-item-new1">
                      {betStore.count.betAmount}
                      {/* {currency.value} */}
                    </span>
                  </div>
                  <div class="s-d-item">
                    {t('lang.sport_orders_winLost')}:{' '}
                    <span class="s-d-item-new2">
                      {betStore.count.winAmount}
                      {/* {currency.value} */}
                    </span>
                  </div>

                  {isShow.value ? (
                    <div class="s-d-item">
                      {t('lang.sport_common_recordTotal')}:{' '}
                      <span class="s-d-item-new">
                        {betStore.count.betCount} {t('lang.sport_bet_betsNum')}
                      </span>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ) : null}
        {state.activeTab == 1 || state.activeTab == 2 || state.activeTab == 3 ? (
          <>
            <>
              {!isEmpty(betStore.betRecord) ? (
                <div style={{ overflow: 'auto' }}>
                  {betStore.betRecord.map(item => (
                    <BettingResultItem status={form.status} key={item.orderId} data={item} />
                  ))}
                </div>
              ) : (
                <DataEmpty type="orders" textColor="#1e6e51" title={t('lang.sport_common_noBetData')} class="empty" />
              )}
            </>
          </>
        ) : null}

        {state.activeTab == 0 ? (
          <>
            {earlyStore.value?.length ? (
              <>
                <div style={{ overflow: 'auto' }}>
                  {earlyStore.value.map(item => {
                    return (
                      <BettingResultItem
                        status={form1.status}
                        key={item.orderId}
                        datas={item}
                        onChange={e => {
                          item[e.key] = e.value;
                        }}
                        onClose={datas => {
                          filterArrayWeb(datas);
                        }}
                        onGetCashOutValueData={() => getCashOutValueData()}
                        onCombineLoadData={(param: any) => combineLoadData(param)}
                      />
                    );
                  })}
                </div>

                {/* <Spin spinning={state.loading}> </Spin> */}
              </>
            ) : (
              <DataEmpty type="orders" textColor="#1e6e51" title={t('lang.sport_common_noBetData')} class="empty" />
            )}
          </>
        ) : null}

        {state.activeTab == 1 || state.activeTab == 2 || state.activeTab == 3 ? (
          <PaginationView>
            <Pagination
              class="orders-pagination"
              v-model:current={form.current}
              onChange={async () => await getData()}
              hide-on-single-page={isHidePage.value}
              v-model:pageSize={form.size}
              pager-count={5}
              total={betStore.total}
            />
          </PaginationView>
        ) : null}
        <div class={['select-box', state.filterVisible ? 'select-box-show' : '']} onClick={toggleDate}>
          <div class={'select-box-list'}>
            {getDateList1().map((item, index) => (
              <div
                class={['select-box-list-item', index === state.avtiveFilterDate ? 'act' : null]}
                key={index + '_TY_KEY'}
                onClick={() => {
                  onTabChange({ key: 'avtiveFilterDate', index }, backTop);
                  toggleDate();
                }}
              >
                {t(item.i18n)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
});
