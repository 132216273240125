import { computed, defineComponent, nextTick, onMounted } from 'vue';
import './style/cart.style.less';
import { Popup } from 'vant';
import BettingOrders from './betting-orders';
import BettingResult from './result';
import { useAuth } from '@/hooks';
import { useBet } from '@/hooks/use365Bet.hook';
// import { useRoute } from 'vue-router';
// import { SportRouteName } from 'common';
import { isEmpty } from 'lodash-es';

export default defineComponent({
  name: 'ShoppingCart',
  props: {
    tabbarExact: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { betTickets, ticketSize, clearAllTickets, openTickets, handleClosePopup, state } = useBet();
    const { isLogin } = useAuth();
    // const route = useRoute();
    // 显示购物车
    const showCart = computed(() => {
      // const whiteRoutes = [SportRouteName.home, SportRouteName.inPlay, SportRouteName.detail] as string[];
      // const showRoute = whiteRoutes.includes(String(route.name));
      return isLogin.value && !state.isShowPopup && !isEmpty(betTickets.value);
    });
    onMounted(() => {
      nextTick(() => {
        !isLogin.value && clearAllTickets();
      });
    });

    const ShopCartRender = () =>
      showCart.value ? (
        <div class={['shopping-cart', props.tabbarExact ? 'tabbar-exact' : null]} onClick={openTickets}>
          <div class="icon-container">
            <span class="order-count">
              <i>{ticketSize.value}</i>
            </span>
          </div>
        </div>
      ) : null;

    return () => [
      <ShopCartRender />,
      <Popup
        teleport="body"
        closeOnPopstate
        safeAreaInsetBottom
        v-model:show={state.isShowPopup}
        onClick-overlay={handleClosePopup}
        round
        position="bottom"
      >
        {state.betted ? <BettingResult /> : <BettingOrders onClearAllTickets={clearAllTickets} />}
      </Popup>
    ];
  }
});
