interface Data {
  title: string;
  tabs: string[];
  data: { subTiel: string; Data: string[]; id: number }[];
}

import cn_data from /* webpackChunkName: "cn_rules" */ './cn.json';
import en_data from /* webpackChunkName: "en_rules" */ './en.json';

export default {
  'zh-CN': cn_data as Data,
  en: en_data as Data
};
