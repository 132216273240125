import cssVars from 'css-vars-ponyfill';
const isSupportCssVar = window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)');

export function cssRootVar(variable = {}) {
  if (isSupportCssVar) {
    const root = document.querySelector("style[data-sp-theme='theme']") || document.createElement('style');
    const cssvar = Object.keys(variable)
      .map(item => `${item}:${variable[item]} !important;\n`)
      .join('');
    root.innerHTML = `:root{\n${cssvar}}`;
    if (!root.getAttribute('data-sp-theme')) {
      root.setAttribute('data-sp-theme', 'theme');
      const header = document.querySelector('head');
      header && header.appendChild(root);
    }
  } else {
    cssVars({
      variables: variable,
      shadowDOM: false,
      updateDOM: true,
      onlyLegacy: process.env.NODE_ENV === 'develop',
      silent: true,
      updateURLs: true,
      preserveVars: false,
      include: 'link[rel=stylesheet],style',
      watch: true
    });
  }
}

// 获取主题
export function getThemeStyle(): string {
  if (!window.tenant) {
    return 'green';
  }
  const themeStyle = window.tenant && (window.tenant.theme || window.tenant.themeStyle);
  return themeStyle;
}
