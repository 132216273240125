import { defineComponent } from 'vue';

import style from './index.module.less';

export default defineComponent({
  setup(props, { slots }) {
    props;
    return () => <div class={style.radio}>{slots.default?.()}</div>;
  }
});
