import { Fn } from '#/global';
import { createRouter, createWebHashHistory, RouteLocationNormalized, Router } from 'vue-router';
import { RouteEnum, isDev } from '@/common';

const mainRoutes: any[] = [
  {
    path: RouteEnum.ROOT,
    name: RouteEnum.ROOT,
    component: () => import(/* webpackChunkName: "web_home_page" */ '@/pages/kg-web/views/home/index'),
    meta: {
      title: 'Home',
      showHeader: true
    }
  },
  {
    path: RouteEnum.OPTIONS,
    name: RouteEnum.OPTIONS,
    component: () => import(/* webpackChunkName: "web_potions_page" */ '@/pages/kg-web/views/options/index'),
    meta: {
      title: 'Options',
      showHeader: false
    }
  },
  {
    path: RouteEnum.ORDERS,
    name: RouteEnum.ORDERS,
    component: () => import(/* webpackChunkName: "web_orders_page" */ '@/pages/kg-web/views/orders'),
    meta: {
      title: 'Orders',
      showHeader: false
    }
  },
  {
    path: RouteEnum.SPORT_DETAIL,
    name: RouteEnum.SPORT_DETAIL,
    component: () => import(/* webpackChunkName: "web_detail_page" */ 'kg-web/views/detail/index'),
    meta: {
      title: 'WebDetail',
      showHeader: true
    }
  },
  {
    path: RouteEnum.COURSE,
    name: RouteEnum.COURSE,
    component: () => import(/* webpackChunkName: "web_course_page" */ 'kg-web/views/course/index'),
    meta: {
      title: 'Course',
      showHeader: false
    }
  },
  {
    path: RouteEnum.RULES,
    name: RouteEnum.RULES,
    component: () => import(/* webpackChunkName: "web_rules_page" */ 'kg-web/views/rules/index'),
    meta: {
      title: 'Rules',
      showHeader: false
    }
  },
  {
    path: RouteEnum.NOT_FOUND,
    name: RouteEnum.NOT_FOUND,
    component: () => import(/* webpackChunkName: "web_404_page" */ 'kg-web/views/not-found/index'),
    meta: {
      title: '404 not found!',
      showHeader: false
    }
  },
  {
    path: '/:pathMath(.*)', // 此处需特别注意置于最底部
    redirect: RouteEnum.NOT_FOUND
  }
];

const devRoute = [
  {
    path: RouteEnum.LOGIN,
    name: RouteEnum.LOGIN,
    component: () => import(/* webpackChunkName: "web_login_page" */ '@/components/auth/login_v2.vue'),
    meta: {
      title: 'Login'
    }
  }
];

export const routes = window.DEBUG || isDev ? [...mainRoutes, ...devRoute] : mainRoutes;

function registerRouter(router: any) {
  router.beforeEach(async (_to: any, _from: RouteLocationNormalized, next: Fn) => {
    if (window.__POWERED_BY_QIANKUN__) {
      next();
      return;
    }
    document.title = localStorage.getItem('title') || '';
    next();
  });
}

export function initRouter(): Router {
  const router = createRouter({
    history: createWebHashHistory('/'),
    routes,
    strict: true,
    scrollBehavior: () => ({ left: 0, top: 0 })
  });
  registerRouter(router);
  return router;
}
