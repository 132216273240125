import { defineComponent, PropType } from 'vue';
import { SelType } from '@/services/kg-api';
import { HomeMarketOdds, _keyNameByBettype } from '@/common';
import { hasTicketId } from '@/hooks/useBetWeb.hook';
import style from './odds.module.less';
import { StoreMatchInfo } from '@/hooks';

export default defineComponent({
  name: 'MatchSummaryOdds',
  props: {
    oddset: {
      type: Array as PropType<HomeMarketOdds[]>,
      default: () => ['', '', '', '', '', '']
    },
    matchId: {
      type: String,
      required: true
    },
    sportId: {
      type: Number
    },
    info: {
      type: Object as PropType<StoreMatchInfo>,
      default: () => {}
    }
  },
  emits: ['addBetTicket'],
  setup(props, { emit }) {
    const LockOddsRender = () => (
      <div class={style.lockOdds}>
        <svg-icon name="lock" />
      </div>
    );

    const NoneOddsRender = () => (
      <div class={style.noneOdds}>
        <span class={style.oddsPrice}>--</span>
      </div>
    );

    return () => (
      <div class={style.oddsSwiper}>
        <div class={style.oddsSwiperContainer}>
          {props.oddset.map((item, index) => (
            <div class={style.odds} key={item.OddsId || index}>
              {item.sels.map((sel: SelType, i) => {
                // if (index > 2) return;
                return (
                  <>
                    {!sel ? (
                      <div
                        class={style.col1}
                        style={{ height: `calc(100% / ${props.info.isLive ? 3 : item.sels.length})` }}
                      >
                        <div class={style.col} key={i}>
                          <NoneOddsRender />
                        </div>
                      </div>
                    ) : sel.locked || sel?.Price <= 0 ? (
                      <div
                        class={style.col1}
                        style={{ height: `calc(100% / ${props.info.isLive ? 3 : item.sels.length})` }}
                      >
                        <div class={style.col} key={i}>
                          <LockOddsRender />
                        </div>
                      </div>
                    ) : (
                      <div
                        class={[
                          style.col1,
                          hasTicketId({
                            matchId: props.matchId,
                            marketId: item.OddsId,
                            wagerSelectionId: sel.WagerSelectionId
                          })
                            ? style.selectedItem
                            : null
                        ]}
                        style={{ height: `calc(100% / ${props.info.isLive ? 3 : item.sels.length})` }}
                        key={i}
                      >
                        <div
                          class={style.col}
                          onClick={() => {
                            emit('addBetTicket', {
                              betType: item.Bettype,
                              betTypeName: item.BettypeName,
                              matchId: props.matchId,
                              marketId: item.OddsId,
                              sportType: props.sportId,
                              oddsType: sel.OddsType,
                              wagerSelectionId: sel.WagerSelectionId,
                              price: sel.Price,
                              periodId: item.PeriodId,
                              point: sel.Point,
                              key: sel.Key,
                              keyName: sel.KeyName,
                              outrightTeamId: 0,
                              EuropePrice: sel.EuropePrice,
                              Combo: item.Combo,
                              home: props.info.home,
                              away: props.info.away,
                              leagueName: props.info.leagueName,
                              market: props.info.market,
                              matchTime: props.info.matchTime,
                              allowBetBuilder: item.allowBetBuilder
                            });
                          }}
                        >
                          {index == 0 ? (
                            <span>
                              <span class={style.oddsPoint}>{sel.Point ? sel.Point : sel.KeyName} </span>
                              <span class={style.oddsPrice}> {sel?.Price} </span>
                            </span>
                          ) : (
                            <span>
                              <span class={style.oddsKeyname}>{sel.KeyName}</span>
                              <span class={style.oddsPrice}> {sel?.Price} </span>
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  }
});
