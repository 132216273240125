export interface GeetestType {
  geetest_seccode: string;
  geetest_validate: string;
  geetest_challenge: string;
}

enum CaptchaType {
  /**
   * 极验
   */
  GEETEST = 'geetest',
  /**
   * 网易云盾
   */
  NE = 'ne',
  /**
   * 腾讯盾
   */
  TENCENT = 'tencent',
  /**
   * 图形验证码
   */
  IMG = 'image'
}

type CaptchaLang = 'zh-cn' | 'zh-hk' | 'zh-tw' | 'en' | 'ja' | 'ko' | 'id' | 'ru' | 'ar' | 'es' | 'pt-pt' | 'fr' | 'de';
type CaptchaGeetestMode = 'popup' | 'float' | 'custom' | 'bind';

/**
 * 极验数据
 */
interface GeetDataType extends GeetestType {
  status: number;
}

type CaptchaWyMode = 'popup' | 'float' | 'embed';

export { GeetDataType, CaptchaType, CaptchaWyMode, CaptchaGeetestMode, CaptchaLang };
