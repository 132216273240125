import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { LeagueItem } from '@/services/kg-api';
import './match-filter.style.less';
import { IndexBar, IndexAnchor } from 'kg-web/components';
import { onScroll } from 'kg-web/components/IndexBar/index';
import { Skeleton, CheckboxGroup, Checkbox } from 'ant-design-vue';
import { DataEmpty, Scrollbar, CheckView } from 'kg-web/components';
import { useLeagueList } from '@/hooks';
import { useRoute } from 'vue-router';
import { isEmpty } from 'lodash-es';
import { useI18n } from '@/hooks';
interface AllLeagueList {
  [name: string]: LeagueItem[];
}

export default defineComponent({
  name: 'LeagueFilter',
  props: {
    sportId: {
      type: [Number, String],
      require: true
    },
    isResult: {
      type: Boolean,
      default: false
    },
    time: {
      type: Object as PropType<{ startTime: number | string; endTime: number | string }>,
      require: false
    },
    modelValue: Array as PropType<string[] | number[]>
  },
  emits: ['confirm', 'close', 'update:modelValue'],
  setup(props, { emit }) {
    const route = useRoute();
    const { t } = useI18n();
    const index = ref(0);
    const filteredArray = ref<string[]>([]);
    const selectedItems = ref<Object[]>([]);
    const { getLeaguesData, checkAll, toggleAll, leagueStore, leagueCounter, letters, leagueCouter, state } =
      useLeagueList({
        isResult: props.isResult
      });

    onMounted(async () => {
      leagueStore.form.periodId = Number(route.query.periodId);
      leagueStore.form.sportId = Number(props.sportId);
      if (props.time) {
        leagueStore.resultForm.startTime = props.time.startTime;
        leagueStore.resultForm.sportId = props.sportId as unknown as number;
        leagueStore.resultForm.endTime = props.time.endTime;
      }
      await getLeaguesData();
      if (!isEmpty(props.modelValue) && props.modelValue) {
        leagueStore.leagueIds = props.modelValue;
        return;
      }
      handleCheckAll(true);
      leagueStore.isCheckedAll = true;
    });

    watch(
      () => route.query.periodId,
      value => {
        if (value != undefined) {
          onHandleClose();
        }
      }
    );
    // 关闭
    const onHandleClose = async () => emit('close');

    //Trying to connect queryEventsAndMarkets API
    const confirmChecked = async () => {
      if (selectedItems.value.length <= 0) {
        await onHandleClose();
        return;
      }
      emit('confirm', selectedItems.value);
      await onHandleClose();
    };

    const useFilteredLeagues = () => {
      letters.value.map((letter: any) => {
        leagueStore.leaguesList[letter].map(league => {
          if (filteredArray.value.includes(league.leagueId) === false) {
            filteredArray.value.push(league.leagueId);
          }
        });
      });

      return { filteredLeagues: filteredArray.value };
    };

    const handleCheckAll = (isChecked: boolean) => {
      checkAll(isChecked);
      const { filteredLeagues } = useFilteredLeagues();
      leagueStore.leagueIds = isChecked ? filteredLeagues : [];
      selectedItems.value = [...leagueStore.leagueIds];
      emit('update:modelValue', []);
    };

    const handleAntiCheck = (isChecked: boolean) => {
      toggleAll(isChecked);
      const { filteredLeagues } = useFilteredLeagues();
      // @ts-ignore;
      leagueStore.leagueIds = filteredLeagues.filter(x => !leagueStore.leagueIds.includes(x));
      leagueStore.isCheckedAll = filteredLeagues.length == leagueStore.leagueIds.length ? true : false;
      selectedItems.value = [...leagueStore.leagueIds];
      if (leagueStore.isCheckedAll) {
        emit('update:modelValue', []);
      } else {
        emit('update:modelValue', leagueStore.leagueIds);
      }
    };

    const handleCheckGroup = (checkedValues: string[]) => {
      const checkedCount = checkedValues.length;
      leagueStore.isCheckedAll = checkedCount === leagueCounter.value;
      if (leagueStore.isCheckedAll) {
        emit('update:modelValue', []);
      } else {
        emit('update:modelValue', checkedValues);
      }
    };

    const onChangeCheckBox = (selected: LeagueItem) => {
      if (!selectedItems.value.includes(`${selected.leagueId}`)) {
        selectedItems.value.push(`${selected.leagueId}`);
      } else {
        selectedItems.value.splice(
          selectedItems.value.findIndex(item => item === `${selected.leagueId}`),
          1
        );
      }
    };

    // 多选框UI
    const renderCheckbox = (item: LeagueItem) => (
      <CheckView>
        <Checkbox
          onChange={() => onChangeCheckBox(item)}
          name={item.leagueId}
          value={item.leagueId}
          class="league-check"
        >
          {item.leagueName}
        </Checkbox>
      </CheckView>
    );

    // 所有联赛dom
    const RenderAllLeague = () => (
      <Scrollbar onScroll={data => (index.value = onScroll(data))}>
        <div class="all-league">
          <IndexBar
            index={index.value}
            indexList={letters.value}
            v-slots={{
              title: () => (
                <div class="text-01" onClick={() => handleCheckAll(!leagueStore.isCheckedAll)}>
                  {t('lang.sport_filterLeague_allLeagues')} A---Z
                </div>
              )
            }}
          >
            <CheckboxGroup v-model:value={leagueStore.leagueIds} onChange={handleCheckGroup}>
              {!isEmpty(letters.value) &&
                letters.value.map((el: keyof AllLeagueList, index: number) => (
                  <>
                    <IndexAnchor index={index}>{el}</IndexAnchor>
                    {!!leagueStore.leaguesList[el] &&
                      leagueStore.leaguesList[el].map((item: LeagueItem, i: number) => (
                        <div class="league-select" key={i + '_LI_KEY'}>
                          {renderCheckbox(item)}
                        </div>
                      ))}
                  </>
                ))}
            </CheckboxGroup>
          </IndexBar>
        </div>
      </Scrollbar>
    );

    // 底部Dom
    const FooterRender = () => (
      <div class="filter-footer">
        <div class="selected-count">
          {/* 已选联赛  */}
          {t('lang.sport_filterLeague_selectedLeagues')} [<span>{leagueCouter.value}</span>]
        </div>
        <div class="button-wrapper">
          <div class="console-btn">
            <div>
              <CheckView>
                <Checkbox
                  v-model:checked={leagueStore.isCheckedAll}
                  onChange={(value: any) => handleCheckAll(value?.target?.checked)}
                >
                  {/* 全选 */}
                  {t('lang.sport_filterLeague_allSelect')}
                </Checkbox>
              </CheckView>
            </div>
            <div>
              <CheckView>
                <Checkbox
                  v-model:checked={leagueStore.isReverse}
                  onChange={(value: any) => handleAntiCheck(value?.target?.checked)}
                >
                  {/* 反选 */}
                  {t('lang.sport_common_selectReverse')}
                </Checkbox>
              </CheckView>
            </div>
          </div>
          <div class="confirm" onClick={confirmChecked}>
            {/* 确定 */}
            {t('lang.sport_common_confirm')}
          </div>
        </div>
      </div>
    );

    return () => (
      <div class="web-league-filter">
        <Skeleton loading={state.loading} paragraph={{ rows: 8 }}>
          {isEmpty(leagueStore.leaguesList) ? (
            <DataEmpty title={t('lang.sport_handicap_noneMatch')} class="nodata" />
          ) : (
            <RenderAllLeague />
          )}
          <FooterRender />
        </Skeleton>
      </div>
    );
  }
});
