/**
 *  比赛与盘口数据
 */
import { reactive } from 'vue';
import { DataTypeEnum, MarketType, _formatMatchInfo, _formatHomeMarketOdds } from '@/common';
import { MatchItem, EventsAndMarkets, EventsAndMarketsPrams, queryEventsAndMarkets } from '@/services/kg-api';
import { useFavortMatch, useSportOptions, TimerEnum, useTimerHook } from '@/hooks';
import { map, isEmpty } from 'lodash-es';
import { useStore } from 'vuex';

// import { useStore } from 'vuex';

export interface StoreMatchInfoII {
  id: string;
  home: string;
  league: string;
  away: string;
  sportId: number;
  current?: number;
  time: string;
  homeId: number;
  awayId: number;
  date: string;
  leagueName: string;
  sportName: string;
  isNeutral: boolean;
  period: string;
  isLive: boolean;
  market: string;
  count: number;
  score: number[];
  sections?: [number, number][];
  video?: boolean;
  animate?: boolean;
  isMark: boolean;
  Number: any;
  tech?: {
    ht: [number, number];
    red: [number, number];
    yellow: [number, number];
    corner?: [number, number];
  };
}

export interface StoreMarketDataII {
  leagueId: string;
  leagueName: string;
  key: string;
  matches: StoreMatchInfoII[];
}

interface EventStore {
  /**
   * 请求参数
   */
  form: EventsAndMarketsPrams;
  /**
   * 下拉加载
   * pull loading
   */
  loading: boolean;
  /**
   * 转换市场数据
   */
  marketList: StoreMarketDataII[];
}

const storeMain = reactive<EventStore>({
  form: {
    oddsType: MarketType.Europe,
    sportId: 1,
    periodId: DataTypeEnum.Live,
    leagueIds: null,
    selectDate: null,
    queryCount: 100,
    eventIds: ''
  },
  loading: true,
  marketList: []
});

function getStore() {
  return reactive<EventStore>({
    form: {
      oddsType: MarketType.Europe,
      sportId: 1,
      periodId: DataTypeEnum.Live,
      leagueIds: null,
      selectDate: null,
      queryCount: 100,
      eventIds: ''
    },
    loading: true,
    marketList: []
  });
}

export function useMarketsBySport(useOnlyStore = true) {
  let store = storeMain;
  if (useOnlyStore) {
    store = getStore();
  }
  const vuexStore = useStore();
  const { optionsStore } = useSportOptions();
  const { isMarkedById, clearMark } = useFavortMatch();
  const { addTimer } = useTimerHook();

  /**
   * 获取市场数据
   */
  const getData = async () => {
    store.loading = true;
    store.form.oddsType = optionsStore.value.marketType;
    const fn = async () => {
      const [res, data] = (await queryEventsAndMarkets(store.form)) as [boolean, EventsAndMarkets[]];
      store.loading = false;
      if (res) {
        clearMark(store.form, data);
        const tempList: any[] = [];
        // 转换数据
        if (!isEmpty(data)) {
          map(data, (marketItem: any) => {
            const result = {
              leagueId: marketItem.LeagueId,
              leagueName: marketItem.LeagueName,
              matches: marketItem.matches.map((match: MatchItem) => {
                if (!!match.MoreInfo) {
                  const RedCardH = match.MoreInfo.redCardH;
                  const RedCardA = match.MoreInfo.redCardA;
                  const CornerH = match.MoreInfo.cornersH;
                  const CornerA = match.MoreInfo.cornersA;
                  const YellowCardH = match.MoreInfo.yellowCardH;
                  const YellowCardA = match.MoreInfo.yellowCardA;
                  const HalfTimeH = match.MoreInfo.halfScoreH;
                  const HalfTimeA = match.MoreInfo.halfScoreA;
                  match.MoreInfo = Object.assign(match.MoreInfo || {}, {
                    CornerH,
                    CornerA,
                    HalfTimeH,
                    HalfTimeA,
                    RedCardH,
                    RedCardA,
                    YellowCardH,
                    YellowCardA
                  });
                }
                return {
                  ..._formatMatchInfo({
                    match,
                    league: marketItem,
                    isInProgress: match.IsLive === 1,
                    sportId: marketItem.SportType
                  }),
                  video: !!vuexStore.getters?.getVideoByMid(match.MatchId),
                  animate: !!vuexStore.getters?.getAnimateyByMid(match.MatchId),
                  isMark: isMarkedById({ matchId: match.MatchId, sportId: marketItem.SportType })
                };
              })
            };
            tempList.push(result);
          });
        } else {
          store.marketList = [];
        }
        store.marketList = tempList;
      } else {
        store.marketList = [];
      }
    };
    addTimer(TimerEnum.SPORT_LIST, fn, Number(store.form.periodId) === DataTypeEnum.Live ? 15000 : 60 * 1000);
  };

  return {
    eventStore: store,
    getSportsSideGames: getData
  };
}
