import { defineComponent, onMounted, reactive, ref } from 'vue';
import { Skeleton } from 'ant-design-vue';
import './notice.style.less';

import { useI18n } from '@/hooks';
import { lastSevenDays, today } from '@/common/utils';
import { getPublicMessage } from '@/services';
import { toTime } from '@/common/utils';

import { DataEmpty, Scrollbar } from 'kg-web/components';

export default defineComponent({
  name: 'JBBpublicNotice',
  setup() {
    const { t } = useI18n();
    const getMessage = ref([]);
    const store = reactive({
      loading: false
    });
    onMounted(async () => {
      const startDate = lastSevenDays();
      const endDate = today();
      store.loading = true;
      const [res, data] = await getPublicMessage({ startDate, endDate, source: 'im' });
      if (res) {
        getMessage.value = data;
      }
      store.loading = false;
    });

    return () => (
      <div class="notice-detail">
        <Skeleton
          loading={store.loading}
          paragraph={{
            rows: 8
          }}
        >
          {getMessage.value.length > 0 ? (
            <Scrollbar>
              <div class="notice-list">
                {getMessage.value.map((item: any, index) => (
                  <div class="notice-item" key={index}>
                    <div class="list_top">
                      <div class="title_text">
                        {/* 赛事公告 */}
                        {t('lang.sport_common_matchNotice')}
                      </div>
                      <div class="title_right">{toTime(item.post_time, 'YYYY-MM-DD HH:mm')}</div>
                    </div>
                    <div style="padding: 5px 0px">
                      <p class="list_text">{item.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Scrollbar>
          ) : (
            <DataEmpty type={'notice'} title={t('lang.sport_common_noMatchNotice')} /> // 暂无公告
          )}
        </Skeleton>
      </div>
    );
  }
});
