import { httpSB, v2_http } from '@/common';
import { HttpResponse_v2 } from '#/global';

import {
  BetOrderData,
  AutoResponse,
  BetOrdersPrams,
  PartialCashOut,
  AutoCashOut,
  BetCashOut,
  DeleteCashOut,
  GameParms,
  LoginAccountInfo,
  UserInfo,
  UserLoginForm
} from '../types/user.interface.v2';

/**
 * 登录接口
 */
function signInApi(parm: UserLoginForm): Promise<HttpResponse_v2<LoginAccountInfo>> {
  return v2_http.post('/api/auth/sign_in', parm);
}

/**
 * 跳转游戏
 */
function jumpGameApi(parm: GameParms): Promise<HttpResponse_v2<string>> {
  return v2_http.get('/api/game/play', parm);
}

/**
 * captchaCode 验证码
 */
function captchaCode(params: any): Promise<HttpResponse_v2> {
  return v2_http.get('/api/captcha/code', params);
}

/**
 * 获取全平台游戏余额
 */
function freeTransferApi({
  platformCode,
  gameType
}: {
  platformCode: string;
  gameType: string;
}): Promise<HttpResponse_v2> {
  gameType;
  return v2_http.get('/api/game/freeTransfer', { platformCode, gameType });
}

/**
 * 刷新凭证token
 */
function refreshTokenApi({ refreshToken }: { refreshToken: string }): Promise<HttpResponse_v2<any>> {
  return v2_http.post('/api/auth/token/refresh', { refreshToken });
}

/**
 * 获取用户信息
 */
function getUserInfoApi(): Promise<HttpResponse_v2<UserInfo>> {
  return v2_http.get('/api/user/info');
}

/**
 * 获取用户信息
 */
function getDomainListApi(): Promise<HttpResponse_v2<string>> {
  return v2_http.get('/api/tenant/domain/list');
}

/**
 * 用户退出登录
 */
function uersSignOutApi(): Promise<HttpResponse_v2<string>> {
  return v2_http.post('/api/auth/logout');
}

/**
 * 获取体育注单
 */
const getOrders = (prams: BetOrdersPrams): Promise<HttpResponse_v2<BetOrderData>> => {
  return httpSB.post(`/agent/getBetList`, prams);
};

const getCashOutValues = (): Promise<HttpResponse_v2> => {
  return httpSB.post(`/agent/getCashOutValues`);
};

const loadCashOutValues = (): Promise<HttpResponse_v2> => {
  return httpSB.post(`/agent/loadCashOutValues`);
};

const postCashout = (prams: BetCashOut): Promise<HttpResponse_v2> => {
  return httpSB.post(`/agent/postCashout`, prams);
};

const postPartialCashout = (prams: PartialCashOut): Promise<HttpResponse_v2> => {
  return httpSB.post(`/agent/postPartialCashout`, prams);
};

const postAutoCashout = (prams: AutoCashOut): Promise<HttpResponse_v2<AutoResponse>> => {
  return httpSB.post(`/agent/postAutoCashout`, prams);
};

const deleteAutoCashout = (prams: DeleteCashOut): Promise<HttpResponse_v2<AutoResponse>> => {
  return httpSB.post(`/agent/deleteAutoCashout`, prams);
};

export {
  getOrders,
  getCashOutValues,
  loadCashOutValues,
  postCashout,
  postPartialCashout,
  postAutoCashout,
  deleteAutoCashout,
  signInApi,
  getUserInfoApi,
  uersSignOutApi,
  freeTransferApi,
  refreshTokenApi,
  getDomainListApi,
  captchaCode,
  jumpGameApi
};
