import { defineComponent } from 'vue';
import { SvgIcon } from '@/components';
import { RouteEnum, queryToStr } from '@/common';
import { useRouter, useRoute } from 'vue-router';
import './header.style.less';

// 加了个参数, 依次 参数back默认回到上一页, 加backPath路由指定到某路由,emit('click-right') 自定义

export default defineComponent({
  name: 'MobileHeader',
  props: {
    shadow: {
      type: Boolean,
      default: false
    },
    /**
     * 左侧返回是否显示
     */
    showArrow: {
      type: Boolean,
      default: true
    },
    /**
     * 标题
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * 右边内容
     */
    right: {
      type: String,
      default: ''
    },
    /**
     * 固定置顶
     */
    fiexd: {
      type: Boolean,
      default: false
    },
    /**
     * 是否返回上一页
     */
    back: {
      type: Boolean,
      default: true
    },
    /**
     * 返回的路由
     */
    backPath: {
      type: String,
      default: ''
    }
  },
  emits: ['click-left'],
  setup(props, { slots, emit }) {
    const router = useRouter();
    const route = useRoute();
    const onClickLeft = () => {
      if (props.backPath) {
        router.replace(props.backPath);
        return;
      }
      emit('click-left');
    };
    const goToHome = () => {
      const queryObj = {
        ...route.query
      };
      const queryStr = queryToStr(queryObj);
      router.replace(RouteEnum.ROOT + queryStr);
    };
    return () => (
      <div class="top-header">
        <div class={['top-header-content', props.fiexd ? 'fixed' : null, props.shadow ? 'shadow' : null]}>
          {props.showArrow ? (
            slots.left ? (
              <div class={'top-header-left'}>{slots.left()}</div>
            ) : (
              <div class={'top-header-left'}>
                <div class={'top-header-left-icon'} onClick={() => (props.back ? goToHome() : onClickLeft())}>
                  <SvgIcon name="back" />
                </div>
              </div>
            )
          ) : null}
          <div class={'top-header-center'}>{slots.title ? slots.title() : <span>{props.title}</span>}</div>
          <div class={'top-header-right'}>{slots.default?.() || slots.right?.()}</div>
        </div>
      </div>
    );
  }
});
