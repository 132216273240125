import { Modules, StoreUserInfo } from '@/store/types';
import { ActionContext } from 'vuex';
import { getLoginData } from '@/common/utils';
import { getBalance } from '@/services/kg-api';
import { BET_TICKET_KEY } from '@/hooks';

const initState = (): StoreUserInfo => ({
  userName: '',
  balance: 0,
  token: '',
  currency: 'CNY'
});

const state: StoreUserInfo = initState();

const getters = {
  userName: (state: StoreUserInfo) => state.userName,
  balance: (state: StoreUserInfo) => state.balance,
  isLogin: (state: StoreUserInfo) => !!state.token
};
const actions = {
  // 检查登录
  async CHECK_IS_LOGIN({ dispatch, commit }: ActionContext<StoreUserInfo, Modules>) {
    const authInfo = getLoginData();
    if (!!authInfo.token) {
      commit('UPDATE_USER_INFO', {
        token: authInfo.token
      });
      await dispatch('UPDATE_BLANCE');
      return;
    }
    console.error('ERROE: 未登录');
  },
  async SIGN_OUT({ commit }: ActionContext<StoreUserInfo, Modules>) {
    commit('SIGN_OUT_SUCCESS');
  },
  async UPDATE_BLANCE({ commit }) {
    const authInfo = getLoginData();
    const [result, userData] = await getBalance();
    if (result && typeof userData === 'object') {
      commit('UPDATE_USER_INFO', {
        userName: userData.userName,
        balance: userData.balance,
        currency: userData.currency,
        token: authInfo.token
      });
    } else if (userData === 'Invalid Token !!!') {
      // token失效
      commit('SIGN_OUT_SUCCESS');
      return;
    }
    clearInterval((window as any).poolTimer);
    (window as any).poolTimer = setInterval(async () => {
      const [result, data] = await getBalance();
      if (result && typeof data === 'object') {
        commit('UPDATE_USER_INFO', {
          userName: data.userName,
          balance: data.balance,
          currency: data.currency,
          token: authInfo.token
        });
      } else {
        clearInterval((window as any).poolTimer);
        (window as any).poolTimer = null;
      }
    }, 8000);
  }
};

const mutations = {
  // 更新用户信息
  UPDATE_USER_INFO(state: StoreUserInfo, paylod: StoreUserInfo) {
    Object.assign(state, paylod);
  },
  SIGN_OUT_SUCCESS(state: StoreUserInfo) {
    const defaultVal = initState();
    const keys = Object.keys(defaultVal);
    clearInterval((window as any).poolTimer);
    (window as any).poolTimer = null;
    localStorage.removeItem('token');
    keys.map(key => {
      state[key] = defaultVal[key];
    });
    localStorage.setItem(BET_TICKET_KEY, '{}');
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
