import { ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'KeyboardKey',
  emits: ['press'],

  setup(_props, { slots, emit }) {
    const active = ref(false);

    const onTouchStart = () => {
      active.value = true;
    };

    const onTouchMove = () => {
      active.value = false;
    };

    const onTouchEnd = () => {
      if (active.value) {
        active.value = false;
        emit('press');
      }
    };

    return () => (
      <div
        class={{ 'btn-active': active.value }}
        onTouchstart={onTouchStart}
        onTouchmove={onTouchMove}
        onTouchend={onTouchEnd}
        onTouchcancel={onTouchEnd}
      >
        {slots.default?.()}
      </div>
    );
  }
});
