import { defineComponent, inject, watch, ref, Ref } from 'vue';
import { SvgIcon } from '@/components';
import './collapse.style.less';

export default defineComponent({
  name: 'CollapsePanel',
  props: {
    expand: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { slots }) {
    const isExpand = ref(false);

    const handleClickToggle = () => {
      isExpand.value = !isExpand.value;
    };
    const expandAll = inject('expandAll') as Ref<boolean>;
    const showArrow = inject('arrow') as boolean;
    const type = inject('type') as boolean;

    watch(
      () => expandAll.value,
      () => {
        isExpand.value = expandAll.value;
      },
      { immediate: true }
    );

    watch(
      () => props.expand,
      v => {
        if (v) {
          isExpand.value = true;
        }
      },
      { immediate: true }
    );

    return () => (
      <div class={['web-collapse-panel', isExpand.value ? 'is-expand' : null, type]}>
        <div class="web-collapse-panel-header" onClick={() => handleClickToggle()}>
          {showArrow ? (
            <div class="expand-icon">
              <SvgIcon name="arrow_bottom" />
            </div>
          ) : null}
          <div class="web-collapse-panel-header-conetnt">{slots.header?.()}</div>
          {slots.right?.()}
        </div>
        <div class="web-collapse-panel-content">{slots.default?.()}</div>
      </div>
    );
  }
});
