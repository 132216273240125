import { defineComponent } from 'vue';
import './style.skeleton.less';

export default defineComponent({
  name: 'MatchDetailSkeleton',
  props: {
    loading: {
      default: false,
      type: Boolean
    }
  },
  setup(props, { slots }) {
    const Item = () => (
      <div class="market-item-skeleton">
        <div class="header-info">
          <div class="header-info-left">
            <div class="skeleton-block"></div>
            <div class="skeleton-block"></div>
          </div>
          <div class="header-info-right">
            <div class="skeleton-block"></div>
            <div class="skeleton-block"></div>
            <div class="skeleton-block"></div>
          </div>
        </div>
        <div class="m-info">
          <div class="m-info-left">
            <div class="m-info-left-line">
              <div class="skeleton-block"></div>
              <div class="skeleton-block"></div>
            </div>
            <div class="m-info-left-line">
              <div class="skeleton-block"></div>
              <div class="skeleton-block"></div>
            </div>
            <div class="m-info-left-line">
              <div class="skeleton-block"></div>
              <div class="skeleton-block"></div>
            </div>
          </div>
          <div class="m-info-right">
            <div class="skeleton-block"></div>
            <div class="skeleton-block"></div>
            <div class="skeleton-block"></div>
          </div>
        </div>
        <div class="m-info">
          <div class="m-info-left">
            <div class="m-info-left-line">
              <div class="skeleton-block"></div>
              <div class="skeleton-block"></div>
            </div>
            <div class="m-info-left-line">
              <div class="skeleton-block"></div>
              <div class="skeleton-block"></div>
            </div>
            <div class="m-info-left-line">
              <div class="skeleton-block"></div>
              <div class="skeleton-block"></div>
            </div>
          </div>
          <div class="m-info-right">
            <div class="skeleton-block"></div>
            <div class="skeleton-block"></div>
            <div class="skeleton-block"></div>
          </div>
        </div>
      </div>
    );
    return () =>
      props.loading ? (
        <div class="skeleton-h5">
          <div class="top-info">
            <div class="skeleton-block"></div>
            <div class="skeleton-block"></div>
          </div>
          {[1, 2].map(() => (
            <Item></Item>
          ))}
        </div>
      ) : (
        slots.default?.()
      );
  }
});
