/**
 * 数字键盘输入
 */
import { defineComponent, reactive, withModifiers } from 'vue';
import './keyboard.style.less';

import KeyboardKey from './keyboard-key-new';
import { Numbers } from './config';
import { SvgIcon } from '@/components';

export default defineComponent({
  name: 'NumericKeypad',
  props: {
    balance: {
      type: Number,
      default: 0
    },
    extrasType: {
      type: String,
      default: 'OK'
    },
    max: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: ''
    },
    // 是否激活显示键盘
    activeKeyboard: {
      type: Boolean,
      default: false
    },
    amount: {}
  },
  emits: ['update:amount', 'open-keyboard', 'done'],
  setup(props, { emit }) {
    const state = reactive({
      defaultValue: '',
      showKeyboard: props.activeKeyboard
    });

    const getValue = (value: any) => {
      const reg = /^((\d+)|(\d+((\.{1,1})|(\.{1,1}\d{0,2}))))$/; // 数字正则输入
      if (reg.test(value)) {
        return value;
      } else {
        let tureValue = value;
        for (let i = value.length - 1; i >= 0; i--) {
          tureValue = tureValue.slice(0, i);
          if (reg.test(tureValue) || tureValue === '') {
            value = tureValue;
            break;
          }
        }
        return value;
      }
    };

    // 处理普通数字
    const _handleClickNumber = (value: 'del' | '.' | number | any) => {
      let newValue: any = '';
      const oldValue = Number(state.defaultValue);
      const walletBlance = Number(props.balance);
      if (props.max < 0 || props.min < 0) {
        state.defaultValue = '0';
        emit('update:amount', '0');
        return;
      }
      if (value === 'MAX') {
        // 处理最大Max
        newValue = Math.min(props.max, props.balance);
      }
      // 数字
      if (typeof value === 'number') {
        newValue =
          oldValue === 0 && value === 0 ? '0' : `${state.defaultValue === '0' ? '' : state.defaultValue}${value}`;
      }
      // 处理 .
      if (value === '.') {
        newValue = `${state.defaultValue === '0' || state.defaultValue === '' ? '' : state.defaultValue}${value}`;
        // ....
      }
      // 处理删除
      if (value === 'del') {
        const oldStrValue = String(oldValue);
        if (!oldStrValue.length) return;
        newValue = oldStrValue.substring(0, oldStrValue.length - 1);
      }
      // 检查值是否大于最大 或 者大于钱包
      const flag = +newValue > props.max || +newValue > walletBlance;
      if (flag) {
        newValue = Math.floor(props.max - walletBlance < 0 ? props.max : walletBlance);
      }
      // newValue = newValue === '' ? 0 : Number(newValue);
      newValue = getValue(newValue);
      state.defaultValue = newValue;
      emit('update:amount', newValue || '');
    };

    return () =>
      props.activeKeyboard ? (
        <div class="numeric-keypad" onClick={withModifiers(() => {}, ['stop'])}>
          <div class="numeric-keypad-numbers">
            {Numbers.map(key => (
              <KeyboardKey onPress={() => _handleClickNumber(key)} key={key}>
                {key}
              </KeyboardKey>
            ))}
          </div>
          <div class="numeric-keypad-extras">
            <KeyboardKey onPress={() => _handleClickNumber('MAX')}>MAX</KeyboardKey>
            <KeyboardKey onPress={() => _handleClickNumber('del')}>
              <SvgIcon name="del" />
            </KeyboardKey>
            <KeyboardKey class="done" onPress={() => emit('done')}>
              完成
            </KeyboardKey>
          </div>
        </div>
      ) : null;
  }
});
