import { computed, defineComponent } from 'vue';
// import { Checkbox } from 'vant';
import './style/ticket.style.less';
import { SvgIcon } from '@/components';
import { OddsPrice } from '365-mobile/components';
import { useI18n } from '@/hooks';
import { useBet } from '@/hooks/use365Bet.hook';

export default defineComponent({
  name: 'BetTicketItem',
  props: {
    id: {
      type: String as any
    },
    bet: {
      type: Object as any,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    marketId: {
      type: String,
      default: '1'
    },
    keyboardId: {
      type: String,
      default: '0'
    }
  },
  emits: ['oddsChange'],
  setup(props, { slots, emit }) {
    const { deleteTicket, state, currentCheckAll } = useBet();
    const { t } = useI18n();
    const isSameGame = computed(() => {
      return props.bet.isSameGame && state.tabActive !== 0;
    });
    const marketClosed = computed(() => {
      return props.bet.closed || !props.bet.price;
    });
    const unSupportParlay = computed(() => {
      return !(props.bet.combo > 0) && state.tabActive !== 0 && currentCheckAll.value.includes(props.bet.uniqKey);
    });
    const SingleBetRenader = () => (
      <div
        class={['ticket-item-info', props.keyboardId === props.marketId && 'isAct', props.keyboardId, props.marketId]}
      >
        <div class="ticket-item-match">
          <div class="t-bettype">
            <span class="label">
              {props.bet.periodId === 3 ? <span>{t('lang.sport_handicap_live')} </span> : null}{' '}
              <span>{props.bet.betTypeName}</span>
            </span>
            {props.bet.periodId !== null &&
            props.bet.live_home_score != undefined &&
            props.bet.live_away_score != undefined ? (
              <span class="t-teams-score">{`(${props.bet.live_home_score}-${props.bet.live_away_score})`}</span>
            ) : null}
            <div
              class={['delelt-it', marketClosed.value || unSupportParlay.value ? 'remind' : null]}
              onClick={() => deleteTicket(props.bet)}
            >
              <SvgIcon name="365del" />
            </div>
          </div>
          <div class="t-league">
            <span class="t-league-name">{props.bet.leagueName}</span>
            {marketClosed.value ? (
              // 盘口关闭
              <span class="error">{t('lang.sport_common_oddsOff')}</span>
            ) : unSupportParlay.value ? (
              // 不支持串场
              <span class="error">{t('lang.sport_common_fieldNotAllow')}</span>
            ) : (
              <OddsPrice
                symbol="@"
                value={props.bet.price} //串关显示欧赔
                rangeIcon
                onChange={() => emit('oddsChange')}
              />
            )}
          </div>
          <div class="t-teams">
            {!props.bet.isChamp ? (
              <div class="t-teams-info">
                <span>{props.bet.home}</span>
                <span>VS</span>
                <span>{props.bet.away}</span>
              </div>
            ) : null}
            <div class="label">{props.bet.keyName}</div>
          </div>
        </div>
      </div>
    );
    const ParlayBetRenader = () => (
      <div
        class={[
          'ticket-item-info parlay-ticket-item-info',
          props.keyboardId === props.marketId && 'isAct',
          props.keyboardId,
          props.marketId
        ]}
      >
        <div class="ticket-item-match">
          <div class="t-bettype">
            <div class="t-bettype-left">
              <span class="round"></span>
              <span class="label">
                {props.bet.periodId === 3 ? <span>{t('lang.sport_handicap_live')} </span> : null}{' '}
                <span>{props.bet.betTypeName}</span>
              </span>
              <OddsPrice
                symbol="@"
                value={props.bet.EuropePrice} //串关显示欧赔
                rangeIcon
                onChange={() => emit('oddsChange')}
              />
            </div>
            <div
              class={['delelt-it', marketClosed.value || unSupportParlay.value ? 'remind' : null]}
              onClick={() => deleteTicket(props.bet)}
            >
              <SvgIcon name="365del" />
            </div>
          </div>
          <div class="t-league">
            <span class="t-league-name">{props.bet.keyName}</span>
          </div>
        </div>
      </div>
    );
    return () => (
      <div
        class={{
          'ticket-item': true,
          'is-same': isSameGame.value,
          'is-locked': marketClosed.value,
          'is-error': unSupportParlay.value
        }}
      >
        {props.type === 'single' ? <SingleBetRenader /> : null}
        {props.type === 'parlay' ? <ParlayBetRenader /> : null}
        {slots.default?.()}
      </div>
    );
  }
});
