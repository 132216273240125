/**
 * 运动类型
 */

import { reactive, computed, nextTick } from 'vue';
import { getEventSummaryApi, EventSummaryApi } from '@/services/kg-api';
import { isEmpty, sumBy, isArray } from 'lodash-es';
import { DataTypeEnum, SPORT_MAP_KEY, RouteEnum, queryToStr } from '@/common';
import { useRouter, useRoute } from 'vue-router';
import { localMarketedMatchs } from './useFavortMatch.hook';
import { TimerEnum, useAuth, useTimerHook } from '@/hooks';
// import { sportIcons } from '@/components/sport-icon/icons';

interface SportTypesData extends EventSummaryApi {
  i18n: string;
  follow: number;
  markMatchs: any[];
}
interface StoreData {
  /**
   * 源数据
   */
  sportTypes: SportTypesData[];
  loading: boolean;
  firstLoading: boolean;
  /**
   * 数据统计
   */
  dateCounts: {
    live: number;
    today: number;
    early: number;
    championLeague: number;
  };
}

// export const DateTypeKeys = ['live', 'today', 'early', 'champion'];
export const DateTypeKeys = {
  3: 'live',
  1: 'today',
  2: 'early',
  5: 'championLeague',
  '-1': 'follow'
};

export const PeriodI18n = {
  1: 'lang.sport_handicap_today', // 今日
  2: 'lang.sport_earlyHandicap', // 早盘
  3: 'lang.sport_handicap_live', // 滚球
  5: 'champion', // 串关'lang.sport_bet_stray'
  '-1': 'lang.sport_handicap_focus' // 关注
};

/**
 * 投注类型列表
 * live: 滚球, today: 今日 , early: 早盘,
 */
export const DATE_TYPES = [
  {
    label: PeriodI18n[DataTypeEnum.Live],
    i18n: 'lang.sport_handicap_live',
    code: 'live',
    value: DataTypeEnum.Live,
    index: 'l'
  },
  {
    label: PeriodI18n[DataTypeEnum.Today],
    i18n: 'lang.sport_handicap_today',
    code: 'today',
    value: DataTypeEnum.Today,
    index: 't'
  },
  {
    label: PeriodI18n[DataTypeEnum.Early],
    i18n: 'lang.sport_earlyHandicap',
    code: 'early',
    value: DataTypeEnum.Early,
    index: 'e'
  }
];

export const CHAMPION_TYPE = [
  {
    label: PeriodI18n[DataTypeEnum.championLeague],
    i18n: 'lang.sport_champion',
    code: 'champion',
    value: DataTypeEnum.championLeague,
    index: 'c'
  }
];

export const NEW_DATE_TYPE = [
  {
    label: PeriodI18n[DataTypeEnum.Followed],
    i18n: 'lang.sport_common_collect',
    code: 'followed',
    value: DataTypeEnum.Followed,
    index: 'f'
  }
];

export interface DataTypeItem {
  label: string;
  i18n: string;
  code: string;
  value?: any;
  index?: string;
  count: number;
}

const store = reactive<StoreData>({
  sportTypes: [],
  dateCounts: {
    live: 0,
    today: 0,
    early: 0,
    championLeague: 0
  },
  loading: false,
  firstLoading: true
});

export function useSportTypes() {
  const { checkIsLogin } = useAuth();
  const router = useRouter();
  const route = useRoute();
  const { addTimer } = useTimerHook();
  const activePeriodId = computed(() => Number(route.query.periodId) || 0);
  const activeSportType = computed(() => Number(route.query.sportId) || 0);

  // 统计
  const getSportCountByTab = (item: EventSummaryApi, dataTab: string) => {
    if (isEmpty(store.sportTypes)) return 0;
    return item[dataTab] || 0;
  };

  // 切换运动类目
  const onSportTabchange = ({ sportType }: { sportType: number }) => {
    if (activeSportType.value === sportType) return;
    delete route.query.favorite;
    const queryObj = {
      ...route.query,
      sportId: sportType
    };
    const queryStr = queryToStr(queryObj);
    router.replace(RouteEnum.ROOT + queryStr);
  };

  const onDateTabchange = (index: DataTypeEnum) => {
    if (activePeriodId.value === index && route.path === RouteEnum.ROOT) return;
    delete route.query.favorite;
    if (index === DataTypeEnum.Followed && !checkIsLogin()) return;
    if (index !== DataTypeEnum.Early) {
      delete route.query.selectDate;
    } else {
      route.query.selectDate = 1 as any;
    }
    const effectiveSportId = store.sportTypes.find(e => e[DateTypeKeys[index]] > 0)?.sportType;
    const queryObj = {
      ...route.query,
      sportId: effectiveSportId || '', //collect will be empty now
      periodId: index
    };
    const queryStr = queryToStr(queryObj);
    router.replace(RouteEnum.ROOT + queryStr);
  };

  const onWeekDayChange = (dayValue: number | '') => {
    delete route.query.favorite;
    const queryObj = {
      ...route.query,
      selectDate: dayValue
    };
    const queryStr = queryToStr(queryObj);
    router.replace(RouteEnum.ROOT + queryStr);
  };

  // 时间类型
  // const dataTypeComp: ComputedRef<DataTypeItem[]> = computed(() => {
  //   DATE_TYPES.map(dateItem => {
  //     dateItem.count = store.dateCounts[dateItem.code];
  //   });
  //   return DATE_TYPES;
  // });

  /**
   * 获取运动列表
   */
  const getSportTypes = async (): Promise<EventSummaryApi[] | null> => {
    store.loading = true;
    const [res, data] = (await getEventSummaryApi()) as any;

    store.loading = false;
    store.firstLoading = false;
    if (res && isArray(data)) {
      store.sportTypes = data || [];
      // 存一份运动类目枚举到缓存
      const sportsMap = {}; //all matches included.
      data.map(item => {
        sportsMap[item.sportType] = item.sportTypeName;
        return item;
      });
      // data = data.map(item => {
      //   if (Object.keys(sportIcons).includes(`${item.sportType}`)) {
      //     sportsMap[item.sportType] = item.sportTypeName;
      //     console.log(item, 'this is item');
      //     return item;
      //   }
      // });
      // data = data.filter(x => x);
      // store.sportTypes = data || [];

      localStorage.setItem(SPORT_MAP_KEY, JSON.stringify(sportsMap));
    }
    // 类目统计
    store.dateCounts = {
      live: sumBy(data, 'live') || 0,
      today: sumBy(data, 'today') || 0,
      early: sumBy(data, 'early') || 0,
      championLeague: sumBy(data, 'championLeague') || 0
    };
    updateMatchMarks();
    return res ? data : null;
  };

  const getSportName = () => {
    const sportsMap = {};
    store.sportTypes.map(item => {
      sportsMap[item.sportType] = item.sportTypeName;
    });
    return sportsMap[activeSportType.value];
  };
  /**
   * 更新关注数量
   */
  const updateMatchMarks = () => {
    !isEmpty(store.sportTypes) &&
      store.sportTypes.map((e: { follow: any; sportType: string | number; markMatchs: any }) => {
        const storageMatch: any = localMarketedMatchs.value.get(Number(e.sportType)) || [];
        e.follow = storageMatch.length; //followed matches inside array
        e.markMatchs = storageMatch.length; //marked matches
      });
  };

  // 初始化tab参数
  const initTab = async () => {
    const data = await getSportTypes();
    updateMatchMarks();
    addTimer(TimerEnum.MENU, getSportTypes, 6 * 1000);
    if (!data) return;
    const { sportId, periodId, selectDate } = route.query as any;
    const efficaciousSid = () => {
      const periodKey = DateTypeKeys[periodId];
      // 当前是否有该时间段比赛
      const objById = data.find(e => e.sportType === Number(sportId));
      // 找到第一个有效的运动
      const objByEffect = data.find(e => e[periodKey || 'live'] > 0) || { sportType: sportId };
      return objById && objById[DateTypeKeys[periodId]] > 0 ? sportId : objByEffect.sportType;
    };
    const efficaciousPid = () => {
      return Number(periodId || 3);
    };
    nextTick(() => {
      const query = {
        sportId: efficaciousSid(),
        periodId: efficaciousPid(),
        selectDate: selectDate
      };
      if (efficaciousPid() !== 2) {
        delete query.selectDate;
      } else {
        query.selectDate = selectDate || 1;
      }
      if (route.path === RouteEnum.SPORT_DETAIL) return;
      const queryObj = {
        ...route.query
      };
      const queryStr = queryToStr(queryObj);
      router.replace(RouteEnum.ROOT + queryStr);
    });
  };

  return {
    store,
    initTab,
    getSportTypes,
    activePeriodId,
    onWeekDayChange,
    activeSportType,
    onDateTabchange,
    getSportCountByTab,
    updateMatchMarks,
    onSportTabchange,
    getSportName
    // dataTypeComp
  };
}
