import { SPORT_ACCESS_UUID_KEY } from '@/common';

export function uuid(len = 32, radix = 16) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid: any[] = [];
  let i: number;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}

/**
 * crypto 生成随机数
 */
export function makeRandomId(length: number) {
  if ('Crypto' in window) {
    return Array.prototype.map
      .call(window.crypto.getRandomValues(new Uint8Array(32)), item => item.toString(32))
      .join('')
      .slice(length);
  }
  return Math.random().toString(36).slice(length);
}

export function urlEncode(param: any, key?: string, encode?: string) {
  if (param == null) return '';
  let paramStr = '';
  const t = typeof param;
  if (t == 'string' || t == 'number' || t == 'boolean') {
    paramStr += '&' + key + '=' + (encode == null || encode ? encodeURIComponent(param) : param);
  } else {
    for (const i in param) {
      const k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
}

export const generateUUID = () => {
  const uid = uuid();
  localStorage.setItem(SPORT_ACCESS_UUID_KEY, uid);
  return uid;
};

export const getUUID = () => {
  const uuid = localStorage.getItem(SPORT_ACCESS_UUID_KEY);
  if (!uuid) {
    return generateUUID();
  }
  return uuid;
};
