import { Client, Message, messageCallbackType, StompConfig } from '@stomp/stompjs';
import { Ref, ref } from 'vue';
import { getLoginData, getTenant, getWebsocketApi } from '@/common';

const WS_URL = getWebsocketApi() + '/system/websocket';

let WSclient: any;

interface UseSportsTechWsRn {
  /**
   * 连接成功
   */
  isConnected: Ref<boolean>;
  /**
   * 初始化聊天
   */
  initWs: Function;
  /**
   * 推送信息列表
   */
  dataList: Ref<any[]>;
  /**
   * 订阅
   */
  subscribe: (destination: string, fn?: messageCallbackType) => void;
  /**
   * 取消订阅
   */
  unSubscribe: (destination?: string, fn?: messageCallbackType) => void;
  /**
   * ws实例
   */
  WSclient: any;
}

export function useSportsTech(): UseSportsTechWsRn {
  const { token = '' } = getLoginData(); // 获取令牌

  const isConnected = ref(false);

  const headers = {
    ack: 'auto',
    tenant: getTenant(),
    token: token,
    userType: 'system',
    nick: '',
    isFirst: 1,
    guestTeamId: 0,
    homeTeamId: 0,
    chId: 0
  };

  const dataList: Ref<any[]> = ref([]);

  const chatConfig: StompConfig = {
    connectHeaders: {
      token, //协议新增
      tenant: getTenant() //租户标识
    },
    brokerURL: WS_URL,
    reconnectDelay: 5000,
    heartbeatIncoming: 210 * 1000,
    heartbeatOutgoing: 210 * 1000,
    onStompError: (error: any) => {
      console.log(
        '%c===============================\n= Chat Server Connected Fail! =\n===============================',
        'font-size:14px;color:red'
      );
      console.error(error);
    },
    onDisconnect: (error: any) => {
      console.error('断开了 ws', error);
      isConnected.value = WSclient.connected;
    }
  };

  // 订阅
  const subscribe = (destination: string, fn?: messageCallbackType) => {
    const msgCb = Boolean(fn) ? fn : ({ body }: Message) => onMessage(JSON.parse(body));
    try {
      WSclient.subscribe(destination, msgCb, headers);
    } catch (error) {
      console.error(error);
    }
  };

  // 取消订阅
  const unSubscribe = () => {
    WSclient.unsubscribe();
  };

  // 初始化连接
  const initWs = async (options: Partial<StompConfig>) => {
    WSclient = new Client({ ...chatConfig, ...options });
    await WSclient.activate();
  };

  // 消息接收
  const onMessage = async (data: any) => {
    if (!data) return;
    try {
    } catch (ex) {
      console.error('msg parse failed', ex);
    }
  };

  return {
    isConnected,
    dataList,
    unSubscribe,
    subscribe,
    initWs,
    WSclient
  };
}
