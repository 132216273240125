/**
 * 旧版赛事推荐hook
 */

import { reactive } from 'vue';
import { useStore } from 'vuex';
import { getRecommendedMarketList, OddsetData } from '@/services/kg-api';
import { StoreMatchInfo } from '@/hooks';
import { isEmpty, map } from 'lodash-es';
import { _formatMatchInfo, _formatRecMarketOdds, TechId } from '@/common';
// import { TechId } from '@/common/enums/sportsTechEnum';
export interface RecTeamInfo {
  IsLive: 1 | 0;
  LeagueName: string;
  MarketCount: number;
  SportType?: number;
  SportId?: number;
  HomeName: string;
  AwayName: string;
  SportName: string;
  HomeID: number;
  AwayID: number;
  MatchId: number;
  LeagueId: string;
  oddset: OddsetData[];
  RunTime?: string;
}

export function useRecommendedMarket() {
  const vuexStore = useStore();

  const store = reactive({
    recMatchList: [] as {
      key: string;
      info: StoreMatchInfo;
      oddset: OddsetData[];
    }[]
  });

  const formatScore = data => {
    if (typeof data !== 'object' || !data) return data;
    if (data.MatchId) {
      const tech = vuexStore?.getters?.getTechById(data.MatchId);
      if (tech) {
        const RedCard = tech.get(TechId.RED) || [];
        const RedCardH = RedCard[0];
        const RedCardA = RedCard[1];
        const YellowCard = tech.get(TechId.YELLOW) || [];
        const YellowCardH = YellowCard[0];
        const YellowCardA = YellowCard[1];
        const Corner = tech.get(TechId.CORNER) || [];
        const CornerH = Corner[0];
        const CornerA = Corner[1];

        data.MoreInfo = Object.assign(data.MoreInfo || {}, {
          RedCardH,
          RedCardA,
          YellowCardH,
          YellowCardA,
          CornerH,
          CornerA
        });
      } else if (!!data.MoreInfo) {
        const RedCardH = data.MoreInfo.redCardH;
        const RedCardA = data.MoreInfo.redCardA;
        const CornerH = data.MoreInfo.cornersH;
        const CornerA = data.MoreInfo.cornersA;
        const YellowCardH = data.MoreInfo.yellowCardH;
        const YellowCardA = data.MoreInfo.yellowCardA;
        const HalfTimeH = data.MoreInfo.halfScoreH;
        const HalfTimeA = data.MoreInfo.halfScoreA;
        data.MoreInfo = Object.assign(data.MoreInfo || {}, {
          CornerH,
          CornerA,
          HalfTimeH,
          HalfTimeA,
          RedCardH,
          RedCardA,
          YellowCardH,
          YellowCardA
        });
      }
    }
    return data;
  };
  const getRecommendedMarketData = async () => {
    const [isSuccess, data] = (await getRecommendedMarketList()) as [boolean, RecTeamInfo[]];
    if (isSuccess) {
      const tempList: any[] = [];
      // 转换数据
      if (!isEmpty(data)) {
        map(data, (marketItem: RecTeamInfo, idx) => {
          const result = {
            key: marketItem.MatchId + '_mk' + idx,
            info: _formatMatchInfo({
              match: formatScore(marketItem),
              league: { LeagueName: marketItem.LeagueName, SportName: marketItem.SportName },
              isInProgress: !!marketItem.RunTime || marketItem.IsLive === 1,
              sportId: marketItem.SportType || marketItem.SportId
            }),
            oddset: _formatRecMarketOdds(marketItem.oddset)
          };
          tempList.push(result);
        });
      }
      store.recMatchList = tempList;
    }
  };

  return {
    recStore: store,
    getRecommendedMarketData
  };
}
