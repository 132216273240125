import type { App } from 'vue';
import type { I18n, I18nOptions } from 'vue-i18n';

import { createI18n } from 'vue-i18n';

import { languageList, localeSetting } from './localeTypes';

const { fallback, availableLocales } = localeSetting;

export let i18n: ReturnType<typeof createI18n>;

async function createI18nOptions(locale = 'zh-CN'): Promise<I18nOptions> {
  // const defaultLocal = await import(`./lang/${locale}.ts`);
  // 检查语言是否存在列表
  const isExist = languageList.find(e => e.seriNo === locale);
  if (!isExist) {
    console.error('不存在当前语言' + locale);
    locale = languageList[0].seriNo;
  }
  const defaultLocal = await import(/* webpackChunkName: "i18n.[request]" */ `./lang/${locale}.json`);
  const message = defaultLocal.default ?? {};

  return {
    legacy: false,
    locale,
    fallbackLocale: fallback,
    messages: {
      [locale]: {
        lang: message
      }
    },
    availableLocales: availableLocales,
    sync: true, //If you don’t want to inherit locale from global scope, you need to set sync of i18n component option to false.
    silentTranslationWarn: true, // true - warning off
    missingWarn: false,
    silentFallbackWarn: true
  };
}

// setup i18n instance with glob
export async function setupI18n(app: App, locale = 'zh-CN') {
  const options = await createI18nOptions(locale);
  i18n = createI18n(options) as I18n;
  app.use(i18n);
}
