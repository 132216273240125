import { defineComponent, computed, PropType, ref, watch } from 'vue';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { Toast, Dialog } from 'vant'; //Toast
import { Slider, message, Badge, Modal } from 'ant-design-vue';
import { FormatOrderItem } from '@/common';
import { EarlyTypes, toTime } from '@/common/utils';
import { useI18n } from 'vue-i18n';
import '../style/order-new-item.less';
import { useBettingRecord } from '@/hooks';
import Laoding from '@/components/loading-toast';
import { SvgIcon } from '@/components';

export default defineComponent({
  name: 'OrdersItem',
  components: {
    Dialog: Dialog.Component
  },
  props: {
    data: {
      type: Object as PropType<FormatOrderItem>,
      default: () => {}
    },
    datas: {
      type: Object as PropType<EarlyTypes>,
      default: () => {}
    },
    status: {
      type: Number as PropType<any>
    }
  },
  emits: ['change', 'close', 'getCashOutValueData', 'combineLoadData'],
  setup(props, { emit }) {
    const {
      betStore,
      earlyStore,
      mainCashout,
      mainPartialCashout,
      getCashOutValues,
      mainAutoCashout,
      delAutoCashout,
      SETTLE_TABS,
      state,
      onTabChange,
      errorMessages
    } = useBettingRecord();
    const parlayData = computed(() => props.data?.parlayList || []);
    const parlayDatas = computed(() => props.datas?.parlayList || []);
    const { t } = useI18n();
    const number = ref({});

    const isShow = ref(false);
    watch(
      () => {
        return earlyStore.value;
      },
      value => {
        number.value = {};
        value.forEach(item => {
          number.value[item.orderId] = item.stake;
        });
      }
    );

    const backTop = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };

    const buttonLoad = async (items: any) => {
      if (items?.isFinished) return;

      const l = Laoding.show({
        text: t('lang.sport_order_earlySettlementLoading' /**提前结算更新中 */)
      });
      const res = await mainCashout({
        betOrderNo: items.orderId,
        cashOutValue: items.cashoutValue
      });

      Laoding.hide(l);

      if (res[0]) {
        emit('combineLoadData', [items, res[0]]);
      } else {
        Toast(errorMessages[+res[2]]);
      }
    };
    const visible = ref(false);
    const partialCashout = ref({} as any);
    const partialLoad = async (items: any) => {
      if (partialCashout.value && partialCashout.value.partialCashoutStake === items.partialCashoutStake) return;
      visible.value = true;
      partialCashout.value = items;
    };
    const handleOk = async () => {
      visible.value = false;
      const l = Laoding.show({
        text: t('lang.sport_order_partialSettlementLoading')
      });
      await getCashOutValues();
      const res = await mainPartialCashout({
        betOrderNo: partialCashout.value.orderId,
        currentCashoutValue: partialCashout.value.cashoutValue,
        partialCashoutStake: partialCashout.value.partialCashoutStake,
        stake: partialCashout.value.stake
      });

      if (res[0]) {
        emit('combineLoadData', 0);

        Toast(t('lang.sport_common_success'));
      } else {
        Toast(errorMessages[+res[2]]);
      }
      Laoding.hide(l);
    };

    const setAutoCashedout = (items: any) => {
      if (!items.isAutoCashedout) {
        autoLoad(items);
      } else {
        deleteLoad(items);
      }
    };
    const autoLoad = async (items: any) => {
      if (!items?.autoCashOutValueThreshold) {
        Toast('输入不能为空');
        return;
      }
      const l = Laoding.show({
        text: '自动结算设置加载中... '
      });
      getCashOutValues();
      setTimeout(async () => {
        Laoding.hide(l);

        const res = await mainAutoCashout({
          betOrderNo: items.orderId,
          autoCashOutValueThreshold: items.autoCashOutValueThreshold
        });
        if (res[0]) emit('getCashOutValueData');
        else {
          Toast(errorMessages[+res[2]]);
        }
      }, 1000);
    };

    const deleteLoad = async (items: any) => {
      const l = Laoding.show({
        text: '自动结算终止中... '
      });
      getCashOutValues();
      setTimeout(async () => {
        Laoding.hide(l);
        const res = await delAutoCashout({
          betOrderNo: items.orderId
        });
        if (res[0]) emit('getCashOutValueData');
      }, 1000);
    };

    const singleLoad = () => {
      isShow.value = !isShow.value;
    };

    // 复制
    const handleClickCopy = (txt: string) => {
      const { isSuccessRef } = useCopyToClipboard(txt);
      if (isSuccessRef.value) {
        message.success({
          content: t('lang.sport_common_copySuccessfully')
        });
      }
    };
    const onKeydown = element => {
      if (element.code === 'KeyE') {
        element.preventDefault();
      }
    };
    const localValue = ref('') as any;
    const onInput = element => {
      const value = element.target.value;
      const reg = /^((\d+)|(\d+((\.{1,1})|(\.{1,1}\d{0,2}))))$/; // 数字正则输入
      if ((reg.test(value) || value === '') && value.length < 10) {
        localValue.value = value;
        emit('change', { key: 'autoCashOutValueThreshold', value: +value });
      } else {
        let tureValue = value;
        for (let i = value.length - 1; i >= 0; i--) {
          tureValue = tureValue.slice(0, i);
          if (reg.test(tureValue) || tureValue === '') {
            localValue.value = tureValue;
            emit('change', { key: 'autoCashOutValueThreshold', value: +tureValue });
            break;
          }
        }
      }
    };
    const onChange = element => {
      // 有效值输入
      let value = element.target.value;
      const reg = /(^[1-9]([0-9]){0,}((\.)|(\.[0-9]{1,5}){0,1})$)|(^0$)/; // 数字正则
      // 检查值是否大于最大 或 者大于钱包
      if (Number(value) < props.datas?.stake) {
        value = +props.datas?.stake;
      }
      if (Number(value) > +props.datas.currentPossibleWinning) {
        value = props.datas?.currentPossibleWinning;
      }
      if (reg.test(Number(value) + '') || value === '') {
        if (typeof value === 'string' && value.includes('.')) {
          localValue.value = value;
          emit('change', { key: 'autoCashOutValueThreshold', value: +value });
        } else {
          localValue.value = Number(value);
          emit('change', { key: 'autoCashOutValueThreshold', value: Number(value) });
        }
      } else {
        localValue.value = '';
        emit('change', { key: 'autoCashOutValueThreshold', value: '' });
      }
    };
    watch(
      () => props.datas?.autoCashOutValueThreshold,
      val => {
        localValue.value = val;
      },
      {
        immediate: true
      }
    );
    watch(
      () => props.datas?.cashoutThreshold,
      val => {
        localValue.value = val;
      },
      {
        immediate: true
      }
    );
    localValue.value = props.datas?.autoCashOutValueThreshold || props.datas?.cashoutThreshold;
    // value={`${props.datas?.autoCashOutValueThreshold || props.datas?.cashoutThreshold}`}
    //处理国际化;
    const mulTypeToName = (mulType: string) => {
      switch (mulType) {
        case 'Doubles':
          return `2${t('lang.sport_bet_connect')}1`;
        case 'Trebles':
          return `3${t('lang.sport_bet_connect')}1`;
        case 'Trixie':
          return `3${t('lang.sport_bet_connect')}4`;
        case 'Lucky7':
          return `幸运7`;
        case 'Patent':
          return `幸运7`;
        case 'Fold4':
          return `4${t('lang.sport_bet_connect')}1`;
        case 'Yankee':
          return `洋基`;
        case 'Lucky15':
          return `幸运15`;
        case 'Fold5':
          return `5${t('lang.sport_bet_connect')}1`;
        case 'Canadian':
          return `超级美国佬`;
        case 'Lucky31':
          return `幸运31`;
        case 'Fold6':
          return `6${t('lang.sport_bet_connect')}1`;
        case 'Heinz':
          return `亨氏`;
        case 'Lucky63':
          return `幸运63`;
        case 'Fold7':
          return `7${t('lang.sport_bet_connect')}1`;
        case 'SuperHeinz' || 'Super Yankee':
          return `超级亨氏`;
        case 'Lucky127':
          return `幸运127`;
        case 'Fold8':
          return `8${t('lang.sport_bet_connect')}1`;
        case 'Goliath':
          return `大亨`;
        case 'Lucky255':
          return `幸运255`;
        case 'Fold9':
          return `9${t('lang.sport_bet_connect')}1`;
        case 'Fold10':
          return `10${t('lang.sport_bet_connect')}1`;
        case 'SuperYankee':
          return `5串26`;
        case 'Super Yankee':
          return `5串26`;
        case 'Block9':
          return `9${t('lang.sport_bet_connect')}520`;
        case 'Block10':
          return `10${t('lang.sport_bet_connect')}1013`;
        default:
          return mulType;
      }
    };

    const findOddsName = (oddsType: string) => {
      const match = betStore.betTypes.filter(betItem => betItem.bettype == oddsType);
      const matchedBettypeName = match[0]?.bettypeName;
      return matchedBettypeName ? matchedBettypeName : oddsType;
    };
    const betAmountInfoRender = (data: any) => (
      <div class="bet-amount-info">
        <div class="amount-box">
          <div class="amount-title">{t('lang.sport_bet_betAmount')}</div>
          <div class="amount">{data?.stake || '--'}</div>
        </div>
        <div class="amount-box">
          <div class="amount-title">
            {data?.ticketStatus <= 2 ? t('lang.sport_bet_winAmount') : t('lang.sport_orders_winLost')}
          </div>
          <div class="amount">{data?.ticketStatus > 2 ? data?.winLostAmount : data?.winLostAmount || '--'}</div>
        </div>
      </div>
    );
    const EarlyRender = () => (
      <>
        <div class={['betting-result-items']}>
          <div class="bet-title">
            <span>
              {props.datas?.currentPossibleWinning} {props.datas?.betType}
            </span>
          </div>
          <div class="black-text">
            {props.datas?.ticketStatus > 2 && (props.datas?.homeScore || props.datas?.awayScore) ? (
              <span>
                {t('lang.sport_tabbar_result')}
                {`${props.datas?.homeScore || '--'}-${props.datas?.awayScore || '--'}`}
              </span>
            ) : null}
          </div>
          <div class="bet-match-info-single">
            <div class="title-text">
              <span>{props.datas?.keyName}</span>
              <span>{props.datas?.odds || '--'}</span>
            </div>
            <div class="black-text">
              <div>{props.datas.betTypeName}</div>
            </div>

            <div class="time-match">
              {props.datas?.isChampion ? (
                <span>{props.datas?.leagueName}</span>
              ) : (
                <span>
                  {props.datas?.homeName && props.datas?.awayName
                    ? `${props.datas?.homeName} vs ${props.datas?.awayName}`
                    : props.datas?.leagueName}
                </span>
              )}
              <span class="match-time">{props.datas?.matchTime || '--'}</span>
            </div>
          </div>
          {betAmountInfoRender(props.datas)}
          <div class="bet-match-info-footer">
            <div>
              {toTime(props.datas?.transactionTime, 'YYYY-MM-DD HH:mm:ss') || '--:--'}(
              {t(props.datas?.oddsTypeName_i18n)})
            </div>
            <div>
              <span>
                {t('lang.sport_orders_orderNumber')}: {props.datas?.id || '--'}{' '}
              </span>
              {props.datas?.id ? (
                <>
                  <span class="copy-text-single" onClick={() => handleClickCopy(props.datas?.id)}>
                    {t('lang.sport_orders_copy')}
                  </span>
                </>
              ) : null}
            </div>
          </div>
          <div class="btn-box">
            <div class={['btn', props.datas?.isFinished ? '' : 'green']} onClick={() => buttonLoad(props.datas)}>
              <SvgIcon class="new-icon" name="cancel" />
              {props.datas?.isFinished
                ? `${t('lang.sport_order_cashedSuccess' /**兑现成功 */)} ${props.datas.cashoutValue}`
                : `${t('lang.sport_order_earlySettlement' /**提前结算 */)} ${props.datas.cashoutValue}`}
            </div>
            <div class="option" v-show={!props.datas?.isFinished} onClick={() => singleLoad()}>
              {!isShow.value && !!props.datas?.isAutoCashedout ? <Badge class="badge-new" dot /> : null}
              <SvgIcon class="new-icon" name="option2" />
            </div>
          </div>
          {isShow.value && !props.datas?.isFinished ? (
            <div class="settle-option">
              <ul class={'settle-option-table'}>
                {SETTLE_TABS.map((item, index) => (
                  <li
                    class={['settle-option-item', index === state.settleActiveTab ? 'active' : null]}
                    key={index + '_TY_KEY'}
                    v-show={item.value === 5}
                    onClick={() => onTabChange({ key: 'settleActiveTab', index: index }, backTop)}
                  >
                    <span>{t(item.label)}</span>
                  </li>
                ))}
              </ul>
              <div class="settle-option-content">
                {state.settleActiveTab == 0 ? (
                  <div class="settle-option-slider">
                    <div class="settle-option-slider-left">{0.01}</div>
                    <div class="settle-option-slider-mid">
                      <div class={'ant-slider-track'}></div>
                      <Slider
                        min={0.01}
                        max={props.datas?.cashoutValue}
                        step={0.01}
                        v-model={number.value[props.datas?.orderId]}
                        disabled={props.datas?.isFinished}
                        onAfterChange={(value: any) => {
                          emit('change', {
                            key: 'partialCashoutStake',
                            value: value.toFixed(2)
                          });
                          partialLoad(props.datas);
                        }}
                      />
                    </div>
                    <div class="settle-option-slider-right">{props.datas?.cashoutValue}</div>
                  </div>
                ) : null}
                {state.settleActiveTab == 1 ? (
                  <>
                    <div class="settle-option-ranges">
                      <div class="settle-option-ranges-tips">
                        {t('lang.sport_bet_limit' /**限额 */)}: {props.datas?.stake} ~{' '}
                        {props.datas?.currentPossibleWinning}
                      </div>
                      <div class="settle-option-ranges-label">
                        {t('lang.sport_order_autoCashedAmount' /**自动结算金额 */)}：
                      </div>
                      <input
                        class="settle-option-ranges-input"
                        type="number"
                        placeholder={t('lang.sport_bet_settingStake')}
                        disabled={props.datas?.cashoutThreshold}
                        value={localValue.value}
                        onChange={onChange}
                        onInput={onInput}
                        onKeydown={onKeydown}
                      ></input>
                    </div>
                    <div
                      class={['btn', props.datas?.isAutoCashedout ? '' : 'green']}
                      onClick={() => setAutoCashedout(props.datas)}
                    >
                      <>
                        {props.datas?.isAutoCashedout
                          ? t('lang.sport_order_cancelAutoCashed' /**取消自动结算 */)
                          : t('lang.sport_order_openAutoCashed' /**开启自动结算 */)}
                      </>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
    const EarlyParlayRender = () => (
      <>
        <div class={['betting-result-items']}>
          <div class="bet-title">
            <span>
              {props.datas?.currentPossibleWinning}
              {'     '} {props.datas.parlayCout || 0}
              {t('lang.sport_bet_gamsCouter')}:{mulTypeToName(props.datas.comboType)}{' '}
            </span>
          </div>
          <div class="black-text">
            {props.datas?.ticketStatus > 2 && (props.datas?.homeScore || props.datas?.awayScore) ? (
              <span>
                {t('lang.sport_tabbar_result')}
                {`${props.datas?.homeScore || '--'}-${props.datas?.awayScore || '--'}`}
              </span>
            ) : null}
          </div>
          <div>
            {parlayDatas.value.map(item => (
              <div class="bet-match-info-single">
                <div class="title-text">
                  <span>{item.keyName}</span>
                  <span>{item.odds || '--'}</span>
                </div>
                <div class="black-text">
                  <div>{findOddsName(item.betType)}</div>
                </div>

                <div class="time-match">
                  {item.isChampion ? (
                    <span>{item.leagueName}</span>
                  ) : (
                    <span>
                      {item.homeName && item.awayName ? `${item.homeName} vs ${item.awayName}` : item.leagueName}
                    </span>
                  )}
                  <span class="match-time">{item.matchTime || '--'}</span>
                </div>
              </div>
            ))}
          </div>
          {betAmountInfoRender(props.datas)}
          <div class="bet-match-info-footer">
            <div>
              {toTime(props.datas?.transactionTime, 'YYYY-MM-DD HH:mm:ss') || '--:--'}(
              {t(props.datas?.oddsTypeName_i18n)})
            </div>
            <div>
              <span>
                {t('lang.sport_orders_orderNumber')}: {props.datas?.id || '--'}{' '}
              </span>
              {props.datas?.id ? (
                <>
                  <span class="copy-text-single" onClick={() => handleClickCopy(props.datas?.id)}>
                    {t('lang.sport_orders_copy')}
                  </span>
                </>
              ) : null}
            </div>
          </div>
          <div class="btn-box">
            <div class={['btn', props.datas?.isFinished ? '' : 'green']} onClick={() => buttonLoad(props.datas)}>
              <SvgIcon class="new-icon" name="cancel" />
              {props.datas?.isFinished
                ? `${t('lang.sport_order_cashedSuccess' /**兑现成功 */)} ${props.datas.cashoutValue}`
                : `${t('lang.sport_order_earlySettlement' /**提前结算 */)} ${props.datas.cashoutValue}`}
            </div>
            <div class="option" v-show={!props.datas?.isFinished} onClick={() => singleLoad()}>
              {!isShow.value && !!props.datas?.isAutoCashedout ? <Badge class="badge-new" dot /> : null}
              <SvgIcon class="new-icon" name="option2" />
            </div>
          </div>
          {isShow.value && !props.datas?.isFinished ? (
            <div class="settle-option">
              <ul class={'settle-option-table'}>
                {SETTLE_TABS.map((item, index) => (
                  <li
                    class={['settle-option-item', index === state.settleActiveTab ? 'active' : null]}
                    key={index + '_TY_KEY'}
                    v-show={item.value === 5}
                    onClick={() => onTabChange({ key: 'settleActiveTab', index: index }, backTop)}
                  >
                    <span>{t(item.label)}</span>
                  </li>
                ))}
              </ul>
              <div class="settle-option-content">
                {state.settleActiveTab == 0 ? (
                  <div class="settle-option-slider">
                    <div class="settle-option-slider-left">{0.01}</div>
                    <div class="settle-option-slider-mid">
                      <div class={'ant-slider-track'}></div>
                      <Slider
                        min={0.01}
                        max={props.datas?.cashoutValue}
                        step={0.01}
                        v-model={number.value[props.datas?.orderId]}
                        disabled={props.datas?.isFinished}
                        onAfterChange={(value: any) => {
                          emit('change', {
                            key: 'partialCashoutStake',
                            value: value.toFixed(2)
                          });
                          partialLoad(props.datas);
                        }}
                      />
                    </div>
                    <div class="settle-option-slider-right">{props.datas?.cashoutValue}</div>
                  </div>
                ) : null}
                {state.settleActiveTab == 1 ? (
                  <>
                    <div class="settle-option-ranges">
                      <div class="settle-option-ranges-tips">
                        {t('lang.sport_bet_limit' /**限额 */)}: {props.datas?.stake} ~{' '}
                        {props.datas?.currentPossibleWinning}
                      </div>
                      <div class="settle-option-ranges-label">
                        {t('lang.sport_order_autoCashedAmount' /**自动结算金额 */)}：
                      </div>
                      <input
                        class="settle-option-ranges-input"
                        type="number"
                        placeholder={t('lang.sport_bet_settingStake')}
                        disabled={props.datas?.cashoutThreshold}
                        value={localValue.value}
                        onChange={onChange}
                        onInput={onInput}
                        onKeydown={onKeydown}
                      ></input>
                    </div>
                    <div
                      class={['btn', props.datas?.isAutoCashedout ? '' : 'green']}
                      onClick={() => setAutoCashedout(props.datas)}
                    >
                      <>
                        {props.datas?.isAutoCashedout
                          ? t('lang.sport_order_cancelAutoCashed' /**取消自动结算 */)
                          : t('lang.sport_order_openAutoCashed' /**开启自动结算 */)}
                      </>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );

    const SingleRender = () => (
      <>
        <div class={['betting-result-items', props.data?.ticketTag]}>
          {props.status == 2 || props.status == 0 ? <div class="tag"></div> : null}
          <div class="bet-title">
            <span>
              {props.data?.stake} {props.data?.betType}
            </span>{' '}
            <span class="ticket-tag">{t(props.data.ticketTag_i18n || '')}</span>
          </div>

          <div class="bet-match-info-single">
            <div class="title-text">
              <span>{props.data.keyName}</span>
              <span>{props.data.odds || '--'}</span>
            </div>
            <div class="black-text">
              <div>{props.data.betType}</div>
            </div>

            <div class="time-match">
              {props.data?.isChampion ? (
                <span>{props.data?.leagueName}</span>
              ) : (
                <span>
                  {props.data?.homeName && props.data?.awayName
                    ? `${props.data?.homeName} vs ${props.data?.awayName}`
                    : props.data?.leagueName}
                </span>
              )}
              <span class="match-time">{props.data?.matchTime || '--'}</span>
            </div>
          </div>
          {betAmountInfoRender(props.data)}
          <div class="bet-match-info-footer">
            <div>
              {toTime(props.data?.transactionTime, 'YYYY-MM-DD HH:mm:ss') || '--:--'}({t(props.data?.oddsTypeName_i18n)}
              )
            </div>
            <div>
              <span>
                {t('lang.sport_orders_orderNumber')}: {props.data?.id || '--'}{' '}
              </span>
              {props.data?.id ? (
                <>
                  <span class="copy-text-single" onClick={() => handleClickCopy(props.data?.id)}>
                    {t('lang.sport_orders_copy')}
                  </span>
                </>
              ) : null}
              {!!props.data.isCashedout ? (
                <div>
                  <span class="settled-time">
                    <div class="gray">{t('lang.sport_common_state')}:</div>
                    {t('lang.sport_order_cashed')} {toTime(props.data.cashedoutDate, 'YYYY-MM-DD HH:mm:ss')}{' '}
                    <span class="red">
                      {t('lang.sport_order_amount')}：{props.data.cashoutValue}
                    </span>
                  </span>
                </div>
              ) : null}
              {!!props.data.isPartialCashedout ? (
                <div>
                  <span class="settled-time">
                    <div class="gray">{t('lang.sport_common_state')}:</div>
                    <div>
                      {props.data.partialCashedoutData.map(subItem => (
                        <div>
                          {' '}
                          {t('lang.sport_order_cashed')} {toTime(subItem.partialCashedOutDate, 'YYYY-MM-DD HH:mm:ss')}{' '}
                          <span class="red">
                            {t('lang.sport_order_amount')}：{subItem.cashoutValue}
                          </span>
                        </div>
                      ))}
                    </div>
                  </span>
                </div>
              ) : null}
              {/*{!!props.data.isAutoCashedout && !props.data.isPartialCashedout && !props.data.isCashedout ? (
                <div>
                  <span class="settled-time">
                    <div class="gray">{t('lang.sport_common_state')}:</div>
                    {t('lang.sport_order_cashed')} {toTime(props.data.settlementTime, 'YYYY-MM-DD HH:mm:ss') || '--:--'}{' '}
                    <span class="red">
                      {t('lang.sport_order_amount')}：{currency(+props.data.stake + +props.data.winLostAmount)}
                    </span>
                  </span>
                </div>
              ) : null}*/}
              <div>
                <span class="settled-time" v-show={!!props.data.settlementTime}>
                  <div class="gray">{t('lang.sport_order_settlementTime')}:</div>
                  {props.data.settlementTime}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );

    const ParlayRender = () => (
      <div>
        <div class={['betting-result-items', props.data?.ticketTag]}>
          {props.status == 2 || props.status == 0 ? <div class="tag"></div> : null}
          <div class="bet-title">
            <span>
              {props.data?.stake}
              {props.data.parlayCout || 0}
              {t('lang.sport_bet_gamsCouter')}:{mulTypeToName(props.data.comboType)}{' '}
            </span>
            <span>{t(props.data?.ticketTag_i18n)} </span>
          </div>

          <div>
            {parlayData.value.map(item => (
              <div class="bet-match-info-single" key={item.parlayId}>
                <div class="title-text">
                  <span>{item.keyName}</span>
                  <span>{item.odds || '--'}</span>
                </div>
                <div class="black-text">
                  <div>{findOddsName(item.betType)}</div>
                </div>

                <div class="time-match">
                  {item?.isChampion ? (
                    <span>{item?.leagueName}</span>
                  ) : (
                    <span>
                      {item?.homeName && item?.awayName ? `${item?.homeName} vs ${item?.awayName}` : item?.leagueName}
                    </span>
                  )}
                  <span class="match-time">{item?.matchTime || '--'}</span>
                </div>
                <div class="black-text">
                  {props.data?.ticketStatus > 2 && (props.data?.homeScore || props.data?.awayScore) ? (
                    <span>
                      {t('lang.sport_tabbar_result')}
                      {`${props.data?.homeScore || '--'}-${props.data?.awayScore || '--'}`}
                    </span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          {betAmountInfoRender(props.data)}
          <div class="bet-match-info-footer">
            <div>
              {toTime(props.data?.transactionTime, 'YYYY-MM-DD HH:mm:ss') || '--:--'}({t(props.data?.oddsTypeName_i18n)}
              )
            </div>
            <div>
              <span>
                {t('lang.sport_orders_orderNumber')}: {props.data?.orderId || '--'}{' '}
              </span>
              {props.data?.orderId ? (
                <>
                  <span class="copy-text-single" onClick={() => handleClickCopy(props.data?.orderId)}>
                    {t('lang.sport_orders_copy')}
                  </span>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );

    return () => (
      <>
        <div>{props.data && !parlayData.value?.length ? <SingleRender /> : null}</div>
        <div>{props.data && parlayData.value?.length ? <ParlayRender /> : null}</div>
        <div>{props.datas && !parlayDatas.value?.length ? <EarlyRender /> : null}</div>
        <div>{props.datas && parlayDatas.value?.length ? <EarlyParlayRender /> : null}</div>
        <Modal
          class="tips-modal"
          cancelText={t('lang.sport_common_cancel')}
          okText={t('lang.sport_filterLeague_sure' /*确认 */)}
          width={320}
          closable={false}
          v-model:visible={visible.value}
          onOk={() => handleOk()}
        >
          <div>{t('lang.sport_order_partialAmount')}</div>
          <div class="stake">{partialCashout.value.partialCashoutStake}</div>
        </Modal>
      </>
    );
  }
});
