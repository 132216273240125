import { computed, defineComponent } from 'vue';

import { sportIcons } from './icons';

export default defineComponent({
  name: 'SportIcon',
  props: {
    sportId: {
      type: Number,
      default: 1
    },
    size: {
      type: String,
      default: ''
    },
    gray: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const style = computed(() => props.size && `width: ${props.size || 16}px;height: ${props.size || 16}px`);

    const src = computed(() => {
      if (!sportIcons[props.sportId]) {
        return props.gray ? sportIcons[1].gray : sportIcons[4].general;
      }
      const img = props.gray ? sportIcons[props.sportId].gray : sportIcons[props.sportId].general;
      return img;
    });

    return () => (
      <div class="sport-icon">
        <img src={src.value} style={style.value} />
      </div>
    );
  }
});
