import dsbridge from 'dsbridge';

export interface Response<T = any> {
  /**
   * @enum 1 成功
   * @enum 0 失败
   */
  code: 1 | 0;
  /**
   * 失败的信息
   */
  message: string;
  /**
   * 接口的返回数据
   */
  data: T;
}

export interface AutoLogin {
  /**
   * autoLogin：是否自动跳登录  false，app提示登录
   */
  autoLogin: boolean;
}

/**
 * 根据userAgent识别App信息
 * @returns {false|{isApp,merchat,version,platform}}
 */

/**
 * proxyCall
 */
function proxyCall(name: string, params?: any, callback?: any): Promise<any> {
  return new Promise(function (resolve, reject) {
    dsbridge.call(name, params, function (res) {
      if (callback) {
        callback(res, resolve, reject);
      } else {
        if (typeof res === 'string') {
          res = JSON.parse(res);
        }
        if (res === undefined) {
          resolve(res);
        } else if (res.code === 1) {
          resolve(res);
        } else {
          reject(res);
        }
      }
      console.log('APP data:', res);
    });
  });
}

/**
 * 跳转到原生页面
 * @callName jumpToNativePage
 * @demo
 * jumpToNativePage({
 *   type: "userCenter",
 *   data: undefined,
 * });
 */
function jumpToNativePage(params: any, callback?: any) {
  return proxyCall('jumpToNativePage', params, callback);
}

/**
 * 跳转到原生app登录页，登录成功会触发onLoginSuccess事件
 * autoLogin：是否自动跳登录
 * @callName jumpToNativeLogin
 * @param {boolean} autoLogin false，app提示登录
 */
function jumpToNativeLogin(params: AutoLogin) {
  return proxyCall('jumpToNativeLogin', params);
}

export { jumpToNativePage, jumpToNativeLogin };
