import { defineComponent, computed, onMounted, watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isEmpty } from 'lodash-es';
import { SportTypeSide, ChampList, MarketList, RecommendedMatch, WeekCalendar } from './components';
import { Layout, LayoutSider } from 'ant-design-vue';
import { useEventsAndMarkets, useSportTypes, useI18n, EventsChampMarket } from '@/hooks';
import { LeagueFilter, ShoppingCart } from 'kg-web/components';
import { DataTypeEnum, queryToStr, RouteEnum } from '@/common';
import { useBetWeb } from '@/hooks/useBetWeb.hook';
// import Orders from '../orders';
import OrdersNew from '../orders-new';
import style from './home.module.less';

export default defineComponent({
  name: 'JBBsportHome',
  setup() {
    const { eventStore, getEventsAndMarkets } = useEventsAndMarkets();
    const { eventChamp, getEventsAndChamp } = EventsChampMarket();
    const { onWeekDayChange } = useSportTypes();
    const { t } = useI18n();
    const { prebetInfo } = useBetWeb();
    const route = useRoute();
    const router = useRouter();
    const showLeagueFilter = ref(false);

    const periodId = computed(() => Number(route.query.periodId || 3));
    const favoriteComp = computed(() => Number(route.query.favorite) === 1);
    onMounted(async () => {
      if (isEmpty(route.query)) {
        redirect();
      }
      eventStore.form = Object.assign(eventStore.form, route.query);
      await getMarketsData(route.query);
    });
    const redirect = () => {
      const queryObj = {
        sportId: 1,
        periodId: 3
      };
      const queryStr = queryToStr(queryObj);
      router.replace(RouteEnum.ROOT + queryStr);
    };
    const onLeagueFilterChange = async () => {
      await getEventsAndMarkets(true);
    };

    const filterToggle = () => {
      showLeagueFilter.value = !showLeagueFilter.value;
    };
    const tabAct = ref(0);
    const getMarketsData = async (query: any, isPull = false, isLoad = false) => {
      if (!query.sportId || route.path !== '/' || favoriteComp.value) {
        eventStore.marketList = [];
        setTimeout(() => {
          eventStore.loading = false;
        }, 1000);
        return;
      }
      eventStore.form.sportId = Number(query.sportId);
      eventStore.form.selectDate = query.selectDate ? Number(query.selectDate) : null;
      if (Number(query.periodId) === DataTypeEnum.Followed) {
        eventStore.form.periodId = DataTypeEnum.Followed;
        await getEventsAndMarkets(isPull, false, Number(query.periodId));
      } else if (Number(query.periodId) === DataTypeEnum.championLeague) {
        eventChamp.form.sportId = Number(query.sportId);
        await getEventsAndChamp(true);
      } else {
        eventStore.form.periodId = Number(query.periodId) || DataTypeEnum.Live;
        eventStore.form.eventIds = [];
        await getEventsAndMarkets(isPull, isLoad, Number(query.periodId));
      }
    };
    watch(
      () => route.query,
      async (urlQuerys: { selectDate?: string; periodId: string; sportId: string }) => {
        eventStore.form.leagueIds = [];
        if (isEmpty(route.query)) {
          redirect();
        } else {
          getMarketsData(urlQuerys);
        }
      }
    );
    watch(
      () => prebetInfo.value.length,
      () => {
        tabAct.value = 0;
      }
    );
    return () => (
      <Layout class={style.container}>
        <LayoutSider>
          <SportTypeSide />
        </LayoutSider>
        <Layout class={style.body}>
          {periodId.value === DataTypeEnum.Early ? <WeekCalendar onChange={day => onWeekDayChange(day)} /> : null}
          <RecommendedMatch
            periodId={periodId.value}
            v-show={[DataTypeEnum.Live, DataTypeEnum.Today, DataTypeEnum.championLeague].includes(periodId.value)}
          />
          <Layout class={style.main}>
            {periodId.value === DataTypeEnum.championLeague ? (
              <ChampList
                ChampData={eventChamp.champList}
                loading={eventChamp.loading}
                leagueCounter={eventChamp.form.leagueIds?.length}
                onOpenLeague={() => filterToggle()}
              />
            ) : (
              <MarketList
                marketData={eventStore.marketList}
                loading={eventStore.pageLoading}
                requestFinash={eventStore.requestFinash}
                requestLoading={eventStore.requestLoading}
                onLoadMore={() => getMarketsData(route.query, false, true)}
                leagueCounter={eventStore.form.leagueIds?.length}
                onOpenLeague={() => filterToggle()}
              />
            )}

            {showLeagueFilter.value ? (
              <div class={style.leaguePop}>
                <LeagueFilter
                  v-model={eventStore.form.leagueIds}
                  sportId={eventStore.form.sportId}
                  onConfirm={onLeagueFilterChange}
                  onClose={() => filterToggle()}
                />
              </div>
            ) : null}
          </Layout>
        </Layout>
        <LayoutSider width={350}>
          <div class={style.tabs}>
            <div class={[style.btn, !tabAct.value && style.btnAct]} onClick={() => (tabAct.value = 0)}>
              {t('lang.sport_bet_bettingSlip')} {prebetInfo.value.length ? prebetInfo.value.length : ''}
            </div>
            <div class={[style.btn, tabAct.value && style.btnAct]} onClick={() => (tabAct.value = 1)}>
              {t('lang.sport_common_bettingRecords')}
            </div>
          </div>
          {!tabAct.value ? <ShoppingCart /> : null}
          {tabAct.value ? <OrdersNew /> : null}
        </LayoutSider>
      </Layout>
    );
  }
});
