// ===================
// ======沙巴体育======
// ===================

import { http, httpSB } from '@/common';
import { HttpResponse } from '#/global';
import qs from 'qs';

// const _isDev = process.env.NODE_ENV === 'development';

import {
  LeagueItem,
  EventSummary,
  PlaceBetPrams,
  LeagueListPrams,
  MatchEventsData,
  SingleTicketData,
  MatchEventsPrams,
  MacthMarketInfoPrams,
  EventsAndMarketsPrams,
  RecommendedMatchData,
  ParlayTicketPrams,
  SingleTicketPrams,
  FinishedMatchItem,
  PlaceParlayBetData,
  PlacePreBetData
} from '@/services';
import { useGameSet } from '@/hooks/useGameSet';
import { SourceType } from '@/types/config';
const { currentBetOption } = useGameSet();

/**
 * 02:获取赛事数量（供大厅使用）
 * @description: 可以获取到滚球、早盘、今日等类目的体育项目类型数量
 * @param language 语种
 * @return {Promise<HttpResponse>}
 */
const getEventSummary = ({ language }: { language: string }): Promise<HttpResponse> => {
  return httpSB.get<HttpResponse<EventSummary[]>>('/sports/sb/player/getEventSummary?' + qs.stringify({ language }));
};

/**
 * 03:获取赛事和市场数据
 * @description: 传体育运动项目id、赛事区间、赛事id取得当下的赛事盘口信息
 * @param {number} oddsType 盘口类型 1、马来盘 2、 香港盘 3、欧洲盘 4、印度尼西亚盘 5、美国盘
 * @param {string} sportId 体育项目ID
 * @param {number} periodId 赛事区间 1: Today 今日(预设) 、2: Early 早盘 、3: Live 滚球
 * @param {string} leagueIds 联赛ids,以,隔开
 * @param {number} selectDate 查询向后指定天数偏移的matches(赛事)数据，传值范围：1-7，当periodId传值为2时，此值才会生效。
 * @param {string} eventIds 指定赛事 ID 来获取赛事状态(包括半场比分数据)
 * @return {Promise<HttpResponse>}
 */

const queryEventsAndMarkets = ({
  sportId,
  periodId,
  leagueIds,
  selectDate,
  eventIds,
  queryCount,
  isParlay,
  betTypeIds
}: EventsAndMarketsPrams): Promise<HttpResponse> => {
  const pram = {
    sportId,
    periodId: periodId === 4 ? 3 : periodId, // 串关选项卡默认 参数3(滚球)
    leagueIds,
    selectDate,
    eventIds,
    isParlay,
    betTypeIds,
    queryCount
  };
  if (periodId === -1) {
    pram.periodId = 0;
  }
  return http.post<HttpResponse<any>>('/sports/sb/player/queryEventsAndMarkets', pram);
};

/**
 * 获取赛事列表补充信息
 *
 * @param {string} eventIds 赛事ID  ，隔开
 * @return {*}
 */
const getMatchInfoByIds = (eventIds: string) => {
  return http.post<HttpResponse<any>>('/sports/sb/player/getGameDetail', {
    eventIds
  });
};

/**
 * @description:
 * @param {string} gameId 体育id
 * @param {string} startTime 开始时间
 * @param {string} endTime 结束时间
 * @return {*}
 */
const getShaBaResults = (prams: {
  gameId?: number | string;
  startTime: string;
  endTime: string;
}): Promise<HttpResponse> => {
  return http.get<HttpResponse<{ resultsInfo: FinishedMatchItem[] }>>(
    '/sports/match/player/shabaResults?' + qs.stringify(prams)
  );
};

/**
 * 04:赛事盘口信息
 * @description: 传入sportId(体育运动项目id)、marketType(赛事区间)取得当下的赛事盘口信息
 * @param {string} sportId 体育项目ID 1、足球 2、篮球 3、美式足球 4、冰上曲棍球 5、网球 6、排球
 * @param {number} marketType 赛事区间 1: Today 今日(预设) 、2: Early 早盘 、3: Live 滚球
 * @param {string} eventIds 指定赛事 ID 来获取赛事状态(包括半场比分数据)
 * @return {Promise<HttpResponse>}
 */
const queryMatchEvents = (prams: MatchEventsPrams): Promise<HttpResponse> => {
  return http.post<HttpResponse<MatchEventsData>>('/sports/sb/player/queryEvents', prams);
};

/**
 * 05:联赛列表数据
 * @description: 通过体育运动项目id、赛事区间获取联赛列表数据
 * @param {number} periodId 赛事区间 1: Today 今日(预设) 、2: Early 早盘 、3: Live 滚球
 * @param {number} sportId 体育项目ID
 * @param {number} oddsType 盘口类型 1、马来盘 2、 香港盘 3、欧洲盘 4、印度尼西亚盘 5、美国盘
 * @return {Promise<HttpResponse>}
 */
const queryLeagueList = (prams: LeagueListPrams): Promise<HttpResponse> => {
  return http.post<HttpResponse<LeagueItem[]>>('/sports/sb/player/queryLeagueList', prams);
};

/**
 * 06:单条预投注
 * @description: 用于更新单注的盘口数据
 * @param {number} sportType 体育项目ID
 * @param {string} marketId 赔率ID
 * @param {string} key 从queryEventsAndMarkets接口取得,并可参考沙巴附件-投注类型与选项
 * @param {number} oddsType 盘口类型 1、马来盘 2、 香港盘 3、欧洲盘 4、印度尼西亚盘 5、美国盘
 * @return {Promise<HttpResponse>}
 */
const getSingleTicket = ({ sportType, marketId, key, oddsType }: SingleTicketPrams): Promise<HttpResponse> => {
  return httpSB.post<HttpResponse<SingleTicketData>>('/sports/sb/player/getSingleTicket', {
    sportType,
    marketId,
    key,
    oddsType
  });
};

/**
 * 07:单条投注
 * @description: 用户单条投注
 * @param {number} sportType 体育项目ID
 * @param {string} marketId 赔率ID
 * @param {string} price 赔率
 * @param {string} stake 投注金额
 * @param {string} point 点数
 * @param {number} oddsOption 赔率选项 0:不接受盘口变更 (预设)、 1:只接受更好赔率、 2:接受任何赔率
 * @param {number} oddsType 盘口类型 0、默认赔率|1、马来赔率|2、香港赔率|3、Decimal赔率|4、印地赔率|5、美国赔率
 * @param {string} key 从queryEventsAndMarkets接口取得,并可参考沙巴附件-投注类型与选项
 * @return {Promise<HttpResponse>}
 */
const doPlaceBet = ({
  sportType,
  marketId,
  price,
  stake,
  point,
  key,
  oddsType
}: PlaceBetPrams): Promise<HttpResponse> => {
  return httpSB.post<HttpResponse<any>>('/sports/sb/player/doPlaceBet', {
    sportType,
    marketId,
    price,
    stake,
    point,
    oddsType,
    oddsOption: currentBetOption.value,
    key
  });
};

/**
 * 08:串关预投注
 * @description: 用户单条投注
 * @param {number} sportType 体育项目ID
 * @param {string} marketId 赔率ID
 * @param {string} price 赔率
 * @param {string} stake 投注金额
 * @param {string} point 点数
 * @param {number} oddsOption 赔率选项 0:不接受盘口变更 (预设)、 1:只接受更好赔率、 2:接受任何赔率
 * @param {number} oddsType 盘口类型 0、默认赔率|1、马来赔率|2、香港赔率|3、Decimal赔率|4、印地赔率|5、美国赔率
 * @param {string} key 从queryEventsAndMarkets接口取得,并可参考沙巴附件-投注类型与选项
 * @return {Promise<HttpResponse>}
 */
const getParlayTickets = (prams: SingleTicketPrams[]): Promise<HttpResponse> => {
  return httpSB.post<HttpResponse<PlacePreBetData>>('/sports/sb/player/getParlayTickets', prams);
};

/**
 * 08-a:串关投注
 * @description: 用户串关投注
 * @param {array} matches 串关投注信息，需要组装成jsonArray即数组对象传入，具体值烦请直接对照沙巴文档placeParlayBet接口
 * @param {array} combos 串关投注组合，需要组装成jsonArray即数组对象传入，具体值烦请直接对照沙巴文档placeParlayBet接口
 * @param {string} oddsOption 下注选项 0:不接受盘口变更 (预设)、 1:接受任何赔率
 * @return {Promise<HttpResponse>}
 */
const doPlaceParlayBet = (prams: ParlayTicketPrams): Promise<HttpResponse> => {
  return httpSB.post<HttpResponse<PlaceParlayBetData>>('/sports/sb/player/placeParlayBet', prams);
};

/**
 * 09: 获取推荐比赛数据
 * @description: 用户单条投注
 * @param {string} source 数据源
 * @param {string} time 时间戳
 */
const getRecommendedMatchList = (prams: SourceType): Promise<HttpResponse> => {
  return http.get<HttpResponse<RecommendedMatchData>>('/sports/nano/player/recommendedMatchList?source=' + prams);
};

/**
 * 10:获取单场比赛数据
 * @description: 用户单条投注
 * @param {string} sportId 体育项目ID
 * @param {number} marketType 赛事区间 1: Today 今日(预设) 、2: Early 早盘 、3: Live 滚球
 * @param {string} eventIds 指定赛事 ID 来获取赛事状态(包括半场比分数据)
 * @param {string} betType 投注类型ID，非必填
 * @return {Promise<HttpResponse>}
 */
const getMacthMarketInfo = ({
  sportId,
  oddsType,
  eventIds,
  marketType
}: MacthMarketInfoPrams): Promise<HttpResponse> => {
  return http.post<HttpResponse<any>>('/sports/sb/player/getMarkets', { sportId, oddsType, eventIds, marketType });
};

/**
 * @description: 查获取视频链接
 * @return {Promise<HttpResponse>}
 */
const getSportVideoByMatchID = (apiUrl: string): Promise<HttpResponse<any>> => {
  return http.get<HttpResponse<any>>(apiUrl);
};

// /api/sports/match/player/sportUnionData

export {
  doPlaceBet,
  getEventSummary,
  getSportVideoByMatchID,
  queryLeagueList,
  getSingleTicket,
  queryMatchEvents,
  getParlayTickets,
  doPlaceParlayBet,
  getMacthMarketInfo,
  queryEventsAndMarkets,
  getMatchInfoByIds,
  getRecommendedMatchList,
  getShaBaResults
};
