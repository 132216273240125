import { defineComponent, inject, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DataEmpty, TeamLogo } from 'kg-web/components';
import { get } from 'lodash-es';
import live from './live-up.module.less';

export default defineComponent({
  name: 'JBBsportLineUp',
  props: {
    lineUpData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { matchInfo, sportType } = inject('matchInfo') as any;
    const position = ref('home');
    const array = computed(() => get(props.lineUpData.lineup, position.value));
    const onClick = (team: string) => {
      position.value = team;
    };
    const isEmpty = computed(() => !Array.isArray(array.value) || array.value.length === 0);
    return () =>
      isEmpty.value ? (
        <DataEmpty title={t('lang.sport_deatils_noLineUp')} />
      ) : (
        <div class={live.page}>
          <div class={live.header}>{t('lang.sport_details_lineUp')}</div>
          <div>
            <div class={live.tabs}>
              <div
                class={`${live.tabItem} ${live.home} ${position.value === 'home' ? live.tabActive : ''}`}
                onClick={() => onClick('home')}
              >
                <TeamLogo sportId={sportType.value} teamId={matchInfo.value.HomeID} size={'xs'} />
                <span title={matchInfo.value.HomeName} class={live.teamName}>
                  {matchInfo.value.HomeName}
                </span>
              </div>
              <div
                class={`${live.tabItem} ${live.away} ${position.value === 'away' ? live.tabActive : ''}`}
                onClick={() => onClick('away')}
              >
                <span title={matchInfo.value.AwayName} class={live.teamName}>
                  {matchInfo.value.AwayName}
                </span>
                <TeamLogo sportId={sportType.value} teamId={matchInfo.value.AwayID} size={'xs'} marginLeft />
              </div>
            </div>
            <div class={`${live.liveBox} ${position.value}`}>
              {/* <div class={live.teacher}>
                <span>思维迪克，马丁</span>
                <div class={live.teach}>
                  {t('lang.sport_details_headCoach')}
                  <div class={`${live.country} ${live.tanland}`}></div>
                </div>
              </div> */}
              <div class={live.aways}>
                <div class={live.awaysHeader}>
                  {position.value === 'home' && (
                    <TeamLogo sportId={sportType.value} teamId={matchInfo.value.HomeID} size={'xs'} />
                  )}
                  <span class={live.awaysTitle}>{t('lang.sport_deatils_usuallyStarterRecently')}</span>
                  {position.value === 'away' && (
                    <TeamLogo sportId={sportType.value} teamId={matchInfo.value.AwayID} size={'xs'} marginLeft />
                  )}
                </div>
                {array.value?.map(item => (
                  <div class={live.outItem}>
                    <span class={live.outNumber}>{item.no}</span>
                    <div class={live.outBox}>
                      <span class={live.outName}>{item.name}</span>
                      {/* 后卫 */}
                      <span class={live.outDoName}>{item.doName || `--`}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
  }
});
