//LiveStats
import { defineComponent, PropType } from 'vue';
import style from './live-statistics.module.less';
import { SvgIcon } from '@/components';

import { StoreMatchInfo } from '@/hooks';
import { SportIdEnum } from '@/common';
import { get } from 'lodash-es';

export default defineComponent({
  name: 'LiveEvetsStatistics',
  props: {
    info: {
      type: Object as PropType<StoreMatchInfo>,
      default: () => ({})
    }
  },
  setup(props) {
    const FootballRender = () => (
      <>
        {get(props.info, 'tech.corner[0]') && get(props.info, 'tech.corner[1]') ? (
          <>
            <SvgIcon name="corner" />
            <span class={style.EventVal}>
              {get(props.info, 'tech.corner[0]') || ''}-{get(props.info, 'tech.corner[1]') || ''}
            </span>
          </>
        ) : null}
        {get(props.info, 'tech.ht[0]') && get(props.info, 'tech.ht[1]') ? (
          <>
            <SvgIcon name="ht" />
            <span class={style.EventVal}>
              {get(props.info, 'tech.ht[0]') || ''}-{get(props.info, 'tech.ht[1]') || ''}
            </span>
          </>
        ) : null}
      </>
    );

    const OtherSocerRender = () => {
      const formatData = props.info.sections || [[0, 0]];
      return (
        <div class={style.OtherSocerEvents}>
          {/* 测试说的乒乓球不用显示这部分 */}
          {formatData.map((item, index) => (
            <span
              v-show={index <= Number(props.info?.current)}
              class={[style.EventVal, props.info?.current === index ? style.Current : null]}
            >
              {item[0] || 0}-{item[1] || 0}
            </span>
          ))}
        </div>
      );
    };

    return () => (
      <div class={style.EvetsStatistics}>
        {props.info.sportId === SportIdEnum.Soccerball && props.info.isLive ? <FootballRender /> : <OtherSocerRender />}
      </div>
    );
  }
});
