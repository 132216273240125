export enum TechId {
  /**
   * 进球
   */
  GOAL = 1,
  /**
   * 角球
   */
  CORNER = 2,
  /**
   * 黄牌
   */
  YELLOW = 3,
  /**
   * 红牌
   */
  RED = 4,
  /**
   * 界外球
   */
  FOUL = 5,
  /**
   * 任意球
   */
  FREE = 6,
  /**
   * 球门球
   */
  GOAL_KICK = 7,
  /**
   * 点球
   */
  PENALTY = 8,
  /**
   * 换人
   */
  SUBSTITUTION = 9,
  /**
   * 比赛开始
   */
  START = 10,
  /**
   * 中场
   */
  HALF = 11,
  /**
   * 结束
   */
  END = 12,
  /**
   * 半场比分
   */
  HALF_SCORE = 13,
  /**
   * 两黄变红
   */
  TWO_YELLOW_TO_RED = 15,
  /**
   * 点球未进
   */
  PENALTY_MISSED = 16,
  /**
   * 乌龙球
   */
  OWN_GOALS = 17,
  /**
   * 助攻
   */
  ASSIST = 18,
  /**
   * 伤停补时
   */
  STOPPAGE_TIME = 19,
  /**
   * 射正
   */
  SHOOT_RIGHT = 21,
  /**
   * 射偏
   */
  SHOT_OFF = 22,
  /**
   * 进攻
   */
  ATTACK = 23,
  /**
   * 危险进攻
   */
  DANGEROUS_ATTACK = 24,
  /**
   * 控球率
   */
  POSSESSION = 25,
  /**
   * 加时赛结束
   */
  OVERTIME_IS_OVER = 26,
  /**
   * 点球大战结束
   */
  PENALTY_ENDED = 27,
  /**
   * 比赛开始时间
   */
  START_TIME = 28,
  /**
   * 比赛状态
   */
  STATUS = 29,
  /**
   * 足球比赛进行时间
   */
  SOCCERBALL_MATCHTIME = 30,
  /**
   * 篮球比赛 倒计时
   */
  BASKETBALL_MATCHTIME = 31
}
