export interface Extras {
  label: string;
  value: number | string;
}
export const keyboardExtras: any[] = [
  { label: '1,000', value: 1000 },
  { label: '5,000', value: 5000 },
  { label: '10,000', value: 10000 },
  { label: 'MAX', value: 'MAX' }
];

export const keyboardNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, 'del'] as '.'[] | string[] | number[];
