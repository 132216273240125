// 足球
enum soccerBallEventEnum {
  '1H' = '上半场',
  '2H' = '下半场',
  'HT' = '半场',
  'FT' = '全场',
  'OT' = '加时赛'
}

enum soccerBallEventEnum_i18n {
  '1H' = 'lang.sport_common_firstHarf',
  '2H' = 'lang.sport_common_secondHalf',
  'HT' = 'lang.sport_earlyHandicap_halfTime',
  'FT' = 'lang.sport_earlyHandicap_audience',
  'OT' = 'lang.sport_common_jiaShiSai',
  'NT' = 'lang.sport_common_noStart'
}

// 篮球
enum basketballEventEnum {
  '1Q' = '第一节',
  '2Q' = '第二节',
  '3Q' = '第三节',
  '4Q' = '第四节',
  'TIE' = '平局'
}

const processing_i18n = 'lang.sport_handicap_underway';

// 数字转中文 下标取
const numberToZh = [, '1st', '2nd', '3th', '4th', '5th', '6th', '7th', '8th'];

const numberToI18n = [
  ,
  'lang.sport_common_1st',
  'lang.sport_common_2nd',
  'lang.sport_common_3rd',
  'lang.sport_common_4th',
  'lang.sport_common_5th'
];

// 足球: 上半场 下半场
// 篮球: 第一节
// 网球, 排球: 第1盘
// 电竞: 第1局
// 其他: 进行中

// 沙巴时间显示格式化
// InPlayTime  适用足球(1)/篮球(2)/美式足球(3)/冰上曲棍球(4)
export function eventTimeFormat({ SportType, InPlayTime, RunTime, TennisCurrentSet, LivePeriod, LLP, IsHT }: any) {
  const timeIndex = !!InPlayTime ? InPlayTime.split(' ')[0] : '';
  const runTime = !!RunTime ? RunTime : '';
  const jinxingzhong_text = '进行中';
  // 足球
  if ([1].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return '半场 ' + runTime;
    if (!InPlayTime) return jinxingzhong_text;
    return `${soccerBallEventEnum[timeIndex]} ${runTime}`;
    // 篮球
  } else if ([2].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return '半场';
    if (Number(LLP) > 4) return '加时赛';
    if (!timeIndex && !LLP) return jinxingzhong_text;
    if (timeIndex[0] === 'OT') {
      return `加时赛  ${runTime}`;
    }
    return basketballEventEnum[timeIndex || LLP] && runTime
      ? `${basketballEventEnum[timeIndex || LLP]} ${runTime}`
      : jinxingzhong_text;
    // 网球
  } else if ([5].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return '休息';
    return !!Number(LLP)
      ? `${numberToZh[LLP]}  ${runTime}`
      : !!numberToZh[TennisCurrentSet]
      ? `${numberToZh[TennisCurrentSet]} ${runTime}`
      : jinxingzhong_text;
  } else if ([43].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return '休息';
    return !!Number(LLP) ? `${numberToZh[LLP]}  ${runTime}` : `${numberToZh[LivePeriod]}  ${runTime}`;
  } else if ([6, 18].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return '休息';
    return !!Number(LLP) ? `${numberToZh[LLP]}  ${runTime}` : `${jinxingzhong_text}`;
    // 其他
  } else if ([9].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return '休息';
    return !!Number(LLP) ? `${numberToZh[LLP]}` : `${jinxingzhong_text}`;
    // 其他
  } else {
    if (IsHT || Number(LLP) == -1) return '休息';
    return jinxingzhong_text;
  }
}

export function eventTimeFormatForIM({
  IsLive,
  SportType,
  InPlayTime,
  TennisCurrentSet,
  LivePeriod,
  LLP,
  IsHT
}: any): string {
  if (IsLive === 0) return soccerBallEventEnum_i18n.NT;
  const timeIndex = !!InPlayTime ? InPlayTime.split(' ')[0] : '';
  // 足球
  if ([1].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return soccerBallEventEnum_i18n.HT;
    if (!InPlayTime) return processing_i18n;
    return `${soccerBallEventEnum_i18n[timeIndex] || processing_i18n}`;
    // 篮球
  } else if ([2].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return soccerBallEventEnum_i18n.HT;
    if (Number(LLP) > 4) return soccerBallEventEnum_i18n.OT;
    if (!timeIndex && !LLP) return processing_i18n;
    if (timeIndex[0] === 'OT') {
      return soccerBallEventEnum_i18n.OT;
    }
    return !!Number(LLP) ? `${numberToI18n[LLP]}` : `${processing_i18n}`;
    // 网球
  } else if ([5].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return soccerBallEventEnum_i18n.HT;
    return !!Number(LLP)
      ? `${numberToI18n[LLP]}`
      : !!numberToI18n[TennisCurrentSet]
      ? `${numberToI18n[TennisCurrentSet]}`
      : processing_i18n;
  } else if ([43].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return soccerBallEventEnum_i18n.HT;
    return `${numberToI18n[LivePeriod]}`;
  } else if ([6, 18].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return soccerBallEventEnum_i18n.HT;
    // 乒乓球
    return !!Number(LLP) ? `${numberToI18n[LLP]}` : `${processing_i18n}`;
    // 其他
  } else if ([9].includes(SportType)) {
    if (IsHT || Number(LLP) == -1) return soccerBallEventEnum_i18n.HT;
    return !!Number(LLP) ? `${numberToI18n[LLP]}` : `${processing_i18n}`;
    // 其他
  } else {
    if (IsHT || Number(LLP) == -1) return soccerBallEventEnum_i18n.HT;
    return processing_i18n;
  }
}

// 聊天昵称加密
export const secretNickname = (nick: string) => {
  if (!nick) return '';
  const leng = nick.length;
  let str = '';
  if (leng <= 4) {
    str = nick;
  } else {
    str = nick.substring(0, 4) + '****';
  }
  return str;
};
