import { defineComponent, computed } from 'vue';
import { currency } from '@/common';
import { SvgIcon } from '@/components';
import { useBalance } from '@/hooks';
import './balance.style.less';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'HeaderBalance',
  props: {
    onlyBalance: {
      default: false,
      type: Boolean
    }
  },
  setup(props) {
    const store = useStore();
    const currencySymbol = computed(() => {
      const info = store.state.user;
      return info.currency;
    });

    const { sportBalance, isFreshing, onHandleFresh_365 } = useBalance();

    return () =>
      props.onlyBalance ? (
        <span class="text-money">
          {currencySymbol.value}
          {currency(sportBalance.value)}
        </span>
      ) : (
        <div class="balance-info" onClick={() => onHandleFresh_365()}>
          <span class="text-money">
            {currency(sportBalance.value)} {currencySymbol.value}
          </span>
          <div class="icon-recharge">
            <div class="loading-icon">
              <SvgIcon name="refresh" class={{ 'is-load': isFreshing.value }} />
            </div>
          </div>
        </div>
      );
  }
});
