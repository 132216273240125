/**
 * 余额操作
 */

import { useAuth } from '@/hooks';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export function useBalance({ platformCode, gameType }: { platformCode?: string; gameType?: string } = {}) {
  const isFreshing = ref(false);
  const store = useStore();
  const { isLogin } = useAuth();

  const onHandleFresh = async () => {
    if (!isLogin || isFreshing.value) return;
    isFreshing.value = true;
    try {
      await store.dispatch('GET_SPORTS_BALANCE', { platformCode, gameType });
    } catch (error) {
      isFreshing.value = false;
    } finally {
      setTimeout(() => {
        isFreshing.value = false;
      }, 1000);
    }
  };

  const onHandleFresh_365 = async () => {
    if (!isLogin || isFreshing.value) return;
    isFreshing.value = true;
    try {
      await store.dispatch('UPDATE_BLANCE');
    } catch (error) {
      isFreshing.value = false;
    } finally {
      setTimeout(() => {
        isFreshing.value = false;
      }, 1000);
    }
  };

  // 显示账户余额
  const sportBalance = computed(() => {
    return store.getters.balance || 0;
  });

  return {
    isFreshing,
    sportBalance,
    onHandleFresh_365,
    onHandleFresh
  };
}
