import { defineComponent, PropType, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { StoreMarketData, useSportTypes, useI18n } from '@/hooks';
import { getSportNameById, DataTypeEnum } from '@/common';

import style from './market-list.module.less';
import { MatchSummary } from '..';
import { SvgIcon } from '@/components';
import { Collapse, DataEmpty } from 'kg-web/components';
import { Skeleton } from 'ant-design-vue';

export default defineComponent({
  name: 'MarketList',
  props: {
    marketData: {
      type: Array as PropType<StoreMarketData[]>,
      default: () => []
    },
    leagueCounter: {
      type: Number
    },
    /**
     * 加载分页
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * 请求结束,阻止请求
     */
    requestFinash: {
      type: Boolean,
      default: false
    },
    requestLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['leagueChange', 'openLeague', 'loadMore'],
  setup(props, { emit }) {
    const { activeSportType, getSportName, activePeriodId } = useSportTypes();
    const { t } = useI18n();
    const route = useRoute();

    const state = reactive({
      showLeagueFilter: false,
      isExpandAll: true
    }) as any;

    watch(
      () => route.query.periodId,
      () => {
        state.showLeagueFilter = false;
      }
    );

    const onClick = () => {
      state.isExpandAll = !state.isExpandAll;
    };

    const filterToggle = () => emit('openLeague');

    const MarketListHeader = () => {
      return (
        <div class={style.headerContainer}>
          <span class={style.sportLabel}>{getSportName() || getSportNameById(activeSportType.value)}</span>
          <div class={style.headerTools}>
            <div class={style.leagueFilterBtn} onClick={() => filterToggle()}>
              {/* 联赛筛选 */}
              {t('lang.sport_common_leagueFilter')} [
              <span>
                {props.leagueCounter && props.leagueCounter > 0
                  ? props.leagueCounter
                  : `${t('lang.sport_common_quanBu')}`}
              </span>
              ]{/* : '全部' */}
            </div>
            <div class={[style.toggleIcon, state.isExpandAll ? null : style.rotate]} onClick={onClick}>
              <SvgIcon name="arrow_top_v2" />
            </div>
          </div>
        </div>
      );
    };
    const ClickLoadMoreRender = () =>
      props.requestFinash ? (
        <div class={style.marketLoadMore}>{t('lang.sport_common_noMoreData')}</div>
      ) : props.loading ? (
        <div class={style.marketLoadMore}>
          <SvgIcon name="loading_v3" />
          <span>{t('lang.sport_common_loading')}</span>
        </div>
      ) : (
        <div class={style.marketLoadMore} onClick={() => emit('loadMore')}>
          {t('lang.sport_common_clickLoading')}
        </div>
      );
    return () => (
      <>
        <div class={style.marketList}>
          <MarketListHeader />
          <Skeleton loading={props.requestLoading} paragraph={{ rows: 8 }}>
            {props.marketData && props.marketData.length ? (
              <div class={style.marketListScroll}>
                <Collapse expandAll={state.isExpandAll}>
                  {props.marketData.map(item => (
                    <MatchSummary isExpandAll={state.isExpandAll} data={item} key={item.key} />
                  ))}
                </Collapse>
                {[DataTypeEnum.Today, DataTypeEnum.Early].includes(activePeriodId.value) ? (
                  <ClickLoadMoreRender />
                ) : null}
              </div>
            ) : (
              <DataEmpty title={t('lang.sport_common_noAnyData')} type="odds" />
            )}
          </Skeleton>
          |
        </div>
      </>
    );
  }
});
