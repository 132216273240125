import jinqiu from '@/assets/real-time/jinqiu.png';
import wulongqiu from '@/assets/real-time/wulongqiu.png';
import dianqiu from '@/assets/real-time/dianqiu.png';
import jiaoqiu from '@/assets/real-time/jiaoqiu.png';
import huanren from '@/assets/real-time/huanren.png';
import huangpai from '@/assets/real-time/huangpai.png';
import hongpai from '@/assets/real-time/hongpai.png';
import lianghuang from '@/assets/real-time/lianghuang.png';

import jiewaiqiu from '@/assets/real-time/jiewaiqiu.png';
import renyiqiu from '@/assets/real-time/renyiqiu.png';
import qiumenqiu from '@/assets/real-time/qiumenqiu.png';
import bisaikaishi from '@/assets/real-time/bisaikaishi.png';
import zhongchang from '@/assets/real-time/zhongchang.png';

import jieshu from '@/assets/real-time/jieshu.png';
import banchangbifen from '@/assets/real-time/banchangbifen.png';
import dianqiuweijin from '@/assets/real-time/dianqiuweijin.png';
import shangtinbushi from '@/assets/real-time/shangtinbushi.png';
import shezheng from '@/assets/real-time/shezheng.png';
import shepian from '@/assets/real-time/shepian.png';
import jingong from '@/assets/real-time/jieshu.png';
import weixianjingong from '@/assets/real-time/weixianjingong.png';
import kongqiulv from '@/assets/real-time/kongqiulv.png';
import jiashisaijieshu from '@/assets/real-time/jiashisaijieshu.png';
import dianqiudazhanjieshu from '@/assets/real-time/dianqiudazhanjieshu.png';

import zero from '@/assets/real-time/zero.png';
/**
 * 1	进球	足球技术统计
 * 2	角球	足球技术统计
 * 3	黄牌	足球技术统计
 * 4	红牌	足球技术统计
 * 5	界外球	足球技术统计
 * 6	任意球	足球技术统计
 * 7	球门球	足球技术统计
 * 8	点球	足球技术统计
 * 9	换人	足球技术统计
 * 10	比赛开始	足球技术统计
 * 11	中场	足球技术统计
 * 12	结束	足球技术统计
 * 13	半场比分	足球技术统计
 * 15	两黄变红	足球技术统计
 * 16	点球未进	足球技术统计
 * 17	乌龙球	足球技术统计
 * 19	伤停补时	足球技术统计
 * 21	射正	足球技术统计
 * 22	射偏	足球技术统计
 * 23	进攻	足球技术统计
 * 24	危险进攻	足球技术统计
 * 25	控球率	足球技术统计
 * 26	加时赛结束	足球技术统计
 * 27	点球大战结束	足球技术统计
 */

export const soccerBallStatus = {
  0: {
    title: 'lang.sport_common_notState', // 未状态
    value: zero,
    type: 0
  },
  1: {
    title: 'lang.sport_common_goal', // 进球
    value: jinqiu,
    type: 1
  },
  2: {
    title: 'lang.sport_details_cornerBall', // 角球
    value: jiaoqiu,
    type: 2
  },
  3: {
    title: 'lang.sport_common_yellow', // '黄牌
    value: huangpai,
    type: 3
  },
  4: {
    title: 'lang.sport_common_red', // 红牌
    value: hongpai,
    type: 4
  },
  5: {
    title: 'lang.sport_details_foulBall', // 界外球
    value: jiewaiqiu,
    type: 5
  },
  6: {
    title: 'lang.sport_details_freeKick', // 任意球
    value: renyiqiu,
    type: 6
  },
  7: {
    title: 'lang.sport_details_goalKick', // 球门球
    value: qiumenqiu,
    type: 7
  },
  8: {
    title: 'lang.sport_details_penalty', // 点球
    value: dianqiu,
    type: 8
  },
  9: {
    title: 'lang.sport_details_substitube', // 换人
    value: huanren,
    type: 9
  },
  10: {
    title: 'lang.sport_details_gameStart', // 比赛开始
    value: bisaikaishi,
    type: 10
  },
  11: {
    title: 'lang.sport_details_midfield', // 中场
    value: zhongchang,
    type: 11
  },
  12: {
    title: 'lang.sport_details_finish', // 结束
    value: jieshu,
    type: 12
  },
  13: {
    title: 'lang.sport_details_halfTimeScore', // 半场比分
    value: banchangbifen,
    type: 13
  },
  15: {
    title: 'lang.sport_details_2YellowAnd1Red', // 两黄一红
    value: lianghuang,
    type: 15
  },
  16: {
    title: 'lang.sport_details_penaltyMissed', // 点球未进
    value: dianqiuweijin,
    type: 16
  },
  17: {
    title: 'lang.sport_details_ownGoals', // 乌龙球
    value: wulongqiu,
    type: 17
  },
  19: {
    title: 'lang.sport_details_injuryTime', // 伤停补时
    value: shangtinbushi,
    type: 19
  },
  21: {
    title: 'lang.sport_common_shotOnTarget', // 射正
    value: shezheng,
    type: 21
  },
  22: {
    title: 'lang.sport_details_shotOff', // 射偏
    value: shepian,
    type: 22
  },
  23: {
    title: 'lang.sport_details_attack', // 进攻
    value: jingong,
    type: 23
  },
  24: {
    title: 'lang.sport_details_dangerousAttack', // 危险进攻
    value: weixianjingong,
    type: 24
  },
  25: {
    title: 'lang.sport_details_ballPossession', // 控球率
    value: kongqiulv,
    type: 25
  },
  26: {
    title: 'lang.sport_details_overtimeEnd', // 加时赛结
    value: jiashisaijieshu,
    type: 26
  },
  27: {
    title: 'lang.sport_details_penaltyEnd', // 点球大战束
    value: dianqiudazhanjieshu,
    type: 27
  }
};

export const tipsLabelList = [
  {
    title: 'lang.sport_common_goal', // 进球
    value: jinqiu
  },
  {
    title: 'lang.sport_details_ownGoals', // 乌龙球
    value: wulongqiu
  },
  {
    title: 'lang.sport_details_penalty', // 点球
    value: dianqiu
  },
  {
    title: 'lang.sport_details_cornerBall', // 角球
    value: jiaoqiu
  },
  {
    title: 'lang.sport_details_substitube', // 换人
    value: huanren
  },
  {
    title: 'lang.sport_common_yellow', // 黄牌
    value: huangpai
  },
  {
    title: 'lang.sport_common_red', // 红牌
    value: hongpai
  },
  {
    title: 'lang.sport_details_2YellowAnd1Red', // 两黄一红
    value: lianghuang
  }
];
