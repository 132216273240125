import { reactive } from 'vue';
import { getDayTimestamp, getWeek } from '@/common';
import { isEmpty, groupBy } from 'lodash-es';
import { getResultsApi } from '@/services/kg-api';
import { useDebounceFn } from '@vueuse/core';
// import { isMobile } from '@/common';
// import Laoding from '@/components/loading-toast';

import dayjs from 'dayjs';
import { getSportId } from '@/services';

interface DayWeek {
  week: any;
  date: string;
  startTime: number;
  endTime: number;
}

function generateLast7days() {
  const temlsit: DayWeek[] = [];
  for (let index = 0; index < 7; index++) {
    temlsit[index] = {
      week: getWeek(getDayTimestamp(7 - index)),
      date: dayjs().subtract(index, 'day').format('MM/DD'),
      startTime: dayjs().subtract(index, 'day').startOf('day').valueOf(),
      endTime: dayjs().subtract(index, 'day').endOf('day').valueOf()
    };
  }
  return temlsit;
}

export const LAST_7_DAYS = generateLast7days();

export function useEventResult() {
  const state = reactive({
    showLeagus: false,
    showSheet: false,
    matchMapByLeagueId: {} as any,
    showTypeKey: 'time' as 'time' | 'sport',
    currentTimeIndex: 0,
    currentSportIndex: 0,
    expandKeys: [] as string[],
    loading: false,
    sportTypes: [] as { sportType: number; sportTypeName: string }[]
  });

  const form = reactive({
    sportId: 1,
    startTime: generateLast7days()[0].startTime,
    endTime: generateLast7days()[0].endTime,
    leagueId: null
  });

  const openSheet = ({ type }: { type: 'time' | 'sport' }) => {
    state.showSheet = true;
    state.showTypeKey = type;
  };

  const closeSheet = () => {
    state.showSheet = false;
  };

  const openLeagues = () => {
    state.showLeagus = !state.showLeagus;
  };

  const getData = async (cb?: () => void) => {
    if (state.sportTypes.length <= 0) {
      await getSportsData();
    }
    state.matchMapByLeagueId = {};
    // isMobile && Laoding.show();
    state.loading = true;
    const [res, data] = await getResultsApi(form);
    // isMobile && Laoding.hide();
    // setTimeout(() => {
    state.loading = false;
    if (!isEmpty(data) && res) {
      state.matchMapByLeagueId = isEmpty(data) ? {} : groupBy(data, 'leagueName');
      state.expandKeys = Object.keys(state.matchMapByLeagueId);
    }
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    cb && cb();
  };

  const getSportsData = async () => {
    const [res, data] = await getSportId({ enabledOnly: 1 });
    if (res) {
      state.sportTypes = data;
      form.sportId = data[state.currentSportIndex].sportType;
    }
  };

  const onHandleDate = async (index: number, isWeb = false) => {
    if (state.currentTimeIndex === index && !isWeb) return;
    state.currentTimeIndex = index;
    form.startTime = LAST_7_DAYS[state.currentTimeIndex].startTime;
    form.endTime = LAST_7_DAYS[state.currentTimeIndex].endTime;
    await getData();
    closeSheet();
  };

  const onHandleSport = async (typeId: number, isWeb = false) => {
    if (state.currentSportIndex === typeId && !isWeb) return;
    state.currentSportIndex = typeId;
    form.sportId = typeId;
    await getData();
    closeSheet();
  };

  return {
    getData,
    onHandleDate: useDebounceFn(onHandleDate, 200),
    onHandleSport: useDebounceFn(onHandleSport, 200),
    openSheet,
    closeSheet,
    openLeagues,
    form,
    state
  };
}
