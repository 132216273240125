/**
 * 投注注单格式化数据
 * Betting order data formatting
 */

import { BetOrder, BetOrderOther, ParlayItem, PartialCashedoutData } from '@/services';
import { get, isEmpty } from 'lodash-es';
import { MarketTypeList, SettlementList, SettlementEnum, toTime, currency } from '@/common';

interface FormatParlayItem {
  orderId: string;
  betId: string;
  cashoutValue: any;
  stake: number;
  currentPossibleWinning: number;
  status?: 0 | null;
  ticketTag: string;
  ticketTag_i18n: string;
  homeName: string;
  awayName: string;
  homeScore: string;
  awayScore: string;
  leagueName: string;
  matchTime: any;
  betSelection: string;
  handicap: string;
  odds: string;
  isSettlement: boolean; // 是否已结算
  betType: string;
  currency: string; // 币种
  winLostAmountState: string;
  winLostAmount: string;
  payoutAmount: string;
  creationDate: any; // 订单创建时间
  oddsTypeName_i18n: string; // 盘口名字
  parlayList: FormatParlayItem[]; // 串关列表
  parlayCout: number; // 串关数量
  comboType_i18n: string | null;
  keyName: string;
  id: string;
  transactionTime: string;
  point: string;
  ticketStatus: number;
  parlayData: ParlayItem[];
  comboType: string;
  isFinished: boolean;
  isFinishedNew: boolean;
  currentCashoutValue: number;
  partialCashoutStake: number;
  autoCashOutValueThreshold: number;
  estimatedPayoutFullAmount: any;
  settlementTime: string;
  isAutoCashedout: number;
  parlayId: string;
  message: string;
  isChampion: number;
}

export interface FormatOrderItem {
  ticketTag: string;
  ticketTag_i18n: string;
  homeName: string;
  awayName: string;
  homeScore: string;
  awayScore: string;
  leagueName: string;
  matchTime: any;
  betSelection: string;
  handicap: string;
  odds: string;
  isSettlement: boolean; // 是否已结算
  betType: string;
  stake: string; // 投注额
  currency: string; // 币种
  winLostAmountState: 'win' | 'lost' | '';
  winLostAmount: string;
  payoutAmount: string;
  creationDate: any; // 订单创建时间
  oddsTypeName_i18n: string; // 盘口名字
  orderId: string; // 订单号
  parlayList: FormatParlayItem[]; // 串关列表
  parlayCout: number; // 串关数量
  comboType_i18n: string | null;
  keyName: string;
  id: string;
  transactionTime: string;
  point: string;
  ticketStatus: number;
  parlayData: any;
  comboType: string;
  cashoutValue: number;
  settlementTime: string;
  isAutoCashedout: number;
  pendingCashOut: number;
  betTypeName: string;
  isPartialCashedout: number;
  isCashedout: number;
  partialCashedoutData: PartialCashedoutData[];
  cashoutThreshold: any;
  overallCashoutAmount: number;
  cashOutDate: string;
  cashedoutDate: string;
  updateTime: string;
  isChampion: number;
}

export interface EarlyTypes {
  orderId: string;
  betId: string;
  cashoutValue: any;
  stake: number;
  currentPossibleWinning: number;
  status?: 0 | null;
  ticketTag: string;
  ticketTag_i18n: string;
  homeName: string;
  awayName: string;
  homeScore: string;
  awayScore: string;
  leagueName: string;
  matchTime: any;
  betSelection: string;
  handicap: string;
  odds: string;
  isSettlement: boolean; // 是否已结算
  betType: string;
  currency: string; // 币种
  winLostAmountState: string;
  winLostAmount: string;
  payoutAmount: string;
  creationDate: any; // 订单创建时间
  oddsTypeName_i18n: string; // 盘口名字
  parlayList: FormatParlayItem[]; // 串关列表
  parlayCout: number; // 串关数量
  comboType_i18n: string | null;
  keyName: string;
  id: string;
  transactionTime: string;
  point: string;
  ticketStatus: number;
  parlayData: ParlayItem[];
  comboType: string;
  isFinished: boolean;
  isFinishedNew: boolean;
  currentCashoutValue: number;
  partialCashoutStake: number;
  autoCashOutValueThreshold: number;
  estimatedPayoutFullAmount: any;
  settlementTime: string;
  isAutoCashedout: number;
  isPartialCashedout: number;
  pendingCashOut: number;
  isCashedout: number;
  betTypeName: string;
  message: string;
  cashoutThreshold: any;
  isChampion: number;
}

interface CommonTypeItem {
  label: string;
  code: string;
  // 国际化
  i18n?: string;
  value?: any;
  index?: string;
  count?: number;
  sportVisibleEnum?: number[];
}
// 结算状态枚举
const enum SettlementEnum365 {
  waiting = 1, // 等待中
  running = 2, // 待结算
  void = 3, // 作废
  refund = 4, // 退款
  reject = 5, // 拒绝
  lose = 6, // 输
  won = 7, // 赢
  draw = 8, // 和局
  halfWon = 9, // 赢半
  open = 2, // 待结算
  halfLost = 11, // 输半
  cashedOut = 12, // 提前兑现
  cancelled = 13 // 取消
}

const SettlementList365: CommonTypeItem[] = [
  {
    i18n: 'lang.sport_orders_waiting',
    label: '等待中',
    code: 'waiting',
    value: SettlementEnum365.waiting
  },
  {
    i18n: 'lang.sport_orders_running',
    label: '待结算',
    code: 'running',
    value: SettlementEnum365.running
  },
  {
    i18n: 'lang.sport_orders_running',
    label: '待结算',
    code: 'open',
    value: SettlementEnum365.open
  },
  {
    i18n: 'lang.sport_orders_void',
    label: '作废',
    code: 'void',
    value: SettlementEnum365.void
  },
  {
    i18n: 'lang.sport_orders_refund',
    label: '退款',
    code: 'refund',
    value: SettlementEnum365.refund
  },
  {
    i18n: 'lang.sport_orders_reject',
    label: '拒绝',
    code: 'reject',
    value: SettlementEnum365.reject
  },
  {
    i18n: 'lang.sport_details_lost',
    label: '输',
    code: 'lose',
    value: SettlementEnum365.lose
  },
  {
    i18n: 'lang.sport_orders_won',
    label: '赢',
    code: 'won',
    value: SettlementEnum365.won
  },
  {
    i18n: 'lang.sport_common_tie',
    label: '和',
    code: 'draw',
    value: SettlementEnum365.draw
  },
  {
    i18n: 'lang.sport_orders_winHalf',
    label: '赢半',
    code: 'halfWon',
    value: SettlementEnum365.halfWon
  },
  {
    i18n: 'lang.sport_orders_loseHalf',
    label: '输半',
    code: 'halfLost',
    value: SettlementEnum365.halfLost
  },
  {
    i18n: 'lang.sport_orders_cashedOut',
    label: '提前兑现',
    code: 'cashedOut',
    value: SettlementEnum365.cashedOut
  },
  {
    i18n: 'lang.sport_common_cancel',
    label: '取消',
    code: 'cancelled',
    value: SettlementEnum365.cancelled
  }
];
// 显示标识样式
const tagClassName365 = (ticketStatus: number) => {
  const obj = SettlementList365.find(e => e.value === ticketStatus);
  return obj?.code || '';
};

// 显示标识字段
const tagTicketName365 = (ticketStatus: number): string => {
  const obj = SettlementList365.find(e => e.value === ticketStatus);
  return obj?.i18n || '';
};
// 显示标识样式
const tagClassName = (ticketStatus: number) => {
  const obj = SettlementList.find(e => e.value === ticketStatus);
  return obj?.code || '';
};

// 显示标识字段
const tagTicketName = (ticketStatus: number): string => {
  const obj = SettlementList.find(e => e.value === ticketStatus);
  return obj?.i18n || '';
};

const isSettlement = (ticketStatus: number) => {
  const array = [
    SettlementEnum.running,
    SettlementEnum.waiting,
    SettlementEnum.reject,
    SettlementEnum.refund,
    SettlementEnum.void,
    SettlementEnum.cancelled,
    SettlementEnum.cashedOut
  ];
  // among the new ticketStatus cancelled and cashedOut were added to this list. But SettlementEnum.open isn't.
  // please add if it is needed and then remove these comments
  return !array.find(item => item === ticketStatus);
};
// 盘口名字
const oddsTypeName = (type: number) => {
  const obg = MarketTypeList.find(ty => ty.value === type);
  return obg?.label || '--';
};

// 输赢样式
const winLostAmountClass = (numberStr: number | string) => {
  const n = Number(numberStr);
  return n === 0 ? '' : n > 0 ? 'win' : 'lost';
};

const mulTypeToName_i18n = (mulType: string) => {
  switch (mulType) {
    case 'Doubles':
      return `2${'lang.sport_bet_connect'}1`;
    case 'Trebles':
      return `3${'lang.sport_bet_connect'}1`;
    case 'Trixie':
      return `3${'lang.sport_bet_connect'}4`;
    case 'Lucky7':
      return `幸运7`;
    case 'Patent':
      return `幸运7`;
    case 'Fold4':
      return `4${'lang.sport_bet_connect'}1`;
    case 'Yankee':
      return `洋基`;
    case 'Lucky15':
      return `幸运15`;
    case 'Fold5':
      return `5${'lang.sport_bet_connect'}1`;
    case 'Canadian':
      return `超级美国佬`;
    case 'Lucky31':
      return `幸运31`;
    case 'Fold6':
      return `6${'lang.sport_bet_connect'}1`;
    case 'Heinz':
      return `亨氏`;
    case 'Lucky63':
      return `幸运63`;
    case 'Fold7':
      return `7${'lang.sport_bet_connect'}1`;
    case 'SuperHeinz':
      return `超级亨氏`;
    case 'Lucky127':
      return `幸运127`;
    case 'Fold8':
      return `8${'lang.sport_bet_connect'}1`;
    case 'Goliath':
      return `大亨`;
    case 'Lucky255':
      return `幸运255`;
    case 'Fold9':
      return `9${'lang.sport_bet_connect'}1`;
    case 'Fold10':
      return `10${'lang.sport_bet_connect'}1`;
    case 'SuperYankee':
      return `5串26`;
    case 'Super Yankee':
      return `5串26`;
    case 'Block9':
      return `9${'lang.sport_bet_connect'}520`;
    case 'Block10':
      return `10${'lang.sport_bet_connect'}1013`;
    case '3串4':
      return `3${'lang.sport_bet_connect'}4`;
    case '4串11':
      return `4${'lang.sport_bet_connect'}11`;
    case '5串26':
      return `5${'lang.sport_bet_connect'}26`;
    case '6串57':
      return `6${'lang.sport_bet_connect'}57`;
    case '7串120':
      return `7${'lang.sport_bet_connect'}120`;
    case '9串502':
      return `9${'lang.sport_bet_connect'}502`;
    case '10串1013':
      return `10${'lang.sport_bet_connect'}1013`;
    case '8串247':
      return `8${'lang.sport_bet_connect'}247`;
    default:
      return mulType;
  }
};

// 串关数据处理
const formatParlayList = (list: ParlayItem[]): FormatParlayItem[] => {
  if (isEmpty(list)) return [];
  const temp: FormatParlayItem[] = [];
  list.map(item => {
    if (item != null) {
      temp.push({
        parlayId: item.parlayId || item.id,
        keyName: item.keyName,
        ticketStatus: item.ticketStatus,
        currentPossibleWinning: item.currentPossibleWinning,
        homeName: item.homeName,
        awayName: item.awayName,
        handicap: get(item, 'exculding.Handicap')
          ? get(item, 'exculding.Handicap')
          : item.point
          ? item.point
          : item.keyName,
        betSelection: get(item, 'exculding.BetSelection')
          ? get(item, 'exculding.BetSelection')
          : !!item.keyName
          ? ''
          : item.betTeam,
        betType: item.betTypeName ? item.betTypeName : item.betType,
        awayScore: item.awayScore,
        homeScore: item.homeScore,
        leagueName: item.leagueName,
        matchTime: toTime(item.matchTime, 'YYYY-MM-DD HH:mm'),
        odds: item.odds || '--',
        cashoutValue: item.cashoutValue,
        orderId: '',
        betId: '',
        stake: 0,
        ticketTag: '',
        ticketTag_i18n: '',
        isSettlement: false,
        currency: '',
        winLostAmountState: '',
        winLostAmount: '',
        payoutAmount: '',
        creationDate: undefined,
        oddsTypeName_i18n: '',
        parlayList: [],
        parlayCout: 0,
        comboType_i18n: null,
        id: '',
        transactionTime: '',
        point: '',
        parlayData: [],
        comboType: '',
        isFinished: false,
        isFinishedNew: false,
        currentCashoutValue: 0,
        partialCashoutStake: 0,
        autoCashOutValueThreshold: 0,
        estimatedPayoutFullAmount: undefined,
        settlementTime: '',
        isAutoCashedout: 0,
        message: '',
        isChampion: item.isChampion
      });
    }
  });

  return temp;
};

// 注单列表转换
export const ordersFormat_365 = (records: BetOrder[]): FormatOrderItem[] => {
  if (isEmpty(records)) return [];
  const temp: FormatOrderItem[] = [];
  records.map(item => {
    temp.push({
      ticketTag: tagClassName365(item.ticketStatus),
      ticketTag_i18n: tagTicketName365(item.ticketStatus),
      homeName: item.homeName,
      awayName: item.awayName,
      homeScore: item.homeScore,
      awayScore: item.awayScore,
      leagueName: item.leagueName,
      matchTime: toTime(item.matchTime, 'YYYY-MM-DD HH:mm:ss'),
      handicap: get(item, 'exculding.Handicap')
        ? get(item, 'exculding.Handicap')
        : item.point
        ? item.point
        : item.keyName,
      betSelection: get(item, 'exculding.BetSelection')
        ? get(item, 'exculding.BetSelection')
        : !!item.keyName
        ? ''
        : item.betTeam,
      odds: item.odds,
      isSettlement: isSettlement(item.ticketStatus),
      betType: item.betTypeName ? item.betTypeName : item.betType,
      stake: currency(item.stake),
      currency: item.currency,
      winLostAmountState: winLostAmountClass(item.winLostAmount),
      winLostAmount: currency(item.winLostAmount),
      payoutAmount: currency(item.winLostAmount),
      creationDate: toTime(item.creationDate, 'YYYY-MM-DD HH:mm:ss'),
      oddsTypeName_i18n: oddsTypeName(item.oddsType),
      orderId: item.id,
      parlayList: formatParlayList(item.parlayData),
      parlayData: item.parlayData,
      parlayCout: isEmpty(item.parlayData) ? 0 : item.parlayData.length,
      comboType: item.comboType,
      comboType_i18n: item.comboType === 'None' || isEmpty(item.comboType) ? null : mulTypeToName_i18n(item.comboType),
      keyName: item.keyName,
      id: item.id,
      transactionTime: item.transactionTime,
      point: item.point,
      ticketStatus: item.ticketStatus,
      cashoutValue: item.cashedoutData?.cashoutValue,
      settlementTime: item.settlementTime,
      isAutoCashedout: item.isAutoCashedout,
      betTypeName: '',
      pendingCashOut: 0,
      cashoutThreshold: item.cashoutThreshold,
      isPartialCashedout: item.isPartialCashedout,
      isCashedout: item.isCashedout,
      partialCashedoutData: item.partialCashedoutData,
      overallCashoutAmount: item.overallCashoutAmount,
      cashOutDate: item.cashOutDate,
      cashedoutDate: item.cashedoutDate,
      updateTime: item.updateTime,
      isChampion: item.isChampion
    });
  });
  return temp;
};
// 注单列表转换
export const ordersFormat = (records: BetOrder[]): FormatOrderItem[] => {
  if (isEmpty(records)) return [];
  const temp: FormatOrderItem[] = [];
  records.map(item => {
    temp.push({
      ticketTag: tagClassName(item.ticketStatus),
      ticketTag_i18n: tagTicketName(item.ticketStatus),
      homeName: item.homeName,
      awayName: item.awayName,
      homeScore: item.homeScore,
      awayScore: item.awayScore,
      leagueName: item.leagueName,
      matchTime: toTime(item.matchTime, 'YYYY-MM-DD HH:mm:ss'),
      handicap: get(item, 'exculding.Handicap')
        ? get(item, 'exculding.Handicap')
        : item.point
        ? item.point
        : item.keyName,
      betSelection: get(item, 'exculding.BetSelection')
        ? get(item, 'exculding.BetSelection')
        : !!item.keyName
        ? ''
        : item.betTeam,
      odds: item.odds,
      isSettlement: isSettlement(item.ticketStatus),
      betType: item.betTypeName ? item.betTypeName : item.betType,
      stake: currency(item.stake),
      currency: item.currency,
      winLostAmountState: winLostAmountClass(item.winLostAmount),
      winLostAmount: currency(item.winLostAmount),
      payoutAmount: currency(item.winLostAmount),
      creationDate: toTime(item.creationDate, 'YYYY-MM-DD HH:mm:ss'),
      oddsTypeName_i18n: oddsTypeName(item.oddsType),
      orderId: item.id,
      parlayList: formatParlayList(item.parlayData),
      parlayData: item.parlayData,
      parlayCout: isEmpty(item.parlayData) ? 0 : item.parlayData.length,
      comboType: item.comboType,
      comboType_i18n: item.comboType === 'None' || isEmpty(item.comboType) ? null : mulTypeToName_i18n(item.comboType),
      keyName: item.keyName,
      id: item.id,
      transactionTime: item.transactionTime,
      point: item.point,
      ticketStatus: item.ticketStatus,
      cashoutValue: item.cashedoutData?.cashoutValue,
      settlementTime: item.settlementTime,
      isAutoCashedout: item.isAutoCashedout,
      betTypeName: '',
      pendingCashOut: 0,
      cashoutThreshold: item.cashoutThreshold,
      isPartialCashedout: item.isPartialCashedout,
      isCashedout: item.isCashedout,
      partialCashedoutData: item.partialCashedoutData,
      overallCashoutAmount: item.overallCashoutAmount,
      cashOutDate: item.cashOutDate,
      cashedoutDate: item.cashedoutDate,
      updateTime: item.updateTime,
      isChampion: item.isChampion
    });
  });
  return temp;
};

export const EarlyordersFormat = (records: BetOrder[]): FormatOrderItem[] => {
  if (isEmpty(records)) return [];
  const temp: FormatOrderItem[] = [];
  records.map(item => {
    temp.push({
      ticketTag: tagClassName(item.ticketStatus),
      ticketTag_i18n: tagTicketName(item.ticketStatus),
      homeName: item.homeName,
      awayName: item.awayName,
      homeScore: item.homeScore,
      awayScore: item.awayScore,
      leagueName: item.leagueName,
      matchTime: toTime(item.matchTime, 'YYYY-MM-DD HH:mm:ss'),
      handicap: get(item, 'exculding.Handicap')
        ? get(item, 'exculding.Handicap')
        : item.point
        ? item.point
        : item.keyName,
      betSelection: get(item, 'exculding.BetSelection')
        ? get(item, 'exculding.BetSelection')
        : !!item.keyName
        ? ''
        : item.betTeam,
      odds: item.odds,
      isSettlement: isSettlement(item.ticketStatus),
      betType: item.betTypeName ? item.betTypeName : item.betType,
      stake: currency(item.stake),
      currency: item.currency,
      winLostAmountState: winLostAmountClass(item.winLostAmount),
      winLostAmount: currency(item.winLostAmount),
      payoutAmount: currency(item.winLostAmount),
      creationDate: toTime(item.creationDate, 'YYYY-MM-DD HH:mm:ss'),
      oddsTypeName_i18n: oddsTypeName(item.oddsType),
      orderId: item.id,
      parlayList: formatParlayList(item.parlayData),
      parlayData: item.parlayData,
      parlayCout: isEmpty(item.parlayData) ? 0 : item.parlayData.length,
      comboType: item.comboType,
      comboType_i18n: item.comboType === 'None' || isEmpty(item.comboType) ? null : mulTypeToName_i18n(item.comboType),
      keyName: item.keyName,
      id: item.id,
      transactionTime: item.transactionTime,
      point: item.point,
      ticketStatus: item.ticketStatus,
      cashoutValue: item.cashedoutData?.cashoutValue,
      settlementTime: item.settlementTime,
      isAutoCashedout: item.isAutoCashedout,
      betTypeName: '',
      pendingCashOut: 0,
      cashoutThreshold: item.cashoutThreshold,
      isPartialCashedout: item.isPartialCashedout,
      isCashedout: item.isCashedout,
      partialCashedoutData: item.partialCashedoutData,
      overallCashoutAmount: item.overallCashoutAmount,
      cashOutDate: item.cashOutDate,
      cashedoutDate: item.cashedoutDate,
      updateTime: item.updateTime,
      isChampion: item.isChampion
    });
  });
  return temp;
};

export interface ConversionStatistics {
  /**
   * 投注额
   */
  betAmount: string;
  /**
   * 投注统计
   */
  betCount: number;
  /**
   * 有效额
   */
  validAmount: string;
  /**
   * 输赢
   */
  winAmount: string;
  /**
   * 货币
   */
  currency: string;
}

// 统计
export const stateCounterFormat = (counts: BetOrderOther): ConversionStatistics => {
  if (isEmpty(counts))
    return {
      betAmount: '0',
      betCount: 0,
      validAmount: '0',
      winAmount: '0',
      currency: ''
    };
  return {
    betAmount: currency(counts.betAmount),
    betCount: counts.betCount,
    validAmount: currency(counts.validAmount),
    winAmount: currency(counts.winAmount),
    currency: counts.currency
  };
};
