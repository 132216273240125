// 前缀
const SPORT_PREFIX = 'SP_';

// 关注赛事 key
export const FAVORT_MATCHS_KEY = SPORT_PREFIX + 'FAVORT_MATCHS_IDS';

// 旧版设置项-盘口类型
export const SET_ODD_TYPE_KEY = SPORT_PREFIX + 'SET_ODD_TYPE';

// 旧版设置项
export const SET_OPTION_TYPE_KEY = SPORT_PREFIX + 'SET_OPTION_TYPE';

// 设置项
export const OPTIONS_SETTING_KEY = SPORT_PREFIX + 'OPTIONS_SETTING';

// 体育API
export const SPORT_API_KEY = SPORT_PREFIX + 'SPORT_API';

// 体育logo域名
export const SPORT_LOGO_URL_KEY = SPORT_PREFIX + 'LOGO_URL';

// CMS平台API
export const CMS_API_KEY = SPORT_PREFIX + 'CMS_API';

// websocket API
export const WS_API_KEY = SPORT_PREFIX + 'WS_API';

// H5 url
export const SPORT_H5_URL_KEY = SPORT_PREFIX + 'H5_URL';

// web url
export const SPORT_WEB_URL_KEY = SPORT_PREFIX + 'WEB_URL';

// 接口获取存的运动map
export const SPORT_MAP_KEY = SPORT_PREFIX + 'SPORT_MAP_KEY';

// 设备ID
export const SPORT_ACCESS_UUID_KEY = SPORT_PREFIX + 'ACCESS_UUID';

// 租户标识
export const SPORT_TENANT_KEY = SPORT_PREFIX + 'TNANET';
