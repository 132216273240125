import { useStore } from 'vuex';
import { TimerEnum, useTimerHook } from '@/hooks';
import { reactive, computed } from 'vue';
import { isEmpty, get } from 'lodash-es';
import { convertSportsDetailList, SportIdEnum, DataTypeEnum, TechId } from '@/common';
import { EventsAndMarketsPrams, queryEventsAndMarkets, getClassifyApi } from '@/services/kg-api';

interface Store {
  loading: boolean;
  raw: object;
  info: any;
  odds: any[];
  periodId: number;
  leagueId: string | number | any;
  leagueName: string;
  showMatchs: boolean;
  showSheet: boolean;
  group: number;
  groups: any[];
  OddsType: number;
  /**
   * 页面空数据状态
   */
  errorPage: boolean;
  groupList: { classifyId: number; classifyName: string }[];
}

const initData = () => ({
  loading: false,
  raw: {},
  info: {},
  odds: [],
  periodId: 1,
  leagueId: null,
  showMatchs: false,
  showSheet: false,
  groups: [],
  group: 0,
  leagueName: '',
  errorPage: false,
  OddsType: 1,
  groupList: []
});

// const defaultGroupList = [
//   {
//     groupId: 1,
//     groupName: '让球&大/小',
//     i18n: 'lang.sport_common_HandicapOverUnder'
//   },
//   {
//     groupId: 2,
//     groupName: '波胆',
//     i18n: 'lang.sport_common_correctScore'
//   },
//   {
//     groupId: 3,
//     groupName: '角球',
//     i18n: 'lang.sport_common_cornerKick'
//   },
//   {
//     groupId: 4,
//     groupName: '进球',
//     i18n: 'lang.sport_common_goal'
//   },
//   {
//     groupId: 5,
//     groupName: '半场',
//     i18n: 'lang.sport_common_haftTime'
//   },
//   {
//     groupId: 6,
//     groupName: '特别投注',
//     i18n: 'lang.sport_common_special'
//   }
// ];

const store = reactive<Store>(initData());

export function useDetailHook() {
  const vuexStore = useStore();
  const { addTimer } = useTimerHook();

  const getClassifyData = async ({ oddsType }) => {
    const [res, data] = await getClassifyApi({ oddsType });
    if (res) {
      store.groupList = data;
    }
  };

  /**
   *
   * @param params
   * @param first
   * @param isFill  波胆-主客和 是否补足数据
   */
  const getData = async (params: EventsAndMarketsPrams, first = true, isFill = false) => {
    if (first) {
      store.loading = true;
    }
    await getClassifyData(params);
    store.loading = false;
    const fn = async () => {
      if (first) {
        store.loading = true;
      }
      const [res, data] = await queryEventsAndMarkets(params);
      store.loading = false;
      first = false;
      if (isEmpty(data)) {
        store.errorPage = true;
        return;
      }
      let animate;
      if (res) {
        if (get(data, '0.matches.0.MatchId')) {
          const match = get(data, '0.matches.0');
          animate = match.animate;
          const tech = vuexStore?.getters?.getTechById(get(data, '0.matches[0]MatchId'));
          if (tech) {
            const RedCard = tech.get(TechId.RED) || [];
            const RedCardH = RedCard[0];
            const RedCardA = RedCard[1];
            const YellowCard = tech.get(TechId.YELLOW) || [];
            const YellowCardH = YellowCard[0];
            const YellowCardA = YellowCard[1];

            get(data, '0.matches.0', {}).MoreInfo = Object.assign(get(data, '0.matches.0.MoreInfo') || {}, {
              RedCardH,
              RedCardA,
              YellowCardH,
              YellowCardA
            });
          } else if (!!match.MoreInfo) {
            const RedCardH = match.MoreInfo.redCardH;
            const RedCardA = match.MoreInfo.redCardA;
            const CornerH = match.MoreInfo.cornersH;
            const CornerA = match.MoreInfo.cornersA;
            const YellowCardH = match.MoreInfo.yellowCardH;
            const YellowCardA = match.MoreInfo.yellowCardA;
            const HalfTimeH = match.MoreInfo.halfScoreH;
            const HalfTimeA = match.MoreInfo.halfScoreA;
            get(data, '0.matches.0', {}).MoreInfo = Object.assign(get(data, '0.matches.0.MoreInfo') || {}, {
              RedCardH,
              RedCardA,
              YellowCardH,
              YellowCardA,
              CornerH,
              CornerA,
              HalfTimeH,
              HalfTimeA
            });
          }
        }
        const {
          info,
          odds,
          groups = [],
          leagueId,
          leagueName
        } = convertSportsDetailList(
          data,
          {
            sportId: params.sportId as SportIdEnum
          },
          isFill
        );
        info.animate = animate;
        store.raw = data;
        store.info = info;
        store.odds = odds;
        store.groups = [
          { classifyId: 0, classifyName: 'lang.sport_common_all' },
          ...(store.groupList.filter(g => groups.includes(g.classifyId)) || [])
        ];
        store.leagueId = leagueId;
        store.leagueName = leagueName;
        store.errorPage = false;
      }
    };
    addTimer(TimerEnum.DETAIL, fn, params.periodId === DataTypeEnum.Live ? 5000 : 60 * 1000);
  };

  const showOdds = computed(() => {
    if (store.group === 0) return store.odds;
    const res = store.odds.filter(odd => (odd.group || []).includes(store.group));
    if (res.length === 0) {
      changeGroup(0);
    }
    return res;
  });
  const changeGroup = (classifyId: number) => {
    store.group = classifyId;
  };

  const clearStore = () => {
    const initStore = initData();
    const keys = Object.keys(initStore);
    const temObj: { [name: string]: string } = {};
    keys.forEach(k => (temObj[k] = initStore[k]));
    Object.assign(store, temObj);
  };

  return { store, showOdds, getData, changeGroup, getClassifyData, clearStore };
}
