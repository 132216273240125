import pako from 'pako';

// 压缩
export function zip(inStr: string) {
  const binaryString = pako.gzip(encodeURIComponent(inStr), { to: 'string' });
  return btoa(binaryString);
}

// 缓冲数据
function arrayBufferToString(buffer: any) {
  const bufView = new Uint16Array(buffer);
  const length = bufView.length;
  let result = '';
  let addition = Math.pow(2, 16) - 1;

  for (let i = 0; i < length; i += addition) {
    if (i + addition > length) {
      addition = length - i;
    }
    // @ts-ignore;
    result += String.fromCharCode.apply(null, bufView.subarray(i, i + addition));
  }

  return result;
}

// 解压
export function unzip(inStr: string) {
  let strData = atob(inStr);
  const charData = strData.split('').map(x => x.charCodeAt(0));
  const binData = new Uint8Array(charData);
  const outData = pako.inflate(binData);
  strData = arrayBufferToString(new Uint16Array(outData) as any);
  //  String.fromCharCode.apply(null, new Uint16Array(outData) as any);
  return decodeURIComponent(strData);
}
