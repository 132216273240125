import { defineComponent, ref, watch } from 'vue';
import { SvgIcon } from '@/components';
import style from './favorit-star.module.less';
// import { useAuth } from '@/hooks';  Temporarily commented to make click Active.

export default defineComponent({
  name: 'FavoritStar',
  props: {
    isMarked: {
      type: Boolean,
      require: true
    }
  },
  emits: ['mark'],
  setup(props, { emit }) {
    const mark = ref(props.isMarked);
    // const { checkIsLogin } = useAuth(); Temporarily commented to make click Active.

    const onMark = () => {
      // if (!checkIsLogin()) return;  Temporarily commented to make click Active.
      mark.value = !mark.value;
      emit('mark', mark.value);
    };
    watch(
      () => props.isMarked,
      () => {
        mark.value = props.isMarked;
      }
    );
    return () => (
      <div class={[style.favoritStar, mark.value ? style.isMark : null]} onClick={() => onMark()}>
        <SvgIcon name="star" />
      </div>
    );
  }
});
