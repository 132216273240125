/**
 * 联赛筛选
 */

import { reactive, computed, ref } from 'vue';
import { queryLeagueList } from '@/services/kg-api';
import { getThirdLeagueId } from '@/services';
import { LeagueItem } from '@/services/kg-api';
import { formatLeagueList, MarketType } from '@/common';
import { isEmpty } from 'lodash-es';
import { useSportOptions } from '@/hooks';

interface Letters {
  [name: string]: LeagueItem[];
}

interface LeagueStore {
  leaguesList: Letters | [];
  leagueIds: string[] | number[];
  isCheckedAll: boolean;
  isReverse: boolean;
  resultForm: {
    startTime: string | number;
    endTime: string | number;
    sportId: number;
  };
  form: {
    periodId: number;
    // 体育项目ID
    sportId: number;
    oddsType: MarketType;
    isParlay: boolean;
  };
}

const checkboxGroupDom = ref<any>(null);

export function useLeagueList({ isResult } = { isResult: false }) {
  const { optionsStore } = useSportOptions();

  const store = reactive<LeagueStore>({
    form: {
      periodId: 0,
      sportId: 0,
      oddsType: optionsStore.value.marketType,
      isParlay: false
    },
    resultForm: {
      startTime: '',
      endTime: '',
      sportId: 1
    },
    leaguesList: {},
    leagueIds: [],
    isCheckedAll: false,
    isReverse: false
  });

  const state = reactive({
    loading: false
  });

  // 是否可以点击
  const isBtnDisabled = computed(() => isEmpty(store.leagueIds));

  const leagueCouter = computed(() => Number(store.leagueIds && store.leagueIds.length));

  // 首字母
  const letters = computed(() => Object.keys(store.leaguesList) || []);

  const leagueCounter = computed(() => {
    let reulst = 0;
    letters.value.map((el: any) => {
      reulst += store.leaguesList[el].length;
    });
    return reulst;
  });

  // 全选
  const checkAll = isChecked => {
    store.isCheckedAll = isChecked;
    !!checkboxGroupDom.value && checkboxGroupDom.value.toggleAll(true);
  };

  // 反选
  const toggleAll = isChecked => {
    store.isReverse = isChecked;
    !!checkboxGroupDom.value && checkboxGroupDom.value.toggleAll(true);
  };

  const getLeaguesData = async () => {
    state.loading = true;
    if (isResult) {
      await getSesultData();
    } else {
      await getMarketLeagues();
    }
  };

  // 获取盘口联赛列表
  const getMarketLeagues = async () => {
    const [res, data] = await queryLeagueList(store.form);
    state.loading = false;
    if (res && !isEmpty(data)) {
      const d = await formatLeagueList(data);
      store.leaguesList = d;
    } else {
      store.leagueIds = [];
    }
  };

  // 获取赛果联赛列表
  const getSesultData = async () => {
    const [res, data] = await getThirdLeagueId(store.resultForm);
    state.loading = false;
    if (res && !isEmpty(data)) {
      const d = await formatLeagueList(data);
      store.leaguesList = d;
    } else {
      store.leagueIds = [];
    }
  };

  return {
    leagueStore: store,
    state,
    checkAll,
    toggleAll,
    leagueCouter,
    getLeaguesData,
    checkboxGroupDom,
    leagueCounter,
    letters,
    isBtnDisabled
  };
}
