import { defineComponent, PropType } from 'vue';
import style from './anchor-list.module.less';
import { useI18n, MatchInfoCard } from '@/hooks';
import { Image } from 'ant-design-vue';

export default defineComponent({
  name: 'AnchorList',
  props: {
    data: {
      type: Array as PropType<MatchInfoCard[]>
    },
    modelValue: [String, Number]
  },
  emits: ['update:modelValue', 'switch'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const onHandleAnchor = (data: any) => {
      emit('update:modelValue', data.id);
      emit('switch', data);
    };

    const AnchorItemRender = ({ item }: { item: MatchInfoCard }) => {
      const isLive = item.status === 1;
      return (
        <div
          class={[
            style.anchorItem,
            props.modelValue === item.id ? style.anchorItem__active : null,
            isLive ? style.isLive : null
          ]}
          onClick={() => onHandleAnchor(item)}
        >
          <div class={style.anchorItem__label}>
            <span>{isLive ? t('lang.sport_detail_living') : t('lang.sport_detail_noLiving')}</span>
          </div>
          <div class={style.anchorItem__info}>
            <div class={style.match}>
              <div class={style.league}>{item.league}</div>
              <div class={style.home}>{item.home}</div>
              <div class={style.away}>{item.away}</div>
            </div>
            <div class={style.anchorItem__cover}>
              <Image src={item.cover} />
            </div>
          </div>
          <div class={style.anchorItem__footer}>
            <div class={style.anchorItem__time}>{isLive ? '上半场 02:20' : item.startTime}</div>
            <div class={style.anchorItem__nick}>{item.anchorNick}</div>
          </div>
        </div>
      );
    };

    return () => (
      <div class={style.anchorList}>
        {props.data && props.data.map(aItem => <AnchorItemRender item={aItem} key={aItem.id} />)}
      </div>
    );
  }
});
