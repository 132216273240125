import { defineComponent, PropType } from 'vue';
import { get, isEmpty } from 'lodash-es';
import LiveVideo from 'kg-web/views/detail/components/live-video/index';
import { TeamLogo } from 'kg-web/components';
import style from './live-match-video.module.less';
import { useI18n } from '@/hooks';

export default defineComponent({
  props: {
    videoUrls: {
      type: Array as PropType<string[]>
    },
    matchData: {
      type: Object as PropType<any>
    }
  },
  setup(props) {
    const { t } = useI18n();

    const MatchInfoRender = () => (
      <div class={style.liveMatchCard__matchInfo}>
        <div class={style.liveMatchCard__teamWrap}>
          {props.matchData?.homeLogo ? (
            <img class={style.logo} src={props.matchData?.homeLogo} />
          ) : (
            <TeamLogo class={style.logo} teamId={props.matchData?.homeId} size={'xs'} />
          )}
          <div class={style.liveMatchCard__team}>{props.matchData?.home || '--'}</div>
        </div>
        <div class={style.liveMatchCard__score}>
          <span>{get(props.matchData, 'score[0]') || '0'}</span>
          <span>VS</span>
          <span>{get(props.matchData, 'score[1]') || '0'}</span>
        </div>
        <div class={style.liveMatchCard__teamWrap}>
          <div class={style.liveMatchCard__team}>{props.matchData?.away || '--'}</div>
          {props.matchData?.awayLogo ? (
            <img class={style.logo} src={props.matchData?.awayLogo} />
          ) : (
            <TeamLogo class={style.logo} teamId={props.matchData?.awayId} size={'xs'} marginLeft />
          )}
        </div>
      </div>
    );

    const VideoViewRender = () => (
      <div class={style.liveMatchCard__videoWrap}>
        {!isEmpty(props.videoUrls) ? (
          <LiveVideo class={style.liveMatchCard__videoWrapInner} urls={props.videoUrls} />
        ) : (
          <div class={[style.liveMatchCard__videoWrapInner, style.noMatch]}>
            <div>
              {/* 比赛尚未开始 */}
              {t('lang.sport_detail_noMatch')}
            </div>
            <div class={style.buttonTheme}>
              {/* 更多比赛 */}
              {t('lang.sport_detail_moreMatch')}
            </div>
          </div>
        )}
      </div>
    );

    return () => (
      <div class={style.liveMatchCard}>
        <MatchInfoRender />
        <VideoViewRender />
      </div>
    );
  }
});
