<template>
  <div class="ontell-box" v-if="IsIntelllist">
    <div class="box_top">
      <AvatarCmt :dividerShow="false" :nametitle="matchInfo.HomeName" :sportId="sportType" />
      <AvatarCmt :dividerShow="false" :nametitle="matchInfo.AwayName" :sportId="sportType" :flexRver="true" />
    </div>
    <div class="box_content">
      <div class="box">
        <div v-if="intelllist.info.good.home && intelllist.info.good.home.length > 0">
          <p class="favorable-p">{{ t('lang.sport_detail_GoodIntelligence') }}</p>
          <p class="favorable-span" v-for="(item, index) in intelllist.info.good.home" :key="index">{{ item[1] }}</p>
        </div>
        <div v-if="intelllist.info.bad.home && intelllist.info.bad.home.length > 0">
          <p class="unfavorable-p">{{ t('lang.sport_detail_BadIntelligence') }}</p>
          <p class="unfavorable-span" v-for="(item, index) in intelllist.info.bad.home" :key="index">{{ item[1] }}</p>
        </div>
      </div>
      <div class="box">
        <div v-if="intelllist.info.good.away && intelllist.info.good.away.length > 0">
          <p class="favorable-p flex_end">{{ t('lang.sport_detail_GoodIntelligence') }}</p>
          <p class="favorable-span" v-for="(item, index) in intelllist.info.good.away" :key="index">{{ item[1] }}</p>
        </div>
        <div v-if="intelllist.info.bad.away && intelllist.info.bad.away.length > 0">
          <p class="unfavorable-p flex_end">{{ t('lang.sport_detail_BadIntelligence') }}</p>
          <p class="unfavorable-span" v-for="(item, index) in intelllist.info.bad.away" :key="index">{{ item[1] }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="neutual-box" v-if="IsIntelllist && intelllist.info.neutral">
    <p class="text_title">{{ t('lang.sport_detail_NeutralIntelligence') }}</p>
    <div class="text_content_box" v-for="(item, index) in intelllist.info.neutral" :key="index">
      <p class="p_s">{{ item[1] }}</p>
    </div>
  </div>
  <data-empty v-else :title="t('lang.sport_common_noIntelligenceData')" />
</template>

<script>
import { inject, computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { DataEmpty } from 'kg-web/components';
import AvatarCmt from '../range-data/smallCMT/AvatarCmt.vue';
export default defineComponent({
  components: {
    DataEmpty,
    AvatarCmt
  },
  props: {
    intelllist: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { t } = useI18n();

    const IsIntelllist = computed(() => JSON.stringify(props.intelllist) != '{}');

    const { matchInfo, sportType, moreMatchInfo } = inject('matchInfo');

    return { t, matchInfo, sportType, moreMatchInfo, IsIntelllist };
  }
});
</script>

<style lang="less" scoped>
.favorable-p,
.unfavorable-p {
  color: @white;
  margin: 12px 0 8px;
}
.favorable-span,
.unfavorable-span {
  margin: 0px;
  padding: 8px 10px;
  background: #535353;
  color: #cecece;
  font-size: 12px;
  margin-top: 6px;
}
.unfavorable-span {
  background: #404040;
}
.ontell-box {
  border-radius: 20px;
  background-color: #383838;
  margin-bottom: 10px;
  .box_top {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    border-bottom: 1px solid #404040;
  }
  .box_content {
    display: flex;
    padding: 12px 6px;
    .box {
      flex: 1;
      padding: 0px 10px;
    }
  }
}
.neutual-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #1e6e51;
  margin: 0 16px 15px;
  padding: 6px 0;
  .text_title {
    font-weight: 600;
    margin-bottom: 4px;
    color: @white;
  }
  .text_content_box {
    width: 90%;
    border-radius: 10px;
    padding: 0px 8px;
    margin: 2px 0px;
  }
}

.flex_end {
  display: flex;
  justify-content: flex-end;
}
</style>
