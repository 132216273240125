import { defineComponent, ref, watch } from 'vue';

import style from './index.module.less';

export default defineComponent({
  props: {
    maxHeight: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  emits: ['scroll'],
  setup(props, { slots, emit }) {
    const element = ref(null) as any;
    watch(
      () => element.value,
      () => {
        element.value?.addEventListener('scroll', e => emit('scroll', e));
      }
    );
    return () => (
      <div
        class={style.container}
        style={{
          height: props.height,
          maxHeight: props.maxHeight
        }}
        ref={element}
      >
        {slots.default?.()}
      </div>
    );
  }
});
