import { defineComponent } from 'vue';
import { RadioGroup, Checkbox, Radio, Switch } from 'ant-design-vue';

import { RadioView, CheckView } from 'kg-web/components';
import { BetPreferenceList } from '@/common';
import { useSportOptions, useI18n } from '@/hooks';

import style from './setting.module.less';

export default defineComponent({
  name: 'JBBsportPersonal',
  setup() {
    const { optionsStore } = useSportOptions();
    const { t } = useI18n();

    const onChange = value => {
      optionsStore.value.betSuccessSound = value;
      optionsStore.value.confirmationSound = value;
    };
    return () => (
      <div class={style.settings}>
        <div class={style.title}>{t('lang.sport_setting_betOptions')}</div>
        <RadioGroup v-model:value={optionsStore.value.preference}>
          {BetPreferenceList.map(item => (
            <RadioView>
              <Radio value={item.value}>{t(item.label)}</Radio>
            </RadioView>
          ))}
        </RadioGroup>
        <div class={style.hr}></div>
        <div class={style.betRow}>
          <div class={style.betTitle}>{t('lang.sport_setting_soundsSwitch')}</div>
          <Switch v-model:checked={optionsStore.value.switchSound} onChange={onChange} />
        </div>
        <div class="el-radio-group">
          <CheckView>
            <Checkbox v-model:checked={optionsStore.value.betSuccessSound} disabled>
              {/* 投注成功音效 */}
              <div class={style.checdBox}>{t('lang.sport_setting_betSuccessSound')}</div>
            </Checkbox>
          </CheckView>
          <CheckView>
            <Checkbox v-model:checked={optionsStore.value.confirmationSound} disabled>
              {/* 投注确认中音效 */}
              <div class={style.checdBox}>{t('lang.sport_setting_betconfirmationSound')}</div>
            </Checkbox>
          </CheckView>
        </div>
        <div class={style.hr}></div>
      </div>
    );
  }
});
