<template>
  <div class="box">
    <!-- <div :id="id" class="echarts-box" style="width: 165px; height: 130px" ref="chartBox"></div> -->
    <RingPieChart :win="processData[0]" :lost="processData[1]" />
    <div class="process">
      <div class="item">
        <span class="label">{{ t('lang.sport_details_winRate') }}</span
        ><span class="win">{{ processData[0] + '%' }}</span>
      </div>
      <div class="item not-win">
        <div>
          <span class="label">{{ t('lang.sport_common_defeat') }}</span
          ><span class="loss">{{ processData[1] + '%' }}</span>
        </div>
        <div>
          <span class="label">{{ t('lang.sport_details_draw') }}</span
          ><span class="again">{{ processData[2] + '%' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { sum } from 'lodash-es';
import { useI18n } from 'vue-i18n';
import RingPieChart from '@/pages/kg-web/components/ring-pie-chart/index.tsx';

export default {
  name: 'Winloststatus',
  components: { RingPieChart },
  props: {
    id: {
      type: String,
      default: 'main'
    },
    arrlist: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { t } = useI18n();
    const processData = computed(() => {
      const sumData = sum(props.arrlist);
      return sumData === 0 ? ['无', '无', '无'] : props.arrlist.map(o => (o / sumData) * 100);
    });
    return { processData, t };
  }
};
</script>

<style lang="less" scoped>
.box {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.process {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 16px 8px 16px;
  margin-top: 20px;
  .item {
    box-sizing: border-box;
    width: 100%;
  }
  .label {
    font-size: 12px;
    color: #cecece;
    margin-left: 4px;
  }
  .win {
    color: #b52520;
    font-size: 21px;
    font-size: 500;
  }
  .loss {
    color: #009944;
    font-size: 18px;
  }
  .again {
    color: #cecece;
    font-size: 18px;
  }
  .not-win {
    margin-top: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
}
</style>
