import { defineComponent, PropType, reactive, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import './market-list.style.less';
import { MarketItem } from '365-mobile/components';
import { StoreMarketData, useI18n, useSportTypes } from '@/hooks';
import {
  BetTypeEnum,
  getSportNameById,
  gameType1,
  gameType2,
  gameTypeArray,
  targetTypeArray,
  targetType,
  DataTypeEnum
} from '@/common';
import { SvgIcon } from '@/components';
import { onClickOutside, useStorage, RemovableRef } from '@vueuse/core';
const GAME_TYPE_ACTIVE = 'GAME_TYPE_ACTIVE';

export default defineComponent({
  name: 'MarketList',
  props: {
    marketData: {
      type: Array as PropType<StoreMarketData[]>,
      default: () => []
    },
    leagueCounter: {
      type: Number
    },
    sportName: {
      type: String,
      default: ''
    },
    showLeagueFilter: {
      type: Boolean,
      default: true
    },
    /**
     * 加载分页
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * 请求结束,阻止请求
     */
    requestFinish: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openLeagueFilter', 'loadMore', 'delMarkets'],
  setup(props, { emit }) {
    const { activeSportType, getSportName, activePeriodId } = useSportTypes();
    const route = useRoute();
    const { t } = useI18n();
    const outTarget = ref(null);

    onClickOutside(outTarget, () => (states.target = false));
    const gameTypeActive: RemovableRef<number> = useStorage(GAME_TYPE_ACTIVE, 3);
    const states = reactive({
      avtiveTime: 0,
      isExpandAll: true,
      open: false,
      setVisible: false,
      setTrick: false,
      target: false,
      betType: BetTypeEnum.proposition,
      gameTypeActive: computed(() => gameTypeActive.value),
      targetTypeActive: 0
    });

    // const openLeagueFilter = () => emit('openLeagueFilter');
    const periodId = computed(() => Number(route.query.periodId || 3));
    // const show = computed(() => {
    //   return [1, 2].includes(Number(route.query.sportId));
    // });
    const choseGameType = (key: number) => {
      states.setTrick = !states.setTrick;
      gameTypeActive.value = key;
    };
    const choseTargetType = (key: number) => {
      states.target = !states.target;
      states.targetTypeActive = key;
    };
    const openLeagueFilter = () => emit('openLeagueFilter');

    const MarketListHeader = () => {
      return (
        <div class="market-list-header">
          <div class={'curret-ball'}>
            <span class={'curret-ball-name'}>
              {props.sportName || getSportName() || getSportNameById(activeSportType.value)}
            </span>
            {/*展示选项*/}
            <div
              v-show={DataTypeEnum.Live === periodId.value && activeSportType.value == 1}
              class={states.open ? 'curret-ball-mode' : 'curret-ball-act'}
              onClick={() => (states.open = !states.open)}
            />
          </div>
          <div class="market-list-box">
            <div class="market-list-pop">
              <div class="market-list-drop" onClick={() => (states.setTrick = !states.setTrick)}>
                <div>
                  {activeSportType.value === 1 || activeSportType.value === 2 || activeSportType.value === 16
                    ? t(gameType1[states.gameTypeActive].bettypeName)
                    : t(gameType2[states.gameTypeActive].bettypeName)}
                </div>
                <SvgIcon name="arrow_down" />
              </div>
              <div v-show={states.setTrick} class="market-list-dialog">
                {gameTypeArray.map((item, index) => (
                  <p
                    class={states.gameTypeActive == item ? 'active' : ''}
                    key={index + 'g'}
                    onClick={() => {
                      choseGameType(item);
                    }}
                  >
                    {activeSportType.value === 1 || activeSportType.value === 2 || activeSportType.value === 16
                      ? t(gameType1[item].bettypeName)
                      : t(gameType2[item].bettypeName)}
                  </p>
                ))}
              </div>
            </div>
            {/* 联赛筛选 */}
            {props.showLeagueFilter ? (
              <div
                class="market-list-box-league"
                onClick={() => openLeagueFilter()}
                // v-show={Number(route.query.periodId) !== -1}
              />
            ) : null}
          </div>
        </div>
      );
    };
    const ControlBar = () => {
      return (
        <div class="control-bar" onClick={() => (states.setTrick = false)}>
          <p>
            {t('lang.sport_common_show')}
            <i
              onClick={() => {
                states.target = !states.target;
              }}
            >
              {targetType[states.targetTypeActive].targetName}
            </i>
          </p>
          <div v-show={states.target} ref={outTarget} class="market-list-table">
            {targetTypeArray.map((item, index) => (
              <p
                key={index + 's'}
                onClick={() => {
                  choseTargetType(item);
                }}
                class={states.targetTypeActive == item ? 'active' : ''}
              >
                {/*<img src={item} alt="" />*/}
                <svg-icon name={targetType[item].icon} />
                {targetType[item].targetName}
              </p>
            ))}
          </div>
        </div>
      );
    };
    const ClickLoadMoreRender = () =>
      props.requestFinish ? (
        <div class="market-load-more">{t('lang.sport_common_noMoreData')}</div>
      ) : props.loading ? (
        <div class="market-load-more">
          <SvgIcon name="loading_v3" />
          <span>{t('lang.sport_common_loading')}</span>
        </div>
      ) : (
        <div class="market-load-more" onClick={() => emit('loadMore')}>
          {t('lang.sport_common_clickLoading')}
        </div>
      );
    return () => [
      <div class="market-list">
        <MarketListHeader />
        <ControlBar v-show={states.open} />
        {props.marketData.map((item, index) => (
          <MarketItem
            itemIndex={index}
            data={item}
            betType={states.betType}
            gameTypeActive={states.gameTypeActive}
            targetTypeActive={states.targetTypeActive}
            key={item.leagueId + String(index)}
            isChildShow={states.open}
          />
        ))}
        {[DataTypeEnum.Today, DataTypeEnum.Early].includes(activePeriodId.value) ? <ClickLoadMoreRender /> : null}
      </div>
    ];
  }
});
