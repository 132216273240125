import { onMounted, onBeforeUnmount, provide, watch, defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useEventsAndMarkets, useDetailHook, useTimerHook, TimerEnum, useI18n } from '@/hooks';
import { MarketType } from '@/common';
import { Scrollbar, ShoppingCart } from 'kg-web/components';
import { useBetWeb } from '@/hooks/useBetWeb.hook';
// import Orders from '../orders';
import OrdersNew from '../orders-new';
import { SidebarSports, ScorecardMinimal, LiveMatchAnimate, DetailedMatchOdds } from './components';
import style from './detail.module.less';

export default defineComponent({
  setup() {
    const route = useRoute();
    const detailProvide = useDetailHook();
    const { store, getData } = detailProvide;
    const { eventStore, getEventsAndMarkets } = useEventsAndMarkets();
    const { clearTimer } = useTimerHook();
    const { prebetInfo } = useBetWeb();
    const { t } = useI18n();
    const tabAct = ref(1);
    const isFirstAnimate = ref(false);
    const isAnimate = computed(() => !!store.info && !!store.info.animate);
    provide('detailProvide', detailProvide);
    onMounted(async () => {
      const { sportId, periodId, matchId } = route.query;
      eventStore.form.periodId = Number(periodId);
      eventStore.form.sportId = Number(sportId);
      store.periodId = Number(periodId);
      await getData(
        {
          oddsType: MarketType.Europe,
          sportId: Number(sportId),
          periodId: Number(periodId),
          eventIds: String(matchId)
        },
        true,
        true
      );
      await getEventsAndMarkets(true);
      if (prebetInfo.value.length) {
        tabAct.value = 1;
      }
      if (!!store.info && !!store.info.animate) {
        tabAct.value = 0;
      }
    });
    watch(
      () => route.query,
      async val => {
        if (route.path !== '/detail') return;
        const { sportId, periodId, matchId } = val;
        store.periodId = Number(periodId);
        await getData({
          oddsType: MarketType.Europe,
          sportId: Number(sportId),
          periodId: Number(periodId),
          eventIds: String(matchId)
        });
      }
    );
    watch(
      () => prebetInfo.value.length,
      () => {
        tabAct.value = 1;
      }
    );
    watch(
      () => isAnimate.value,
      val => {
        if (!val) {
          tabAct.value = 1;
          isFirstAnimate.value = false;
        }
      }
    );
    watch(
      () => store.info,
      () => {
        if (!!store.info && !!store.info.animate && !isFirstAnimate.value) {
          tabAct.value = 0;
          isFirstAnimate.value = true;
        }
      }
    );
    onBeforeUnmount(() => {
      clearTimer(TimerEnum.DETAIL);
      store.errorPage = false;
      detailProvide.clearStore();
    });

    return () => (
      <section class={style.pageWrap}>
        <aside class={style.asideDefault}>
          <Scrollbar>
            <SidebarSports />
          </Scrollbar>
        </aside>
        <section class={style.mainDefault}>
          <ScorecardMinimal matchInfo={store.info} />
          <DetailedMatchOdds />
        </section>
        <aside class={style.asideDefault}>
          <div class={style.tabs}>
            {isAnimate.value ? (
              <div class={[style.btn, !tabAct.value && style.btnAct]} onClick={() => (tabAct.value = 0)}>
                {t('lang.sport_details_animate')}
              </div>
            ) : null}
            <div class={[style.btn, tabAct.value === 1 && style.btnAct]} onClick={() => (tabAct.value = 1)}>
              {t('lang.sport_bet_bettingSlip')} {prebetInfo.value.length ? prebetInfo.value.length : ''}
            </div>
            <div class={[style.btn, tabAct.value === 2 && style.btnAct]} onClick={() => (tabAct.value = 2)}>
              {t('lang.sport_common_bettingRecords')}
            </div>
          </div>
          {tabAct.value === 0 ? (
            <Scrollbar height="calc(100% - 44px)">
              <LiveMatchAnimate animate={store.info.animate} />
            </Scrollbar>
          ) : null}
          {tabAct.value === 1 ? <ShoppingCart /> : null}
          {tabAct.value === 2 ? <OrdersNew /> : null}
        </aside>
      </section>
    );
  }
});
