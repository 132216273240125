import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import style from './index.module.less';

const list = [] as any;
export const onScroll = data => {
  return list.findIndex((item: { value: { offsetTop: number } }) => {
    return item?.value?.offsetTop >= data?.target?.scrollTop;
  });
}; // elscroll取不到滚动条元素下
export const IndexBar = defineComponent({
  props: {
    indexList: {
      type: Array,
      default: () => []
    },
    element: {
      type: Object
    },
    index: {
      type: Number,
      default: 0
    }
  },
  setup(props, { slots }) {
    const onClick = (index: number) => {
      const element = list[index];
      element?.value?.scrollIntoView();
    };
    const select = ref(null);
    let init = false;
    const onScroll = () => {
      init = true;
      select.value = list.findIndex((item: { value: { offsetTop: number } }) => {
        return item.value.offsetTop >= props?.element?.value.scrollTop;
      });
    };
    const getSelect = () => {
      return !init ? props.index : select.value;
    };
    setTimeout(() => {
      props?.element?.value?.addEventListener('scroll', onScroll);
    }, 2000); // 注册事件

    watch(
      () => props.indexList,
      () => {
        list.length = 0;
        props.indexList.forEach(() => {
          const element = ref(null);
          list.push(element);
        });
      },
      {
        immediate: true
      }
    );

    onBeforeMount(() => {
      props?.element?.value?.removeEventListener('scroll', onScroll);
    });
    return () => (
      <div>
        <div class={style.indexBar}>
          <div class={style.indexBar__sidebar}>
            {slots.title?.()}
            {props.indexList.map((item, index) => (
              <span
                class={[style.indexBar__index, getSelect() === index ? style.active : '']}
                onClick={() => onClick(index)}
              >
                {item}
              </span>
            ))}
          </div>
        </div>
        {slots.default?.()}
      </div>
    );
  }
});

export const IndexAnchor = defineComponent({
  props: {
    index: {
      type: [String, Number],
      default: ''
    }
  },
  setup(props, { slots }) {
    return () => (
      <div class={style.indexAnchor} ref={list[props.index]}>
        {slots.default?.()}
      </div>
    );
  }
});
