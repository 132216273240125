import { defineComponent, onMounted, onUnmounted, ref, withModifiers } from 'vue';
import { jumpToNativeLogin } from '@/common';
import { useI18n } from '@/hooks';
import './style.less';

export default defineComponent({
  name: 'LoginDialog',
  setup() {
    const { t } = useI18n();
    const show = ref(true);
    const hideAnimate = ref(false);

    onMounted(() => {
      document.body.classList.add('login-lock-scroll');
      window.addEventListener('popstate', onHandleClose);
    });

    onUnmounted(() => {
      document.body.classList.remove('login-lock-scroll');
      window.removeEventListener('popstate', onHandleClose);
    });

    const onHandleClose = () => {
      if (!show.value) return;
      hideAnimate.value = true;
      setTimeout(() => {
        show.value = false;
        hideAnimate.value = false;
        document.body.classList.remove('login-lock-scroll');
      }, 200);
    };

    const toLogin = () => {
      window.parent.postMessage({ type: 'login' }, '*');
      jumpToNativeLogin({ autoLogin: false });
      console.log('To Login');
    };

    const DomRnder = () => (
      <div class="login-dialog" v-show={show.value} onClick={withModifiers(onHandleClose, ['stop'])}>
        <div class={['login-dialog-container', hideAnimate.value ? 'top-fade-out' : null]}>
          <div class="login-dialog-stream">精彩内容等你来体验,快去登录吧!</div>
          <div
            class="login-dialog-login-button"
            onClick={withModifiers(() => {
              toLogin();
              onHandleClose();
            }, ['stop'])}
          >
            <div class="login-dialog-login-button-text">{t('lang.sport_home_login')}</div>
          </div>
          <div class="login-dialog-join-button" onClick={withModifiers(onHandleClose, ['stop'])}>
            <div class="login-dialog-join-button-text">{t('lang.sport_common_cancel')}</div>
          </div>
        </div>
      </div>
    );

    return DomRnder;
  }
});
