import { AxiosRequestConfig, AxiosError } from 'axios';
import qs from 'qs';
import md5 from 'blueimp-md5';
import { createHttp } from './src';
import { HttpCode } from '@/common/enums';
import { isEmpty, isUndefined } from 'lodash-es';
import { getLoginData, clearLoginData, getTenant, getSportApi, langFormat } from '@/common/utils';
export { httpSB } from './httpSB';
const APP_VERSION = process.env.VUE_APP_VERSION;
import store from '@/store';
import { useSportOptions } from '@/hooks';

let osType = 1;

export function setOsType(type: 1 | 0) {
  osType = type;
}

export function getOsType() {
  return osType;
}

export const http = createHttp({
  baseURL: getSportApi(),
  timeout: 20000
});

function urlEncode(param: any, key?: string, encode?: string) {
  if (param == null) return '';
  let paramStr = '';
  const t = typeof param;
  if (t == 'string' || t == 'number' || t == 'boolean') {
    paramStr += `&${key}=${encode == null || encode ? encodeURIComponent(param) : param}`;
  } else {
    for (const i in param) {
      const k = key == null ? i : key + (param instanceof Array ? `[${i}]` : `.${i}`);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
}

function getUTClabel() {
  const timeOffset = 0 - new Date().getTimezoneOffset() / 60;
  const label = 'GMT+' + (Math.abs(timeOffset) > 10 ? timeOffset : `0${timeOffset}`) + ':00';
  return label;
}

// H5头参数
function getH5Header() {
  const { optionsStore } = useSportOptions();

  const timestamp = Date.now();
  const signParams: any = {};
  const { token } = getLoginData();
  signParams['device-id'] = 'no-uuid';
  signParams['os-type'] = getOsType();
  signParams.timestamp = timestamp;
  signParams.version = APP_VERSION || '1.0.0';
  const urlStr = urlEncode(signParams).substr(1);
  signParams.sign = md5(`${urlStr}global`);
  signParams['app-type'] = 1;
  if (!!token) {
    signParams.token = token;
    signParams.uid = '';
  }
  signParams.country = langFormat(optionsStore.value.lang);
  signParams.tenant = getTenant();
  signParams['time-zone'] = getUTClabel();
  return signParams;
}

http.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    if (config.method === 'post') {
      const params = config.data;
      if (typeof params === 'object') {
        config.data = qs.stringify(params, { allowDots: true });
      }
    }
    config.headers = Object.assign(
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...(await getH5Header())
      },
      config.headers
    );
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response: any) {
    /**
     * 处理获取geet
     */
    const { success } = response;
    if (!!success) {
      return [true, response];
    }
    /**
     * 特殊处理部分接口数据结构混乱
     */
    if (response.code === undefined) {
      return response;
    }
    if (response.code === HttpCode.SUCCESS) {
      return [true, response.data];
    }
    if (response.code === HttpCode.FAIL) {
      return [false, response.message];
    }
    if (response.code === HttpCode.AUTH_FAIL) {
      clearLoginData();
      store.dispatch('SIGN_OUT');
      return [false, undefined];
    }
    if (response.code == 112) {
      clearLoginData();
      store.dispatch('SIGN_OUT');
      return [false, { msg: response.message, code: response.code }];
    }
    if (response && isEmpty(response)) {
      return [false, undefined];
    }
    if (response && isUndefined(response.code) && !response.errno) {
      return [true, response ? response.data : undefined];
    }
    if (response.code === HttpCode.EXPIRE || response.code === HttpCode.KICK) {
      // 退出登录到login
      // 如果是APP登录失效 退会app 登录
      // 清除cookie 清除定时器 跳转app
      // clearInterval(window.heartBeatTimer);
      // window.heartBeatTimer = undefined;
      clearLoginData();
      store.dispatch('SIGN_OUT');

      return [false, response];
    }
    // captureError(data, signParams);
    return [false, response];
  },
  function (error: AxiosError<any>) {
    const response = { code: (error.response && error.response.status) || -999, message: error.message };
    if (error.response && error.response.status) {
      if (error.response.status === 404) {
        response.message = 'ERROR 网络请求丢失';
      } else if (error.response.status === 600) {
        response.message = '请求失败，请重新登录';
      } else if ([500, 502, 503, 504].includes(error.response.status)) {
        response.message = 'ERROR 服务器维护中';
      } else if ([501].includes(error.response.status)) {
        response.message = 'ERROR 网络请求错误';
      } else if (error.response.statusText.includes('timeout')) {
        response.message = 'ERROR 请求超时';
      } else if (error.response.status === HttpCode.KICK) {
        // 退出登录到login
        // 如果是APP登录失效 退会app 登录

        clearLoginData();
        store.dispatch('SIGN_OUT');
        console.error('ERROR 登录失败, 请重试');
        response.message = 'ERROR 登录失败, 请重试';
      } else {
        response.message = 'ERROR 请求失败!';
      }
    }
    return Promise.resolve([false, response]);
  }
);

window.http = http;
