import { defineComponent, PropType, reactive, ref, computed } from 'vue';
import './market-list.style.less';
import { MarketItem } from '365-mobile/components';
import { StoreMarketData, useI18n } from '@/hooks';
import {
  BetTypeEnum,
  gameType1,
  gameType2,
  gameTypeArray,
  targetTypeArray,
  targetType,
  getSportNameById
} from '@/common';
import { SvgIcon } from '@/components';
import { onClickOutside, useStorage, RemovableRef } from '@vueuse/core';
const GAME_TYPE_ACTIVE = 'GAME_TYPE_ACTIVE';

export default defineComponent({
  name: 'FollowMarketList',
  props: {
    marketData: {
      type: Array as PropType<StoreMarketData[]>,
      default: () => []
    },
    sportId: {
      type: [String, Number],
      default: ''
    }
  },
  setup(props) {
    const { t } = useI18n();
    const outTarget = ref(null);

    onClickOutside(outTarget, () => (states.target = false));
    const gameTypeActive: RemovableRef<number> = useStorage(GAME_TYPE_ACTIVE, 3);
    const states = reactive({
      avtiveTime: 0,
      isExpandAll: true,
      open: false,
      setVisible: false,
      setTrick: false,
      target: false,
      betType: BetTypeEnum.proposition,
      gameTypeActive: computed(() => gameTypeActive.value),
      targetTypeActive: 0
    });

    const choseGameType = (key: number) => {
      states.setTrick = !states.setTrick;
      gameTypeActive.value = key;
    };
    const choseTargetType = (key: number) => {
      states.target = !states.target;
      states.targetTypeActive = key;
    };

    const MarketListHeader = () => {
      return (
        <div class="market-list-header">
          <div class={'curret-ball'}>
            <span class={'curret-ball-name'}>{getSportNameById(Number(props.sportId)) || ''}</span>
            {/*展示选项*/}
            <div
              v-show={[1].includes(Number(props.sportId))}
              class={states.open ? 'curret-ball-act' : 'curret-ball-mode'}
              onClick={() => (states.open = !states.open)}
            />
          </div>
          <div class="market-list-box">
            <div class="market-list-pop">
              <div class="market-list-drop" onClick={() => (states.setTrick = !states.setTrick)}>
                <div>
                  {Number(props.sportId) === 1 || Number(props.sportId) === 2 || Number(props.sportId) === 16
                    ? t(gameType1[states.gameTypeActive].bettypeName)
                    : t(gameType2[states.gameTypeActive].bettypeName)}
                </div>
                <SvgIcon name="arrow_down" />
              </div>
              <div v-show={states.setTrick} class="market-list-dialog">
                {gameTypeArray.map((item, index) => (
                  <p
                    class={states.gameTypeActive == item ? 'active' : ''}
                    key={index + 'g'}
                    onClick={() => {
                      choseGameType(item);
                    }}
                  >
                    {Number(props.sportId) === 1 || Number(props.sportId) === 2 || Number(props.sportId) === 16
                      ? t(gameType1[item].bettypeName)
                      : t(gameType2[item].bettypeName)}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    };
    const ControlBar = () => {
      return (
        <div class="control-bar">
          <p>
            {t('lang.sport_common_show')}
            <i onClick={() => (states.target = !states.target)}>{targetType[states.targetTypeActive].targetName}</i>
          </p>
          <div v-show={states.target} ref={outTarget} class="market-list-table">
            {targetTypeArray.map((item, index) => (
              <p
                key={index + 's'}
                onClick={() => choseTargetType(item)}
                class={states.targetTypeActive == item ? 'active' : ''}
              >
                <svg-icon name={targetType[item].icon} />
                {targetType[item].targetName}
              </p>
            ))}
          </div>
        </div>
      );
    };

    return () => [
      <div class="market-list">
        <MarketListHeader />
        <ControlBar v-show={states.open} />
        {props.marketData.map((item, index) => (
          <MarketItem
            itemIndex={index}
            data={item}
            betType={states.betType}
            gameTypeActive={states.gameTypeActive}
            targetTypeActive={states.targetTypeActive}
            key={item.leagueId + String(index)}
            isChildShow={states.open}
          />
        ))}
      </div>
    ];
  }
});
