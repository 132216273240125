import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
window.dayjs = dayjs;

// 格式化时间
export function toTime(time: Date | string | number, format = 'YYYY/MM/DD HH:mm:ss') {
  if (!time) return '';
  const formatTime = dayjs(time).format(format);
  if (formatTime === 'Invalid Date') {
    return time;
  }
  return formatTime;
}

//精选赛事 时间格式处理
export function isTodayFormat(time: Date | string | number, format = 'HH:mm') {
  if (!time) return '';
  const date = dayjs(time);
  const todayFormat = 'MM/DD';
  const formatTime = date.format(todayFormat);
  if (formatTime === 'Invalid Date') {
    return time;
  }
  const today = dayjs().format(todayFormat);
  if (formatTime === today) {
    return [true, date.format(format)];
  }
  return [false, formatTime + ' ' + date.format(format)];
}

export function format(date: string | number | Date | Dayjs) {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}

export function today() {
  return format(new Date());
}

export function yestoday(day = -1) {
  return format(dayjs(new Date()).add(day, 'day'));
}

export function lastMonth() {
  return format(dayjs(new Date()).add(-1, 'month'));
}

export function lastSevenDays() {
  return format(dayjs(new Date()).add(-7, 'day'));
}
export function getDay(id, Bool = false) {
  if (Bool) {
    const date = format(dayjs(new Date()).add(id, 'day')) as any;
    return toTime(date, 'MM月DD日');
  } else {
    return format(dayjs(new Date()).add(id, 'day'));
  }
}

export function getWeekDay(date) {
  const weekDay = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const myDate = new Date(Date.parse(date.replace(/-/g, '/')));
  return weekDay[myDate.getDay()];
}

export const DATE_FIRST = [
  {
    title: getWeekDay(getDay(1)),
    date: getDay(1, true),
    starTime: { startTime: getDay(1), endTime: getDay(1) },
    value: 1
  },
  {
    title: getWeekDay(getDay(2)),
    date: getDay(2, true),
    starTime: { startTime: getDay(2), endTime: getDay(2) },
    value: 2
  },
  {
    title: getWeekDay(getDay(3)),
    date: getDay(3, true),
    starTime: { startTime: getDay(3), endTime: getDay(3) },
    value: 3
  },
  {
    title: getWeekDay(getDay(4)),
    date: getDay(4, true),
    starTime: { startTime: getDay(4), endTime: getDay(4) },
    value: 4
  },
  {
    title: getWeekDay(getDay(5)),
    date: getDay(5, true),
    starTime: { startTime: getDay(5), endTime: getDay(5) },
    value: 5
  },
  {
    title: getWeekDay(getDay(6)),
    date: getDay(6, true),
    starTime: { startTime: getDay(6), endTime: getDay(6) },
    value: 6
  },
  {
    title: getWeekDay(getDay(7)),
    date: getDay(7, true),
    starTime: { startTime: getDay(7), endTime: getDay(7) },
    value: 7
  },
  {
    title: '其他',
    date: '',
    starTime: '',
    value: 8
  }
];

export const DATE_LAST_WEEK = [
  {
    title: getWeekDay(getDay(0)),
    date: getDay(0, true),
    starTime: { startTime: getDay(0), endTime: getDay(0) }
  },
  {
    title: getWeekDay(getDay(-1)),
    date: getDay(-1, true),
    starTime: { startTime: yestoday(-1), endTime: yestoday(-1) }
  },
  {
    title: getWeekDay(getDay(-2)),
    date: getDay(-2, true),
    starTime: { startTime: yestoday(-2), endTime: yestoday(-2) }
  },
  {
    title: getWeekDay(getDay(-3)),
    date: getDay(-3, true),
    starTime: { startTime: yestoday(-3), endTime: yestoday(-3) }
  },
  {
    title: getWeekDay(getDay(-4)),
    date: getDay(-4, true),
    starTime: { startTime: yestoday(-4), endTime: yestoday(-4) }
  },
  {
    title: getWeekDay(getDay(-5)),
    date: getDay(-5, true),
    starTime: { startTime: yestoday(-5), endTime: yestoday(-5) }
  },
  {
    title: getWeekDay(getDay(-6)),
    date: getDay(-6, true),
    starTime: { startTime: yestoday(-6), endTime: yestoday(-6) }
  }
];

interface DayItem {
  date: number;
  starTime: { startTime: number; endTime: number };
  value: number;
  week: string | number;
}

/**
 * 获取星期的索引
 * 国际化支持
 */
export function getWeek(date?: Dayjs | number | string): number | string {
  const weekNamesI18 = [
    '',
    'lang.sport_earlyhandicap_monday',
    'lang.sport_earlyhandicap_tuesday',
    'lang.sport_earlyhandicap_wednesday',
    'lang.sport_earlyhandicap_thursday',
    'lang.sport_earlyhandicap_friday',
    'lang.sport_earlyhandicap_saturday',
    'lang.sport_earlyhandicap_sunday'
  ];
  const index = dayjs(date).isoWeekday();
  return weekNamesI18[index];
}

/**
 * 获取今天第几天日期
 */

export function getDayTimestamp(n: number) {
  return dayjs().add(n, 'day').valueOf();
}

/**
 * 生成未来7天日历
 */
export function generate7daysCalendar(): DayItem[] {
  const temArr: DayItem[] = [];
  for (let index = 1; index < 8; index++) {
    temArr.push({
      date: getDayTimestamp(index),
      starTime: { startTime: getDayTimestamp(index), endTime: getDayTimestamp(index + 1) },
      value: index,
      week: getWeek(getDayTimestamp(index))
    });
  }
  return temArr;
}

/**
 * 生成过去7天日历
 */
export function generateLast7days(): DayItem[] {
  const temArr: DayItem[] = [];
  for (let index = 8; index > 0; index--) {
    temArr.push({
      date: getDayTimestamp(index),
      starTime: { startTime: getDayTimestamp(index), endTime: getDayTimestamp(index + 1) },
      value: index,
      week: getWeek(getDayTimestamp(index))
    });
  }
  return temArr;
}
