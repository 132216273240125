import { defineComponent, computed } from 'vue';
import { RouteEnum, SPORT_H5_URL_KEY } from '@/common';
import { useRoute } from 'vue-router';
import styles from './tabbar.module.less';
import { useI18n } from '@/hooks';

const TAB_CONFIGS = [
  {
    icon: styles.bet,
    i18n_label: 'lang.sport_tabbar_bet',
    label: '投注',
    path: RouteEnum.ROOT
  },
  {
    icon: styles.orders,
    i18n_label: 'lang.sport_tabbar_orders',
    label: '注单',
    path: RouteEnum.ORDERS
  },
  {
    icon: styles.result,
    i18n_label: 'lang.sport_tabbar_result',
    label: '赛果',
    path: RouteEnum.RESULT
  },
  // {
  //   icon: styles.notice,
  //   i18n_label: 'lang.sport_tabbar_post',
  //   label: '公告',
  //   path: RouteEnum.NOTICE
  // },
  {
    icon: styles.set,
    i18n_label: 'lang.sport_tabbar_option',
    label: '设置',
    path: RouteEnum.OPTIONS
  }
];

const backUrl = () => {
  const link = localStorage.getItem(SPORT_H5_URL_KEY);
  if (!link) return '';
  const lastStr = link.substring(link.length - 1);
  return lastStr === '/' ? link + 'm/' : link + '/m/';
};

export default defineComponent({
  name: 'Tabbar',
  props: {},
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const path = computed(() => route.path);

    const TabbarItemRender = ({ tab }: { tab: { icon: string; label: string; path: string; i18n_label: string } }) => (
      <router-link
        to={tab.path}
        class={[styles.tabbarItem, path.value === tab.path ? styles.tabbarItem__active : null]}
        key={tab.icon}
      >
        <div class={[styles.tabbarItem__icon, tab.icon]}></div>
        <div class={styles.tabLabel}>{t(tab.i18n_label)}</div>
      </router-link>
    );
    return () => (
      <div class={styles.tabbar}>
        <a class={styles.tabbarItem} href={backUrl() || ''}>
          <div class={[styles.tabbarItem__icon, styles.game]}></div>
          <div class={styles.tabLabel}>{t('lang.sport_tabbar_game')}</div>
        </a>
        {TAB_CONFIGS.map(item => (
          <TabbarItemRender tab={item} />
        ))}
      </div>
    );
  }
});
