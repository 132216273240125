import { Fn } from '@/types/global';
import { RouteEnum } from '../enums';

export function getBoundingClientRect(element: Element): DOMRect | number {
  if (!element || !element.getBoundingClientRect) {
    return 0;
  }
  return element.getBoundingClientRect();
}

const docEle = document.documentElement;
const VH_100 = '--100vh';
export function setPercentHeight() {
  const innerH = (window.innerHeight as any) + 'px';
  docEle.style.setProperty(VH_100, innerH);
}

/**
 * set page Title
 * @param {*} title  :page Title
 */
function setDocumentTitle(title: string) {
  document.title = title;
  const ua = navigator.userAgent;
  const regex = /\bMicroMessenger\/([\d.]+)/;
  // 兼容
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe');
    i.src = '/favicon.ico';
    i.style.display = 'none';
    i.onload = function () {
      setTimeout(function () {
        i.remove();
      }, 9);
    };
    document.body.appendChild(i);
  }
}

export function setTitle(title: string, appTitle?: string) {
  if (title) {
    const _title = title ? ` ${title} - ${appTitle} ` : `${appTitle}`;
    setDocumentTitle(_title);
  }
}

// 去掉斜杠获取URL
export function removeSlashUrl(url: string) {
  if (!url) {
    return '';
  }
  const str = new URL(url);
  return str.origin;
}

/**
 * open window
 */
export const openWindow = (
  path: RouteEnum | string,
  { width, height }: { width: number; height: number } = { width: screen.width * 0.65, height: screen.height * 0.6 },
  cb?: Fn
) => {
  const myWidth = width;
  const myHeight = height;
  const left = (screen.width - myWidth) / 2;
  const top = (screen.height - myHeight) / 2;
  const customsetOptios =
    'target=_blank,toolbar=no,location=no, titlebar=no, directories=no, ' +
    'status=no, menubar=no, scrollbars=no, resizable=0, copyhistory=no, width=' +
    myWidth +
    ', height=' +
    myHeight +
    ', top=' +
    top +
    ', left=' +
    left;
  window.open('/#' + path, path, customsetOptios);
  cb && cb();
};
