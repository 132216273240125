import { computed, defineComponent, onMounted, ref, reactive, onBeforeMount, onUpdated, nextTick, watch } from 'vue';
import './style/betting-orders.style.less';

import { SportBlance, KeyboardInput } from '365-mobile/components';
import { isEmpty } from 'lodash-es';

import BetTicket from './bet-ticket';
import { SvgIcon } from '@/components';
import { currency } from '@/common';
import { useBet, CombItem } from '@/hooks/use365Bet.hook';
import { useAuth, useI18n, useSportOptions, useBalance } from '@/hooks';

export default defineComponent({
  name: 'BettingOrders',
  emits: ['clearAllTickets'],
  setup(_props, { emit }) {
    const { isLogin } = useAuth();
    const { t } = useI18n();
    const { optionsStore } = useSportOptions();
    const currencySymbol = computed(() => {
      return optionsStore.value.currency;
    });
    const {
      state,
      betAmount,
      prebetInfo,
      betLimit,
      winAmount,
      betBtnActived,
      needRecharge,
      handleBet,
      onTabChange,
      palaryConter,
      maxComboCount,
      singleConter,
      prebetList
    } = useBet(true);
    // 全选

    const { sportBalance, onHandleFresh_365 } = useBalance();
    const keyboardId = ref('0');
    let result = new Map<String, object[]>();
    let currentEls = new Map<String, object[]>();
    //const tickets = ref(result);
    //const itemRefs = ref([]);
    const changeTickets = (matchId, el) => {
      if (result.has(matchId)) {
        //@ts-ignore
        result.get(matchId).push(el);
      } else {
        result.set(matchId, [el]);
      }
    };
    const changeKeyBoardId = (id: string) => {
      keyboardId.value = id;
    };
    const states = reactive({
      change: false, // 赔率变化标志位
      oddsChange() {
        states.change = true;
      }
    });

    const getElementPagePosition = element => {
      //计算x坐标
      let actualLeft = element.offsetLeft;
      let current = element.offsetParent;
      while (current !== null) {
        actualLeft += current.offsetLeft;
        current = current.offsetParent;
      }
      //计算y坐标
      let actualTop = element.offsetTop;
      current = element.offsetParent;
      while (current !== null) {
        actualTop += current.offsetTop + current.clientTop;
        current = current.offsetParent;
      }
      //返回结果
      return { x: actualLeft, y: actualTop };
    };

    const drawLine = (startObj, endObj) => {
      const positionS = getElementPagePosition(startObj);
      const positionE = getElementPagePosition(endObj);
      return `<div class='line' 
                    style='top:${positionS.x - 2}px;position: absolute; height:${
        positionE.y - positionS.y
      }px; width: 3px;
      left: 8px;
      background-color: #16654c;'>
                </div>`;
    };

    const activeKeyboard = computed(() => prebetInfo.value.length === 1);
    onBeforeMount(async () => {
      //result.splice(0, result.length);
    });
    onMounted(async () => {
      await onHandleFresh_365();
    });
    onUpdated(() => {
      nextTick(() => {});
    });

    watch(
      () => prebetList.value,
      newValue => {
        if (!!newValue && newValue.length > 0) {
          const newResult = new Map<String, object[]>();
          newValue.forEach(d => {
            if (d.length > 0) {
              const matchId = d[0].matchId;
              if (result.has(matchId)) {
                const new_arr: object[] = [];
                const arr = result.get(matchId);
                d.forEach(t => {
                  const target = t['oddsId'] + '_' + t['key'];
                  let currentE;
                  if (
                    // @ts-ignore
                    arr.some(e => {
                      if (e != null) {
                        const source = e['bet']['oddsId'] + '_' + e['bet']['key'];
                        currentE = e;
                        return source === target;
                      } else {
                        return false;
                      }
                    })
                  ) {
                    new_arr.push(currentE);
                  }
                });
                newResult.set(matchId, new_arr);
              } else {
                result.set(matchId, []);
              }
            }
          });
          result = newResult;
          currentEls.clear();
          currentEls = new Map<String, object[]>([...newResult]);
          if (currentEls.size > 0) {
            currentEls.forEach(v => {
              if (v.length > 0) {
                const validLineEl = v[0]['$el'].childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[1];
                if (v.length > 1) {
                  const firstEl = v[0]['$el'].childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0];
                  const endEl =
                    v[v.length - 1]['$el'].childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0];
                  //console.log('11111111111111', firstEl, endEl);
                  if (validLineEl.className == 'line') {
                    validLineEl.remove();
                  }
                  firstEl.insertAdjacentHTML('afterend', drawLine(firstEl, endEl));
                } else {
                  if (validLineEl.className == 'line') {
                    validLineEl.remove();
                  }
                }
              }
            });
          }
        }
      },
      { deep: true }
    );

    // 单投
    const SingleBetRenader = () => (
      <div class="single-bet">
        {prebetInfo.value.map(item => (
          <BetTicket
            onOddsChange={() => states.oddsChange()}
            bet={item}
            marketId={item.key}
            keyboardId={keyboardId.value}
            key={item.uniqKey}
            type={'single'}
          >
            <KeyboardInput
              showInformation={true}
              currency={currencySymbol.value}
              activeKeyboard={activeKeyboard.value}
              balance={sportBalance.value}
              max={item.max_bet || 0}
              min={item.min_bet || 0}
              v-model:amount={betLimit.value[item.uniqKey].stake}
              marketId={item.key}
              keyboardId={keyboardId.value}
              onChange={changeKeyBoardId}
            />
          </BetTicket>
        ))}
      </div>
    );

    const combosList = computed<CombItem[]>(() => {
      const list: any[] = [];
      if (isEmpty(state.betParlyCombo)) return [];
      state.betParlyCombo.forEach((value, _key) => {
        list.push(value);
      });
      return list;
    });

    const DuplexParlayBetRender = () => (
      <div class="duplex-parlay">
        {prebetList.value.map(list => {
          result.clear();
          return list.length > 0 ? (
            <div class="league-match">
              <div class="league-match-name">{list[0].leagueName}</div>
              {!list[0].isChamp ? (
                <div class="league-match-sub-name">
                  {list[0].home} VS {list[0].away}
                  {list[0].periodId !== null &&
                  list[0].live_home_score != undefined &&
                  list[0].live_away_score != undefined ? (
                    <span class="t-teams-score">{`(${list[0].live_home_score}-${list[0].live_away_score})`}</span>
                  ) : null}
                </div>
              ) : null}
              {list.map((item, index) => (
                <>
                  <BetTicket
                    id={item.matchId + '_ticket_' + index}
                    bet={item}
                    key={item.uniqKey}
                    type={'parlay'}
                    ref={el => {
                      changeTickets(item.matchId, el);
                    }}
                  ></BetTicket>
                </>
              ))}
            </div>
          ) : null;
        })}
        {!isEmpty(combosList.value) ? (
          <div class="duplex-parlay-container">
            {combosList.value.map(value => {
              const comboItem = () => {
                const o = state.betParlyCombo.get(value.mulType);
                if (!!o) return o;
                return { stake: 0 };
              };
              const comboLabel = `${value.mulType} * ${value.bet_count}`; // e.g: 3串1 * 3
              return (
                <KeyboardInput
                  key={value.combo_type}
                  showInformation={true}
                  currency={currencySymbol.value}
                  balance={sportBalance.value}
                  max={Number(value.max_bet)}
                  min={Number(value.min_bet)}
                  comboLabel={comboLabel}
                  marketId={value.combo_type}
                  keyboardId={keyboardId.value}
                  v-model:amount={comboItem().stake}
                  onChange={changeKeyBoardId}
                />
              );
            })}
          </div>
        ) : null}
        {!isEmpty(prebetInfo.value) || !isEmpty(combosList.value) ? (
          <>
            {prebetInfo.value.find(item => item.closed || !item.price) ? (
              <span class="num-text">
                {t('lang.sport_bet_closingTheMarketDoesNotCountTowardsTheAmountThatCanBeLinked')}
              </span>
            ) : null}
            {prebetInfo.value.length < maxComboCount.value ? (
              <span class="num-text">{t('lang.sport_bet_InsufficientNumberOfHandicapUnableToJoinTheField')}</span>
            ) : null}
            {prebetInfo.value.find(c => c.isSameGame) ? (
              <span class="num-text">
                {t('lang.sport_bet_pleaseDeleteItemsThatDoNotComplyWithTheStringFieldRules')}
              </span>
            ) : null}
            {state.noCombos && prebetInfo.value.some(item => !item.allowBetBuilder) ? (
              <span class="num-text">{t('lang.sport_bet_noCombos')}</span>
            ) : null}
          </>
        ) : null}
      </div>
    );

    const BetButtonsRender = () => (
      <div class="bet-button-container">
        <div class="bet-button">
          {state.tabActive === 0 ? (
            <button class="bet-add-mul" onClick={() => onTabChange(2)}>
              {t('lang.sport_bet_addMul')}
            </button>
          ) : null}
          {state.tabActive === 2 ? (
            <button class="bet-add-mul" onClick={() => onTabChange(0)}>
              {t('lang.sport_bet_backSingleBet')}
            </button>
          ) : null}
          {needRecharge.value ? (
            // 余额不足
            <button class="bet-btn diabled">{t('lang.sport_bet_insufficientwalletBalance365')}</button>
          ) : (
            // 无法点击状态
            <button
              class={['bet-btn', betBtnActived.value ? '' : 'diabled']}
              disabled={!betBtnActived.value}
              onClick={() => (betBtnActived.value ? handleBet() : null)}
            >
              {t('lang.sport_bet_bet')}
              <span class="canwin-amount" v-show={winAmount.value > 0}>
                {t('lang.sport_bet_winAmount')}：{currency(winAmount.value)}
                {winAmount.value > 0 ? `(${currencySymbol.value})` : ''}
              </span>
            </button>
          )}
        </div>
        <div class="duplex-parlay-info">
          <span>
            {t('lang.sport_common_recordTotal')}{' '}
            <i>{state.tabActive === 0 ? singleConter.value : palaryConter.value}</i>
          </span>
          <span>
            {t('lang.sport_bet_betAmount')} <i>{currency(betAmount.value)}</i> CNY
          </span>
        </div>
      </div>
    );

    return () => (
      <div class="betting-orders-365">
        <div class="loading-layout" v-show={state.loading}>
          <SvgIcon name="loading_v2" />
          <div>{t('lang.sport_bet_bettingConfirmation')}</div>
        </div>
        <div class="betting-orders-365-header">
          <span class="betting-orders-365-header-title">
            {state.tabActive === 0 ? t('lang.sport_bet_singleBet') : null}
            {state.tabActive === 2 ? t('lang.sport_bet_mulBet') : null}
          </span>
          <div class="betting-orders-365-header-right">{isLogin.value ? <SportBlance /> : null}</div>
          <div class="delelt-all" onClick={() => emit('clearAllTickets')}>
            <SvgIcon name="clear" />
          </div>
        </div>
        {state.tabActive === 0 ? <SingleBetRenader /> : null}
        {state.tabActive === 2 ? <DuplexParlayBetRender /> : null}
        <BetButtonsRender />
      </div>
    );
  }
});
