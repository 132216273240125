import { defineComponent } from 'vue';

import style from './index.module.less';

export default defineComponent({
  props: {
    direction: {
      type: String,
      default: 'vertical'
    }
  },
  setup(props, { slots }) {
    return () => <section class={[style.container, props.direction]}>{slots.default?.()}</section>;
  }
});
