import { http, toTime, v2_http } from '@/common';
import { HttpResponse, HttpResponse_v2 } from '#/global';
import { GetVirtualEventVideoForm } from '..';
import { AnchorItem, GetMessageForm, LiveMatchItem } from '../types/global.interface';
import qs from 'qs';

// https://kgsapk.pokjhgfc.cn/teamLogo/ls/1/10000.png

// https://kgsapk.pokjhgfc.cn/eventLogo/xj/1/27317.png  联赛loogo

type ChatroomType = 'match' | 'anchor' | string;
interface ReportForm {
  uid: string; //当前用户uid
  nick: string; //当前用户昵称
  account: string; //当前用户账户
  targetUid: string; // 被举报人uid
  targetNick: string; // 被举报人昵称
  targetAccount: string; //举报人账户
  targetRole: number; //被举报用户角色
  matchId: any; //赛事ID
  msgBody: string; // 消息内容
  role?: string; //当前用户角色
  chatroomType: ChatroomType; //房间类型
  reportTypeCode: any; //举报类型编码
  chId: string; //赛事ID
}

/**
 * 举报
 */
function addReportUser(param: ReportForm): Promise<HttpResponse> {
  return http.post<HttpResponse<any>>('/anchor/chatroom/player/report/add', param);
}

/**
 * 举报类型获取
 */
function getReportCate(): Promise<HttpResponse> {
  return http.get<HttpResponse<any[]>>('/anchor/chatroom/player/load/reportType');
}

type MatchSource = 3 | 29 | 30;
/**
 * 获取主播直播信息
 * @param matchSource gameId 3 小金|29 BTI|30 沙巴
 * @param matchId 赛事ID
 */
function getAnchorInfoApi({
  matchSource = 30,
  matchId
}: {
  matchSource?: MatchSource;
  matchId: number;
}): Promise<HttpResponse> {
  return http.post<HttpResponse<any[]>>('/anchor/user/player/match/live/info', { matchSource, matchId });
}

/**
 * @description: 获取虚拟赛事视频链接
 * @return {Promise<HttpResponse>}
 */
const getVirtualEventVideo = ({
  sportType,
  streamingOption,
  channelCode
}: GetVirtualEventVideoForm): Promise<HttpResponse<any>> => {
  return http.post<HttpResponse<any>>('/sports/sb/player/getStreamingURL', {
    sportType,
    streamingOption,
    channelCode
  });
};

/**
 * @description: 查询联赛数据
 * @return {Promise<HttpResponse>}
 */
const getSportUnionData = (): any => {
  return http.get<HttpResponse<any>>('/match/player/sportUnionData');
};

/**
 * @description: 通过版本号获取最新的讯息
 * @param {GetMessageForm} prams
 * @param country 国家地区码
 * @param startDate 查询起始时间
 * @param endDate 查询结束时间
 * @return {*}
 */
const getPublicMessage = (prams: GetMessageForm): any => {
  return http.post<HttpResponse<any>>('/agent/getPublicMessage', prams);
};

/**
 * @description: 赛果第三方联赛查询接口
 * @param {GetMessageForm} prams
 * @param startTime 开始时间
 * @param endTime 结束时间
 * @param gameId 体育id
 * @param thirdType 沙巴传”shaba“ ,im传”im“,bti传”bti“
 * @return {*}
 */
const getThirdLeagueId = ({
  startTime,
  endTime,
  sportId
}: {
  startTime: number | string;
  endTime: number | string;
  sportId: number;
}): any => {
  return http.post<HttpResponse<any>>(
    '/agent/queryResultsLeagueList?' +
      qs.stringify({
        startTime: toTime(startTime, 'YYYY-MM-DD HH:mm:ss'),
        endTime: toTime(endTime, 'YYYY-MM-DD HH:mm:ss'),
        sportId
      })
  );
};

// const getThirdSportId = ({ thirdType }: { thirdType: string }): any => {
//   return http.get<HttpResponse<any>>('/sports/match/player/getThirdSportId?' + qs.stringify({ thirdType }));
// };

const getSportId = ({ enabledOnly }: { enabledOnly: number }): any => {
  return http.post<HttpResponse<any>>('/agent/getSportId?', enabledOnly);
};

/**
 * @description: 直播数据接口
 * @param sportId 赛种
 * @return {*}
 */
const getLiveList = ({ sportId }: { sportId: number }): Promise<HttpResponse_v2<LiveMatchItem[]>> => {
  return v2_http.get('/api/sport/match/list', { sportId });
};

/**
 * @description: 主播数据接口
 * @return {*}
 */
const getAnchorList = (): Promise<HttpResponse_v2<AnchorItem[]>> => {
  return v2_http.get('/api/sport/match/player/match');
};

export {
  // getThirdSportId,
  getSportId,
  getSportUnionData,
  getThirdLeagueId,
  getPublicMessage,
  addReportUser,
  getReportCate,
  getAnchorInfoApi,
  getVirtualEventVideo,
  getLiveList,
  getAnchorList
};
