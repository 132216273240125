import { MatchItem } from '@/services/kg-api';
import { useStore } from 'vuex';
import { TechId } from '@/common/enums/sportsTechEnum';
import { computed } from 'vue';

export interface ScoreType {
  h: number;
  a: number;
  active: boolean;
}

// 转换其他分节分数
export function transformSectionScore({ MoreInfo, IsLive, SportType }: MatchItem): ScoreType[] | null {
  if (IsLive !== 1) return null;
  const { LLP, TennisAwayGameScore, TennisHomeGameScore } = MoreInfo;
  const tempList: ScoreType[] = [];
  // 篮球
  if (SportType === 2 && MoreInfo.a1Q) {
    for (let index = 0; index < Number(LLP); index++) {
      tempList[index] = {
        h: MoreInfo[`h${index + 1}Q`],
        a: MoreInfo[`a${index + 1}Q`],
        active: Number(LLP) === index + 1
      };
    }
  }

  // 网球
  if (SportType === 5 && typeof TennisHomeGameScore === 'object') {
    for (let index = 0; index < Number(LLP); index++) {
      tempList[index] = {
        h: TennisHomeGameScore[index],
        a: TennisAwayGameScore[index],
        active: Number(LLP) === index + 1
      };
    }
  }
  // 乒乓球
  if (SportType === 18 && !!MoreInfo.a1S) {
    for (let index = 0; index < Number(LLP); index++) {
      tempList[index] = {
        h: MoreInfo[`h${index + 1}S`],
        a: MoreInfo[`a${index + 1}S`],
        active: Number(LLP) === index + 1
      };
    }
  }
  return tempList;
}

// 足球数据
export function useSoccerScore(mid: string) {
  const vuexStore = useStore();

  const soccerTech = computed(() => {
    const tech = vuexStore.getters.getTechById(mid);
    if (!tech)
      return {
        homeCorners: 0,
        awayCorners: 0,
        homeHalfScore: 0,
        awayHalfScore: 0
      };
    const [homeCorners, awayCorners] = tech.get(TechId.CORNER) || [0, 0];
    const [homeHalfScore, awayHalfScore] = tech.get(TechId.HALF_SCORE) || [0, 0];
    return {
      homeCorners,
      awayCorners,
      homeHalfScore,
      awayHalfScore
    };
  });

  return {
    soccerTech
  };
}
