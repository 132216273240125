export enum UiType {
  // 其他
  UiType0 = 'ui_0',
  // 让球
  UiType1 = 'ui_1',
  // 大小
  UiType2 = 'ui_2',
  // 独赢
  UiType3 = 'ui_3',
  // 波胆
  UiType4 = 'ui_4',
  // 单双
  UiType5 = 'ui_5',
  // 胜负
  UiType6 = 'ui_6',
  // 进球
  UiType7 = 'ui_7'
}
