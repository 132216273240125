import { ref, watch, defineComponent } from 'vue';
import styleClass from './odds-price.module.less';

export default defineComponent({
  name: 'OddsPrice',
  props: {
    value: {
      type: [Number, String],
      default: 1
    },
    symbol: {
      type: String,
      default: ''
    },
    rangeIcon: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const floatClass = ref('');

    // 监听值
    watch(
      () => Number(props.value),
      (newVal, oldVal) => {
        if (Number(newVal) > Number(oldVal)) {
          floatClass.value = styleClass.up;
        } else if (Number(newVal) < Number(oldVal)) {
          floatClass.value = styleClass.down;
        }
        emit('change');
        setTimeout(() => {
          floatClass.value = '';
        }, 3 * 1000);
      }
    );

    return () => (
      <span
        class={[
          floatClass.value,
          styleClass.oddsValue,
          props.type === 'background' ? styleClass.background : '',
          'odds-value'
        ]}
      >
        {props.rangeIcon ? <div class={styleClass.rangeIcon}></div> : null}
        {props.symbol}
        {props.value}
      </span>
    );
  }
});
