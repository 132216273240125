/**
 * 关注比赛
 */
import { computed, ComputedRef, reactive } from 'vue';

import { isEmpty, map, pull } from 'lodash-es';
import {
  FAVORT_MATCHS_KEY,
  MarketType,
  _formatMatchInfo,
  _formatHomeMarketOdds,
  TechId,
  formatMoreInfo
} from '@/common';
import { RemovableRef, useStorage } from '@vueuse/core';
import { useSportTypes, useI18n } from '@/hooks';
import { Toast } from '@/components';
import { EventsAndMarkets, MatchItem, queryEventsAndMarkets } from '@/services/kg-api';
import vuexStore from '@/store';

const FAVORT_KEY = FAVORT_MATCHS_KEY;

interface Match {
  matchId: string;
  sportId: number;
}

export interface useFavortMatchRn {
  mark: (matchInfo: Match) => void;
  isMarkedById: (matchInfo: Match) => boolean;
  clearMark: (form: any, matches?: any) => void;
  markMatchsCount: ComputedRef<number>;
}

export const localMarketedMatchs: RemovableRef<Map<number, string[]>> = useStorage(FAVORT_KEY, new Map());

export function useFavortMatch(): useFavortMatchRn {
  const { updateMatchMarks } = useSportTypes();
  // 关注总数量
  const markMatchsCount = computed(() => {
    let count = 0;
    const deepList = localMarketedMatchs.value;
    deepList.forEach((e, i) => {
      if (i === -1) return;
      count = count + e.length;
    });
    return count;
  });
  /**
   * 关注比赛
   */
  const mark = async ({ matchId, sportId }: Match) => {
    const ids = localMarketedMatchs.value.get(sportId) || [];
    const { t } = useI18n();
    if (!sportId || !matchId || ids.length < 0) return;
    // 是否已关注
    const isExist = ids.includes(matchId);
    if (isExist) {
      pull(ids, matchId);
    } else {
      ids.push(matchId);
    }

    const message = isExist ? t('lang.sport_common_unMark') : t('lang.sport_common_markSuccess');
    Toast(message);
    localMarketedMatchs.value.set(sportId, ids);
    updateMatchMarks();
  };

  const isMarkedById = ({ matchId, sportId }: Match) => {
    const ids = localMarketedMatchs.value.get(sportId) || [];
    if (!sportId || !matchId || ids.length === 0) return false;
    return ids.includes(matchId);
  };

  const clearMark = (form, matches) => {
    if (!form || form.periodId !== -1 || !form.sportId) return;
    const { eventIds } = form;
    if (!eventIds || !Array.isArray(matches) || matches.length === 0) {
      return localMarketedMatchs?.value?.clear();
    }
    const res = [] as any;
    matches.forEach(item => {
      (item.matches || []).forEach(item => {
        res.push(item.MatchId);
      });
    });
    localMarketedMatchs?.value?.set(+form.sportId, res);
  };

  return {
    mark,
    markMatchsCount,
    isMarkedById,
    clearMark
  };
}

interface MatchInfoItem {
  sportId: string | number;
  matchData: any[];
}

// 获取关注的列表
export function useGetFavortMatch() {
  const state = reactive({
    form: {
      oddsType: MarketType.Europe,
      periodId: null,
      leagueIds: null,
      selectDate: null,
      queryCount: 100
    },
    loading: true,
    list: [] as MatchInfoItem[]
  });

  // 获取
  const getFavortMatchList = async () => {
    if (isEmpty(localMarketedMatchs.value)) {
      state.loading = false;
      return;
    }
    const sportKeys = localMarketedMatchs.value.entries();
    for (const [sid, matchIds] of sportKeys) {
      const item = await getOneFavortMatchList({ sid, eventIds: matchIds });
      if (!item) return;
      if (isEmpty(item) || isEmpty(matchIds)) {
        localMarketedMatchs.value.delete(sid);
      } else {
        state.list.push({
          sportId: sid,
          matchData: item as any[]
        });
        // 更新关注
        const newIds: any[] = [];
        map(item, e => {
          map(e.matches, (i: any) => {
            newIds.push(i.info.id);
          });
        });
        localMarketedMatchs.value.set(sid, newIds);
      }
    }
    state.loading = false;
  };

  const getOneFavortMatchList = async ({ sid, eventIds }: { sid: any; eventIds: string[] }) => {
    const [res, data] = (await queryEventsAndMarkets({
      sportId: sid,
      ...state.form,
      eventIds: eventIds.join(',')
    })) as [boolean, EventsAndMarkets[]];
    if (!res) return null;
    const tempList: any[] = [];
    if (!isEmpty(data)) {
      map(data, (marketItem: any) => {
        const result = {
          leagueId: marketItem.leagueId ? marketItem.leagueId : marketItem.LeagueId,
          leagueName: marketItem.LeagueName,
          matches: marketItem.matches.map((match: MatchItem) => {
            if (match?.MatchId) {
              const tech = vuexStore?.getters?.getTechById(match?.MatchId);
              if (tech) {
                const RedCard = tech.get(TechId.RED) || [];
                const RedCardH = RedCard[0];
                const RedCardA = RedCard[1];
                const [CornerH, CornerA] = tech.get(TechId.CORNER) || [0, 0];
                const YellowCard = tech.get(TechId.YELLOW) || [];
                const YellowCardH = YellowCard[0];
                const YellowCardA = YellowCard[1];
                const [HalfTimeH, HalfTimeA] = tech.get(TechId.HALF_SCORE) || [0, 0];

                match.MoreInfo = Object.assign(match.MoreInfo || {}, {
                  CornerH,
                  CornerA,
                  HalfTimeH,
                  HalfTimeA,
                  RedCardH,
                  RedCardA,
                  YellowCardH,
                  YellowCardA
                });
              } else if (!!match.MoreInfo) {
                const RedCardH = match.MoreInfo.redCardH;
                const RedCardA = match.MoreInfo.redCardA;
                const CornerH = match.MoreInfo.cornersH;
                const CornerA = match.MoreInfo.cornersA;
                const YellowCardH = match.MoreInfo.yellowCardH;
                const YellowCardA = match.MoreInfo.yellowCardA;
                const HalfTimeH = match.MoreInfo.halfScoreH;
                const HalfTimeA = match.MoreInfo.halfScoreA;
                match.MoreInfo = Object.assign(match.MoreInfo || {}, {
                  CornerH,
                  CornerA,
                  HalfTimeH,
                  HalfTimeA,
                  RedCardH,
                  RedCardA,
                  YellowCardH,
                  YellowCardA
                });
              }
            }
            return {
              info: {
                ..._formatMatchInfo({
                  match,
                  league: marketItem,
                  isInProgress: match.IsLive === 1,
                  sportId: Number(marketItem.SportType)
                }),
                video: false,
                animate: false,
                isMark: true,
                moreInfo: !!match.MoreInfo ? formatMoreInfo(match.MoreInfo) : {},
                RunTime: match.RunTime,
                ShowTime: match.ShowTime
              },
              oddset: _formatHomeMarketOdds(marketItem.SportType, match.oddset)
            };
          })
        };
        tempList.push(result);
      });
    }
    return tempList;
  };

  return {
    getOneFavortMatchList,
    getFavortMatchList,
    state
  };
}
