import { defineComponent } from 'vue';
import style from './teamlogo.module.less';
import { Image } from 'vant';
import errorImg from './default.png';
import { getCNDlogoUrl } from '@/common';

export default defineComponent({
  name: 'MobileTeamLogo',
  props: {
    sportId: {
      default: 1,
      type: [Number, String]
    },
    teamId: {
      type: [Number, String]
    },
    teamName: {
      type: String
    },
    logoUrl: {
      type: [String]
    },
    size: {
      type: String
    }
  },
  setup(props) {
    return () => {
      let imgUrl: string;
      if (props.logoUrl != null) {
        imgUrl = props.logoUrl;
      } else {
        // const imgUrl_back = `http://ipis-cdn.speedy4site.com/TeamImage/${props.teamId || ''}.png`;
        imgUrl = props.teamId
          ? `${getCNDlogoUrl()}/teamLogo/im/${props.sportId}/${props.teamId || ''}.png`
          : props.teamName
          ? `${getCNDlogoUrl()}/teamLogo/sb/${props.sportId}/${props.teamName || ''}.png`
          : '#';
      }
      return (
        <Image lazyLoad src={imgUrl} class={[style.teamLogo, props.size]}>
          {{
            error: () => <img src={errorImg} />,
            loading: () => <img src={errorImg} />
          }}
        </Image>
      );
    };
  }
});
