import { defineComponent, PropType } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MatchSummaryOdds from './odds/index';
import { SvgIcon } from '@/components';
import { TeamLogo } from 'kg-web/components';
import { StoreMarketData, StoreMatchInfo, useI18n, useFavortMatch } from '@/hooks';
import { useBetWeb } from '@/hooks/useBetWeb.hook';
import LiveStatistics from './live-statistics/index';

import style from './match-summary.module.less';

import { RouteEnum, toTime, queryToStr } from '@/common';
import { FavoritStar, CollapsePanel } from 'kg-web/components';

const { t } = useI18n();
// ['让球', '大小', '独赢']
const types_1 = ['lang.sport_common_handicap', 'lang.sport_common_size', 'lang.sport_common_winAlone'];
const types_2 = ['lang.sport_common_handicap', 'lang.sport_odd_even', 'lang.sport_common_winAlone'];

export default defineComponent({
  name: 'MatchSummary',
  props: {
    isExpandAll: {
      type: Boolean
    },
    data: {
      type: Object as PropType<StoreMarketData>,
      default: () => ({})
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const { addTicket } = useBetWeb();
    const { mark, isMarkedById } = useFavortMatch();
    const pathToDetail = (matchId: string) => {
      const queryObj = {
        ...route.query,
        matchId: matchId
      };
      const queryStr = queryToStr(queryObj);
      router.replace(RouteEnum.SPORT_DETAIL + queryStr);
    };

    const MatchInfoRender = ({ matchEvent }: { matchEvent: StoreMatchInfo }) => (
      <div class={style.MatchInfo}>
        <div class={style.StarView}>
          <FavoritStar
            class="mark-icon"
            isMarked={isMarkedById({ matchId: matchEvent.id, sportId: matchEvent.sportId })}
            onMark={() => mark({ matchId: matchEvent.id, sportId: matchEvent.sportId })}
          />
        </div>
        <div class={style.AdditionalInfoInner}>
          <div class={style.PeriodWrapper}>
            <div class={[style.Timer, matchEvent ? style.InPlayTimer : null]}>
              {matchEvent.isLive ? t(matchEvent.period) : ''}
              <span>{Number(route.query.periodId) === 1 ? toTime(matchEvent.date, 'MM-DD') : ''}</span>
              <span class={Number(route.query.periodId) === 3 ? style.red : ''}>
                {Number(route.query.periodId) === 2 ? toTime(matchEvent.date, 'MM-DD') : ''} {matchEvent.time}
              </span>
            </div>
          </div>
          <div class={style.IconWrapper} onClick={() => pathToDetail(matchEvent.id)}>
            <span>{matchEvent.count || 0}</span>
            <SvgIcon class={style.Icon} name="arrow_right" />
          </div>
        </div>
      </div>
    );
    const Video = ({ matchData }: { matchData: StoreMatchInfo }) => (
      <div class={[style.matchEntertainment, matchData.isLive ? style.isLiveIcon : null]}>
        <div class={style.icon} v-show={matchData.video} onClick={() => pathToDetail(matchData.id)}>
          <svg-icon name="event_video" />
        </div>
        <div class={style.icon} v-show={matchData.animate} onClick={() => pathToDetail(matchData.id)}>
          <svg-icon name="event_animation" style="fill: #05e79d !important;" />
        </div>
      </div>
    );

    const MatchInfo = ({ matchData }: { matchData: StoreMatchInfo }) => (
      <div class={style.information}>
        <div class={style.homeTeam}>
          <div class={style.goals}>
            <TeamLogo class={style.teamLogo} size={'xss'} logoUrl={matchData.homeLogo} />
            <span class={style.teamName} onClick={() => pathToDetail(matchData.id)}>
              <span>{matchData.home}</span>
            </span>
            {matchData.tech?.red[0] ? (
              <span class={style.cardOffense + ' ' + style.redCard}>{matchData.tech?.red[0]}</span>
            ) : null}
            {matchData.tech?.yellow[0] ? (
              <span class={style.cardOffense + ' ' + style.yellowCard}>{matchData.tech?.yellow[0]}</span>
            ) : null}
          </div>
          <div class={style.matchDelta}>
            {matchData.score ? <span class={style.teamScoreHight}>{matchData.score[0]}</span> : null}
          </div>
          {/*<div class={style.matchDelta}>*/}
          {/*  {matchData.tech?.corner ? <span class={style.halfScore}>{matchData.tech?.corner?.[0]}</span> : null}*/}
          {/*</div>*/}
        </div>
        <div class={style.awayTeam}>
          <div class={style.goals}>
            <TeamLogo class={style.teamLogo} size={'xss'} logoUrl={matchData.awayLogo} />
            <span class={style.teamName} onClick={() => pathToDetail(matchData.id)}>
              <span>{matchData.away}</span>
            </span>
            {matchData.tech?.red[1] ? (
              <span class={style.cardOffense + ' ' + style.redCard}>{matchData.tech?.red[1]}</span>
            ) : null}
            {matchData.tech?.yellow[1] ? (
              <span class={style.cardOffense + ' ' + style.yellowCard}>{matchData.tech?.yellow[1]}</span>
            ) : null}
          </div>
          <div class={style.matchDelta}>
            {matchData.score ? <span class={style.teamScoreHight}>{matchData.score[1]}</span> : null}
          </div>
          {/*<div class={style.matchDelta}>
            {matchData.tech?.corner ? <span class={style.halfScore}>{matchData.tech?.corner?.[1]}</span> : null}
          </div>*/}
        </div>
        {matchData.isLive ? (
          <div class={style.otherMatchInfo}>
            <LiveStatistics info={matchData} class={style.eventsContainer} />
            {matchData.isNeutral ? (
              <span class={style.otherMatchInfoSvg}>
                <SvgIcon name="neutral" />{' '}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    );
    return () => (
      <CollapsePanel
        v-slots={{
          header: () => (
            <div class={style.header}>
              <div class={style.titlemain}>
                <TeamLogo class={style.teamLogo} size={'xss'} logoUrl={props.data.leagueLogo} />
                <span>{props.data.leagueName}</span>
                {/*{Number(route.query.periodId) === 3 ? (*/}
                {/*  <span class={style.half}>{t('lang.sport_common_cornerKick')}</span>*/}
                {/*) : null}*/}
              </div>
              <div class={style.matchCount}>{/* <span>{props.data.matches.length || 0}</span> */}</div>
              <div class={style.mainLetter}>
                {props.data.sportId === 1
                  ? types_1.map((item, i: number) => (
                      <span class={style.letter} key={i}>
                        {t(item)}
                      </span>
                    ))
                  : types_2.map((item, i: number) => (
                      <span class={style.letter} key={i}>
                        {t(item)}
                      </span>
                    ))}
                <div class={style.nthLetter}></div>
              </div>
            </div>
          )
        }}
      >
        {props.data.matches.map((items, index) => (
          <div class={style.fixture} style={items.info.isLive ? '' : { height: '80px' }} key={items.info.id + index}>
            <div class={style.StandardScores}>
              <div class={style.StarSports}>
                <MatchInfoRender matchEvent={items.info} />
                <MatchInfo matchData={items.info} />
              </div>
              <MatchSummaryOdds
                onAddBetTicket={sel =>
                  addTicket(
                    {
                      ...sel
                    },
                    true
                  )
                }
                oddset={items.oddset}
                sportId={items.info.sportId}
                matchId={items.info.id}
                info={items.info}
              />
              <Video matchData={items.info} />
            </div>
          </div>
        ))}
      </CollapsePanel>
    );
  }
});
