import { defineComponent } from 'vue';
import { SelChamp } from '@/services/kg-api';
import { OddsPrice } from '365-mobile/components';
import { _keyNameByBettype2, BetTypeEnum, gameTypeDefault } from '@/common';
import { hasTicketId } from '@/hooks/use365Bet.hook';
import './odds-champ.less';

export default defineComponent({
  name: 'ChampOdds',
  props: {
    oddset: {
      type: Object,
      default: () => {}
    },
    matchId: {
      type: String,
      required: true
    },
    sportId: {
      type: Number
    },
    betType: {
      type: Number,
      default: BetTypeEnum.proposition
    },
    gameTypeActive: {
      type: Number,
      default: gameTypeDefault
    }
  },
  emits: ['addBetTicket'],
  setup(props, { emit }) {
    // const { store } = useDetailHook();

    return () => (
      <div class="champ-oddss-swiper">
        {props.oddset.sels.map((sel: SelChamp, i) => (
          <div
            class={[
              'champ-odds-item',
              hasTicketId({
                matchId: props.matchId,
                marketId: props.oddset.OddsId,
                wagerSelectionId: sel.WagerSelectionId
              })
                ? 'selected'
                : null
            ]}
            key={i}
            onClick={() =>
              emit('addBetTicket', {
                betType: props.oddset.Bettype,
                betTypeName: props.oddset.BettypeName,
                matchId: props.matchId,
                sportType: props.sportId,
                marketId: props.oddset.OddsId,
                periodId: props.oddset.PeriodId,
                point: sel.Point,
                keyName: sel.KeyName,
                outrightTeamId: 0,
                price: sel.Price,
                EuropePrice: sel.EuropePrice,
                wagerSelectionId: sel.WagerSelectionId,
                BetTypeSelectionId: 0,
                key: sel.Key,
                Combo: props.oddset.Combo
              })
            }
          >
            <p class="KeyName">
              <span>{sel.KeyName}</span>
            </p>
            <OddsPrice class="price" type="background" value={sel.Price} />
          </div>
        ))}
      </div>
    );
  }
});
