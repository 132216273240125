/**
 * 投注记录
 */
import { computed, reactive, ref } from 'vue';
import {
  getOrders,
  BetOrdersPrams,
  postCashout,
  AutoCashOut,
  postPartialCashout,
  postAutoCashout,
  getCashOutValues,
  deleteAutoCashout,
  loadCashOutValues,
  BetCashOut,
  DeleteCashOut,
  PartialCashOut
} from '@/services';
import { get } from 'lodash-es';
import dayjs from 'dayjs';
import Laoding from '@/components/loading-toast';
import { FormatOrderItem, isMobile, ordersFormat_365, EarlyTypes } from '@/common';
import { Toast } from '@/components';
import { useSportOptions, useTimerHook, TimerEnum } from '@/hooks';
import { useStore } from 'vuex';
import { getBetTypes } from '@/services/kg-api';

//0 for early settlement
//1 - unsettled
//2 - settled
//3- all.

export const FILTER_TABS: { label: string; value: 1 | 2 | 0 }[] = [
  {
    label: 'lang.sport_tape_noSettlement', //'',
    value: 1
  },
  {
    label: 'lang.sport_tape_haveAlreadySettled', //'',
    value: 2
  },
  {
    label: 'lang.sport_common_quanBu',
    value: 0
  }
];
export const SETTLE_TABS: { label: string; value: 4 | 5 }[] = [
  {
    label: 'lang.sport_order_partialSettlement', //部分结算
    value: 4
  },
  {
    label: 'lang.sport_order_autoCashed', //自动结算
    value: 5
  }
];
export const EARLY_TABS: { label: string; value: 3 }[] = [
  {
    label: 'lang.sport_order_earlySettlement',
    value: 3
  }
];

export interface DateTypes {
  title: string;
  i18n: string;
  value: {
    beginTime: any;
    endTime: any;
  };
}

export const getDateList1 = (): DateTypes[] => [
  {
    title: '今天',
    i18n: 'lang.sport_common_today',
    value: {
      beginTime: dayjs().startOf('day').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  },
  {
    title: '昨天',
    i18n: 'lang.sport_common_yesterday',
    value: {
      beginTime: dayjs().startOf('day').subtract(1, 'days').valueOf() + '',
      endTime: dayjs().startOf('day').valueOf() + ''
    }
  },
  {
    title: '7天内',
    i18n: 'lang.sport_common_last7days365',
    value: {
      beginTime: dayjs().subtract(7, 'days').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  },
  {
    title: '30天内',
    i18n: 'lang.sport_common_last30days365',
    value: {
      beginTime: dayjs().subtract(29, 'day').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  }
];

const errorMessages = {
  1001: '投注已经结算',
  101: '投注已经结算',
  102: '未找到赌注',
  1002: '未找到赌注',
  103: '赔率在等待时间后发生变化',
  1003: '赔率在等待时间后发生变化',
  104: '未找到投注类型',
  1004: '未找到投注类型',
  105: '兑现价值低于最低要求',
  1005: '兑现价值低于最低要求',
  106: 'BetBuilder 赔率缺失',
  1006: 'BetBuilder 赔率缺失',
  107: 'BetBuilder赔率组输一半',
  1007: 'BetBuilder赔率组输一半',

  108: 'BetBuilder 兑现选项已禁用',
  1008: 'BetBuilder 兑现选项已禁用',
  109: 'Win 和 Each-Way 投注类型对兑现无效',
  1009: 'Win 和 Each-Way 投注类型对兑现无效',
  110: '运营商已禁用提现',
  1010: '运营商已禁用提现',

  111: '用户禁用提现',
  1011: '用户禁用提现',
  112: '免费投注禁用兑现',
  1012: '免费投注禁用兑现',
  113: '找不到提现配置',
  1013: '找不到提现配置',
  114: '无效的投注选择',
  1014: '无效的投注选择',
  115: '赔率改变超出阈值',
  1015: '赔率改变超出阈值',
  116: '内部错误',
  1016: '内部错误',
  117: '过时的请求',
  1017: '过时的请求',
  118: '并发选择',
  1018: '并发选择',
  119: '更新投注时出错',
  1019: '更新投注时出错',
  121: '投注不符合兑现条件',
  1021: '投注不符合兑现条件',
  122: '提现请求无效',
  1022: '提现请求无效',
  124: '自动兑现不可能',
  1024: '自动兑现不可能',

  125: '自动兑现重试失败',
  1025: '自动兑现重试失败',
  126: '触发自动兑现',
  1026: '触发自动兑现',
  127: '未触发 AutoCashout 阈值',
  1027: '未触发 AutoCashout 阈值',
  131: '缺席综合赔率',
  1031: '缺席综合赔率',
  133: 'AutoCashout 门槛高于可能获胜的门槛',
  1033: 'AutoCashout 门槛高于可能获胜的门槛',
  134: 'AutoCashout 请求已删除',
  1034: 'AutoCashout 请求已删除',
  137: '投注状态不被接受',
  1037: '投注状态不被接受',
  138: '投注类型无效',
  1038: '投注类型无效',
  139: 'COV 变化超出阈值',
  1039: 'COV 变化超出阈值',
  140: '投注已结算或所有投注选项均已结算',
  1040: '投注已结算或所有投注选项均已结算',
  802: '数据不存在'
};

interface CountTypes {
  /**
   * 投注额
   */
  betAmount: number;
  /**
   * 投注统计
   */
  betCount: number;
  /**
   * 有效额
   */
  validAmount: number;
  /**
   * 输赢
   */
  winAmount: number;
  /**
   * 币种
   * currency
   */
  currency: string;
}

export function useBettingRecord() {
  const { optionsStore } = useSportOptions();
  const { addTimer, clearTimer } = useTimerHook();
  // const { t } = useI18n();

  const betStore = reactive({
    betTypes: [] as { betTypeNameZh: string; bettype: string; bettypeName: string; commonly: boolean }[],
    betRecord: [] as FormatOrderItem[],
    total: 0,
    count: {
      betAmount: 0,
      betCount: 0,
      validAmount: 0,
      winAmount: 0,
      currency: ''
    } as CountTypes
  });

  const store = useStore();
  const userInfo = computed(() => store.state.user);
  console.log('userInfo', userInfo);

  // 参数
  const form = reactive<BetOrdersPrams>({
    current: 1,
    size: 20,
    currency: optionsStore.value.currency,
    status: FILTER_TABS[0].value,
    ...getDateList1()[0].value
  });
  const form1 = reactive<BetOrdersPrams>({
    current: 1,
    size: 20,
    currency: optionsStore.value.currency,
    status: 1,
    ...getDateList1()[0].value
  });

  const earlyStore = ref([] as Array<EarlyTypes>);
  const state = reactive({
    pullLoading: false, // 下拉加载请求
    pullUpLoading: false, // 上拉加载, H5专用
    loading: false, // 通用加载
    pages: 10,
    activeTab: 0,
    settleActiveTab: 1,
    activeTab1: 1,
    activeTab2: 2,
    activeTab3: 3,
    activeTab4: 4,
    avtiveFilterDate: 0,
    activeButton: 0,
    filterVisible: false,
    finished: false,
    isExpand: true,
    isFirst: true
  });

  /**
   * 获取投注记录
   */
  const getRecordData = async (
    { isPull, isPolling }: { isPull: boolean; isPolling: boolean } = { isPull: false, isPolling: false }
  ) => {
    let l;
    if (isPull) {
      state.pullLoading = true;
    } else {
      if (isMobile && state.isFirst) l = Laoding.show();
    }
    state.loading = true;

    const responseArray = await getOrders(form);
    if (isPolling) state.isFirst = false;
    const result = responseArray[0];
    const data = responseArray[1];
    state.loading = false;
    if (result) {
      betStore.betRecord = ordersFormat_365(get(data, 'page.records')) || [];
      betStore.count.betCount = get(data, 'otherData.betCount') || 0;
      betStore.count.betAmount = get(data, 'otherData.betAmount') || 0;
      betStore.count.winAmount = get(data, 'otherData.winAmount') || 0;
      betStore.count.currency = get(data, 'otherData.currency') || 'CNY';
      betStore.total = get(data, 'page.total') || 0;
    } else {
      Toast(data.msg, data.code);
      betStore.betRecord = [];
      betStore.count.betCount = 0;
      betStore.count.betAmount = 0;
      betStore.count.winAmount = 0;
      betStore.count.currency = 'CNY';
      betStore.total = 0;
    }
    if (isPull) {
      state.pullLoading = false;
    } else {
      isMobile && Laoding.hide(l);
    }
  };

  const getEarlyData = async (
    { isPull, isLaoding }: { isPull: boolean; isLaoding: boolean } = { isPull: false, isLaoding: true }
  ) => {
    let l;
    if (isPull) {
      state.pullLoading = true;
    } else {
      if (isMobile && isLaoding) l = Laoding.show();
    }
    state.loading = true;
    const responseArray = await loadCashOutValues();
    const betRecordArray = await getOrders(form1);
    const r1 = betRecordArray[0]; //getBetList
    const r2 = responseArray[0]; //loadCashOut
    const temp = responseArray[1];
    const responseArrayData: any = [];
    if (r1 && r2) {
      const betRecordArrayData = ordersFormat_365(get(betRecordArray[1], 'page.records')) || [];
      temp.forEach(item => {
        betRecordArrayData.forEach(item1 => {
          if (item.orderId === item1.id) {
            responseArrayData.push({ ...item1, ...item });
          }
        });
      });

      earlyStore.value = responseArrayData;
      earlyStore.value.forEach(item => {
        item.isFinished = false;
      });
    } else {
      earlyStore.value = [];
    }
    if (isPull) {
      state.pullLoading = false;
    } else {
      isMobile && isLaoding && Laoding.hide(l);
    }
  };
  const combineLoadData = async (param?) => {
    clearTimer(TimerEnum.ORDER_LIST);
    const fn = async () => {
      const resGetCashOutValues = await getCashOutValues();
      if (!resGetCashOutValues[0]) {
        return;
      }
      getRecordData({ isPull: true, isPolling: false });
      const responseArray = await loadCashOutValues();
      const r2 = responseArray[0]; //loadCashOut
      const temp = responseArray[1];
      if (r2) {
        temp.forEach(item => {
          earlyStore.value.forEach((item1, index) => {
            if (item.orderId === item1.id) {
              earlyStore.value[index] = { ...item1, ...item };
            }
          });
        });
        if (param) {
          earlyStore.value.forEach(item1 => {
            if (item1.id === param[0]?.orderId) {
              item1.isFinished = param[1];
            }
          });
        }
      }
    };
    addTimer(TimerEnum.ORDER_LIST, fn, 5000);
  };

  const toggleDate = () => {
    state.filterVisible = !state.filterVisible;
  };

  //filter the tickets
  const filterArray = item => {
    earlyStore.value.find((obj, index) => {
      if (obj.orderId === item.orderId) {
        earlyStore.value.splice(index, 1);
        return true;
      }
    });
  };

  const filterArrayWeb = item => {
    earlyStore.value.find((obj, index) => {
      if (obj.orderId === item.id) {
        earlyStore.value.splice(index, 1);
        return true;
      }
    });
  };

  const mainCashout = async (params: BetCashOut) => {
    const postValue = await postCashout(params);
    return postValue;
  };

  const mainPartialCashout = async (params: PartialCashOut) => {
    const partialValue = await postPartialCashout(params);
    return partialValue;
  };

  const mainAutoCashout = async (params: AutoCashOut) => {
    const autoValue = await postAutoCashout(params);
    return autoValue;
  };

  const delAutoCashout = async (params: DeleteCashOut) => {
    const deleteValue = await deleteAutoCashout(params);
    return deleteValue;
  };

  const onTabChange = async (
    { key, index }: { key: 'activeTab' | 'avtiveFilterDate' | 'settleActiveTab'; index: number },
    cb?: () => void,
    isWeb = false
  ) => {
    // 切换tab收起日期选择
    state.filterVisible = false;
    if (state[key] === index && !isWeb) return;
    state[key] = index;
    form.current = 1;
    switch (key) {
      case 'activeTab':
        form.status = FILTER_TABS[index - 1].value;
        break;
      case 'settleActiveTab':
        form.status = SETTLE_TABS[index].value;
        return;
      case 'avtiveFilterDate':
        form.beginTime = getDateList1()[index].value.beginTime;
        form.endTime = getDateList1()[index].value.endTime;
        break;
      default:
        break;
    }
    form.current = 1;
    state.finished = false;
    !!cb && cb();

    getRecordData();
  };

  const onTabChange1 = async (
    { key, index }: { key: 'activeTab'; index: number },
    cb?: () => void,
    t?,
    isFirst = true
  ) => {
    // 切换tab收起日期选择
    state.filterVisible = false;
    if (state[key] === index && !isFirst) return;
    state[key] = index;

    state.finished = false;
    !!cb && cb();
    const l = Laoding.show({
      text: t('lang.sport_order_earlySettlementLoading') /**提前结算更新中 */
    });
    getCashOutValues();
    setTimeout(async () => {
      await getEarlyData({ isPull: false, isLaoding: false });
      Laoding.hide(l);
    }, 1000);
  };
  // 上拉加载
  const onListLoad = async () => {
    if (state.pullLoading) {
      betStore.betRecord = [];
      state.pullLoading = false;
    }
    // 请求
    form.current = form.current + 1;
    const responseArray = await getOrders(form);
    const result = responseArray[0];
    const data = responseArray[1];
    if (result && get(data, 'page.records')) {
      betStore.betRecord = betStore.betRecord.concat(ordersFormat_365(data.page.records));
    }
    state.pullUpLoading = false;
    // 达到条件停止
    if (form.current >= (get(data, 'page.pages') || 0)) {
      state.finished = true;
    }
  };

  const getBetTypeData = async () => {
    if (betStore.betTypes.length <= 1) {
      await getBetData();
    }
  };

  const getBetData = async () => {
    const [betTypeResponse, betTypeData] = await getBetTypes({ oddsType: 2, commonly: false });
    if (betTypeResponse) {
      betStore.betTypes = betTypeData;
    }
  };

  return {
    onListLoad,
    onTabChange,
    onTabChange1,
    toggleDate,
    SETTLE_TABS,
    state,
    form,
    form1,
    betStore,
    getData: getRecordData,
    earlyData: getEarlyData,
    getBetTypeData,
    postCashout,
    getCashOutValues,
    loadCashOutValues,
    earlyStore,
    filterArray,
    mainCashout,
    mainAutoCashout,
    mainPartialCashout,
    delAutoCashout,
    filterArrayWeb,
    combineLoadData,
    errorMessages
  };
}
