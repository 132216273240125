import { useI18n } from '@/hooks';

const project = '' || (process.env.VUE_APP_PAGE as any);
const isH5 = project.includes('mobile');
console.log('isH5', isH5);

const Toast = async (msg?: string, code?: number) => {
  const { t } = useI18n();
  if (code != null) {
    if (code == 112) {
      msg = t('lang.sport_common_loginTips');
    } else {
      msg = t('lang.sport_common_systemException');
    }
  }
  if (isH5) {
    const { Toast: m } = await import('vant');
    await import('vant/es/toast/style');
    await import('./style.less');
    return m({
      message: msg == null || msg.trim().length == 0 ? t('lang.sport_common_systemException') : msg,
      forbidClick: true,
      className: 'toastH5'
    });
  } else {
    const { message } = await import('ant-design-vue');
    return message.info(msg == null || msg.trim().length == 0 ? t('lang.sport_common_systemException') : msg);
  }
};

export { Toast };
