import { CMS_API_KEY, SPORT_TENANT_KEY } from '@/common';

const env = process.env;
const CMS_SERVER_URL = env.VUE_APP_CMS_API; // 平台api
const SP_SERVER_RUL = env.VUE_APP_SERVER_IP; // 体育开发api
const SP_WS_RUL = env.VUE_APP_WS_URL; // webcoket
export const isDev = env.NODE_ENV === 'development';

const oss = env.VUE_APP_OSS_API;

// 获取租户代码
export const getTenant = (): string => {
  if (isDev) {
    const devTenantCode = process.env.VUE_APP_TENANT_CODE || 'intsit';
    return devTenantCode;
  }
  const webTenant = localStorage.getItem(SPORT_TENANT_KEY) || '';
  return webTenant;
};

// 获取CMS平台api
export function getCMSapi(): string {
  if (isDev) {
    return CMS_SERVER_URL || '';
  }
  const beseAPI = localStorage.getItem(CMS_API_KEY) || '';
  return beseAPI;
}

// 获取体育api
export function getSportApi(need = true): string {
  if (isDev) {
    return SP_SERVER_RUL + '/api';
  }
  const beseAPI = ''; // 使用本身域名
  return need ? beseAPI + '/api' : beseAPI;
}

// 获取websocket api域名
export function getWebsocketApi(): string {
  if (isDev) {
    return SP_WS_RUL || '';
  }
  const beseAPI = location.origin.replace('http', 'ws');
  return beseAPI;
}

// 获取球队标志cdn
export function getCNDlogoUrl(): string {
  const beseURL = oss || '';
  return beseURL;
}

// 语言编码转换
const LANG_CODES = {
  en: 'en-US',
  id: 'in-ID',
  th: 'th-TH',
  vi: 'vi-VN',
  pt: 'pt-PT'
};

export function langFormat(lang: string) {
  const code = LANG_CODES[lang];
  return code || lang;
}
