import { defineComponent, onMounted } from 'vue';
import { useSportTypes, useI18n, useAuth, DATE_TYPES, NEW_DATE_TYPE, CHAMPION_TYPE, useFavortMatch } from '@/hooks';
import { HeaderBalance } from 'kg-web/components';
import HeaderTop from './headert-top/index';
import style from './header.module.less';
import { openWindow, RouteEnum, queryToStr } from '@/common';
import { SvgIcon } from '@/components';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Header',
  setup() {
    const { initTab, onDateTabchange, activePeriodId } = useSportTypes();
    const { markMatchsCount } = useFavortMatch();
    const { account } = useAuth();
    const router = useRouter();
    const { t } = useI18n();
    const pathHom = () => {
      const queryObj = {
        sportId: 1,
        periodId: 3
      };
      const queryStr = queryToStr(queryObj);
      router.replace(RouteEnum.ROOT + queryStr);
    };
    onMounted(async () => {
      await initTab();
    });
    return () => (
      <header class={style.headerCotainer}>
        <HeaderTop />
        <div class={style.header}>
          <div
            class={style.logo}
            onClick={() => {
              pathHom();
              initTab;
            }}
          ></div>
          <div class={style.main}>
            {DATE_TYPES.map(item => (
              <div
                onClick={() => onDateTabchange(item.value)}
                key={item.code}
                class={[style.item, item.value === activePeriodId.value ? style.active : null]}
              >
                <span>{t(item.i18n)}</span>
              </div>
            ))}
            {CHAMPION_TYPE.map(item => (
              <div
                onClick={() => onDateTabchange(5)}
                key={item.label}
                class={[style.item, 5 === activePeriodId.value ? style.active : null]}
              >
                <span>
                  {/* 收藏 */}
                  {t(item.i18n)}
                </span>
              </div>
            ))}
            {NEW_DATE_TYPE.map(item => (
              <div
                onClick={() => onDateTabchange(-1)}
                key={item.label}
                class={[style.item, -1 === activePeriodId.value ? style.active : null]}
              >
                <span>
                  {/* 收藏 */}
                  {t(item.i18n)}
                </span>
                <span>{markMatchsCount.value}</span>
              </div>
            ))}
          </div>
          <div class={style.aside}>
            {/*<div
              class={style.ordersLink}
              onClick={() => openWindow(RouteEnum.ORDERS, { width: 390, height: screen.height * 0.6 })}
            >
               注单历史
              <span>{t('lang.sport_common_betHistory')}</span>
              <SvgIcon class={style.icon} name="order" />
            </div>*/}
            <div class={style.homeHeaderUserInfo}>
              <div class={style.nickName}>
                <span>Hi,{account.value || '--'}!</span>
              </div>
              <HeaderBalance />
            </div>
            <div class={style.optionBtn} onClick={() => openWindow(RouteEnum.OPTIONS)}>
              <SvgIcon name="option" />
            </div>
          </div>
        </div>
      </header>
    );
  }
});
