/**
 * Multi-language related operations
 */

import { unref } from 'vue';

import { i18n } from './setupI18n';
import { languageList, loadLocalePool, LocaleType } from './localeTypes';

function setI18nLanguage(locale: LocaleType) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    (i18n.global.locale as any).value = locale;
  }
  document.querySelector('html')?.setAttribute('lang', locale);
}

export function useLocale() {
  // Switching the language will change the locale of useI18n
  // And submit to configuration modification
  async function changeLocale(locale: LocaleType) {
    const isExist = languageList.find(e => e.seriNo === locale);
    if (!isExist) {
      console.error('不存在当前语言' + locale);
      locale = languageList[0].seriNo;
    }
    const globalI18n = i18n.global;
    const currentLocale = unref(globalI18n.locale);
    if (currentLocale === locale) return locale;

    if (loadLocalePool.includes(locale)) {
      setI18nLanguage(locale);
      return locale;
    }
    const langModule = ((await import(/* webpackChunkName: "i18n.[request]" */ `./lang/${locale}.json`)) as any)
      .default as { [key: string]: string };
    if (!langModule) return;

    globalI18n.setLocaleMessage(locale, { lang: langModule });
    loadLocalePool.push(locale);

    setI18nLanguage(locale);
    return locale;
  }

  return {
    changeLocale
  };
}
