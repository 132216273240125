import { defineComponent, watch, ref, provide } from 'vue';
import './collapse.style.less';

export default defineComponent({
  name: 'Collapse',
  props: {
    expandAll: {
      type: Boolean,
      default: true
    },
    arrow: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'primary'
    }
  },
  setup(props, { slots }) {
    const expand = ref(true);

    watch(
      () => props.expandAll,
      () => {
        expand.value = props.expandAll;
      },
      {
        immediate: true
      }
    );

    provide('expandAll', expand);
    provide('arrow', props.arrow);
    provide('type', props.type);

    return () => <div class={['web-collapse', props.expandAll ? 'expand-all' : null]}>{slots.default?.()}</div>;
  }
});
