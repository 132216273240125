/**
 * 投注记录
 */
import { computed, reactive } from 'vue';
import { getOrders, BetOrdersPrams } from '@/services';
import { get } from 'lodash-es';
import dayjs from 'dayjs';
import Laoding from '@/components/loading-toast';
import { FormatOrderItem, isMobile, ordersFormat } from '@/common';
import { Toast } from '@/components';
import { useSportOptions } from '@/hooks';
import { useStore } from 'vuex';
import { getBetTypes } from '@/services/kg-api';

export const TAB_LIST: { label: string; value: 0 | 1 | 2 }[] = [
  {
    label: 'lang.sport_common_quanBu',
    value: 0
  },
  {
    label: 'lang.sport_tape_noSettlement',
    value: 1
  },
  {
    label: 'lang.sport_tape_haveAlreadySettled',
    value: 2
  }
  // {
  //   label: '已取消',
  //   value: -1
  // }
];

export interface DateType {
  title: string;
  i18n: string;
  value: {
    beginTime: any;
    endTime: any;
  };
}

export const DATE_LIST: DateType[] = [
  {
    title: '今天',
    i18n: 'lang.sport_common_today',
    value: {
      beginTime: dayjs().startOf('day').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  },
  {
    title: '昨天',
    i18n: 'lang.sport_common_yesterday',
    value: {
      beginTime: dayjs().startOf('day').subtract(1, 'days').valueOf() + '',
      endTime: dayjs().startOf('day').valueOf() + ''
    }
  },
  {
    title: '7天内',
    i18n: 'lang.sport_common_last7days',
    value: {
      beginTime: dayjs().subtract(7, 'days').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  },
  {
    title: '30天内',
    i18n: 'lang.sport_common_last30days',
    value: {
      beginTime: dayjs().subtract(29, 'day').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  }
];

export const getDateList = (): DateType[] => [
  {
    title: '今天',
    i18n: 'lang.sport_common_today',
    value: {
      beginTime: dayjs().startOf('day').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  },
  {
    title: '昨天',
    i18n: 'lang.sport_common_yesterday',
    value: {
      beginTime: dayjs().startOf('day').subtract(1, 'days').valueOf() + '',
      endTime: dayjs().startOf('day').valueOf() + ''
    }
  },
  {
    title: '7天内',
    i18n: 'lang.sport_common_last7days',
    value: {
      beginTime: dayjs().subtract(7, 'days').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  },
  {
    title: '30天内',
    i18n: 'lang.sport_common_last30days',
    value: {
      beginTime: dayjs().subtract(29, 'day').valueOf() + '',
      endTime: dayjs().valueOf() + ''
    }
  }
];

interface CountType {
  /**
   * 投注额
   */
  betAmount: number;
  /**
   * 投注统计
   */
  betCount: number;
  /**
   * 有效额
   */
  validAmount: number;
  /**
   * 输赢
   */
  winAmount: number;
  /**
   * 币种
   * currency
   */
  currency: string;
}

export function useBetRecord() {
  const { optionsStore } = useSportOptions();
  // const { t } = useI18n();

  const betStore = reactive({
    betTypes: [] as { betTypeNameZh: string; bettype: string; bettypeName: string; commonly: boolean }[],
    betRecord: [] as FormatOrderItem[],
    total: 0,
    count: {
      betAmount: 0,
      betCount: 0,
      validAmount: 0,
      winAmount: 0,
      currency: ''
    } as CountType
  });

  const store = useStore();
  const userInfo = computed(() => store.state.user);
  console.log('userInfo', userInfo);

  // 参数
  const form = reactive<BetOrdersPrams>({
    current: 1,
    size: 20,
    currency: optionsStore.value.currency,
    status: TAB_LIST[0].value,
    ...getDateList()[0].value
  });

  const state = reactive({
    pullLoading: false, // 下拉加载请求
    pullUpLoading: false, // 上拉加载, H5专用
    loading: false, // 通用加载
    pages: 10,
    activeTab: 0,
    avtiveFilterDate: 0,
    filterVisible: false,
    finished: false,
    toggle: false
  });

  /**
   * 获取投注记录
   */
  const getRecordData = async ({ isPull }: { isPull: boolean } = { isPull: false }) => {
    let l;
    if (isPull) {
      state.pullLoading = true;
    } else {
      if (isMobile) l = Laoding.show();
    }
    state.loading = true;
    const responseArray = await getOrders(form);
    const result = responseArray[0];
    const data = responseArray[1];
    state.loading = false;
    if (result) {
      betStore.betRecord = ordersFormat(get(data, 'page.records')) || [];
      betStore.count.betCount = get(data, 'otherData.betCount') || 0;
      betStore.count.betAmount = get(data, 'otherData.betAmount') || 0;
      betStore.count.winAmount = get(data, 'otherData.winAmount') || 0;
      betStore.count.currency = get(data, 'otherData.currency') || 'CNY';
      betStore.total = get(data, 'page.total') || 0;
    } else {
      Toast(data.msg, data.code);
      betStore.betRecord = [];
      betStore.count.betCount = 0;
      betStore.count.betAmount = 0;
      betStore.count.winAmount = 0;
      betStore.count.currency = 'CNY';
      betStore.total = 0;
    }
    if (isPull) {
      state.pullLoading = false;
    } else {
      isMobile && Laoding.hide(l);
    }
  };

  const toggleDate = () => {
    state.filterVisible = !state.filterVisible;
  };

  const onTabChange = async (
    { key, index }: { key: 'activeTab' | 'avtiveFilterDate'; index: number },
    cb?: () => void,
    isWeb = false
  ) => {
    if (state[key] === index && !isWeb) return;
    state[key] = index;
    form.current = 1;
    switch (key) {
      case 'activeTab':
        form.status = TAB_LIST[index].value;
        break;
      case 'avtiveFilterDate':
        form.beginTime = getDateList()[index].value.beginTime;
        form.endTime = getDateList()[index].value.endTime;
        break;
      default:
        break;
    }
    form.current = 1;
    state.finished = false;
    !!cb && cb();
    await getRecordData();
  };

  // 上拉加载
  const onListLoad = async () => {
    if (state.pullLoading) {
      betStore.betRecord = [];
      state.pullLoading = false;
    }
    // 请求
    form.current = form.current + 1;
    //const { result, data } = await getOrders(form);
    const responseArray = await getOrders(form);
    const result = responseArray[0];
    const data = responseArray[1];
    if (result && get(data, 'page.records')) {
      betStore.betRecord = betStore.betRecord.concat(ordersFormat(data.page.records));
    }
    state.pullUpLoading = false;
    // 达到条件停止
    if (form.current >= (get(data, 'page.pages') || 0)) {
      state.finished = true;
    }
  };

  const getBetTypeData = async () => {
    if (betStore.betTypes.length <= 1) {
      await getBetData();
    }
  };

  const getBetData = async () => {
    const [betTypeResponse, betTypeData] = await getBetTypes({ oddsType: 2, commonly: false });
    if (betTypeResponse) {
      betStore.betTypes = betTypeData;
    }
  };

  return {
    onListLoad,
    onTabChange,
    toggleDate,
    state,
    form,
    betStore,
    getData: getRecordData,
    getBetTypeData
  };
}
