import { defineComponent, inject, watch, ref, Ref } from 'vue';
import './collapse.style.less';

export default defineComponent({
  name: 'CollapsePanel',
  emits: ['change'],
  setup(_props, { slots, emit }) {
    const isExpand = ref(false);

    const handleClickToggle = () => {
      isExpand.value = !isExpand.value;
    };
    const expandAll = inject('expandAll') as Ref<boolean>;
    const showArrow = inject('arrow') as boolean;

    watch(
      () => expandAll.value,
      () => {
        isExpand.value = expandAll.value;
      },
      { immediate: true }
    );
    watch(
      () => isExpand.value,
      () => {
        emit('change', isExpand.value);
      },
      { immediate: true }
    );
    return () => (
      <div class={['mobile-365-collapse-panel', isExpand.value ? 'is-expand' : null]}>
        <div class="mobile-365-collapse-panel-header" onClick={() => handleClickToggle()}>
          <div class="mobile-365-collapse-panel-header-conetnt">{slots.header?.()}</div>
          {showArrow ? <div class="expand-icon"></div> : null}
        </div>
        <div class="mobile-365-collapse-panel-content">{slots.default?.()}</div>
      </div>
    );
  }
});
