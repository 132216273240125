/**
 * 检查登录状态
 */
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { isMobile, SPORT_H5_URL_KEY, SPORT_WEB_URL_KEY, RouteEnum, jumpToNativeLogin } from '@/common';
import { useI18n } from '@/hooks';
import { Toast } from '@/components';

import store from '@/store';

const isDevTool = process.env.NODE_ENV === 'development';

// 返回web的url
const webUrl = localStorage.getItem(SPORT_WEB_URL_KEY) || '';

// 返回web的url
const H5Url = localStorage.getItem(SPORT_H5_URL_KEY) || '';

export function useAuth() {
  const router = useRouter();
  const { t } = useI18n();

  const account = computed(() => {
    return store.getters.userName;
  });

  //是否登录
  const isLogin = computed(() => store.getters.isLogin);

  // 登录
  const toLogin = () => {
    if (isDevTool) {
      router.replace(RouteEnum.LOGIN);
      return;
    }
    const mobileLogin = async () => {
      window.parent.postMessage({ type: 'login' }, '*');
      await jumpToNativeLogin({ autoLogin: false });
    };
    isMobile ? mobileLogin() : null;
  };

  // 注册
  const toMobileRegedit = () => {
    window.open(H5Url + '/m/entry/login');
  };

  // 注册
  // const toWebRegedit = () => {
  //   window.open(webUrl + '/entry/login');
  // };

  // 检查登录, 登录信息组件处理
  const checkIsLogin = async (
    { loginDialog, isToast }: { loginDialog: boolean; isToast?: boolean } = { loginDialog: false, isToast: false }
  ): Promise<boolean> => {
    if (!isLogin.value && loginDialog) {
      // 打开登录框 bet365使用
      const { openLoginDialog } = await import('365-mobile/components');
      openLoginDialog();
      return false;
    }
    if (!isLogin.value && isToast) {
      // bet365WEB只弹出Toast
      console.warn('WARN: 请先登录!');
      const message = t('lang.sport_common_loginTips');
      Toast(message);
      return false;
    }
    if (!isLogin.value) {
      // 弹出Toast
      console.warn('WARN: 请先登录!');
      const message = t('lang.sport_common_loginTips');
      Toast(message);
      toLogin();
      return false;
    }
    return true;
  };

  const backMainPage = () => {
    window.open(isMobile ? H5Url : webUrl);
  };

  // 登出
  const signOut = () => store.dispatch('SIGN_OUT');

  return {
    signOut,
    toMobileRegedit,
    toLogin,
    isLogin,
    account,
    checkIsLogin,
    backMainPage
  };
}
