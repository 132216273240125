import { defineComponent, ref } from 'vue';

import { routes } from 'kg-web/router';

import './style.less';

export default defineComponent({
  name: 'DevTools',
  setup() {
    const isHidden = ref(false);

    return () => (
      <div class={{ 'dev-tool': true, isHidden: !isHidden.value }}>
        <div class="close" onClick={() => (isHidden.value = !isHidden.value)}>
          {!isHidden.value ? '+' : '-'}
        </div>
        <div class="dev-tool-inner" v-show={isHidden.value}>
          <span>*仅测试*</span>
          <hr />
          {routes.map(item => (
            <router-link to={item} key={item.path}>
              <a class="route-style"> {item.meta?.title} </a>
            </router-link>
          ))}
        </div>
      </div>
    );
  }
});
