import { defineComponent, onMounted, reactive, computed } from 'vue';
import { useI18n, usePublicNotice, useSportOptions } from '@/hooks';
import MarqueeText from 'vue-marquee-text-component';
import { languageList, useLocale } from '@/i18n';
import style from './top.module.less';
import { MarketTypeList, openWindow, RouteEnum, langFlags, replaceParamVal } from '@/common';
import { SvgIcon } from '@/components';
import { useBetWeb } from '@/hooks/useBetWeb.hook';

import ClickOutside from './click-outside';

export default defineComponent({
  name: 'HeaderTop',
  directives: {
    outSide: ClickOutside
  },
  setup() {
    const { publicityData, getPublicityData } = usePublicNotice();
    const { optionsStore, changeSetiing } = useSportOptions();
    const { changeLocale } = useLocale();
    const { clearAllTickets } = useBetWeb();
    const { t } = useI18n();

    // 显示的语言名字
    const langLabel = computed(() => {
      const ob = languageList.find(e => e.seriNo === optionsStore.value.lang);
      return ob ? ob.localLanguage : '';
    });

    // 显示的市场类型名字
    const martketTypeLabel = computed(() => {
      const ob = MarketTypeList.find(e => e.value === optionsStore.value.marketType);
      return ob ? t(ob.label) : '';
    });

    const state = reactive({
      isPaused: false,
      visibleLang: false,
      visibleMarket: false
    });

    const changeLang = (lang: string) => {
      changeLocale(lang as any);
      changeSetiing('lang', lang);
      clearAllTickets();
      replaceParamVal('lang', lang);
      setTimeout(() => {
        location.reload();
      }, 500);
    };

    const changeMarket = (mt: number) => {
      changeSetiing('marketType', mt);
      clearAllTickets();
      location.reload();
    };

    const langToggle = () => {
      state.visibleLang = !state.visibleLang;
    };

    const marketToggle = () => {
      state.visibleMarket = !state.visibleMarket;
    };

    onMounted(async () => {
      getPublicityData();
    });

    const SelectOptions = () => (
      <div class={style.setOptions}>
        {MarketTypeList.map(item => (
          <div
            key={item.code}
            class={[style.item, item.value === optionsStore.value.marketType ? style.active : null]}
            onClick={() => changeMarket(item.value)}
          >
            {t(item.label)}
          </div>
        ))}
      </div>
    );

    const SelectLangOptions = () => (
      <div class={style.setOptions}>
        {languageList.map(item => (
          <div
            class={[style.item, item.seriNo === optionsStore.value.lang ? style.active : null]}
            key={item.seriNo}
            onClick={() => changeLang(item.seriNo)}
          >
            {item.localLanguage}
          </div>
        ))}
      </div>
    );

    return () => (
      <div class={style.headerTop}>
        <div class={style.headerTopLeft}>
          <SvgIcon v-show={false} name="notice" />
          <MarqueeText duration={150} v-show={false} class={style.MarqueeText} paused={state.isPaused}>
            <span
              onClick={() => openWindow(RouteEnum.OPTIONS + '?tab=notice')}
              onMouseenter={() => (state.isPaused = true)}
              onMouseleave={() => (state.isPaused = false)}
            >
              {publicityData.value}
            </span>
          </MarqueeText>
        </div>
        <div class={style.headerTopRight}>
          <div
            class={style.headerTopRight__Link}
            onClick={() => langToggle()}
            v-outSide={() => (state.visibleLang = false)}
          >
            <span style="display: flex;align-items: center;">
              <img class={style.flag} src={langFlags[optionsStore.value.lang]} />
              {langLabel.value} <SvgIcon name="arrow_bottom" class={style.icon} />
            </span>
            <SelectLangOptions v-show={state.visibleLang} />
          </div>
          <div
            class={style.headerTopRight__Link}
            onClick={() => marketToggle()}
            v-outSide={() => (state.visibleMarket = false)}
          >
            <span>
              {martketTypeLabel.value} <SvgIcon name="arrow_bottom" class={style.icon} />
            </span>
            <SelectOptions v-show={state.visibleMarket} />
          </div>
          <div class={style.headerTopRight__Link} onClick={() => openWindow(RouteEnum.RULES)}>
            {/* 体育规则  */}
            <span>{t('lang.sport_common_betRules')}</span>
          </div>
          {/* <div class={style.headerTopRight__Link} onClick={() => backMainPage()}> */}
          {/*  /!* 游戏娱乐场 *!/*/}
          {/* <span>{t('lang.sport_common_gameCasino')}</span> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
});
