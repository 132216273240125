import { defineComponent, inject, reactive, computed } from 'vue';
import { chunk, isEmpty } from 'lodash-es';

import { useI18n } from '@/hooks';
import { SvgIcon } from '@/components';
import { UiType } from '@/common';
import { useBetWeb, hasTicketId } from '@/hooks/useBetWeb.hook';
import { Skeleton } from 'ant-design-vue';
import { TeamLogo, DataEmpty, Collapse, CollapsePanel, OddsPriceWeb, Scrollbar } from 'kg-web/components';
import detail from './detailed-match-odds.module.less';
import { useMediaQuery, useWindowSize } from '@vueuse/core';

export default defineComponent({
  name: 'JBBwebSportDetail',
  setup() {
    const { addTicket } = useBetWeb();
    const { t } = useI18n();
    const detailProvide = inject('detailProvide') as any;
    const { store, showOdds, changeGroup } = detailProvide;
    const isLargeScreen = useMediaQuery('(min-width: 1720px)');
    const { width } = useWindowSize();
    const state = reactive({
      isVisible: false,
      expanded: true
    });
    const isCl = computed(() => isLargeScreen.value && showOdds.value.length > 1);
    const largeScreenOdds = computed(() => {
      const largeScreenOdds = [
        { cont: 0, list: [] },
        { cont: 0, list: [] }
      ];
      let n1 = 0;
      let n2 = 0;
      if (showOdds.value.length) {
        for (let i = 0; i < showOdds.value.length; i++) {
          let cl = 0;
          let ln = 0;
          for (let j = 0; j < showOdds.value[i].list.length; j++) {
            let newLn = 0;
            let newCl = 0;
            for (let k = 0; k < showOdds.value[i].list[j].sels.length; k++) {
              if (showOdds.value[i].list[j].sels[k] instanceof Array) {
                newLn = newLn + showOdds.value[i].list[j].sels[k].length;
              } else {
                newLn = newLn + 1;
              }
            }
            // 波胆每行三个
            if (showOdds.value[i].uiType === UiType.UiType4) {
              newCl = Math.ceil(newLn / 3);
            } else if (showOdds.value[i].uiType === UiType.UiType3 && showOdds.value[i].list.isOneLine) {
              newCl = Math.ceil(newLn / 3);
            } else if (showOdds.value[i].list[j].isOneLine) {
              newCl = Math.ceil(newLn / 2);
            } else {
              newCl = Math.ceil(newLn / Math.floor((width.value - 720) / 2 / 100));
            }

            cl = cl + newCl;
            ln = ln + newLn;
          }
          if (n1 > n2) {
            n2 = n2 + cl + 1;
            largeScreenOdds[1].list.push(showOdds.value[i] as never);
          } else {
            n1 = n1 + cl + 1;
            largeScreenOdds[0].list.push(showOdds.value[i] as never);
          }
        }
      }
      return largeScreenOdds;
    });
    const renderScoreOdds = ({ sels, betTypeName, betType, oddsId, combo, periodId, allowBetBuilder }: any) => {
      const [team, [other]] = chunk(sels, 3) as any;
      return (
        <>
          <div class={detail.scoreCloumBox}>
            {team.map((sel: any) => (
              <div class={detail.cloumBox}>
                {sel.map
                  ? sel.map(s => {
                      return isEmpty(s) ? (
                        <div class={detail.oddItem}>-</div>
                      ) : (
                        <div
                          class={[
                            detail.oddItem,
                            hasTicketId({
                              matchId: store.info.id,
                              marketId: oddsId,
                              wagerSelectionId: s.WagerSelectionId
                            })
                              ? detail.active
                              : ''
                          ]}
                          onClick={() =>
                            !s.locked &&
                            addTicket(
                              {
                                betType: betType,
                                betTypeName: betTypeName,
                                matchId: store.info.id,
                                marketId: oddsId,
                                sportType: store.info.sportId,
                                oddsType: s.OddsType,
                                wagerSelectionId: s.WagerSelectionId,
                                key: s.Key,
                                price: s.price,
                                point: s.Point,
                                periodId: periodId,
                                keyName: s.KeyName,
                                home: store.info.home,
                                away: store.info.away,
                                leagueName: store.info.leagueName,
                                market: store.info.market,
                                combo: combo,
                                Combo: combo,
                                EuropePrice: s.EuropePrice,
                                matchTime: store.info.matchTime,
                                allowBetBuilder: allowBetBuilder
                              },
                              true
                            )
                          }
                        >
                          <div class={detail.oddItemName}>{s.name}</div>
                          <div class={detail.oddItemPoint}>
                            {s.locked ? (
                              <SvgIcon class={detail.locked} name="lock" />
                            ) : (
                              <OddsPriceWeb value={s.price} />
                            )}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            ))}
          </div>
          {other.name ? (
            <div
              class={[
                `${detail.oddItem} ${detail.otherOddItem}`,
                hasTicketId({ matchId: store.info.id, marketId: oddsId, wagerSelectionId: other.WagerSelectionId })
                  ? detail.active
                  : ''
              ]}
              onClick={() =>
                !other.locked &&
                addTicket({
                  betType: betType,
                  betTypeName: betTypeName,
                  matchId: store.info.id,
                  marketId: oddsId,
                  sportType: store.info.sportId,
                  oddsType: other.OddsType,
                  wagerSelectionId: other.WagerSelectionId,
                  key: other.Key,
                  price: other.price,
                  point: other.Point,
                  periodId: periodId,
                  keyName: other.KeyName,
                  home: store.info.home,
                  away: store.info.away,
                  leagueName: store.info.leagueName,
                  market: store.info.market,
                  combo: combo,
                  Combo: combo,
                  EuropePrice: other.EuropePrice,
                  matchTime: store.info.matchTime,
                  allowBetBuilder: allowBetBuilder
                })
              }
            >
              <div class={detail.oddItemName}>{other.name}</div>
              <div class={detail.oddItemPoint}>
                {other.locked ? <SvgIcon class={detail.locked} name="lock" /> : <OddsPriceWeb value={other.price} />}
              </div>
            </div>
          ) : null}
        </>
      );
    };
    const renderOddBox = (odds: any) => {
      return odds.map(
        (odd: {
          betType: string | number | symbol | undefined;
          betTypeName: any;
          showTeamName: any;
          list: any[];
          Bettype: any;
          BettypeName: any;
          OddsId: number;
          cl: number;
          ln: number;
          uiType: string;
          allowBetBuilder: number;
        }) => (
          <div class={detail.oddBox}>
            <Collapse expandAll={state.expanded} key={odd.betType}>
              <CollapsePanel
                v-slots={{
                  header: () => (
                    <div class={[detail.oddName, odd.uiType]}>
                      <span>{odd.betTypeName}</span>
                      {odd.allowBetBuilder ? <span class={detail.subName}>单场串关</span> : null}
                    </div>
                  )
                }}
              >
                {
                  <div class={detail.oddsContainer}>
                    {odd.showTeamName && (
                      <div class={detail.oddTeamNameBox}>
                        <div class={detail.oddTeam}>
                          <TeamLogo
                            teamId={store.info.homeId}
                            size={'xs'}
                            teamName={store.info.home}
                            logoUrl={store.info.homeLogo}
                          />
                          {store.info.home}
                        </div>
                        <div class={detail.oddTeam}>
                          <TeamLogo
                            teamId={store.info.awayId}
                            size={'xs'}
                            teamName={store.info.away}
                            logoUrl={store.info.awayLogo}
                          />
                          {store.info.away}
                        </div>
                      </div>
                    )}
                    {odd.uiType === UiType.UiType4 ? (
                      <>
                        <div class={detail.oddTeamNameBox}>
                          <div class={detail.oddTeam}>{t('lang.sport_common_home')}</div>
                          <div class={detail.oddTeam}>{t('lang.sport_common_tie')}</div>
                          <div class={detail.oddTeam}>{t('lang.sport_common_away')}</div>
                        </div>
                        {odd.list.map((item: any) => (
                          <div class={detail.oddHandicapBox}>
                            <div class={detail.oddHandicapCloum}>
                              {renderScoreOdds({
                                ...item,
                                betType: odd.betType,
                                betTypeName: odd.betTypeName,
                                allowBetBuilder: odd.allowBetBuilder
                              })}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {odd.uiType === UiType.UiType7
                          ? odd.list.map((item: any) => (
                              <>
                                {item.sels.map((sel: any) => {
                                  return isEmpty(sel) ? (
                                    <div class={detail.oddItem}>-</div>
                                  ) : (
                                    <div
                                      style="flex-basis: 50%"
                                      class={`${detail.oddItem} ${
                                        hasTicketId({
                                          matchId: store.info.id,
                                          marketId: item.oddsId,
                                          wagerSelectionId: sel.WagerSelectionId
                                        })
                                          ? detail.active
                                          : ''
                                      }`}
                                      onClick={() =>
                                        !sel.locked &&
                                        addTicket(
                                          {
                                            betType: odd.betType,
                                            betTypeName: odd.betTypeName,
                                            matchId: store.info.id,
                                            marketId: item.oddsId,
                                            sportType: store.info.sportId,
                                            oddsType: sel.OddsType,
                                            wagerSelectionId: sel.WagerSelectionId,
                                            key: sel.Key,
                                            price: sel.Price,
                                            point: sel.Point,
                                            periodId: item.periodId,
                                            EuropePrice: sel.EuropePrice,
                                            keyName: sel.KeyName,
                                            home: store.info.home,
                                            away: store.info.away,
                                            leagueName: store.info.leagueName,
                                            market: store.info.market,
                                            Combo: item.combo,
                                            matchTime: store.info.matchTime,
                                            allowBetBuilder: odd.allowBetBuilder
                                          } as any,
                                          true
                                        )
                                      }
                                    >
                                      <div class={detail.oddItemName}>{sel.name}</div>
                                      <div class={detail.oddItemPoint}>
                                        {sel.locked ? (
                                          <SvgIcon class={detail.locked} name="lock" />
                                        ) : (
                                          <OddsPriceWeb value={sel.price} />
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ))
                          : odd.list.map((item: any) => (
                              <div
                                style={item.sels.length && item.sels.length === 1 ? 'width: 50%' : ''}
                                class={`${detail.oddHandicapItem} ${
                                  odd.uiType === UiType.UiType3 ? detail.onlyOneName : ''
                                } ${item.isOneLine ? detail.oneName : ''}`}
                              >
                                {item.sels.map((sel: any) => {
                                  return isEmpty(sel) ? (
                                    <div class={detail.oddItem}>-</div>
                                  ) : (
                                    <div
                                      style={item.sels.length && item.sels.length === 1 ? 'flex-basis: 100%' : ''}
                                      class={`${detail.oddItem} ${
                                        hasTicketId({
                                          matchId: store.info.id,
                                          marketId: item.oddsId,
                                          wagerSelectionId: sel.WagerSelectionId
                                        })
                                          ? detail.active
                                          : ''
                                      }`}
                                      onClick={() =>
                                        !sel.locked &&
                                        addTicket(
                                          {
                                            betType: odd.betType,
                                            betTypeName: odd.betTypeName,
                                            matchId: store.info.id,
                                            marketId: item.oddsId,
                                            sportType: store.info.sportId,
                                            oddsType: sel.OddsType,
                                            wagerSelectionId: sel.WagerSelectionId,
                                            key: sel.Key,
                                            price: sel.Price,
                                            point: sel.Point,
                                            periodId: item.periodId,
                                            EuropePrice: sel.EuropePrice,
                                            keyName: sel.KeyName,
                                            home: store.info.home,
                                            away: store.info.away,
                                            leagueName: store.info.leagueName,
                                            market: store.info.market,
                                            Combo: item.combo,
                                            matchTime: store.info.matchTime,
                                            allowBetBuilder: odd.allowBetBuilder
                                          } as any,
                                          true
                                        )
                                      }
                                    >
                                      <div class={detail.oddItemName}>{sel.name}</div>
                                      <div class={detail.oddItemPoint}>
                                        {sel.locked ? (
                                          <SvgIcon class={detail.locked} name="lock" />
                                        ) : (
                                          <OddsPriceWeb value={sel.price} />
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ))}
                      </>
                    )}
                  </div>
                }
              </CollapsePanel>
            </Collapse>
          </div>
        )
      );
    };
    return () => (
      <Skeleton loading={store.loading} paragraph={{ rows: 8 }}>
        {store.errorPage ? (
          <DataEmpty title={t('lang.sport_common_betTips02')} type="odds" />
        ) : (
          <div class={detail.detail}>
            <div class={detail.handicapTypes}>
              <div
                class={[detail.arrowIcon, state.expanded ? '' : detail.rotate]}
                onClick={() => (state.expanded = !state.expanded)}
              >
                <SvgIcon name="arrow_top" class={detail.icon} />
              </div>

              <div class={detail.groupBox}>
                {/* {console.log('test::', store)} */}
                {store.groups.map((g: { classifyId: any; classifyName: string }, idx: number) => (
                  <div
                    class={`${detail.groupItem} ${g.classifyId === store.group ? detail.groupActive : ''}`}
                    onClick={() => changeGroup(g.classifyId)}
                  >
                    {idx === 0 ? t(g.classifyName) : g.classifyName}
                  </div>
                ))}
              </div>
            </div>
            <Scrollbar
              style={{
                height: 'calc(100% - 36px)',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
              }}
            >
              {showOdds.value && !isCl.value && renderOddBox(showOdds.value)}
              {showOdds.value && isCl.value && (
                <div class={detail.oddBoxContainer}>
                  <div class={detail.oddBoxList}>{renderOddBox(largeScreenOdds.value[0].list)}</div>
                  <div class={detail.oddBoxList}>{renderOddBox(largeScreenOdds.value[1].list)}</div>
                </div>
              )}
            </Scrollbar>
          </div>
        )}
      </Skeleton>
    );
  }
});
