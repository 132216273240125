/**
 *
 * 12345 => $12,345.00
 *
 * @param {String} symbol
 * @param {Number} decimals Decimal places
 * @param {Object} options
 */
import { floor, round, ceil } from 'lodash-es';

export function currency(
  value: string | number | undefined,
  symbol = '',
  decimals = 2,
  options?: {
    thousandsSeparator?: any;
    symbolOnLeft?: any;
    spaceBetweenAmountAndSymbol?: any;
    type?: any;
    decimalSeparator?: any;
  }
) {
  // @ts-ignore
  if (value.toString().indexOf(',') > -1) return symbol + value;
  let thousandsSeparator = '';
  let symbolOnLeft = '';
  let spaceBetweenAmountAndSymbol = '';
  const digitsRE = /(\d{3})(?=\d)/g;
  options = options || {};
  // @ts-ignore;
  const newValue = !!value ? parseFloat(value) : 0;
  if (!isFinite(newValue) || (!newValue && newValue !== 0)) return '';

  thousandsSeparator = options.thousandsSeparator != null ? options.thousandsSeparator : ',';

  symbolOnLeft = options.symbolOnLeft != null ? options.symbolOnLeft : true;

  spaceBetweenAmountAndSymbol =
    options.spaceBetweenAmountAndSymbol != null ? options.spaceBetweenAmountAndSymbol : false;

  let stringified = floor(Math.abs(newValue), decimals).toFixed(decimals);
  if (options.type && options.type === 1) {
    // type 等于1表示进行四舍五入 2表示无条件进位  默认情况为无条件舍弃
    stringified = round(Math.abs(newValue), decimals).toFixed(decimals);
  } else if (options.type && options.type === 2) {
    stringified = ceil(Math.abs(newValue), decimals).toFixed(decimals);
  }

  stringified = options.decimalSeparator ? stringified.replace('.', options.decimalSeparator) : stringified;

  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;

  const i = _int.length % 3;

  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? thousandsSeparator : '') : '';

  const _float = decimals ? stringified.slice(-1 - decimals) : '';

  symbol = spaceBetweenAmountAndSymbol ? (symbolOnLeft ? `${symbol} ` : ` ${symbol}`) : symbol;

  symbol = symbolOnLeft
    ? symbol + head + _int.slice(i).replace(digitsRE, `$1${thousandsSeparator}`) + _float
    : head + _int.slice(i).replace(digitsRE, `$1${thousandsSeparator}`) + _float + symbol;

  const sign = newValue < 0 ? '-' : '';

  return sign + symbol;
}
