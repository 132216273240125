
import { defineComponent, ref, onMounted, onBeforeUnmount, reactive, PropType } from 'vue';

export default defineComponent({
  name: 'LiveVideo',
  props: {
    urls: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const player = ref(null);
    const video = ref<HTMLVideoElement | null>(null);
    let dplayer: any;

    const state = reactive({
      showLines: false,
      currentLine: 0
    });

    const switchLine = async (line: string, i: number) => {
      if (state.currentLine === i) return;
      state.currentLine = i;
      state.showLines = false;
      initPlayer(line);
    };

    const initPlayer = async (url?: string) => {
      let isCanPlatHls = '';
      if (video.value) {
        // 判断浏览器是否原生支持播放hls
        isCanPlatHls =
          video.value.canPlayType('application/x-mpegURL') || video.value.canPlayType('application/vnd.apple.mpegURL');
      }
      if (!isCanPlatHls && !window.Hls) {
        // 不支持动态加载hls.js 进行软解码
        window.Hls = (await import(/* webpackChunkName: "hls" */ 'hls.js')).default;
      }
      const Dplayer = (await import(/* webpackChunkName: "hdplayerls" */ 'dplayer')).default;
      dplayer = new Dplayer({
        container: player.value,
        autoplay: true,
        lang: 'en',
        live: true,
        video: {
          url: url || props.urls[0],
          type: 'hls'
        }
      });
    };
    onBeforeUnmount(() => {
      dplayer && dplayer.destroy();
      dplayer = null;
    });
    onMounted(async () => {
      const p = player.value;
      !!p && (await initPlayer());
    });

    return { player, video, state, switchLine };
  }
});
