import { defineComponent, PropType, reactive, ref } from 'vue';
import { ChampItem } from '365-mobile/components';
import { StoreChampData } from '@/hooks';
import { BetTypeEnum } from '@/common';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
  name: 'ChampList',
  props: {
    ChampData: {
      type: Array as PropType<StoreChampData[]>,
      default: () => []
    },
    leagueCounter: {
      type: Number
    },
    sportName: {
      type: String,
      default: ''
    },
    showLeagueFilter: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const outTarget = ref(null);

    onClickOutside(outTarget, () => (states.target = false));
    const states = reactive({
      avtiveTime: 0,
      isExpandAll: true,
      open: false,
      setVisible: false,
      setTrick: false,
      target: false,
      betType: BetTypeEnum.proposition,
      gameTypeActive: 3,
      targetTypeActive: 0
    });

    return () => [
      <div class="champ-list">
        {props.ChampData.map((item, index) => (
          <ChampItem
            itemIndex={index}
            data={item}
            betType={states.betType}
            gameTypeActive={states.gameTypeActive}
            targetTypeActive={states.targetTypeActive}
            isChildShow={states.open}
          />
        ))}
      </div>
    ];
  }
});
