<template>
  <div :class="['avatar_box', flexRver == true ? 'rever' : '']">
    <div v-show="dividerShow" :class="['divider_s', flexRver == true ? 'green' : 'red']"></div>
    <team-logo :sportId="sportId" :teamId="teamId" size="xs" :teamName="nametitle" />
    <span class="name_s">{{ nametitle }} </span>
  </div>
</template>

<script>
import TeamLogo from 'kg-web/components/team-logo/index';

export default {
  components: {
    TeamLogo
  },
  props: {
    teamId: {
      type: Number,
      default: 0
    },
    nametitle: {
      type: String,
      default: ''
    },
    flexRver: {
      type: Boolean,
      default: false
    },
    dividerShow: {
      type: Boolean,
      default: true
    },
    sportId: {
      type: Number,
      default: 1
    },
    teamInfo: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="less" scoped>
.avatar_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rever {
  flex-direction: row-reverse;

  .name_s {
    margin-left: initial;
    margin-right: 4px;
    text-align: end;
  }
}

.green {
  background: green;
}

.red {
  background: red;
}

.divider_s {
  width: 5px;
  height: 100%;
}

.mrg {
  margin: 0px 5px;
}

.name_s {
  margin-left: 4px;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  height: 22px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #ffffff;
}
</style>
