import { computed, defineComponent } from 'vue';
import style from './live-matches-animate.module.less';
//import { Scrollbar } from '@/pages/kg-web/components';

export default defineComponent({
  name: 'LiveMatchAnimate',
  props: {
    animate: {
      type: String
    }
  },
  setup(props) {
    const animationUrl = computed(() => {
      return props.animate + '&down=1&score=1';
    });
    return () => (
      <div class={style.tabList}>
        {/*<TabHeaderRender />*/}
        <iframe src={animationUrl.value} style={{ height: '100%', width: '100%' }}></iframe>
      </div>
    );
  }
});
