var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };
import axios from 'axios';
import { get } from 'lodash-es';
var CancelTokenSourceMap = new Map();
export function createHttp(config) {
  if (config === void 0) {
    config = {};
  }
  var instance = axios.create(config);
  var http = __assign(__assign({}, instance), {
    request: function (config) {
      return instance.request(config);
    },
    get: function (url, config) {
      return instance.get(url, config);
    },
    delete: function (url, config) {
      return instance.delete(url, config);
    },
    head: function (url, config) {
      return instance.head(url, config);
    },
    post: function (url, data, config) {
      return instance.post(url, data, config);
    },
    put: function (url, data, config) {
      return instance.put(url, data, config);
    },
    patch: function (url, data, config) {
      return instance.patch(url, data, config);
    },
    abort: function (url) {
      var source = CancelTokenSourceMap.get(url);
      if (source) {
        source.cancel();
        CancelTokenSourceMap.delete(url);
      }
    },
    abortAll: function () {
      CancelTokenSourceMap.forEach(function (source) {
        source.cancel();
      });
      CancelTokenSourceMap.clear();
    }
  });
  instance.interceptors.request.use(function (config) {
    if (!config.cancelToken) {
      var source = axios.CancelToken.source();
      config.cancelToken = source.token;
      CancelTokenSourceMap.set(config.url, source);
    }
    return config;
  });
  instance.interceptors.response.use(
    function (response) {
      CancelTokenSourceMap.delete(response.config.url);
      return response.data;
    },
    function (error) {
      // 删除cancel token
      var url = get(error, 'config.url', '');
      if (url) {
        CancelTokenSourceMap.delete(error.config.url);
      }
      // http abort 导致的请求取消
      if (error && error.toString && error.toString() === 'Cancel') {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
  return http;
}
var http = createHttp({});
export { http };
export * from 'axios';
