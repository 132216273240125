import { computed, defineComponent, nextTick, onMounted, PropType } from 'vue';
import { isEmpty } from 'lodash-es';

import { useAuth, useI18n, StoreChampData } from '@/hooks';
import { useBetWeb } from '@/hooks/useBetWeb.hook';
import { DataEmpty } from 'kg-web/components';
import BettingOrders from './betting-orders';
import BettingResult from './result';
import './style/cart.style.less';

export default defineComponent({
  name: 'ShoppingCart',
  props: {
    ChampData: {
      type: Array as PropType<StoreChampData[]>,
      default: () => []
    }
  },
  setup(props) {
    const { betTickets, clearAllTickets, state, getData } = useBetWeb(true);
    const { isLogin } = useAuth();
    const { t } = useI18n();
    // 显示购物车
    const showCart = computed(() => {
      return !isEmpty(betTickets.value) && isLogin.value;
    });
    onMounted(() => {
      nextTick(() => {
        !isLogin.value && clearAllTickets();
      });
      getData();
    });
    return () => [
      showCart.value ? (
        <main class={'web-cart'}>
          {state.betted ? (
            <BettingResult />
          ) : (
            <BettingOrders onClearAllTickets={clearAllTickets} ChampData={props.ChampData} />
          )}
        </main>
      ) : (
        <DataEmpty type={'orders'} title={t('lang.sport_common_noBetData365')} />
      ) // 暂无投注单;
    ];
  }
});
