import { reactive } from 'vue';
import { getBetTypes } from '@/services/kg-api';

export function useBetTypes() {
  const betStore = reactive({
    betTypes: [] as { betTypeNameZh: string; bettype: string; bettypeName: string; commonly: boolean }[]
  });

  const getData = async () => {
    if (betStore.betTypes.length <= 1) {
      await getBetData();
    }
  };

  const getBetData = async () => {
    const [betTypeResponse, betTypeData] = await getBetTypes({ oddsType: 2, commonly: false });
    if (betTypeResponse) {
      betStore.betTypes = betTypeData;
    }
  };

  return { getData, betStore };
}
