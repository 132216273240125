import { defineComponent, computed, reactive, onMounted } from 'vue';
//import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

//import { Skeleton } from 'ant-design-vue';
import { useLiveList, useI18n } from '@/hooks';
import { SportIdEnum } from '@/common';

import { LiveMatchVideo, MatchDetail } from '..';

import style from './live-matches-tab.module.less';
import { DataEmpty, Scrollbar } from '@/pages/kg-web/components';
import { get } from 'lodash-es';

export default defineComponent({
  name: 'LiveMatchesTabs',
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { store, getData: getLiveMatchList } = useLiveList();
    //const { store: storeDetail } = inject('detailProvide') as any;

    const state = reactive({
      activeTab: 3,
      matchData: null as any,
      matchDataLive: null as any,
      videoLoading: false,
      videoUrls: [] as string[]
    });

    const visible = computed(() => Number(route.query.sportId) === SportIdEnum.Soccerball);

    // const TABS = ref([
    //   {
    //     label: t('lang.sport_common_anchor'), // 主播
    //     type: 'anchor',
    //     icon: require('./assets/t01.png'),
    //     icon_hover: require('./assets/t01_h.png'),
    //     count: 111,
    //     value: null
    //   },
    //   {
    //     label: t('lang.sport_common_soccer'), // 足球
    //     type: 'sport',
    //     icon: require('./assets/t02.png'),
    //     icon_hover: require('./assets/t02_h.png'),
    //     count: 111,
    //     value: 1
    //   },
    //   {
    //     label: t('lang.sport_common_basketball'), // 篮球
    //     type: 'sport',
    //     icon: require('./assets/t03.png'),
    //     icon_hover: require('./assets/t03_h.png'),
    //     count: 111,
    //     value: 2
    //   },
    //   {
    //     label: t('lang.sport_detail_data'), // 数据
    //     type: 'data',
    //     count: 111,
    //     icon: require('./assets/t04.png'),
    //     icon_hover: require('./assets/t04_h.png'),
    //     value: null
    //   }
    // ]);

    onMounted(async () => {
      await getLiveMatchList();
      state.matchData = get(store.data, 'liveGroup[0]') || get(store.data, 'noLiveGroup[0]');
    });

    // 视频链接
    //const videoUrl = computed(() => (storeDetail.info.id ? vuexStore.getters?.getVideoByMid(storeDetail.info.id) : ''));

    // 获取视频
    // const getVideo = async (call?) => {
    //   if (state.activeTab === 3 && !call) return;
    //   if (!videoUrl.value) return (state.videoUrls = []);
    //   const url = videoUrl.value.replace('/api', '');
    //   const [_res, data] = await getSportVideoByMatchID(url);
    //   if (_res && data.urlList) {
    //     state.videoUrls = data.urlList;
    //   } else {
    //     state.videoUrls = [];
    //   }
    // };

    // const getVideoByApi = async (vApi: string) => {
    //   state.videoLoading = true;
    //   const url = vApi.replace('/api', '');
    //   const [_res, d] = await getSportVideoByMatchID(url);
    //   state.videoLoading = false;
    //   if (_res && d.urlList) {
    //     state.videoUrls = d.urlList;
    //   } else {
    //     state.videoUrls = [];
    //   }
    // };

    // 切换比赛
    // const onSwitchMatch = async (data: any) => {
    //   if (state.matchData?.id === data.id) return;
    //   state.videoUrls = [];
    //   state.matchData = data;
    //   await getVideoByApi(data.videoApi);
    // };
    //
    // // 切换主播
    // const onAnchorSwitch = (data: any) => {
    //   if (state.matchData?.id === data.id) return;
    //   state.matchData = data;
    //   state.videoUrls = [];
    //   if (data.status === 1 && data.videoApi) {
    //     state.videoUrls = [data.videoApi];
    //   }
    // };

    // const onTabChange = async (tab: any, idx: number) => {
    //   if (state.activeTab === idx) return;
    //   state.activeTab = idx;
    //   state.videoUrls = [];
    //   if (tab.type === 'sport') {
    //     param.sportId = tab.value;
    //     await getLiveMatchList();
    //     state.matchData = get(store.data, 'liveGroup[0]') || get(store.data, 'noLiveGroup[0]');
    //     store.currentMatch = state.matchData.id;
    //     // 如果有视频链接
    //     if (state.matchData.status === 1 && state.matchData.videoApi) {
    //       await getVideoByApi(state.matchData.videoApi);
    //     }
    //   }
    //   if (tab.type === 'anchor') {
    //     await getDataAnchor();
    //     state.videoUrls = [];
    //     state.matchData = storeAnchor.data[0];
    //     store.currentMatch = state.matchData.id;
    //     // 如果有视频链接
    //     if (state.matchData.status === 1 && state.matchData.videoApi) {
    //       state.videoUrls = [state.matchData.videoApi];
    //     }
    //   }
    //   if (tab.type === 'data') {
    //     getVideo(true);
    //     state.matchData = get(store.data, 'liveGroup[0]') || get(store.data, 'noLiveGroup[0]');
    //   }
    // };

    // const TabHeaderRender = () => (
    //   <div class={style.tabList__heaer}>
    //     <div class={style.tabList__title}>
    //       <h2>
    //         {/* 赛事直播 */}
    //         {t('lang.sport_detail_live')}
    //       </h2>
    //     </div>
    //     <div class={style.tabContaier}>
    //       {TABS.value.map((tab, index) => (
    //         <div
    //           class={[style.tabItem, state.activeTab === index ? style.isActive : null]}
    //           key={index}
    //           onClick={() => onTabChange(tab, index)}
    //         >
    //           <div class={style.tabsInner}>
    //             <div
    //               class={style.tabsInner__icon}
    //               style={{
    //                 backgroundImage: `url(${(style.tabItem, state.activeTab === index ? tab.icon_hover : tab.icon)})`
    //               }}
    //             ></div>
    //             <div class={style.tabsInner__textWrap}>
    //               <div class={style.tabsInner__label}>{tab.label}</div>
    //               {/* <div class={style.tabsInner__count}>{'(' + tab.count + ')'}</div> */}
    //             </div>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // );

    return () => (
      <div class={style.tabList}>
        {/*<TabHeaderRender />*/}
        <Scrollbar style={{ paddingBottom: '80px' }}>
          <LiveMatchVideo videoUrls={state.videoUrls} matchData={state.matchData} />
          {/*{state.activeTab === 0 ? (*/}
          {/*  <AnchorList v-model={store.currentMatch} onSwitch={onAnchorSwitch} data={storeAnchor.data} />*/}
          {/*) : null}*/}
          {/*{state.activeTab === 2 || state.activeTab === 1 ? (*/}
          {/*  <ElSkeleton rows={8} loading={store.loading}>*/}
          {/*    {isEmpty(store.data.liveGroup) && isEmpty(store.data.noLiveGroup) ? (*/}
          {/*      <DataEmpty title={t('lang.sport_common_noAnyData')} type="other" />*/}
          {/*    ) : (*/}
          {/*      <LiveMatchList v-model={store.currentMatch} data={store.data} onSwitch={onSwitchMatch} />*/}
          {/*    )}*/}
          {/*  </ElSkeleton>*/}
          {/*) : null}*/}
          {visible.value ? <MatchDetail /> : <DataEmpty title={t('lang.sport_common_noAnyData')} />}
        </Scrollbar>
      </div>
    );
  }
});
