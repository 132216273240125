import { SvgIcon } from '@/components';
import { defineComponent } from 'vue';
import './partial-loading.style.less';

export default defineComponent({
  name: 'PartialLoading',
  props: {
    bg: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    return () => (
      <div class={{ 'partial-loading': true, 'no-bg': !props.bg }}>
        <SvgIcon name="loading" />
      </div>
    );
  }
});
