/**
 * 体育枚举
 */

// 赛事区间 市场类型
export enum DataTypeEnum {
  /**
   * 全部
   */
  All = 0,
  /**
   * 今日
   */
  Today = 1,
  /**
   * 早盘
   */
  Early = 2,
  /**
   * 滚球
   */
  Live = 3,
  /**
   * 串关
   */
  Parlay = 4,
  /**
   * 冠军盘
   */
  championLeague = 5,
  /**
   * 关注
   */
  Followed = -1,
  /**
   * 初始化
   */
  Init = -10
}

/**
 * MarketStatus
 * 盘口状态
 */

export enum MarketStatusEnum {
  /**
   * 盘口开启且可下注
   */
  running = 0,
  /**
   * 盘口赔率仍开启但无法下注
   */
  suspend = 1,
  /**
   *  盘口关闭且无法下注
   */
  closed = 2,
  /**
   * 盘口赔率关闭且无法下注
   */
  closeprice = 3
}

// 投注类型
export enum BetTypeEnum {
  // 让球
  spreads = 1,
  // 单双
  oddOrEven = 2,
  // 大小
  jolly = 3,
  // 独赢
  proposition = 5,
  // 上半场让球
  firstHalfHandicap = 7,
  // 下半场让球
  secondHalfHandicap = 17,
  // 上半场.单双盘
  firstHalfOddAndDoubleSet = 12,
  // 胜负
  windOrLose = 20,
  // 获胜者
  winner = 501,
  // 角球
  corner = 473,
  // 板球获胜者
  cricketwinner = 501
}

// 筛选tab类型
export type TabTypeKeys = 'periodId' | 'sportId' | 'betType' | 'leagueIds';

export interface CommonTypeItem {
  label: string;
  code: string;
  // 国际化
  i18n?: string;
  value?: any;
  index?: string;
  count?: number;
  sportVisibleEnum?: number[];
}

/**
 * 玩法列表
 * spreads:让球,  jolly: 大小,  proposition: 独赢,  corner:角球,
 */
export const BetTypeList: CommonTypeItem[] = [
  {
    label: '让球',
    code: 'spreads',
    value: BetTypeEnum.spreads,
    sportVisibleEnum: [1, 2, 3, 4, 5, 6, 7, 8, 12, 17, 24, 26, 33, 35, 37, 43, 45]
  },
  {
    label: '大小',
    code: 'jolly',
    value: BetTypeEnum.jolly,
    sportVisibleEnum: [1, 2, 3, 4, 5, 24, 17, 43, 26, 33, 35, 37, 45, 56]
  },
  {
    label: '独赢', // 胜负
    code: 'windOrLose',
    value: BetTypeEnum.windOrLose,
    sportVisibleEnum: [
      4, 8, 6, 9, 10, 11, 12, 14, 15, 16, 18, 19, 21, 22, 23, 28, 33, 34, 37, 38, 39, 41, 44, 53, 55, 56, 99
    ]
  },
  {
    label: '独赢',
    code: 'proposition',
    value: BetTypeEnum.proposition,
    sportVisibleEnum: [1, 7, 43]
  },
  // {
  //   label: '获胜者',
  //   code: 'winner',
  //   value: BetTypeEnum.winner,
  //   sportVisibleEnum: []
  // },
  {
    label: '获胜者', // 特指板球
    code: 'cricketwinner',
    value: BetTypeEnum.cricketwinner,
    sportVisibleEnum: [50]
  }
  // {
  //   label: '角球',
  //   code: 'corner',
  //   value: BetTypeEnum['corner']
  // }
];

// 结算状态
// 注单状态 已结算 8:赢半 6:输半 7. won (赢)5. lose (输)9. draw (平)未结算2. running (进行中)1. waiting (等待)其他15. reject(拒绝)16. refund（退款）4. void (取消)
// 已结算 8. WinHalf(赢半) 6. LoseHalf(输半) 7. won (赢)5. lose (输)9. draw (平)
// 未结算 2. running (进行中) 1. waiting (等待)其他  15. reject(拒绝)  16. refund（退款） 4. void (取消)
export type SettlementType =
  | 'waiting'
  | 'running'
  | 'void'
  | 'refund'
  | 'reject'
  | 'lose'
  | 'won'
  | 'draw'
  | 'WinHalf'
  | 'open'
  | 'LoseHalf'
  | 'cashedOut'
  | 'cancelled';

// 结算状态枚举
export enum SettlementEnum {
  waiting = 1,
  running = 2,
  void = 3,
  refund = 4,
  reject = 5,
  lose = 6,
  won = 7,
  draw = 8,
  WinHalf = 9,
  open = 10,
  LoseHalf = 11,
  cashedOut = 12,
  cancelled = 13
}

export const SettlementList: CommonTypeItem[] = [
  {
    i18n: 'lang.sport_orders_loseHalf',
    label: '输半',
    code: 'LoseHalf',
    value: SettlementEnum.LoseHalf
  },
  {
    i18n: 'lang.sport_orders_winHalf',
    label: '赢半',
    code: 'WinHalf',
    value: SettlementEnum.WinHalf
  },
  {
    i18n: 'lang.sport_orders_won',
    label: '胜',
    code: 'won',
    value: SettlementEnum.won
  },
  {
    i18n: 'lang.sport_details_lost',
    label: '输',
    code: 'lose',
    value: SettlementEnum.lose
  },
  {
    i18n: 'lang.sport_common_tie',
    label: '和',
    code: 'draw',
    value: SettlementEnum.draw
  },
  {
    i18n: 'lang.sport_bet_betSucced',
    label: '投注成功',
    code: 'running',
    value: SettlementEnum.running
  },
  {
    i18n: 'lang.sport_orders_waiting',
    label: '待确认',
    code: 'waiting',
    value: SettlementEnum.waiting
  },
  {
    i18n: 'lang.sport_orders_reject',
    label: '投注拒绝',
    code: 'reject',
    value: SettlementEnum.reject
  },
  {
    i18n: 'lang.sport_orders_refund',
    label: '退款',
    code: 'refund',
    value: SettlementEnum.refund
  },
  {
    i18n: 'lang.sport_common_cancel',
    label: '取消',
    code: 'void',
    value: SettlementEnum.void
  },
  {
    i18n: 'lang.sport_common_open',
    label: '打开',
    code: 'open',
    value: SettlementEnum.open
  },
  {
    i18n: 'lang.sport_common_cashedOut',
    label: '兑现',
    code: 'void',
    value: SettlementEnum.cashedOut
  },
  {
    i18n: 'lang.sport_common_cancel',
    label: '取消',
    code: 'cancelled',
    value: SettlementEnum.cancelled
  }
];

// 赛事状态
// Closed/ Running/ Postponed/ Completed

export const GameStatus = [
  {
    label: '比赛关闭',
    code: 'Closed'
  },
  {
    label: '进行中',
    code: 'Running'
  },
  {
    label: '延期',
    code: 'Postponed'
  },
  {
    label: '已结束',
    code: '已结束'
  }
];

/**
 * 投注类型分类
 */
// 0: None (主要)
// 1: FullTime (全场)
// 2: Half (半场)
// 3: Corners (角球)
// 4: Bookings (罚牌)
// 5: Intervals (区间)
// 6: Specials (特别产品)
// 7: Players (选手)
// 8: ExtraTime (加时)
// 9: FastMarket 快速
// 10: Quarter (节)
// 11~19: E-Sports Map 1~9 (电子竞技 适用 – 地图1~9)

export const BetCategoryList = [
  {
    label: '主要盘口',
    code: 'None',
    value: 0
  },
  {
    label: '全场',
    code: 'FullTime',
    value: 1
  },
  {
    label: '半场',
    code: 'Half',
    value: 2
  },
  {
    label: '角球',
    code: 'Corners',
    value: 3
  },
  {
    label: '罚牌',
    code: 'Bookings',
    value: 4
  },
  {
    label: '区间',
    code: 'Intervals',
    value: 5
  },
  {
    label: '特别产品',
    code: 'Specials',
    value: 6
  },
  {
    label: '选手',
    code: 'Players',
    value: 7
  },
  {
    label: '节',
    code: 'ExtraTime',
    value: 8
  },
  {
    label: '快速',
    code: 'FastMarket',
    value: 9
  },
  {
    label: '节',
    code: 'Quarter',
    value: 10
  }
];

// LivePeriod
// int
// 目前进行到第几节
// 共享

// GameSession
// byte
// 赛事节数
// 共享: 比赛有多少节

// InPlayTime
// string
// 目前赛事时间
// 足球(1)/篮球(2)/美式 足球(3)/冰上曲棍球 (4)

// scoreA
// int
// 客队分数
// 共享

// scoreH
// int
// 主队分数
// 共享

// InjuryTime
// short
// 加时时间
// 足球

// RedCardA
// short
// 客队红牌数
// 足球

// RedCardH
// short
// 主队红牌数
// 足球

// YellowCardA
// short
// 客队黃牌数
// 足球

// YellowCardH
// short
// 主队黃牌数
// 足球

// A1S A2S A3S
// string
// 客队第1局比分 – 客队 第5局比分
// 羽球
// 47 / 242
//            A4S A5S
//            H1S H2S H3S H4S H5S
// string
// 主队第1局比分- 主队第 5 局比分
// 羽球
//          ConfirmFlag
// string
// 分数是否确认
// 羽球
//         GetGamesA
// LLP
// string
// 客队总局数
// 羽球
//         GetGamesH
// string
// 主队总局数
// 羽球
//         string
// 目前进行的局数/节数
// 羽球/篮球
//         Serve
// string
// 目前发球方
// 羽球
//         Injury
// string
// 伤停方
// 网球-伤停
//         IsBreak
// byte
// 比赛是否暂停
// 网球
// TennisAwayGameScore
// int
// 客队每盘获得局数
// 网球
//         TennisAwayPointScore
// string
// 客队目前局数比分
// 网球
//         TennisCurrentServe
// int
// 目前发球方
// 网球
//         TennisCurrentSet
// int
// 目前盘数
// 网球
//         TennisHomeGameScore
// int
// 主队每盘获得局数
// 网球
//         TennisHomePointScore
// string
// 主队目前局数比分
// 网球
//            A1Q A2Q A3Q A4Q
// string
// 客队第一节比分 – 客队 第四节比分
// 篮球
//     48 / 242

// H1Q
// H2Q H3Q H4Q
// string
// 主队第一节比分-主队第 四节比分
// 篮球

// OverTimeA
// string
// 客队延长赛比分
// 篮球

// OverTimeH
// string
// 主队延长赛比分
// 篮球

/**
 * Parlay Combo Type (串关组合列表)
 */
export const ParlayComboType = [
  {
    label: '2串1',
    code: 'Doubles',
    value: 0
  },
  {
    label: '3串1',
    code: 'Trebles',
    value: 1
  },
  {
    label: '3串1',
    code: 'Treble',
    value: 1
  },
  {
    label: '3串4',
    code: 'Trixie',
    value: 2
  },
  {
    label: '幸运7',
    code: 'Lucky7',
    value: 3
  },
  {
    label: '4串1',
    code: 'Fold4',
    value: 4
  },
  {
    label: '4串1',
    code: 'FourFolds',
    value: 4
  },
  {
    label: '4串1',
    code: '4-Fold',
    value: 4
  },
  {
    label: '洋基',
    code: 'Yankee',
    value: 5
  },
  {
    label: '幸运15',
    code: 'Lucky15',
    value: 6
  },
  {
    label: '5串1',
    code: 'Fold5',
    value: 7
  },
  {
    label: '5串1',
    code: '5-Fold',
    value: 7
  },
  {
    label: '5串1',
    code: 'FiveFolds',
    value: 7
  },
  {
    label: '超级美国佬',
    code: 'Canadian',
    value: 8
  },
  {
    label: '幸运 31',
    code: 'Lucky31',
    value: 9
  },
  {
    label: '6串1',
    code: 'Fold6',
    value: 10
  },
  {
    label: '6串1',
    code: '6-Fold',
    value: 10
  },
  {
    label: '亨氏',
    code: 'Heinz',
    value: 11
  },
  {
    label: '幸运 63',
    code: 'Lucky63',
    value: 12
  },
  {
    label: '7串1',
    code: 'Fold7',
    value: 13
  },
  {
    label: '7串1',
    code: '7-Fold',
    value: 13
  },
  {
    label: '超级亨氏',
    code: 'SuperHeinz',
    value: 14
  },
  {
    label: '幸运 127',
    code: 'Lucky127',
    value: 15
  },
  {
    label: '8串1',
    code: 'Fold8',
    value: 16
  },
  {
    label: '8串1',
    code: '8-Fold',
    value: 16
  },
  {
    label: '大亨',
    code: 'Goliath',
    value: 17
  },
  {
    label: '幸运255',
    code: 'Lucky255',
    value: 18
  },
  {
    label: '9串1',
    code: 'Fold9',
    value: 19
  },
  {
    label: '9串1',
    code: '9-Fold',
    value: 19
  },
  {
    label: '10串1',
    code: 'Fold10',
    value: 20
  },
  {
    label: '10串1',
    code: '10Fold',
    value: 20
  },
  {
    label: '10串1',
    code: '10-Fold',
    value: 20
  },
  {
    label: '11串1',
    code: 'Fold11',
    value: 21
  }
];

export const PreBetError = [
  {
    // label: '找不到该会员资料',
    label: '盘口关闭',
    code: 'Customer not found',
    value: 3
  },
  {
    label: '球头已不存在',
    code: 'Point isUtil expired',
    value: 6
  },
  {
    label: '赛事 ID 错误或是赛事已关',
    code: 'Match closed or invalid Market ID',
    value: 7
  },
  {
    label: '赔率正在更新',
    code: 'Odds suspend',
    value: 12
  },
  {
    label: '余额不足',
    code: 'Credit problem',
    value: 14
  },
  {
    label: '超过单场投注限额',
    code: 'Over max per match',
    value: 15
  },
  {
    label: '盘口关闭',
    code: 'Close price',
    value: 24
  },
  {
    label: '盘口关闭',
    code: 'Market closed',
    value: 25
  },
  {
    label: '赔率变更',
    code: 'Odds changed',
    value: 8
  }
];

export const DoBetError = [
  {
    label: '执行失败',
    code: 'Failed execution',
    value: 1
  },
  {
    label: '找不到该会员资料',
    code: 'Customer not found',
    value: 3
  },
  {
    label: '厂商注单号码重复',
    code: 'Vendor_Trans_ID Duplicated',
    value: 4
  },
  {
    label: 'SQL 执行错误',
    code: 'SQL Exception',
    value: 5
  },
  {
    label: '球头已不存在',
    code: 'Point is expired',
    value: 6
  },
  {
    label: '赛事 ID 错误或是赛事已关闭',
    code: 'Match closed or invalid Market ID',
    value: 7
  },
  {
    label: '赔率变更',
    code: 'Odds changed',
    value: 8
  },
  {
    label: '厂商标识符错误',
    code: 'Invalidate vendor_id',
    value: 9
  },
  {
    label: '系统维护中',
    code: 'Under maintenance',
    value: 10
  },
  {
    label: '超过最大/最小投注限额',
    code: 'Stake problem',
    value: 11
  },
  {
    label: '赔率正在调整',
    code: 'Odds suspend',
    value: 12
  },
  {
    label: '余额不足',
    code: 'Credit problem',
    value: 14
  },
  {
    label: '超过每场下注限额',
    code: 'Over max per match',
    value: 15
  },
  {
    label: '超过单注下注限额',
    code: 'Over max per bet',
    value: 16
  },
  {
    label: '赔率错误',
    code: 'Odds errorn',
    value: 17
  },
  {
    label: '低于最低串关赛事数量',
    code: 'Under can bet count',
    value: 19
  },
  {
    label: '串关错误',
    code: 'Lucky single problem',
    value: 20
  },
  {
    label: '账号无法投注',
    code: 'Diabled bet',
    value: 21
  },
  {
    label: '赛事比分改变',
    code: 'Score changed',
    value: 22
  },
  {
    label: '赛事球头改变',
    code: 'Point changed',
    value: 23
  },
  {
    label: '盘口暂时关闭',
    code: 'Close price',
    value: 24
  },
  {
    label: '盘口关闭',
    code: 'Market closed',
    value: 25
  }
];

//实时直播  数据源的处理
export enum ObjectName {
  'zero' = 0, //体育类型。0，1标识足球 。 0，2标识篮球
  'one' = 1, //进球
  'two' = 2, //角球
  'three' = 3, //黄牌
  'four' = 4, //红牌
  'five' = 5, //界外球
  'six' = 6, //任意球
  'seven' = 7, //球门球
  'eight' = 8, //点球
  'nine' = 9, //换人
  'ten' = 10, //比赛开始
  'eleven' = 11, //中场
  'twelve' = 12, //结束
  'thirteen' = 13, //半场比分
  'fifteen' = 15, //两黄变红
  'sixteen' = 16, //点球未进
  'seventeen' = 17, //乌龙球
  'nineteen' = 19, //伤停补时
  'twentyone' = 21, //射正
  'twentytwo' = 22, //射偏
  'twentythree' = 23, //进攻
  'twentyfour' = 24, //危险进攻
  'twentyfive' = 25, //控球率
  'twentysix' = 26, //加时赛结束
  'twentyseven' = 27, //点球大战结束
  'twentyeight' = 28, //比赛时间。
  'twentynine' = 29, //比赛状态。 29，1标识未开始
  'thirty' = 30, //当前比赛进行时间 足球
  'thirtyone' = 31 //当前小节剩余时间（篮球）
}

/**
 * 列表排序方式
 * @export
 * @enum {number}
 */
export enum SortTypeEnum {
  League = 'league',
  Time = 'time'
}

/**
 * 首页玩法大全
 *
 */
export const gameType1 = {
  1: {
    bettypeName: 'lang.sport_common_handicap',
    bettypeRule: ['lang.sport_common_home', 'lang.sport_common_away'],
    typeName: 'lang.sport_common_handicap',
    typeEnName: 'Asian Handicap'
  },
  2: {
    bettypeName: 'lang.sport_common_size', //大小
    bettypeRule: ['lang.sport_common_big', 'lang.sport_common_small'],
    typeName: 'lang.sport_common_size',
    typeEnName: 'Over/Under'
  },
  3: {
    bettypeName: 'lang.sport_common_winAlone', //独赢
    bettypeRule: ['lang.sport_common_home', 'lang.sport_common_away'],
    bettypeRuleFooterball: ['lang.sport_common_home', 'lang.sport_common_tie', 'lang.sport_common_away'],
    typeName: 'lang.sport_common_winAlone',
    typeEnName: 'Home Draw Away'
  }
};
export const gameType2 = {
  1: {
    bettypeName: 'lang.sport_common_handicap',
    bettypeRule: ['lang.sport_common_home', 'lang.sport_common_away'],
    typeName: 'lang.sport_common_handicap',
    typeEnName: 'Asian Handicap'
  },
  2: {
    bettypeName: 'lang.sport_odd_even', //奇偶
    bettypeRule: ['单', '双'],
    typeName: 'lang.sport_odd_even',
    typeEnName: 'Odd/Even'
  },
  3: {
    bettypeName: 'lang.sport_common_winAlone', //独赢
    bettypeRule: ['lang.sport_common_home', 'lang.sport_common_away'],
    bettypeRuleFooterball: ['lang.sport_common_home', 'lang.sport_common_tie', 'lang.sport_common_away'],
    typeName: 'lang.sport_common_winAlone',
    typeEnName: 'Home Draw Away'
  }
};
/**
 * 首页玩法key对应数组
 */
export const gameTypeArray = [1, 2, 3];
/**
 * 首页玩法key对应数组
 */
export const gameTypeDefault = 3;
/**
 * 首页目标类型
 */
export const targetType = {
  0: {
    // targetName: 'lang.sport_common_goal',
    targetName: '进球',
    value: 0,
    icon: 'goalKick',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cpath fill='%23FFF' d='M6 11.156a5.156 5.156 0 1 1 10.312 0 5.156 5.156 0 0 1-10.312 0z'/%3E%3Cg fill='%23545454'%3E%3Cpath d='M6.86 14.317c.474-.045 2.393-.318 2.53-.428v-.326l-2.703.44.173.314zm2.871 1.948c.12-.462.294-1.049.237-1.216l-.306-.111-.224 1.257s.1.037.188.058c.043.01.082.016.105.012zM6.102 12.27l1.309-3.334-.264-.186L6 11.898zM9.765 11.885L8.379 8.75l-.316.039 1.425 3.197zM7.328 8.143l-.463-.08-.177.238.492.095zM11.696 12.703l2.582-2.422-.245-.156-2.533 2.377zM13.26 6.152c-.152-.087-.225-.08-.385-.152l1.606 1.57h.43s-1.2-.934-1.652-1.418zm-3.91-.058s-.368.309-.6.705l.133.137L9.67 6l-.32.094zM15.451 10.125l-.248.216-.265 3.725.285-.242zM14.87 14.94a5.59 5.59 0 0 0 .153-.167l-3.465-.523-.058.268s3.19.51 3.37.423zm-1.725-6.37s-.05-.162 0-.219L9.57 7.375l-.133.208 3.708.987z'/%3E%3C/g%3E%3Cpath fill='%23111' d='M11.542 11.998s-.59-.093-1.243-.249c-.652-.156-.994-.249-.994-.249s-.404.467-.746.997c-.341.53-.496 1.246-.496 1.246l1.49 1.495 2.237-.996-.248-2.244zm4.175 1.583l-.858-.018-1.328 1.187s-.056.924.054 1.013c.563-.199 1.754-1.174 2.132-2.182zm-2.097-3.215l1.884.616s.364-.654.423-.917c.05-.226.17-.726.17-.726s-.241-.622-.749-1.252c-.094-.11-1.23-.712-1.23-.712l-1.243.997.745 1.994zm-5.372-.917l1.49-1.765-.496-.996-1.54.549-.417.377s-.373.507-.528.818c-.156.312 0 .997 0 .997h1.49v.02zm-1.14 5.084l-.384-1.463s-.16-.342-.378-.934c-.073-.2-.272-.412-.346-.636-.01.899.4 2.144 1.107 3.033z'/%3E%3C/g%3E%3Cpath d='M0 0h22v22H0z'/%3E%3C/g%3E%3C/svg%3E"
  },
  1: {
    // targetName: 'lang.sport_details_dangerousAttack',
    targetName: '危险进攻',
    value: 1,
    icon: 'dangerous',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23D4D4D4' fill-rule='nonzero' d='M8.87 6.064a.5.5 0 0 1 .64.013l.062.065 3.637 4.546a.5.5 0 0 1-.719.69l-.062-.066L8.79 6.767a.5.5 0 0 1 .078-.703z'/%3E%3Cpath fill='%23D4D4D4' fill-rule='nonzero' d='M12.428 10.688a.5.5 0 0 1 .83.55l-.05.074-3.636 4.546a.5.5 0 0 1-.83-.55l.05-.075 3.636-4.545z'/%3E%3Cpath fill='%23F8F8F8' fill-rule='nonzero' d='M11.602 6.064a.5.5 0 0 1 .64.013l.063.065 3.636 4.546a.5.5 0 0 1-.718.69l-.063-.066-3.636-4.545a.5.5 0 0 1 .078-.703z'/%3E%3Cpath fill='%23F8F8F8' fill-rule='nonzero' d='M15.16 10.688a.5.5 0 0 1 .831.55l-.05.074-3.636 4.546a.5.5 0 0 1-.83-.55l.05-.075 3.635-4.545z'/%3E%3Cpath fill='%23A7A7A7' fill-rule='nonzero' d='M6.142 6.064a.5.5 0 0 1 .64.013l.063.065 3.636 4.546a.5.5 0 0 1-.718.69l-.063-.066-3.636-4.545a.5.5 0 0 1 .078-.703z'/%3E%3Cpath fill='%23A7A7A7' fill-rule='nonzero' d='M9.7 10.688a.5.5 0 0 1 .831.55l-.05.074-3.636 4.546a.5.5 0 0 1-.83-.55l.05-.075L9.7 10.688z'/%3E%3Cpath d='M0 0h22v22H0z'/%3E%3C/g%3E%3C/svg%3E"
  },
  2: {
    // targetName: 'lang.sport_bet_assist',
    targetName: '攻击',
    value: 2,
    icon: 'assist',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23F8F8F8' fill-rule='nonzero'%3E%3Cpath d='M9.142 6.064a.5.5 0 0 1 .64.013l.063.065 3.636 4.546a.5.5 0 0 1-.718.69l-.063-.066-3.636-4.545a.5.5 0 0 1 .078-.703z'/%3E%3Cpath d='M12.7 10.688a.5.5 0 0 1 .831.55l-.05.074-3.636 4.546a.5.5 0 0 1-.83-.55l.05-.075 3.635-4.545z'/%3E%3C/g%3E%3Cpath d='M0 0h22v22H0z'/%3E%3C/g%3E%3C/svg%3E"
  },
  3: {
    // targetName: 'lang.sport_bet_possessionPercentage',
    targetName: '控球',
    isPercent: true,
    value: 4,
    icon: 'possession',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='14.5' cy='14.5' r='1' stroke='%23F8F8F8'/%3E%3Ccircle cx='7.5' cy='7.5' r='1' stroke='%23F8F8F8'/%3E%3Cpath fill='%23F8F8F8' d='M14.5 6H16L7.5 16H6z'/%3E%3Cpath d='M0 0h22v22H0z'/%3E%3C/g%3E%3C/svg%3E"
  },
  4: {
    // targetName: 'lang.sport_bet_shotsOnTarget',
    targetName: '射正',
    value: 5,
    icon: 'shotsOn',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg fill='%23F8F8F8'%3E%3Cpath d='M2.5 6h1v9h-1zM18.556 6h1v9h-1z'/%3E%3Cpath d='M2.5 6h17v1h-17z'/%3E%3C/g%3E%3Cg fill='%2358D7AF'%3E%3Cpath d='M11.623 7v3.109h-.9V7zM11.623 12.891V16h-.9v-3.109zM12.62 11.073l3.11.054-.016.9-3.11-.054zM6.73 11.073l3.109.054-.016.9-3.11-.054z'/%3E%3Cpath d='M11.173 7.982a3.518 3.518 0 1 0 0 7.036 3.518 3.518 0 0 0 0-7.036zm0 .9a2.618 2.618 0 1 1 0 5.236 2.618 2.618 0 0 1 0-5.236z'/%3E%3C/g%3E%3C/g%3E%3Cpath d='M0 0h22v22H0z'/%3E%3C/g%3E%3C/svg%3E"
  },
  5: {
    // targetName: 'lang.sport_bet_shotsOffTarge',
    targetName: '射偏',
    value: 6,
    icon: 'shotsOff',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cpath fill='%23F8F8F8' d='M2 6h1v9H2zM18.056 6h1v9h-1z'/%3E%3Cpath fill='%23F8F8F8' d='M2 6h17v1H2z'/%3E%3Cg fill='%23F66'%3E%3Cpath d='M16.032.918v3.109h-.9V.918zM16.032 6.809v3.109h-.9V6.809zM17.03 4.99l3.109.055-.016.9-3.11-.054zM11.138 4.99l3.11.055-.016.9-3.11-.054z'/%3E%3Cpath d='M15.582 1.9a3.518 3.518 0 1 0 0 7.036 3.518 3.518 0 0 0 0-7.036zm0 .9a2.618 2.618 0 1 1 0 5.236 2.618 2.618 0 0 1 0-5.236z'/%3E%3C/g%3E%3C/g%3E%3Cpath d='M0 0h22v22H0z'/%3E%3C/g%3E%3C/svg%3E"
  },
  6: {
    // targetName: 'lang.sport_details_cornerBall',
    targetName: '角球',
    value: 7,
    icon: 'corners',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EGroup 3%3C/title%3E%3Cdefs%3E%3Cfilter x='0%25' y='0%25' width='100%25' height='100%25' filterUnits='objectBoundingBox' id='a'%3E%3CfeGaussianBlur in='SourceGraphic'/%3E%3C/filter%3E%3Cpath d='M0 .1c2.2 1.298 5.2 2.3 9 3.006-3.884 2.37-6.884 3.372-9 3.007V.1z' id='b'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M9 4.6c2.2 1.298 5.2 2.3 9 3.006-3.884 2.37-6.884 3.372-9 3.007V4.6z' fill='%23E83838' fill-rule='nonzero'/%3E%3Cg transform='translate(9 4.5)'%3E%3Cmask id='c' fill='%23fff'%3E%3Cuse xlink:href='%23b'/%3E%3C/mask%3E%3Cpath d='M0 .1C.464.02 2.21.093 2.671.1c1.825 2.112-2.504 1.552-1.4 6.006-.337.95-.813.362-1.271.007V.1z' fill='%23000' fill-rule='nonzero' opacity='.2' mask='url(%23c)'/%3E%3C/g%3E%3Cpath d='M8 4.142a.953.953 0 0 1 1 0v12.734c-.267.152-.747.178-1 0V4.142z' fill='%23F8F8F8' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E"
  },
  7: {
    // targetName: 'lang.sport_common_yellow',
    targetName: '黄牌',
    value: 7,
    icon: 'yellowCard',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFDF1B' d='M7.5 6h7.273v10H7.5z'/%3E%3Cpath d='M0 0h22v22H0z'/%3E%3C/g%3E%3C/svg%3E"
  },
  8: {
    // targetName: 'lang.sport_common_red',
    targetName: '红牌',
    value: 8,
    icon: 'redCard',
    base64:
      "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23E83838' d='M7.5 6h7.273v10H7.5z'/%3E%3Cpath d='M0 0h22v22H0z'/%3E%3C/g%3E%3C/svg%3E"
  }
};
export const targetTypeArray = [0, 1, 2, 3, 4, 5, 6, 7, 8];
