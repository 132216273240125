import { defineComponent, nextTick, onMounted, PropType, ref } from 'vue';
import { LeagueItem } from '@/services/im-api';
import './match-filter.style.less';

import { Button, IndexBar, CheckboxGroup, IndexAnchor, Checkbox } from 'vant';
import { DataEmpty, MobileHeader } from '365-mobile/components';

import { useRoute } from 'vue-router';
import { isEmpty } from 'lodash-es';
import { useI18n, useLeagueList } from '@/hooks';

interface AllLeagueList {
  [name: string]: LeagueItem[];
}

export default defineComponent({
  name: 'LeagueFilter',
  props: {
    sportId: {
      type: [Number, String],
      require: true
    },
    isResult: {
      type: Boolean,
      default: false
    },
    time: {
      type: Object as PropType<{ startTime: number | string; endTime: number | string }>,
      require: false
    },
    modelValue: Array as PropType<string[] | number[]>
  },
  emits: ['confirm', 'close', 'update:modelValue'],
  setup(props, { emit }) {
    const route = useRoute();
    const { t } = useI18n();
    const filteredArray = ref<string[]>([]);
    const selectedItems = ref<Object[]>([]);
    const {
      getLeaguesData,
      leagueStore,
      leagueCounter,
      state,
      letters,
      checkAll,
      toggleAll,
      checkboxGroupDom,
      leagueCouter,
      isBtnDisabled
    } = useLeagueList({ isResult: props.isResult });

    const useFilteredLeagues = () => {
      letters.value.map((letter: any) => {
        leagueStore.leaguesList[letter].map(league => {
          if (filteredArray.value.includes(league.leagueId) === false) {
            filteredArray.value.push(league.leagueId);
          }
        });
      });

      return { filteredLeagues: filteredArray.value };
    };

    const handleCheckAll = (isChecked: boolean) => {
      checkAll(isChecked);
      const { filteredLeagues } = useFilteredLeagues();
      leagueStore.leagueIds = isChecked ? filteredLeagues : [];
      selectedItems.value = [...leagueStore.leagueIds];
      emit('update:modelValue', []);
    };

    const handleAntiCheck = (isChecked: boolean) => {
      const currentLeagueIds = leagueStore.leagueIds;
      toggleAll(isChecked);
      const { filteredLeagues } = useFilteredLeagues();
      // @ts-ignore;
      leagueStore.leagueIds = filteredLeagues.filter(x => !currentLeagueIds.includes(x));
      console.log('111111111111111111111111111111111', filteredLeagues, leagueStore.leagueIds);
      leagueStore.isCheckedAll = filteredLeagues.length == leagueStore.leagueIds.length ? true : false;
      selectedItems.value = [...leagueStore.leagueIds];
      if (leagueStore.isCheckedAll) {
        emit('update:modelValue', []);
      } else {
        emit('update:modelValue', leagueStore.leagueIds);
      }
    };

    onMounted(async () => {
      leagueStore.form.periodId = Number(route.query.periodId);
      leagueStore.form.sportId = Number(props.sportId);
      if (props.time) {
        leagueStore.resultForm.startTime = props.time.startTime as string;
        leagueStore.resultForm.sportId = props.sportId as unknown as number;
        leagueStore.resultForm.endTime = props.time.endTime as string;
      }
      await getLeaguesData();
      if (isEmpty(letters.value)) return;
      if (!isEmpty(props.modelValue) && props.modelValue) {
        leagueStore.leagueIds = props.modelValue;
        if (leagueStore.leagueIds.length > 0) {
          leagueStore.isCheckedAll =
            leagueStore.leagueIds.length ===
            Object.keys(leagueStore.leaguesList).reduce((x, y) => {
              return x + leagueStore.leaguesList[y]?.length || 0;
            }, 0);
        }
        return;
      }
      await nextTick();
    });

    // 关闭
    const onHandleClose = () => emit('close');

    // 确认选中
    const confirmChecked = async () => {
      emit('confirm');
      onHandleClose();
    };

    // 选中改变
    const checkboxChange = (leaguesIds: any[]) => {
      const checkedCount = leaguesIds.length;
      leagueStore.isCheckedAll = checkedCount === leagueCounter.value;
      if (leagueStore.isCheckedAll) {
        emit('update:modelValue', []);
      } else {
        emit('update:modelValue', leaguesIds);
      }
    };

    // 多选框UI
    const renderCheckbox = (item: LeagueItem) => (
      <Checkbox name={item.leagueId} class="league-check">
        <div class="league-info">{item.leagueName}</div>
      </Checkbox>
    );

    // 所有联赛dom
    const RenderAllLeague = () => (
      <div class="all-league">
        <div class="text-01">{t('lang.sport_filterLeague_allLeagues')}A-Z</div>
        <IndexBar class="index-bar" indexList={letters.value} sticky={false}>
          <CheckboxGroup v-model={leagueStore.leagueIds} ref={checkboxGroupDom} onChange={checkboxChange}>
            {!isEmpty(letters.value) &&
              letters.value.map((el: keyof AllLeagueList, index: number) => (
                <>
                  <IndexAnchor index={`${index}`}>{el}</IndexAnchor>
                  {!!leagueStore.leaguesList[el] &&
                    leagueStore.leaguesList[el].map((item: LeagueItem, i: number) => (
                      <div class="league-select" key={i + '_LI_KEY'}>
                        {renderCheckbox(item)}
                      </div>
                    ))}
                </>
              ))}
          </CheckboxGroup>
        </IndexBar>
      </div>
    );

    // 底部Dom
    const FooterRender = () => (
      <div class="filter-footer">
        <div class="console-btn">
          {/*<div onClick={handleCheckAll(leagueStore.isCheckedAll)} class="checkbox">
            <div class={['check', !!leagueStore.isCheckedAll ? 'checked' : '']}></div>
            <span class="check-text invert-checked">
               全选
              {t('lang.sport_common_selectAll')}
            </span>
          </div>*/}
          <div class="checkbox">
            <Checkbox v-model={leagueStore.isCheckedAll} onChange={(value: any) => handleCheckAll(value)}>
              <span class="check-text invert-checked">{t('lang.sport_filterLeague_allSelect')}</span>
            </Checkbox>
          </div>
          <div class="checkbox" style="padding-left: 10px;">
            <Checkbox v-model={leagueStore.isReverse} onChange={(value: any) => handleAntiCheck(value)}>
              <span class="check-text invert-checked">{t('lang.sport_common_selectReverse')}</span>
            </Checkbox>
          </div>
        </div>
        <Button disabled={isBtnDisabled.value} class="confirm" onClick={confirmChecked}>
          {/* 确定 */}
          {t('lang.sport_common_confirm')}({leagueCouter.value})
        </Button>
      </div>
    );

    return () => {
      return (
        <div class="league-filter">
          <div class="filter-header">
            <MobileHeader
              back={false}
              onClick-left={onHandleClose}
              title={t('lang.sport_filterLeague') + leagueCounter.value}
            />
          </div>
          {state.loading ? (
            <DataEmpty title={t('lang.sport_common_loading')} type={'loading'} class="empty-flex-center" />
          ) : isEmpty(leagueStore.leaguesList) ? (
            <DataEmpty
              title={t('lang.sport_handicap_noneMatch')}
              desc={t('lang.sport_common_lookElsewhere')}
              class="empty-flex-center"
            />
          ) : (
            <div class="filter-content">
              <RenderAllLeague />
            </div>
          )}
          <FooterRender />
        </div>
      );
    };
  }
});
