import { defineComponent, PropType } from 'vue';
import emptyStyle from './empty.module.less';
import NO_OTHER_IMG from './assets/no_other.png';
import NO_MSG_IMG from './assets/no_mesaage.png';
import NO_RECORD_IMG from './assets/no_record.png';
import LOCKED_IMG from './assets/odds_locked.png';
import NO_NEWS_IMG from './assets/no_news.png';
import MARKET_LOADING from './assets/market_loading.png';

const IMGS = {
  other: NO_OTHER_IMG,
  message: NO_MSG_IMG,
  record: NO_RECORD_IMG,
  locked: LOCKED_IMG,
  news: NO_NEWS_IMG,
  loading: MARKET_LOADING
};

export default defineComponent({
  name: 'DataEmpty',
  props: {
    desc: {
      default: '',
      type: String
    },
    title: {
      default: '',
      type: String
    },
    type: {
      default: 'other',
      type: String as PropType<'other' | 'message' | 'record' | 'loading' | 'locked' | 'news'>
    },
    back: {
      default: false,
      type: Boolean
    },
    textColor: {
      default: '',
      type: String
    }
  },
  setup(props) {
    return () => (
      <div class={emptyStyle.empty}>
        <img src={IMGS[props.type]} />
        <div class={emptyStyle.dsc}>
          <span style={{ color: props.textColor }}>{props.title}</span>
        </div>
      </div>
    );
  }
});
