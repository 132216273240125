import { defineComponent, PropType } from 'vue';
import { LiveData, MatchInfoCard, useI18n } from '@/hooks';
import style from './live-match-list.module.less';
import { isEmpty } from 'lodash-es';

export default defineComponent({
  name: 'WebLiveMatchList',
  props: {
    data: {
      type: Object as PropType<LiveData>,
      default: () => ({
        liveGroup: [],
        noLiveGroup: []
      })
    },
    modelValue: [String, Number]
  },
  emits: ['switch', 'update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const onHandleMatch = (data: MatchInfoCard) => {
      emit('update:modelValue', data.id);
      emit('switch', data);
    };

    const OnliveRender = () => (
      <section class={style.liveMatchList}>
        <h3 class={[style.liveMatchList__title, style.live]}>{t('lang.sport_detail_living')}</h3>
        {props.data.liveGroup.map(item => (
          <div class={style.tournamentList} key={item.id}>
            <h5 class={style.tournamentList__title}>{item.league}</h5>
            <div
              class={[style.matchCard, style.isOnline, props.modelValue === item.id ? style.isOnlineActive : null]}
              onClick={() => onHandleMatch(item)}
            >
              <div class={style.matchCard__infoWrap}>
                <div class={style.matchCard__time}>{t('lang.sport_handicap_underway')}</div>
                <div class={style.matchCard__teams}>
                  <span>{item.home}</span>
                  <span>VS</span>
                  <span>{item.away}</span>
                </div>
              </div>
              <span class={style.videoLinkButton} v-show={!!item.videoApi}>
                <svg-icon class={style.videoLinkButton__icon} name="event_video" />
              </span>
            </div>
          </div>
        ))}
      </section>
    );

    const NotOnlineRender = () => (
      <section class={style.liveMatchList}>
        <h3 class={[style.liveMatchList__title]}>{t('lang.sport_detail_noStart')}</h3>
        {props.data.noLiveGroup.map(item => (
          <div class={style.tournamentList} key={item.id}>
            <h5 class={style.tournamentList__title}>{item.league}</h5>
            <div class={style.matchCard}>
              <div class={style.matchCard__infoWrap}>
                <div class={[style.matchCard__time]}>{item.time}</div>
                <div class={style.matchCard__teams}>
                  <span>{item.home}</span>
                  <span>VS</span>
                  <span>{item.away}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    );

    return () => (
      <div>
        {!isEmpty(props.data.liveGroup) ? <OnliveRender /> : null}
        {!isEmpty(props.data.noLiveGroup) ? <NotOnlineRender /> : null}
      </div>
    );
  }
});
