<template>
  <div class="fnexi_box">
    <div class="top_table">
      <table width="100%" cellpadding="20" cellspacing="0" align="center">
        <tr class="table_top">
          <th width="100px" align="left">
            <span class="frist-color"
              ><prefix-text>{{ t('lang.sport_details_teamRanking') }}</prefix-text></span
            >
          </th>
          <th class="last-color">{{ t('lang.sport_common_race') }}</th>
          <th class="last-color">{{ t('lang.sport_common_winDrawLost') }}</th>
          <th class="last-color">{{ t('lang.sport_details_goals') }}/{{ t('lang.sport_details_loses') }}</th>
          <th class="last-color">{{ t('lang.sport_common_netShot') }}</th>
          <th class="last-color">{{ t('lang.sport_details_ranking') }}</th>
        </tr>
        <tr class="table_content" v-if="homeLeagueJF" align="center">
          <td class="table-content-logo">
            <AvatarCmt
              :nametitle="matchInfo.HomeName"
              :teamId="matchInfo.HomeID"
              :sportId="sportType"
              :dividerShow="false"
            />
          </td>
          <td>{{ homeLeagueJF.scheNum }}</td>
          <td>{{ homeLeagueJF.win }}/{{ homeLeagueJF.stand }}/{{ homeLeagueJF.fail }}</td>
          <td>{{ homeLeagueJF.score }}/{{ homeLeagueJF.loss }}</td>
          <td>{{ homeLeagueJF.clean }}</td>
          <td>{{ homeLeagueJF.order }}</td>
        </tr>
        <tr class="table_content" v-if="guestLeagueJF" align="center">
          <td class="table-content-logo">
            <AvatarCmt
              :nametitle="matchInfo.AwayName"
              :teamId="matchInfo.AwayID"
              :sportId="sportType"
              :dividerShow="false"
            />
          </td>
          <td>{{ guestLeagueJF.scheNum }}</td>
          <td>{{ guestLeagueJF.win }}/{{ guestLeagueJF.stand }}/{{ analylist.leagueJF?.guest.total.fail }}</td>
          <td>{{ guestLeagueJF.score }}/{{ guestLeagueJF.loss }}</td>
          <td>{{ guestLeagueJF.clean }}</td>
          <td>{{ guestLeagueJF.order }}</td>
        </tr>
      </table>
      <Empty
        v-if="isEmpty(homeLeagueJF) && isEmpty(guestLeagueJF)"
        class="empty-box"
        :description="t('lang.sport_common_noRank')"
      >
        <template #image>
          <svg-icon class="revenge" name="no_data" />
        </template>
      </Empty>
    </div>
    <div class="divide"></div>
    <div class="content_one">
      <h4>
        <prefix-text>{{ t('lang.sport_details_goalDistribution') }}</prefix-text>
      </h4>
      <div class="comme_box" v-if="!isEmpty(analylist.goalDist)">
        <div class="comme_top">
          <AvatarCmt :nametitle="matchInfo.HomeName" :teamId="matchInfo.HomeID" :sportId="sportType" />
          <AvatarCmt :nametitle="matchInfo.AwayName" :teamId="matchInfo.AwayID" :sportId="sportType" :flexRver="true" />
        </div>
        <ScoreCmt :goalDist="analylist.goalDist" />
      </div>
      <Empty class="empty-box" v-else :description="t('lang.sport_common_noFormation')">
        <template #image>
          <svg-icon class="revenge" name="no_data" />
        </template>
      </Empty>
    </div>
    <div class="divide"></div>
    <div class="content_one">
      <h4>
        <prefix-text>{{ t('lang.sport_details_historicalConfrontation') }}</prefix-text>
      </h4>
      <div class="comme_box" v-if="!isEmpty(analylist.hisMatch)">
        <div class="comme_top">
          <AvatarCmt
            :nametitle="matchInfo.HomeName"
            :teamId="matchInfo.HomeID"
            :sportId="sportType"
            :dividerShow="false"
          />
          <span class="comme_box-tongji">
            {{ t('lang.sport_details_recent') }}{{ sumArr(scheduleInfo) }}{{ t('lang.sport_details_screenings')
            }}{{ scheduleInfo[0] }}{{ t('lang.sport_details_win') }}{{ scheduleInfo[2]
            }}{{ t('lang.sport_details_lost') }}{{ scheduleInfo[1] }}{{ t('lang.sport_details_draw') }}
          </span>
        </div>
        <div v-for="(item, index) in analylist.hisMatch.scheduleInfo" :key="index">
          <div class="comme_content" v-if="index <= 4">
            <div class="lishi_content">
              <span class="time_text">{{ item.match_time }}</span>
              <div class="core_box">
                <div class="div_text left">
                  <span class="team_name">{{ item.home_team }}</span>
                  <team-logo :sportId="sportType" :teamId="item.home_team_id" size="xxl" :teamName="item.home_team" />
                </div>
                <div class="core_content">
                  <span>{{ item.home_score }}:{{ item.guest_score }}</span>
                </div>
                <div class="div_text">
                  <team-logo
                    margin-left
                    :sportId="sportType"
                    size="xxl"
                    :teamId="item.guest_team_id"
                    :teamName="item.guest_team"
                  />
                  <span class="team_name">{{ item.guest_team }}</span>
                </div>
              </div>
              <span :class="['span_x', filterscore(homeTeamName, item.home_team, item.guest_team, item).color]">
                <!-- {{item.guest_score > item.home_score ? '赢' : item.guest_score == item.home_score ? '平' : '输'}} -->
                {{ filterscore(homeTeamName, item.home_team, item.guest_team, item).Str }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Empty class="empty-box" v-else :description="t('lang.sport_common_cross')">
        <template #image>
          <svg-icon class="revenge" name="no_score" />
        </template>
      </Empty>
    </div>
    <div class="divide"></div>
    <div class="content_one">
      <h4>
        <prefix-text
          >{{ t('lang.sport_details_battleRecords') }}
          <small>({{ t('lang.sport_details_recent5') }})</small></prefix-text
        >
      </h4>
      <div class="comme_box" v-if="IsAnalylist">
        <div class="comme_top">
          <AvatarCmt :nametitle="matchInfo.HomeName" :teamId="matchInfo.HomeID" :sportId="sportType" />
          <AvatarCmt :nametitle="matchInfo.AwayName" :teamId="matchInfo.AwayID" :sportId="sportType" :flexRver="true" />
        </div>
        <div class="duizhan_content">
          <div class="Echt_box">
            <span class="title">
              {{ homeScheduleInfo[0] }}{{ t('lang.sport_details_win') }}{{ homeScheduleInfo[1]
              }}{{ t('lang.sport_details_lost') }}{{ homeScheduleInfo[2] }}{{ t('lang.sport_details_draw') }}
            </span>
            <p class="result">
              <span
                :class="[
                  its == t('lang.sport_details_win') ? 'red' : its == t('lang.sport_details_lost') ? 'green' : 'hui'
                ]"
                v-for="(its, idx) in HomeText"
                :key="idx"
                >{{ its }}</span
              >
            </p>
            <EchartCmt id="left" :arrlist="homeScheduleInfo" />
          </div>
          <div class="Echt_box">
            <span class="title"
              >{{ guestScheduleInfo[0] }}{{ t('lang.sport_details_win') }}{{ guestScheduleInfo[1]
              }}{{ t('lang.sport_details_lost') }}{{ guestScheduleInfo[2] }}{{ t('lang.sport_details_draw') }}</span
            >
            <p class="result">
              <span
                :class="[
                  its == t('lang.sport_details_win') ? 'red' : its == t('lang.sport_details_lost') ? 'green' : 'hui'
                ]"
                v-for="(its, idx) in guestText"
                :key="idx"
                >{{ its }}</span
              >
            </p>
            <EchartCmt id="right" :arrlist="guestScheduleInfo" />
          </div>
        </div>
        <div>
          <div class="header">{{ t('lang.sport_details_battleDetails') }}</div>
          <div class="comme_box" v-if="IsAnalylist">
            <div class="comme_top">
              <AvatarCmt
                :nametitle="matchInfo.HomeName"
                :teamId="matchInfo.HomeID"
                :sportId="sportType"
                :dividerShow="false"
              />
            </div>
            <div
              class="comme_content"
              v-for="(item, index) in analylist.lastMatch.homeScheduleInfo"
              :key="index + '_home'"
            >
              <div class="lishi_content" v-if="index < 5">
                <span class="time_text">{{ item.match_time }}</span>
                <div class="core_box">
                  <div class="div_text left">
                    <span class="team_name">{{ item.home_team }}</span>
                    <team-logo :sportId="sportType" :teamId="item.home_team_id" size="xxl" :teamName="item.home_team" />
                  </div>
                  <div class="core_content">
                    <span>{{ item.home_score }}:{{ item.guest_score }}</span>
                  </div>
                  <div class="div_text">
                    <team-logo
                      :sportId="sportType"
                      :teamId="item.guest_team_id"
                      size="xxl"
                      margin-left
                      :teamName="item.guest_team"
                    />
                    <span class="team_name">{{ item.guest_team }}</span>
                  </div>
                </div>
                <span :class="['span_x', filterscore(homeTeamName, item.home_team, item.guest_team, item).color]">
                  <!-- {{item.guest_score > item.home_score ? '赢' : item.guest_score == item.home_score ? '平' : '输'}} -->
                  {{ filterscore(homeTeamName, item.home_team, item.guest_team, item).Str }}
                </span>
              </div>
            </div>
          </div>
          <div class="comme_box" v-if="IsAnalylist">
            <div class="comme_top">
              <AvatarCmt
                :nametitle="matchInfo.AwayName"
                :teamId="matchInfo.AwayID"
                :sportId="sportType"
                :dividerShow="false"
              />
            </div>
            <div
              class="comme_content"
              v-for="(item, index) in analylist.lastMatch.guestScheduleInfo"
              :key="index + '_away'"
            >
              <div class="lishi_content" v-if="index < 5">
                <span class="time_text">{{ item.match_time }}</span>
                <div class="core_box">
                  <div class="div_text left">
                    <span class="team_name">{{ item.guest_team }}</span>
                    <team-logo
                      :sportId="sportType"
                      :teamId="item.guest_team_id"
                      size="xxl"
                      :teamName="item.guest_team"
                    />
                  </div>
                  <div class="core_content">
                    <span>{{ item.guest_score }}:{{ item.home_score }}</span>
                  </div>

                  <div class="div_text">
                    <team-logo
                      margin-left
                      :sportId="sportType"
                      :teamId="item.home_team_id"
                      size="xxl"
                      :teamName="item.home_team"
                    />
                    <span class="team_name">{{ item.home_team }}</span>
                  </div>
                </div>
                <span :class="['span_x', filterscore(guestTeamName, item.home_team, item.guest_team, item).color]">
                  <!-- {{ item.guest_score > item.home_score ? '赢' : item.guest_score == item.home_score ? '平' : '输' }} -->
                  {{ filterscore(guestTeamName, item.home_team, item.guest_team, item).Str }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Empty class="empty-box" v-else :description="t('lang.sport_common_reconciliation')">
        <template #image>
          <svg-icon class="revenge" name="no_score" />
        </template>
      </Empty>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { Empty } from 'ant-design-vue';
import TeamLogo from 'kg-web/components/team-logo/index';
import AvatarCmt from './smallCMT/AvatarCmt.vue';
import ScoreCmt from './smallCMT/ScoreCmt.vue';
import EchartCmt from './smallCMT/EchartCmt.vue';
import PrefixText from './prefix-text';
import { useRangeData } from '@/hooks';

export default defineComponent({
  name: 'JBBsportRangeData',
  components: {
    TeamLogo,
    AvatarCmt,
    ScoreCmt,
    EchartCmt,
    PrefixText,
    Empty
  },
  props: {
    analylist: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const res = useRangeData(props);
    const { t } = useI18n();
    return { ...res, t };
  }
});
</script>

<style lang="less" scoped>
@text-color: #000;
@border-color: #f2f2f2;
.Score_box {
  margin: 10px;
}

.red {
  background: #b52520;
}

.green {
  background: #7bd3b1;
}

.hui {
  background: #636363;
}

.redgrund {
  background: #b52520;
}

.greengrund {
  background: #7bd3b1;
}

.huigrund {
  background: #636363;
}
.header {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 16px 0;
  color: #cecece;
}
.fnexi_box {
  padding-left: 8px;
  color: #fff;
  .top_table {
    border-radius: 20px;
    margin-top: 5px;
  }

  .content_one {
    width: 100%;
    margin-top: 10px;
    border-radius: 20px;

    h4 {
      margin: 0;
      padding: 12px 16px;
      margin-bottom: 12px;

      > small {
        font-size: 16px;
        color: #cecece;
      }
    }
  }

  .duizhan_content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .Echt_box {
      display: flex;
      height: 270x;
      width: 180px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 5px;
      background: #404040;
      .title {
        margin: 15px 0 8px 0;
        font-size: 12px;
        color: #cecece;
      }

      .result {
        width: 100%;
        box-sizing: border-box;
        padding: 0 13px;
        display: flex;
        justify-content: space-between;
      }
    }

    p {
      margin: 0px;
      margin-bottom: 35px;

      span {
        line-height: 20px;
        text-align: center;
        box-sizing: border-box;
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        width: 20px;
        height: 20px;
        border-radius: 16px 16px 16px 16px;
      }
    }

    .echarts-box {
      width: 45%;
      height: 220px;
      background: #ededed;
      border-radius: 20px;
    }
  }

  .comme_box {
    margin-bottom: 10px;
    padding-bottom: 10px;
    .comme_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;

      &-tongji {
        color: @text-color;
      }
    }

    .comme_content {
      display: flex;
      justify-content: center;

      .lishi_content {
        position: relative;
        width: 100%;
        height: 100px;
        background: #404040;
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .time_text {
          margin-bottom: 8px;
          color: #cecece;
        }

        .core_box {
          display: flex;
          width: 90%;
          justify-content: center;
          align-items: center;
          height: 40px;

          .core_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 0 5px;

            span {
              font-size: 16px;
              font-weight: 600;
            }
          }

          .div_text {
            height: 50px;
            display: flex;
            flex: 1;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            color: #fff;
            .team_name {
              font-size: 12px;
              margin: 0 4px;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.left {
              justify-content: flex-end;
            }
          }
        }

        .span_x {
          position: absolute;
          right: 10px;
          top: 10px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          transform: scale(0.85);
          color: white;
          font-size: 12px;
        }
      }
    }
  }

  .Empty_box {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #626262;
    font-size: 14px;
  }

  .empty-box {
    width: 100%;
    height: 150px;

    .svg-icon {
      width: 100%;
      height: 60px;
    }

    .revenge {
      transform: rotate(180deg);
    }
  }
}

table,
tr,
th,
td {
  padding: 10px 2px;
}

table {
  border-collapse: collapse;
}

.frist-color {
  padding-left: 10px;
  font-size: 14px;
}

.last-color {
  color: #cecece;
  font-weight: 400;
}

.table_content td {
  vertical-align: middle;
}

.table_content td:not(:first-child) {
  color: #fff;
}

.table_content td:nth-child(1) {
  font-weight: 550;
}
.table-content-logo {
  padding-left: 16px;
  text-align: left;
}

.divide {
  height: 1px;
  background: #404040;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
</style>
