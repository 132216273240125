import { onMounted, PropType, defineComponent, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { get } from 'lodash-es';
import { useStore } from 'vuex';
import { useMarketsBySport, useFavortMatch, useI18n } from '@/hooks';
import { Collapse, CollapsePanel, FavoritStar } from 'kg-web/components';
import { DataTypeEnum, getSportNameById } from '@/common';
import { Skeleton } from 'ant-design-vue';

import { SportIcon } from '@/components';

import style from './sidebar-sports.module.less';

export default defineComponent({
  name: 'WebSidebarSport',
  props: {
    matchInfo: {
      type: Object as PropType<any>,
      default: () => ({
        score: [0, 0]
      })
    }
  },
  setup() {
    const { t } = useI18n();
    const { eventStore, getSportsSideGames } = useMarketsBySport();
    const route = useRoute();
    const router = useRouter();
    const vuexStore = useStore();
    const { mark } = useFavortMatch();

    onMounted(async () => {
      if (!route.query.sportId) return;
      eventStore.form = Object.assign(eventStore.form, route.query);
      await getSportsSideGames();
    });

    const handleClick = (data: any) => {
      const id = get(data, 'id');
      id &&
        router.replace({
          name: route.name as any,
          query: Object.assign({}, route.query, { matchId: id })
        });
    };
    const favorit = ref({});

    const onMark = (data, match) => {
      favorit.value[`${match.id}_${match.sportId}`] = data;
      mark({ matchId: match.id, sportId: match.sportId });
    };
    const getMark = data => {
      return favorit.value[`${data.id}_${data.sportId}`] === undefined
        ? data.isMark
        : favorit.value[`${data.id}_${data.sportId}`];
    };
    const favoritMatch = computed(() => {
      return eventStore.marketList
        .map(item => {
          return {
            ...item,
            matches: item.matches.filter(item => getMark(item))
          };
        })
        .filter(item => item.matches.length);
    });

    const isVideo = data => {
      return data.id ? vuexStore.getters?.getVideoByMid(data.id) : '';
    };
    return () => (
      <Skeleton loading={eventStore.loading} paragraph={{ rows: 8 }}>
        <div class={style.sidebarSports}>
          {favoritMatch.value.length ? (
            <div class={[style.sidebarSports__header, style.sidebarSports__header__favorit]}>
              <FavoritStar isMarked={true} />
              <h2 class={style.sportStyle}>
                {/* 收藏 */}
                {t('lang.sport_common_collect')}
              </h2>
            </div>
          ) : null}
          {favoritMatch.value.map(tournament => (
            <Collapse expandAll={false} key={tournament.key} arrow={false} type="">
              <CollapsePanel
                v-slots={{
                  header: () => <div>{tournament.leagueName}</div>
                }}
                expand={!!tournament.matches.find(e => e.id == route.query.matchId)}
              >
                {tournament.matches.map(match => (
                  <div key={match.id} class={[style.row, style.ipnFixture]}>
                    <div
                      onClick={() => handleClick(match)}
                      class={[style.ipnFixture_Wrapper, route.query.matchId == match.id ? style.active : '']}
                    >
                      <div class={style.ipnFixture_GameProgress}>
                        <div class={style.ipnFixture_TimerContainer}>{match.time}</div>
                      </div>
                      <div class={style.ipnFixture_TeamStack}>
                        <div class={style.ipnFixture_TeamContainer}>
                          <div class={style.ipnFixture_Team}>{match.home}</div>
                        </div>
                        <div class={style.ipnFixture_TeamContainer}>
                          <div class={style.ipnFixture_Team}>{match.away}</div>
                        </div>
                      </div>
                      {isVideo(match) ? (
                        <div
                          class={[
                            style.ipnFixture_Video,
                            Number(route.query.periodId) === DataTypeEnum.Live ? style.ipnFixture_Video_live : ''
                          ]}
                        >
                          <svg-icon name="event_video" />
                        </div>
                      ) : null}
                      <div class={style.ipnFixture_ScoresDefault}>
                        <div class={style.ipnFixture_ScoresDefaultScore}>
                          <div class={style.ipnFixture_ScoresDefault}>{get(match, 'score[0]')}</div>
                          <div class={style.ipnFixture_ScoresDefault}>{get(match, 'score[1]')}</div>
                        </div>
                      </div>
                      <div class={style.ipnFixture_FavouriteButton}>
                        <FavoritStar isMarked={getMark(match)} onMark={data => onMark(data, match)} />
                      </div>
                    </div>
                  </div>
                ))}
              </CollapsePanel>
            </Collapse>
          ))}
          <div class={style.sidebarSports__header}>
            <SportIcon class={style.sidebarSports__headerLogo} sportId={Number(route.query.sportId)} />
            <h2 class={style.sportStyle}>{getSportNameById(Number(route.query.sportId))}</h2>
          </div>
          {eventStore.marketList.map(tournament => (
            <Collapse expandAll={false} key={tournament.key} arrow={false} type="">
              <CollapsePanel
                v-slots={{
                  header: () => <div>{tournament.leagueName}</div>
                }}
                expand={!!tournament.matches.find(e => e.id == route.query.matchId)}
              >
                {tournament.matches.map(match => (
                  <div key={match.id} class={[style.row, style.ipnFixture]}>
                    <div
                      onClick={() => handleClick(match)}
                      class={[style.ipnFixture_Wrapper, route.query.matchId == match.id ? style.active : '']}
                    >
                      <div class={style.ipnFixture_GameProgress}>
                        <div class={style.ipnFixture_TimerContainer}>
                          {t(match.period)} {t(match.time)}
                        </div>
                      </div>
                      <div class={style.ipnFixture_TeamStack}>
                        <div class={style.ipnFixture_TeamContainer}>
                          <div class={style.ipnFixture_Team}>{match.home}</div>
                        </div>
                        <div class={style.ipnFixture_TeamContainer}>
                          <div class={style.ipnFixture_Team}>{match.away}</div>
                        </div>
                      </div>
                      {isVideo(match) ? (
                        <div
                          class={[
                            style.ipnFixture_Video,
                            Number(route.query.periodId) === DataTypeEnum.Live ? style.ipnFixture_Video_live : ''
                          ]}
                        >
                          <svg-icon name="event_video" />
                        </div>
                      ) : null}
                      <div class={style.ipnFixture_ScoresDefault}>
                        <div class={style.ipnFixture_ScoresDefaultScore}>
                          <div class={style.ipnFixture_ScoresDefault}>{get(match, 'score[0]')}</div>
                          <div class={style.ipnFixture_ScoresDefault}>{get(match, 'score[1]')}</div>
                        </div>
                      </div>
                      <div class={style.ipnFixture_FavouriteButton}>
                        <FavoritStar isMarked={getMark(match)} onMark={data => onMark(data, match)} />
                      </div>
                    </div>
                  </div>
                ))}
              </CollapsePanel>
            </Collapse>
          ))}
        </div>
      </Skeleton>
    );
  }
});
