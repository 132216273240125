import { defineComponent } from 'vue';
import { SelChamp } from '@/services/kg-api';
import { _keyNameByBettype } from '@/common';
import { hasTicketId } from '@/hooks/useBetWeb.hook';
import style from './oddschamp.module.less';

export default defineComponent({
  name: 'ChampSummaryOdds',
  props: {
    oddset: {
      type: Object,
      default: () => {}
    },
    matchId: {
      type: String,
      required: true
    },
    gameStatus: {
      type: String,
      required: true
    },
    sportId: {
      type: Number
    }
  },
  emits: ['addBetTicket'],
  setup(props, { emit }) {
    return () => (
      <div class={style.oddsSwiper}>
        <div class={style.oddsSwiperContainer}>
          {props.oddset.sels.map((sel: SelChamp, i) => {
            return (
              <>
                {!sel ? (
                  <div class={style.col1}></div>
                ) : (
                  <div
                    class={[
                      style.col1,
                      hasTicketId({
                        matchId: props.matchId,
                        marketId: props.oddset.OddsId,
                        wagerSelectionId: sel.WagerSelectionId
                      })
                        ? style.selectedItem
                        : null
                    ]}
                    key={i}
                  >
                    <div
                      class={style.colChamp}
                      onClick={() => {
                        emit('addBetTicket', {
                          betType: props.oddset.Bettype,
                          betTypeName: props.oddset.BettypeName,
                          matchId: props.matchId,
                          sportType: props.sportId,
                          marketId: props.oddset.OddsId,
                          periodId: props.oddset.PeriodId,
                          point: sel.Point,
                          keyName: sel.KeyName,
                          outrightTeamId: 0,
                          price: sel.Price,
                          EuropePrice: sel.EuropePrice,
                          wagerSelectionId: sel.WagerSelectionId,
                          BetTypeSelectionId: 0,
                          key: sel.Key,
                          Combo: props.oddset.Combo
                        });
                      }}
                    >
                      <span>
                        <span class={style.oddsKeyname}>{sel.KeyName}</span>
                        <span class={style.oddsPrice}> {sel?.Price} </span>
                      </span>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  }
});
