import { useI18n } from '@/hooks';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Course',
  setup() {
    const { t } = useI18n();
    return () => (
      <div class="course-page" style="font-size: 100px">
        {/* 投注教程 */}
        {t('lang.sport_common_betCourse')}
      </div>
    );
  }
});
